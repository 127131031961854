import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@material-ui/core"
import { WarningOutlined } from "@material-ui/icons"

export default function DialogCategorias({
    open,
    handleModalAlteracao,
    quantidade,
    acrescentar,
    decrementar,
    setNovaAtividade,
    novaAtividade,
    ativar,
    desativar,
    checkedItems,
}) {
    return (
        <>
            {open && (
                <Dialog
                    open={open}
                    onClose={handleModalAlteracao}
                    style={{ maxWidth: "500px", margin: "auto" }}
                >
                    <DialogTitle
                        style={{
                            textAlign: "center",
                        }}
                    >
                        <Typography variant="h4">Aviso de Alteração</Typography>
                        <WarningOutlined style={{ fontSize: "10rem" }} />
                    </DialogTitle>
                    <DialogContent>
                        <Typography>
                            Você está prestes a alterar{" "}
                            <span style={{ fontSize: "30px", color: "red" }}>{quantidade}</span>{" "}
                            itens. Tem certeza de que deseja continuar com esta ação?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            style={{ backgroundColor: "#dc3545", color: "white" }}
                            onClick={() => {
                                handleModalAlteracao()
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            style={{ backgroundColor: "#28a745", color: "white" }}
                            onClick={() => {
                                switch (novaAtividade) {
                                    case "acrescentar":
                                        acrescentar()
                                        break
                                    case "decrementar":
                                        decrementar()
                                        break
                                    case "desativar":
                                        desativar(checkedItems)
                                        break
                                    case "ativar":
                                        ativar(checkedItems)
                                        break
                                    default:
                                        break
                                }
                                handleModalAlteracao()
                                setNovaAtividade(null)
                            }}
                        >
                            Confirmar
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    )
}
