//INIT
import React, { useEffect } from "react"

//IMPORTS
import { useHistory } from "react-router-dom"

import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import img from "../../assets/img/image_sample_bw.png"
const qz = require("qz-tray")

export default function Teste() {
    const theme = useTheme()
    const history = useHistory()

    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))
    const dayName = ["domingo", "segunda", "terça", "quarta", "quinta", "sexta", "sábado"]
    const monName = [
        "janeiro",
        "fevereiro",
        "março",
        "abril",
        "maio",
        "junho",
        "agosto",
        "outubro",
        "novembro",
        "dezembro",
    ]
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    )
    const aplicativoDados = JSON.parse(localStorage.getItem("aplicativoCF"))
    const autorizacao = localStorage.getItem("chaveSistema")

    const [impressorasQZ, setImpressorasQZ] = React.useState([])

    useEffect(() => {
        verificarSeTemQZ()
    }, [])

    async function imprimirQz(texto, qtd, impressora = "") {
        console.log("imprimirQz", texto)
        //console.log('imprimirQz')
        if (qz.websocket.isActive()) {
            if (!impressora || impressora == "vazio") {
                impressora = await qz.printers.getDefault()
            }

            console.log("impressora()", impressora, texto, qtd)
            return impressao(impressora, texto, qtd)
        }

        qz.websocket
            .connect()
            .then(() => {
                //console.log('Conectado QZ!');
                // return qz.printers.find(); //array de impressoras
                if (!impressora) {
                    impressora = qz.printers.getDefault()
                }

                const print = impressora //impressora padrao
                localStorage.setItem("impressora", JSON.stringify(print))
                return print
            })
            .then(printer => {
                console.log("impressora()", printer, texto, qtd)

                return impressao(printer, texto, qtd)
            })
            .then(() => {})
            .catch(err => {
                console.error("erro", err)
            })
    }

    const impressao = async () => {
        const printer = await qz.printers.getDefault()
        // var config = qz.configs.create(printer, { encoding: 'ISO-8859-1' });
        var config = qz.configs.create(printer, {
            bounds: null,
            colorType: "color",
            copies: 1,
            density: 0,
            duplex: false,
            fallbackDensity: null,
            interpolation: "bicubic",
            jobName: null,
            legacy: false,
            margins: 0,
            orientation: null,
            paperThickness: null,
            printerTray: null,
            rasterize: false,
            rotation: 0,
            scaleContent: true,
            size: null,
            units: "in",
            altPrinting: false,
            encoding: null,
            spool: {},
        })
        var data = [
            {
                type: "raw",
                format: "image",
                data: img,
                options: {
                    language: "ESCPOS",
                    x: "0",
                    y: "0",
                    dotDensity: "double",
                    xmlTag: "v7:Image",
                    pageWidth: "480",
                    pageHeight: "",
                },
            },
        ]

        qz.print(config, data).catch(function (e) {
            console.error(e)
        })
    }

    const verificarSeTemQZ = () => {
        if (qz.websocket.isActive()) {
            buscarImpressoras()
        } else {
            qz.websocket
                .connect()
                .then(() => {
                    qz.printers
                        .details()
                        .then(function (data) {
                            for (var i = 0; i < data.length; i++) {
                                console.log(
                                    data[i].name +
                                        " " +
                                        data[i].driver +
                                        " " +
                                        data[i].density +
                                        " " +
                                        data[i].trays
                                )
                            }
                        })
                        .catch(function (e) {
                            console.error(e)
                        })

                    buscarImpressoras()
                })
                .catch(err => {})
        }
    }

    const buscarImpressoras = async () => {
        const printers = await qz.printers.find()
        setImpressorasQZ(printers)
    }

    return (
        <div>
            <button onClick={() => impressao()}>IMPRIMIR</button>
        </div>
    )
}
