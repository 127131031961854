import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Divider from "@material-ui/core/Divider"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import Snackbar from "@material-ui/core/Snackbar"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import CancelIcon from "@material-ui/icons/Cancel"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled"
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled"
import { default as AlertFixo, default as MuiAlert } from "@material-ui/lab/Alert"
import { useSnackbar } from "notistack"
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import Package from "../../../package.json"
import desconected from "../../assets/desconected.jpg"
import { RecebedorContextProvider } from "../../contexts/RecebedorContext"
import ServicosContext from "../../contexts/servicosContext"
import { procurarPedido, verificarPrintQRCode } from "../../pages/RecebedorDePedidos/functions"
import api from "../../services/api"
import { RegistrarLogErro } from "../../services/clientefiel"
import { AlertasPainel } from "../../services/fornecedor"
import {
    atualizarStatus,
    atualizarTempoEntrega,
    buscarestabelecimentos,
    controleAcessoAtualizar,
    controleAcessoVerificar,
    getNotaFiscal,
    ObterStatusLojaIfood,
    ResumoDia,
} from "../../services/integracao"
import Cabecalho from "../Cabecalho"
import Loading from "../Componentes/Loading"
import RingAudio from "./audios/ring.mp3"
import BotoesUtilitarios from "./components/BotoesUtilitarios"
import DialogCancelamento from "./components/DialogCancelamento"
import DialogInformacoesPedido from "./components/DialogInformacoesPedido"
import Transition from "./components/Transition"
import DialogAlertas from "./DialogAlertas"
import ListaIntegracoes from "./Integracoes/ListaIntegracoes"
import Print from "./print"
import { imprimirCode, imprimirHTML, imprimirNotaGeral } from "./qzConfig"
import "./styles.css"
import Lista from "./tabelas/Lista"
const qz = require("qz-tray")

const useStyles = makeStyles(theme => ({
    fixedRegistrador: {
        position: "fixed",
        left: "2em",
        bottom: "2em",
        zIndex: 1000,
    },
    fixedStatus: {
        position: "fixed",
        left: "2em",
        bottom: "7em",
        zIndex: 1000,
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    rootAlert: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 999999,
        color: "#fff",
    },
    pos: {
        marginBottom: 12,
    },
    rootList: {
        width: "100%!important",
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: "inline",
        width: "100%",
    },
    adicionarImpressora: { height: "100%", width: "100%", padding: "15px 14px" },
    impressaoComandaCompleta: {
        width: "100%",
        margin: "0",
        display: "flex",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
        "& > *": { flex: 1 },
    },
}))

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

function getImpressoras(configuracoes) {
    if (configuracoes.printer.impressoras?.length) return configuracoes.printer.impressoras

    const impressoras = []

    if (configuracoes.printer.impressora) impressoras.push(configuracoes.printer.impressora)
    if (configuracoes.printer.impressora2) impressoras.push(configuracoes.printer.impressora2)

    return impressoras
}

const LIMITE_NUMERO_IMPRESSORAS = 10

function ListaImpressorasSelecionadas({
    mobile,
    tabStatus,
    tipoPainelImpressao,
    impressorasQZ,
    modalConfiguracoes,
}) {
    const classes = useStyles()

    const { atualizarConfiguracoes, configuracoes } = useContext(ServicosContext)

    const [listaImpressoras, setListaImpressoras] = useState(getImpressoras(configuracoes))
    const [isImprimirComandaCompleta, setIsImprimirComandaCompleta] = useState(
        configuracoes?.printer?.imprimirComandaCompleta ?? false
    )
    const [impressoraComandaCompleta, setImpressoraComandaCompleta] = useState(
        configuracoes.printer.impressoraComandaCompleta ?? "Nenhuma"
    )

    const handleChangeImpressora = (e, i) => {
        listaImpressoras[i] = e.target.value

        const impressorasFiltradas = listaImpressoras.filter(
            i => i && impressorasQZ.find(nomeImpressora => nomeImpressora === i)
        )

        setListaImpressoras(impressorasFiltradas)

        atualizarConfiguracoes({
            ...configuracoes,
            printer: {
                ...configuracoes.printer,
                impressoras: impressorasFiltradas,
                imprimirComandaCompleta: isImprimirComandaCompleta,
                impressoraComandaCompleta:
                    impressoraComandaCompleta !== "Nenhuma" ? impressoraComandaCompleta : null,
            },
        })
    }

    const handleChangeImpressaoIfood = e => {
        configuracoes.printer.imprimirComandasIfood = e.target.checked
        atualizarConfiguracoes(configuracoes)
    }

    if (
        tipoPainelImpressao !== "plugin" ||
        mobile ||
        tabStatus !== "CRUD" ||
        tipoPainelImpressao !== "plugin"
    )
        return null

    const adicionarImpressora = () => setListaImpressoras(prev => [...prev, "Nenhuma"])

    const integradoComIfood = configuracoes.user?.parametros?.ativaIntegracaoIfood

    return (
        <>
            {listaImpressoras?.map((impressora, i) => (
                <Col xs={12} md={6} lg={6} style={{ margin: "0.5em 0" }}>
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                        <InputLabel id="impressoras">{`Imprimir ${i + 1}º Opção`}</InputLabel>
                        <Select
                            fullWidth
                            value={impressora}
                            onChange={e => handleChangeImpressora(e, i)}
                            label={`Imprimir ${i + 1}º Opção`}
                        >
                            <MenuItem value="Nenhuma">Nenhuma</MenuItem>
                            {impressorasQZ.map((printer, index) => (
                                <MenuItem key={index} value={printer}>
                                    {printer}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Col>
            ))}
            {listaImpressoras.length < LIMITE_NUMERO_IMPRESSORAS ? (
                <Col xs={12} md={6} lg={6} style={{ margin: "0.5em 0" }}>
                    <Button
                        variant="outlined"
                        onClick={adicionarImpressora}
                        className={classes.adicionarImpressora}
                    >
                        Adicionar Impressora
                    </Button>
                </Col>
            ) : null}
            {configuracoes?.user?.parametros?.impressaoAlternada ? (
                <>
                    <Row className={classes.impressaoComandaCompleta}>
                        <Col xs={12} md={6} lg={6} style={{ margin: "0" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isImprimirComandaCompleta}
                                        onChange={e =>
                                            setIsImprimirComandaCompleta(e.target.checked)
                                        }
                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    />
                                }
                                label="Imprimir comanda completa"
                            />
                        </Col>
                        {isImprimirComandaCompleta ? (
                            <Col xs={12} md={6} lg={6} style={{ margin: "0.5em 0" }}>
                                <FormControl variant="outlined" style={{ width: "100%" }}>
                                    <InputLabel id="impressoras">
                                        Impr. p/ comanda completa
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        value={impressoraComandaCompleta}
                                        onChange={e => setImpressoraComandaCompleta(e.target.value)}
                                        label="Impr. p/ comanda completa"
                                    >
                                        <MenuItem value="Nenhuma">Nenhuma</MenuItem>
                                        {impressorasQZ.map((printer, index) => (
                                            <MenuItem key={index} value={printer}>
                                                {printer}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Col>
                        ) : null}
                    </Row>
                </>
            ) : null}
            {integradoComIfood ? (
                <Col xs={12} md={6} lg={6} style={{ margin: "0" }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={configuracoes.printer.imprimirComandasIfood ?? true}
                                onChange={handleChangeImpressaoIfood}
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                            />
                        }
                        label="Imprimir comandas do iFood"
                    />
                </Col>
            ) : null}
        </>
    )
}

export default function RecebedorDePedidos() {
    const theme = useTheme()
    const history = useHistory()
    const classes = useStyles()

    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    )
    const aplicativoDados = JSON.parse(localStorage.getItem("aplicativoCF"))
    const autorizacao = localStorage.getItem("chaveSistema")

    const { handleRegistradorDePedidos, atualizarConfiguracoes, configuracoes } =
        useContext(ServicosContext)

    const [valorEntregasFuiApp, setValorEntregasFuiApp] = useState(false)
    const [modalChamarEntregadorFui, setModalChamarEntregadorFui] = useState(false)
    const [formaPagamentoSelecionadaFui, setFormaPagamentoSelecionadaFui] = useState({})

    const [openConfirmacaoPedidosRepetidosFui, setOpenConfirmacaoPedidosRepetidosFui] =
        useState(false)
    const [
        messageOpenConfirmacaoPedidosRepetidosFui,
        setMessageOpenConfirmacaoPedidosRepetidosFui,
    ] = useState("")
    const [pedidosEntregaFuiApp, setPedidosEntregaFuiApp] = useState([])
    const [statusLojaIfood, setStatusLojaIfood] = useState({})
    const [filaPrints, setFilaPrints] = useState([])
    const [mudancaConfiguracao, setMudancaConfiguracao] = useState(false)
    const [contratar, setContratar] = useState(false)
    const [registradorModal, setRegistradorModal] = useState(false)
    const [criarRegistradorModal, setCriarRegistradorModal] = useState(false)
    const [tabStatus, setTabStatus] = useState(
        mobile || localStorage.getItem("tabStatus") === "VIEW" ? "VIEW" : "CRUD"
    )

    useEffect(() => {
        localStorage.setItem("tabStatus", tabStatus)
    }, [tabStatus])

    const [impressorasQZ, setImpressorasQZ] = useState([])
    const [modalEntregadorFuiDelivery, setModalEntregadorFuiDelivery] = useState({
        openModal: false,
    })
    const busqueiTodosPedidos = useRef(false)
    const [modalInformacoesPedido, setModalInformacoesPedido] = useState({})
    const [arrayTodosPedidos, setArrayTodosPedidos] = useState([])
    const [arraySegundo, setArraySegundo] = useState([])
    const [confirmarCancelamento, setConfirmarCancelamento] = useState(null)

    const [pedidosAguardandoAceitacao, setPedidosAguardandoAceitacao] = useState([])
    const [pedidosAceitos, setPedidosAceitos] = useState([])
    const [pedidosEmPreparacao, setPedidosEmPreparacao] = useState([])
    const [pedidosEmEntrega, setPedidosEmEntrega] = useState([])
    const [pedidosFinalizados, setPedidosFinalizados] = useState([])

    const [loadingPedidos, setLoadingPedidos] = useState(true)

    const [expandListaIntegracoes, setExpandListaIntegracoes] = useState(false)

    const { user, printer, recebedor } = configuracoes

    const tamanhoLista = recebedor.prepararPedidoAutomatico ? 4 : 3
    console.log("-> Recebedor Configuração ", recebedor)

    const tipoPainelImpressao =
        impressorasQZ.length > 0
            ? "plugin" //qz
            : window.navigator.userAgent.includes("Electron")
            ? "software" //exe
            : "navegador" //padrao do navegador

    const { parametros } = user

    const [modalIfoodConfig, setModalIfoodConfig] = useState(false)
    const [modalConfiguracoes, setModalConfiguracoes] = useState(false)
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState({ status: false, tipo: "success", mesangem: "" })

    const [estabelecimentos, setEstabelecimentos] = useState([])
    const [openEstabelecimentos, setOpenEstabelecimentos] = useState(false)

    const {
        hashInternaClienteFielIfood,
        ativaIntegracaoIfood,
        listaMotivoCancelamento = {},
    } = parametros

    const tipoPrinter =
        tipoPainelImpressao === "plugin"
            ? printer.tipoImpressao
                ? printer.tipoImpressao
                : "code"
            : ""
    const tamanhoFonteSelect = printer.tamanhoFonteSelect ? printer.tamanhoFonteSelect : "media"

    const exibirTamanhoFonte = tipoPrinter === "html"
    const exibirImprimirLogo = tipoPrinter === "code"
    const exibirSeletorTamanho = tipoPrinter === "code"

    const { enqueueSnackbar } = useSnackbar()
    const alertStart = (msg, tipo) => {
        enqueueSnackbar(msg, { variant: tipo })
    }

    const alertClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setAlert({ status: false, tipo: alert.tipo, mesangem: alert.mesangem })
    }

    const todosPedidos = () => {
        return pedidosAceitos
            .concat(pedidosEmPreparacao)
            .concat(pedidosEmEntrega)
            .concat(pedidosFinalizados)
    }

    const changeCheckFuiApp = (isChecked, identificador) => {
        const pedidos = JSON.parse(JSON.stringify(pedidosEntregaFuiApp))

        if (isChecked) {
            pedidos.push(identificador)
            setPedidosEntregaFuiApp(pedidos)
        } else {
            const index = pedidos.indexOf(identificador)
            pedidos.splice(index) //remove
            setPedidosEntregaFuiApp(pedidos)
        }
    }
    const checkPedidoAgrupaFuiAppDelivery = (pedidoEscolhido, identificador, bairro) => {
        var isSameIdCheck = pedidoEscolhido?.id === identificador

        if (isSameIdCheck && pedidosEntregaFuiApp?.indexOf(identificador) === -1) {
            changeCheckFuiApp(isSameIdCheck, identificador)
        }

        var show = pedidosEntregaFuiApp?.indexOf(identificador) !== -1 || isSameIdCheck

        return (
            <FormControlLabel
                control={
                    <Checkbox
                        checked={show}
                        onChange={e => {
                            var checked = e.target.checked
                            const pedidos = JSON.parse(JSON.stringify(pedidosEntregaFuiApp))

                            if (checked) {
                                pedidos.push(identificador)
                                setPedidosEntregaFuiApp(pedidos)
                            } else {
                                const index = pedidos.indexOf(identificador)
                                pedidos.splice(index) //remove
                                setPedidosEntregaFuiApp(pedidos)
                            }
                        }}
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        name={identificador.toString()}
                        id={identificador.toString()}
                    />
                }
                label={"#" + identificador + " (" + bairro + ")"}
            />
        )
    }

    const showCheckbox = pedidoEscolhido => {
        var rows = []
        var qnt = arraySegundo.length
        for (var i = 0; i < qnt; i++) {
            const lista = arraySegundo
            const item = lista[i]
            var bairro = "--"
            if (item?.deliveryAddress?.neighborhood) {
                bairro = item?.deliveryAddress?.neighborhood
            }
            rows.push(checkPedidoAgrupaFuiAppDelivery(pedidoEscolhido, item.id, bairro))
        }
        return <Container>{rows}</Container>
    }

    const validarPedidosJaEnviadosFuiDelivery = async () => {
        try {
            const body = {
                pedidosId: pedidosEntregaFuiApp,
            }

            setLoading(true)
            await api
                .post(`entrega/fuiAppDelivery/validarPedidosJaEnviados/`, body, {
                    headers: { "Content-Type": "application/json", Authorization: autorizacao },
                })
                .then(response => {
                    setLoading(false)
                    if (response.status === 200) {
                        calcularValorEntregaFuiApp()
                    } else {
                        console.log("Resposta Validação", response)
                        alertStart("Erro ao validar pedido", "error")
                    }
                })
                .catch(error => {
                    setLoading(false)
                    let response = error.response.data
                    setOpenConfirmacaoPedidosRepetidosFui(true)
                    setMessageOpenConfirmacaoPedidosRepetidosFui(response.message)
                })
        } catch (error) {
            setLoading(false)
            console.log(error)
            RegistrarLogErro(error, "validarPedidosJaEnviados", pedidosEntregaFuiApp)
        }
    }

    const calcularValorEntregaFuiApp = async () => {
        try {
            const body = {
                pedidosId: pedidosEntregaFuiApp,
            }

            setLoading(true)
            await api
                .post(`entrega/fuiAppDelivery/consultarValor/`, body, {
                    headers: { "Content-Type": "application/json", Authorization: autorizacao },
                })
                .then(response => {
                    if (typeof response.data === "string") {
                        console.log(response.data)
                        alertStart("Erro ao consultar valores", "error")
                    } else {
                        const retorno = response.data
                        setValorEntregasFuiApp(retorno)
                        setModalChamarEntregadorFui(true)
                    }
                    setLoading(false)
                })
        } catch (error) {
            let response = error.response.data
            alertStart(response.message, "error")
            setLoading(false)
            RegistrarLogErro(error, "consultarValorEntrega", pedidosEntregaFuiApp)
        }
    }

    const chamarEntregadorFui = async (entregaObj, servicoId, formaPagamentoId) => {
        setLoading(true)

        try {
            const body = {
                calculoResponse: entregaObj.calculoResponse,
                entregaParam: entregaObj.entregaParam,
                calculaParam: entregaObj.calculaParam,
                servicoFuiApp: servicoId,
                tipoPagamentoFuiApp: formaPagamentoId,
            }

            await api
                .post(`entrega/fuiAppDelivery/solicitarEntrega/`, body, {
                    headers: { "Content-Type": "application/json", Authorization: autorizacao },
                })
                .then(response => {
                    console.log("Testeeeee ----", console.log(JSON.stringify(response)))
                    if (typeof response.data === "string") {
                        alertStart("Não foi possivel solicitar a entrega", "error")
                        console.log("Retorno Solicitacação Criada Type Error: ", response.data)
                    } else {
                        const retorno = response.data
                        alertStart(retorno.message, "success")
                        // setValorEntregasFuiApp(retorno)
                        // setModalChamarEntregadorFui(true);

                        console.log("Respostaaa Solicitacao", response.data)
                    }
                    setLoading(false)
                })
                .catch(error => {
                    let response = error.response.data
                    alertStart(response.message, "error")
                })
            setLoading(false)
        } catch (error) {
            setLoading(false)
            alertStart(error, "error")
            RegistrarLogErro(error, "solicitarEntrega", { entregaObj, servicoId, formaPagamentoId })
        }
    }

    const showDialogConfirmarValoresFui = () => {
        return (
            <Dialog
                fullScreen={fullScreen}
                open={modalChamarEntregadorFui}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    setFormaPagamentoSelecionadaFui(null)
                    setModalChamarEntregadorFui(false)
                }}
            >
                <DialogTitle>{"Confirmar Dados Entrega"}</DialogTitle>
                <DialogContent>
                    {valorEntregasFuiApp?.calculoResponse?.enderecoOrigem ? (
                        <Col xs={12} md={12} lg={12} style={{ marginBottom: "1em" }}>
                            <Typography>
                                <h4>Origem</h4>
                            </Typography>
                            <Typography>
                                {" * " + valorEntregasFuiApp?.calculoResponse?.enderecoOrigem}
                            </Typography>
                        </Col>
                    ) : null}

                    {valorEntregasFuiApp?.calculoResponse?.destinos ? (
                        <Col xs={12} md={12} lg={12} style={{ marginBottom: "1em" }}>
                            <Typography>
                                <h4>Destino(s)</h4>
                            </Typography>
                        </Col>
                    ) : null}

                    {valorEntregasFuiApp?.calculoResponse?.destinos?.map((destino, index) => (
                        <Col key={index} xs={12} md={12} lg={12} style={{ marginBottom: "1em" }}>
                            <Typography>{" * " + destino.endereco}</Typography>
                        </Col>
                    ))}

                    {!valorEntregasFuiApp?.calculoResponse?.servicos ? (
                        <Col xs={12} md={12} lg={12} style={{ marginBottom: "1em" }}>
                            <Typography>
                                Infelizmente não encontramos serviços referente à sua solicitação
                            </Typography>
                        </Col>
                    ) : null}

                    <Col xs={12} md={12} lg={12} style={{ marginBottom: "1em" }}>
                        <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            style={{ width: "60%" }}
                        >
                            <InputLabel id="pagamentofuiapp">Pagamento</InputLabel>
                            <Select
                                fullWidth
                                onChange={e => {
                                    setFormaPagamentoSelecionadaFui({
                                        name: e.target.name,
                                        id: e.target.value,
                                    })
                                }}
                                label="Forma de Pagamento"
                            >
                                {valorEntregasFuiApp?.formasPagamentoDisponiveis?.map(
                                    (formaPagamentoFui, index) => (
                                        <MenuItem
                                            name={formaPagamentoFui?.tipoPagamentoDesc}
                                            value={formaPagamentoFui?.tipoPagamentoID}
                                            key={formaPagamentoFui?.tipoPagamentoID}
                                        >
                                            <em>{formaPagamentoFui?.tipoPagamentoDesc}</em>
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </Col>

                    {valorEntregasFuiApp?.calculoResponse?.servicos?.map((servico, index) => (
                        <Col key={index} xs={12} md={12} lg={12} style={{ marginBottom: "1em" }}>
                            <Typography>{"-------------------------"}</Typography>
                            <Typography>
                                {servico.servicoNome}
                                {" -- "}
                                {Intl.NumberFormat("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                }).format(servico.valor)}
                                <Button
                                    onClick={() => {
                                        if (!formaPagamentoSelecionadaFui?.id) {
                                            alertStart("Escolha a forma de pagamento", "error")
                                            return
                                        }
                                        chamarEntregadorFui(
                                            valorEntregasFuiApp,
                                            servico,
                                            formaPagamentoSelecionadaFui.id
                                        )
                                        setModalChamarEntregadorFui(false)
                                    }}
                                >
                                    Solicitar
                                </Button>
                            </Typography>
                        </Col>
                    ))}
                    <Col xs={12} md={12} lg={12} style={{ marginBottom: "1em" }}>
                        {"-------------------------"}
                    </Col>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setFormaPagamentoSelecionadaFui(null)
                            setModalChamarEntregadorFui(false)
                        }}
                    >
                        Voltar
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    const desenvolvimento =
        window.location.hostname.includes("localhost") ||
        window.location.hostname.includes("192.168")

    const [abrirAlertas, setAbrirAlertas] = useState(false)
    const [alertas, setAlertas] = useState([])
    const getAlertasSistema = async () => {
        try {
            const response = await AlertasPainel(configuracoes.user?.parametros?.appNome)

            setAlertas(response.map(alerta => alerta.replaceAll("\n", "").replaceAll("\r", "")))

            if (!desenvolvimento) {
                setAbrirAlertas(response.length > 0)
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        const efeitoAtivo = window.getCookie("efeito-sonoro-ativo")
            ? window.getCookie("efeito-sonoro-ativo") === "true"
            : true

        document.querySelector("#audiopedido")?.load()

        if (efeitoAtivo !== recebedor.efeitoSonoro) {
            recebedor.efeitoSonoro = efeitoAtivo
            atualizarConfiguracoes(configuracoes)
            setMudancaConfiguracao(true)

            window.setCookie("efeito-sonoro-ativo", efeitoAtivo, 9999)
        }

        getAlertasSistema()
    }, [])

    useEffect(() => {
        try {
            buscarTodosPedidos(localStorage.getItem("chaveSistema"))
            verificarSeTemQZ()
        } catch (error) {
            RegistrarLogErro(error, "userEffect1")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabStatus])

    useEffect(() => {
        try {
            if (parametros?.estabelecimento?.id && tabStatus === "CRUD") {
                if (window.name === "") {
                    window.name = `${(parametros?.estabelecimento?.nome).replace(
                        /\s/g,
                        ""
                    )}${Math.random()}_${Package.version.replace(/ /g, "").replace("|", "")}`
                    atualizarTabNavegador(window.name)
                }
                const interval = setInterval(() => {
                    verificarTabNavegador(window.name)
                }, 5000)
                return () => clearInterval(interval)
            }
        } catch (error) {
            RegistrarLogErro(error, "userEffect2")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabStatus, configuracoes])

    useEffect(() => {
        try {
            const rootPrint = document.getElementById("rootPrint")

            switch (configuracoes.printer.tamanhoFonteNavegador) {
                case "normal":
                    rootPrint.classList.remove("textoImpressaoGrande")
                    break

                case "grande":
                    rootPrint.classList.add("textoImpressaoGrande")
                    break
            }

            if (tabStatus === "CRUD") {
                const interval = setInterval(() => {
                    verificarSeTemQZ()
                }, 20000)
                return () => clearInterval(interval)
            }
        } catch (error) {
            RegistrarLogErro(error, "userEffect3")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [impressorasQZ, configuracoes, tabStatus])

    const hasIfood = () => {
        return parametros?.ativaIntegracaoIfood
    }

    useEffect(() => {
        console.log("has_integrationn", hasIfood())
        if (hasIfood()) {
            if (tabStatus === "CRUD") {
                try {
                    verificarStatusLojaIfood(autorizacao)
                    var intervalLojaIFood = setInterval(function () {
                        verificarStatusLojaIfood(autorizacao)
                    }, 30000)
                    return () => clearInterval(intervalLojaIFood)
                } catch (error) {
                    console.log("Erro status Loja ifood", error)
                }
            }
        }
    }, [autorizacao, parametros])

    useEffect(() => {
        try {
            if (
                tabStatus === "CRUD" &&
                busqueiTodosPedidos.current && // TODO: Remover?
                recebedor.prepararPedidoAutomatico &&
                pedidosAceitos.length
            ) {
                console.log("aguardando para preparar", pedidosAceitos)

                console.log("preparando", pedidosAceitos)
                prepararPedidosAutomaticos(pedidosAceitos)
            }

            if (busqueiTodosPedidos.current && tabStatus === "CRUD") {
                const interval = setInterval(() => {
                    buscarNovosPedidosNovo(autorizacao) // TODO: Está duplicado?
                }, 5000)

                return () => {
                    clearInterval(interval)
                }
            }
        } catch (error) {
            RegistrarLogErro(error, "userEffect4")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pedidosAceitos])

    useEffect(() => {
        if (pedidosEmPreparacao.length && recebedor.prepararPedidoAutomatico) {
            const maiorId = pedidosEmPreparacao.reduce(
                (maiorId, pedido) => Math.max(pedido.id, maiorId),
                0
            )
            window.setTimeout(() => {
                if (maiorId > localStorage.getItem("ultimo-aviso-sonoro")) {
                    localStorage.setItem("ultimo-aviso-sonoro", maiorId)
                    efeitoSonoro(true)
                }
            }, 1000)
        }
    }, [pedidosEmPreparacao])

    const atualizar = (loading = false) => {
        try {
            if (loading) {
                setLoadingPedidos(true)
            }
            //faz pull indiana
            if (parametros?.ativarIndiana) {
                var objPullIndiana = {
                    codigoFilial: parametros?.codigoFilialIndiana,
                    codigoEmpresa: parametros?.codigoEmpresaEntregaIndiana,
                }
                buscaPedidosIntegracaoIndiana(autorizacao, objPullIndiana)
            } else {
                buscarTodosPedidos(autorizacao)
            }
        } catch (error) {
            RegistrarLogErro(error, "atualizar")
        }
    }

    async function atualizarTempoEntregaEstabelecimento(tempo, estabelecimento, index) {
        try {
            estabelecimento.tempoEntregaTexto = tempo
            const response = await atualizarTempoEntrega(autorizacao, estabelecimento)
            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
                return false
            }

            const novoEstabelecimentos = Object.assign([], estabelecimentos)
            novoEstabelecimentos[index].tempoEntregaTexto = response.tempoEntregaTexto
            setEstabelecimentos(novoEstabelecimentos)
        } catch (error) {}
    }
    async function atualizarStatusEstabelecimento(e, estabelecimento, index) {
        try {
            const data = {
                idEstabelecimento: estabelecimento.id,
                situacao: e.target.value,
            }
            const response = await atualizarStatus(autorizacao, data)
            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
                return false
            }

            const novoEstabelecimentos = Object.assign([], estabelecimentos)
            novoEstabelecimentos[index].situacao = response.situacao
            novoEstabelecimentos[index].pausado = response.pausado
            setEstabelecimentos(novoEstabelecimentos)
        } catch (error) {}
    }

    const solicitarEntregaFuiDelivery = async (Authorization, pedido) => {
        setModalEntregadorFuiDelivery({
            ...modalEntregadorFuiDelivery,
            openModal: true,
            pedidoSelecionado: pedido,
        })
    }

    const handleBuscarDadosEstabelecimentos = async () => {
        try {
            setLoading(true)
            const response = await buscarestabelecimentos(autorizacao)
            console.log("Parametros", response)
            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
                return false
            }
            setEstabelecimentos(response)
            setOpenEstabelecimentos(true)
            setLoading(false)
        } catch (error) {
            RegistrarLogErro(error, "handleBuscarDadosEstabelecimentos")
        }
        return {}
    }

    async function imprimirQz(configuracao) {
        let {
            pedido = {},
            texto = "",
            qtd = 1,
            qrcode = {},
            QZObrigatorio = false,
            numeroDaImpressora = 0,
            impressora = "",
            imprimirNota = false,
        } = configuracao
        console.log("imprimirQz", texto)
        try {
            console.log("Impressão Qz - Recebedor de Pedidos")
            if (qz.websocket.isActive()) {
                if (!impressora || impressora == "vazio") {
                    impressora = await qz.printers.getDefault()
                }
                console.log("impressora()", impressora, texto, qtd)
                configuracao.impressora = impressora
                return impressao(configuracao)
            }

            qz.websocket
                .connect()
                .then(() => {
                    //console.log('Conectado QZ!');
                    // return qz.printers.find(); //array de impressoras
                    if (!impressora) {
                        impressora = qz.printers.getDefault()
                    }

                    const print = impressora //impressora padrao
                    localStorage.setItem("impressora", JSON.stringify(print))
                    return print
                })
                .then(printer => {
                    console.log("impressora()", printer, texto, qtd)

                    configuracao.impressora = printer
                    return impressao(configuracao)
                })
                .then(() => {})
                .catch(err => {
                    console.error("erro", err)
                })
        } catch (error) {
            RegistrarLogErro(error, "imprimirQz", configuracao)
        }
    }

    const impressao = configuracoes => {
        const {
            pedido = {},
            texto = "",
            qtd = 1,
            qrcode = {},
            QZObrigatorio = false,
            numeroDaImpressora = 0,
            impressora = "",
            imprimirNota = false,
        } = configuracoes

        console.log("printer", printer)
        console.log(">printer", printer)

        console.log("tipoImpressao", printer.tipoImpressao)

        configuracoes.qz = qz
        configuracoes.printer = printer

        if (printer.imprimirLogo) {
            configuracoes.logo = parametros?.estabelecimento?.urlLogo
        }

        if (printer.tipoImpressao == "code") {
            imprimirCode(configuracoes)
            // if (imprimirNota && user.parametros.ativarPlugNotas) imprimirNotaFiscal(pedido.id)
            return true
        }
        imprimirHTML(configuracoes)
        // if (imprimirNota && user.parametros.ativarPlugNotas) imprimirNotaFiscal(pedido.id)
    }

    useEffect(() => {
        // TODO: Checar impressão por navegador
        const pausarImpressao = async ms => new Promise(res => setTimeout(res, ms))

        const imprimirLista = async () => {
            const idUltimoPedido = localStorage.getItem("ultimoImprimir")
            const copiaPedidosAceitos = [
                ...(recebedor.prepararPedidoAutomatico ? pedidosEmPreparacao : pedidosAceitos),
            ].sort((a, b) => a.id - b.id)

            // TODO: Otimizar para sair do loop
            for (let i = 0; i < copiaPedidosAceitos.length; i++) {
                const pedido = copiaPedidosAceitos[i]
                if (pedido.id > idUltimoPedido) {
                    localStorage.setItem("ultimoImprimir", pedido.id)

                    if (
                        configuracoes.printer.imprimirComandasIfood === false &&
                        pedido.plataforma === "ifood_integracao"
                    )
                        continue

                    await pausarImpressao(2500)
                    mandarImprimir({
                        pedido: pedido,
                        texto: pedido.texto,
                    })
                }
            }
        }

        if (
            (recebedor.prepararPedidoAutomatico ? pedidosEmPreparacao : pedidosAceitos).length >
                0 &&
            recebedor.aceitarPedidoAutomatico &&
            recebedor.imprimirPedidoAutomatico &&
            tipoPainelImpressao === "navegador"
        ) {
            imprimirLista()
        }
    }, [recebedor.prepararPedidoAutomatico ? pedidosEmPreparacao : pedidosAceitos])

    useEffect(() => {
        const array = recebedor.aceitarPedidoAutomatico
            ? pedidosAceitos
            : pedidosAguardandoAceitacao

        if (!array.length) {
            efeitoSonoro(false)
        }

        pedidosAguardandoAceitacao.forEach(pedido => {
            const index = pedidosAceitos.findIndex(p => p.id === pedido.id)
            if (index !== -1)
                setPedidosAguardandoAceitacao(prev => {
                    const retorno = JSON.parse(JSON.stringify(prev))

                    retorno.splice(index, 1)

                    return retorno
                })
        })
    }, [pedidosAguardandoAceitacao, pedidosAceitos])

    const statusPedido = status => {
        switch (status) {
            case 0:
                return "Aguardando aceitação"

            case 1:
            case 2:
                return "Aceito"

            case 3:
            case 6:
                return "Em entrega"

            case 4:
            case -1:
            case -2:
            case -3:
            case -4:
            case -10:
                return "Finalizado"

            default:
                return "Em preparação"

            // if (status === 0 && recebedor.aceitarPedidoAutomatico) {
            //     return "ACEITARAUTOMATICO"
            // } else if (
            //     status === 0 ||
            //     (recebedor.aceitarPedidoAutomatico && (status === 1 || status === 2))
            // ) {
            //     return "PRIMEIRO"
            // } else if (status === 3 || status === 6) {
            //     return "TERCEIRO"
            // } else if (
            //     status === 4 ||
            //     status === -1 ||
            //     status === -2 ||
            //     status === -3 ||
            //     status === -4 ||
            //     status === -10
            // ) {
            //     return "QUARTO"
            // } else {
            //     return "SEGUNDO"
            // }
        }
    }

    const updatePedidosRecebidos = response => {
        console.log("response", response)
        setArrayTodosPedidos(response.data)

        const pedidosAguardandoAceitacao = []
        const pedidosAceitos = []
        const pedidosEmPreparacao = []
        const pedidosEmEntrega = []
        const pedidosFinalizados = []

        busqueiTodosPedidos.current = true
        ////console.log('todos pedidos', response)
        if (typeof response.data === "string") {
            // alertStart("Erro! Atualize a página!", "error")
            return true
        }
        response.data.forEach(pedido => {
            switch (statusPedido(pedido.status)) {
                case "Aguardando aceitação":
                    pedidosAguardandoAceitacao.push(pedido)
                    break
                case "Aceito":
                    pedidosAceitos.push(pedido)
                    break
                case "Em preparação":
                    pedidosEmPreparacao.push(pedido)
                    break
                case "Em entrega":
                    pedidosEmEntrega.push(pedido)
                    break
                case "Finalizado":
                    pedidosFinalizados.push(pedido)
                    break
            }
        })

        setPedidosAguardandoAceitacao(pedidosAguardandoAceitacao)
        setPedidosAceitos(pedidosAceitos)
        setPedidosEmPreparacao(pedidosEmPreparacao)
        setPedidosEmEntrega(pedidosEmEntrega)
        setPedidosFinalizados(pedidosFinalizados)

        const array = recebedor.aceitarPedidoAutomatico
            ? pedidosAceitos
            : pedidosAguardandoAceitacao

        if (array.length) {
            const maiorId = array.reduce((maiorId, pedido) => Math.max(pedido.id, maiorId), 0)
            window.setTimeout(() => {
                if (maiorId > localStorage.getItem("ultimo-aviso-sonoro")) {
                    localStorage.setItem("ultimo-aviso-sonoro", maiorId)
                    efeitoSonoro(true)
                }
            }, 1000)
        }
    }

    const buscaPedidosIntegracaoIndiana = async (Authorization, body) => {
        console.log("Buscando all Pedidos (INDIANA)", body)
        try {
            await api
                .post(`indiana/pull/all`, body, {
                    headers: { "Content-Type": "application/json", Authorization: Authorization },
                })
                .then(async response => {
                    console.log("response", response)
                    updatePedidosRecebidos(response)
                })
            setLoadingPedidos(false)
        } catch (error) {
            RegistrarLogErro(error, "buscaPedidosIntegracaoIndiana", body)
            alertStart("Erro ao buscar todos os Buscar Pedidos da Indiana!", "error")
            return false
        }
    }

    const buscarTodosPedidos = async Authorization => {
        console.log("Buscando all Pedidos", Authorization)
        try {
            await api
                .get(`integracao/orders/allday`, {
                    headers: { "Content-Type": "application/json", Authorization: Authorization },
                })
                .then(async response => {
                    updatePedidosRecebidos(response)
                })
            setLoadingPedidos(false)
        } catch (error) {
            RegistrarLogErro(error, "buscarTodosPedidos")
            alertStart("Erro ao buscar todos os pedidos!", "error")
            return false
        }
    }

    const atualizarTabNavegador = async name => {
        try {
            const response = await controleAcessoAtualizar(parametros?.estabelecimento?.id, name)

            if (response.retornoErro) {
                //algum erro
                console.error(
                    "controleAcessoAtualizar>> \n\n\n controleAcessoAtualizar>> ",
                    response
                )
            } else {
                //tudo certo
                console.log("controleAcessoAtualizar>> \n\n\n controleAcessoAtualizar>> ", response)
            }
        } catch (error) {
            RegistrarLogErro(error, "atualizarTabNavegador", name)
        }
    }

    const verificarTabNavegador = async name => {
        try {
            const response = await controleAcessoVerificar(
                parametros?.estabelecimento?.id,
                name.replace("/", "-")
            )

            if (response.retornoErro) {
                //algum erro
                console.error(
                    "controleAcessoVerificar>> \n\n\n controleAcessoVerificar>> ",
                    response
                )
            } else {
                //tudo certo
                // console.log("controleAcessoVerificar>> \n\n\n controleAcessoVerificar>> ", response)
                if (response.exist === false) {
                    // setTabStatus("OFF")
                    // window.name = ""
                    document.getElementById("aviso-dois-recebedores").style.display = "block"
                } else {
                    document.getElementById("aviso-dois-recebedores").style.display = "none"
                }
            }
        } catch (error) {
            RegistrarLogErro(error, "verificarTabNavegador", name)
        }
    }

    const verificarSeTemQZ = () => {
        try {
            //console.log('|||||||||||| verificar se tem qz')
            if (qz.websocket.isActive()) {
                buscarImpressoras()
            } else {
                qz.websocket
                    .connect()
                    .then(() => {
                        qz.printers
                            .details()
                            .then(function (data) {
                                for (var i = 0; i < data.length; i++) {
                                    console.log(
                                        data[i].name +
                                            " " +
                                            data[i].driver +
                                            " " +
                                            data[i].density +
                                            " " +
                                            data[i].trays
                                    )
                                }
                            })
                            .catch(function (e) {
                                console.error(e)
                            })

                        //console.log('Conectado QZ!');
                        buscarImpressoras()
                    })
                    .catch(err => {
                        console.error("Erro QZ", err.message)
                        // alertStart("Impressao automatica não identifica!", "error")
                        if (tipoPainelImpressao === "plugin") {
                            setImpressorasQZ([])
                        }
                    })
            }
        } catch (error) {
            RegistrarLogErro(error, "verificarSeTemQZ")
        }
    }

    const buscarImpressoras = async () => {
        try {
            if (!printer?.impressora) {
                printer.impressora = await qz.printers.getDefault()
                atualizarConfiguracoes(configuracoes)
            }

            const printers = await qz.printers.find()
            setImpressorasQZ(printers)
            //console.log('PRINTERS QZ >>>>>>> ', printers)
        } catch (error) {
            // RegistrarLogErro(error, "buscarImpressoras", printer)
        }
    }

    async function imprimirNotaFiscal(idPedido) {
        try {
            if (!idPedido) return

            const notaFiscal = await getNotaFiscal(idPedido)

            if (notaFiscal.codErro) return

            const NFe = notaFiscal.NFe ?? notaFiscal

            const razaoSocial = NFe.infNFe.emit.xFant
            const CNPJ = NFe.infNFe.emit.CNPJ?.replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
                "$1.$2.$3/$4-$5"
            )
            const inscricaoEstadual = NFe.infNFe.emit.IE

            const telefoneContatoEmpresa = (fone => {
                if (!fone) return undefined

                switch (fone.length) {
                    case 8:
                        return fone.replace(/^(\d{4})(\d{4}).*/, "$1-$2")
                    case 9:
                        return fone.replace(/^(\d{5})(\d{4}).*/, "$1-$2")
                    case 10:
                        return fone.replace(/^(\d{2})(\d{4})(\d{4}).*/, "($1)$2-$3")
                    case 11:
                        return fone.replace(/^(\d{2})(\d{5})(\d{4}).*/, "($1)$2-$3")
                    default:
                        return fone
                }
            })(NFe.infNFe.emit.enderEmit.fone)

            const ruaEmpresa = NFe.infNFe.emit.enderEmit.xLgr
            const numeroEmpresa = NFe.infNFe.emit.enderEmit.nro
            const bairroEmpresa = NFe.infNFe.emit.enderEmit.xBairro
            const municipioEmpresa = NFe.infNFe.emit.enderEmit.xMun
            const estadoEmpresa = NFe.infNFe.emit.enderEmit.UF
            const stringEnderecoEmpresa = `${ruaEmpresa}, ${numeroEmpresa}\x0A${bairroEmpresa}, ${municipioEmpresa}, ${estadoEmpresa}`

            const chaveAcesso = NFe.infNFeSupl.urlChave
            const qrCodeNF = NFe.infNFeSupl.qrCode
            const chaveNF = notaFiscal.protNFe?.infProt.chNFe.match(/.{1,4}/g).join(" ")
            const dataEmissaoNF = NFe.infNFe.ide.dhEmi

            const diaEmissaoNF = dataEmissaoNF.split("T")[0].split("-")
            const horarioEmissaoNF = dataEmissaoNF.split("T")[1].split("-")[0].split(":")

            const cpfCliente = NFe.infNFe.dest?.CPF?.replace(
                /^(\d{3})(\d{3})(\d{3})(\d{2}).*/,
                "$1.$2.$3-$4"
            )

            const cnpjCliente = NFe.infNFe.dest?.CNPJ?.replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
                "$1.$2.$3/$4-$5"
            )

            const stringData = `${diaEmissaoNF[2]}/${diaEmissaoNF[1]}/${diaEmissaoNF[0]} ${horarioEmissaoNF[0]}:${horarioEmissaoNF[1]}:${horarioEmissaoNF[2]}`

            const lineBreak = "\x0A"
            const centerAlign = "\x1B" + "\x61" + "\x31"
            const leftAlign = "\x1B" + "\x61" + "\x30"
            const boldOn = "\x1B" + "\x45" + "\x0D"
            const boldOff = "\x1B" + "\x45" + "\x0A"

            imprimirNotaGeral({
                qrcode: {
                    link: qrCodeNF,
                    textosAntes: [
                        centerAlign,
                        lineBreak,
                        "Documento Auxiliar da Nota Fiscal",
                        lineBreak,
                        "Eletronica para Consumidor Final",
                        lineBreak,
                        lineBreak,
                        leftAlign,
                        razaoSocial,
                        lineBreak,
                        "CNPJ: " + CNPJ + " " + "IE: " + inscricaoEstadual,
                        lineBreak,
                        telefoneContatoEmpresa ? "Tel.: " + telefoneContatoEmpresa : "",
                        lineBreak,
                        stringEnderecoEmpresa,
                        lineBreak,
                        lineBreak,
                        centerAlign,
                        "Emissao: " + stringData,
                        lineBreak,
                        "Via Consumidor",
                        lineBreak,
                        cpfCliente
                            ? "CPF Consumidor: " + cpfCliente
                            : cnpjCliente
                            ? "CNPJ Consumidor: " + cnpjCliente
                            : "CONSUMIDOR NAO IDENTIFICADO",
                        lineBreak,
                        lineBreak,
                        "Consulte pela Chave de Acesso em:",
                        lineBreak,
                        chaveAcesso,
                        lineBreak,
                        "Chave:",
                        lineBreak,
                        chaveNF,
                        lineBreak,
                    ],
                    textosDepois: [lineBreak, lineBreak, lineBreak],
                },
                printer: configuracoes.printer,
                impressora: configuracoes.printer.impressora,
                qz,
            })
        } catch (error) {
            console.error(error)
            alertStart(error, "error")
            RegistrarLogErro(error, "imprimirNotaFiscal", { idPedido })
        }
    }

    const gridStatus = status => {
        if (status === 0 && recebedor.aceitarPedidoAutomatico) {
            return "ACEITARAUTOMATICO"
        } else if (
            status === 0 ||
            (recebedor.aceitarPedidoAutomatico && (status === 1 || status === 2))
        ) {
            return "PRIMEIRO"
        } else if (status === 3 || status === 6) {
            return "TERCEIRO"
        } else if (
            status === 4 ||
            status === -1 ||
            status === -2 ||
            status === -3 ||
            status === -4 ||
            status === -10
        ) {
            return "QUARTO"
        } else {
            return "SEGUNDO"
        }
    }

    const prepararPedidosAutomaticos = async arrayPedidos => {
        try {
            console.log("prepararPedidosAutomaticos", arrayPedidos)
            console.log("**PREPARAR AUTOMATICO")
            for (let i = 0; i < arrayPedidos.length; i++) {
                const pedido = arrayPedidos[i]
                if (pedido.status === 1 || pedido.status === 2) {
                    await mudarStatusPara(7, autorizacao, pedido, i)
                }
            }
        } catch (error) {
            RegistrarLogErro(error, "prepararPedidosAutomaticos", arrayPedidos)
        }
    }

    const aceitarPedidosAutomaticos = async arrayPedidos => {
        try {
            console.log("aceitarPedidosAutomaticos", arrayPedidos)
            for (let i = 0; i < arrayPedidos.length; i++) {
                const pedido = arrayPedidos[i]

                if (pedido.status === 0) {
                    aceitarPedido(pedido)
                }
            }
        } catch (error) {
            RegistrarLogErro(error, "aceitarPedidosAutomaticos", arrayPedidos)
        }
    }

    const realizarPlaced = async ArrayPedidos => {
        try {
            const pedidosParaAceitar = JSON.parse(JSON.stringify(pedidosAguardandoAceitacao))

            for (var i = 0; i < ArrayPedidos.length; i++) {
                var jaTem = pedidosParaAceitar.findIndex(
                    p => p.id === ArrayPedidos[i].correlationId
                )

                if (jaTem !== -1) continue

                const retorno = await buscarInformacoesPedido(autorizacao, ArrayPedidos[i])

                if (JSON.stringify(retorno) === "{}") continue

                if (
                    pedidosAceitos.findIndex(p => p.id === ArrayPedidos[i].correlationId) === -1 &&
                    retorno.status >= 0
                )
                    pedidosParaAceitar.push(retorno)
            }

            if (recebedor.aceitarPedidoAutomatico) {
                aceitarPedidosAutomaticos(pedidosParaAceitar)
            } else {
                setPedidosAguardandoAceitacao(pedidosParaAceitar)
            }

            const array = pedidosParaAceitar

            if (array.length) {
                const maiorId = array.reduce((maiorId, pedido) => Math.max(pedido.id, maiorId), 0)
                window.setTimeout(() => {
                    if (maiorId > localStorage.getItem("ultimo-aviso-sonoro")) {
                        localStorage.setItem("ultimo-aviso-sonoro", maiorId)
                        efeitoSonoro(true)
                    }
                }, 1000)
            }
        } catch (error) {
            console.error(error)
            RegistrarLogErro(error, "realizarPlaced", ArrayPedidos)
        }
    }

    // TODO: Refazer
    const realizarAFila = fila => {
        try {
            console.log("realizarAFila> ", fila)
            if (fila.length > 0) {
                const placed = []
                const filaPrint = JSON.parse(JSON.stringify(filaPrints))

                for (var i = 0; i < fila.length; i++) {
                    // eslint-disable-next-line default-case

                    if (fila[i].code.match(/PRINT(\d?)/)) {
                        if (fila[i].exigirConfirmacao) {
                            filaPrint.push(fila[i].texto)
                            return
                        }

                        mandarImprimir({
                            pedido: fila[i],
                            texto: fila[i].texto,
                            qtd: 1,
                            qrcode: verificarPrintQRCode(fila[i]),
                            QZObrigatorio: true,
                            numeroDaImpressora: fila[i].code.match(/PRINT(\d)/)
                                ? Number(fila[i].code.replace(/PRINT(\d)/, "$1"))
                                : 0,
                        })

                        return
                    }

                    switch (fila[i].code) {
                        case "PLACED":
                            placed.push(fila[i])
                            break
                        case "UPDATE":
                            break
                        case "CUPOM_FISCAL":
                            if (fila[i].texto?.includes("Cupom Cancelado"))
                                imprimirNotaGeral({
                                    texto: fila[i].texto + "\n\n\n\n\n",
                                    printer: configuracoes.printer,
                                    impressora: configuracoes.printer.impressora,
                                    qz,
                                })
                            else imprimirNotaFiscal(fila[i].correlationId)
                            break
                    }
                }

                if (filaPrint.length > 0) {
                    setFilaPrints(filaPrint)
                }

                if (placed.length > 0) {
                    realizarPlaced(placed)
                }
            }
        } catch (error) {
            RegistrarLogErro(error, "realizarAFila", fila)
        }
    }

    const verificarStatusLojaIfood = async Authorization => {
        const retorno = await ObterStatusLojaIfood(parametros?.estabelecimento, Authorization)

        if (retorno.retornoErro) {
            setStatusLojaIfood({
                title: "...",
                message: "...",
            })
            return false
        }

        setStatusLojaIfood({
            title: retorno?.available ? "Loja Aberta" : "Loja Fechada",
            message: retorno?.message,
            extraInfo: retorno?.extraInfo,
        })
    }

    const buscarNovosPedidosNovo = async Authorization => {
        try {
            await api
                .get(`integracao/eventspolling`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: Authorization,
                        //"fixed_token_ifood": hashInternaClienteFielIfood, //deprecated
                        //"ifood_integration_active": ativaIntegracaoIfood, //deprecated
                        //"need_call_polling_ifood": verificarSeDeveBuscarIfood(), //deprecated
                    },
                })
                .then(async response => {
                    if (response && response.data) {
                        if (response?.data?.length) {
                            console.log(" -- Novos Pedidos ---- ", response.data)
                        }
                    }
                    const resp = response.data

                    const allFila = []

                    for (let j = 0; j < resp.length; j++) {
                        await api
                            .post(`integracao/events/acknowledgmentsingle`, resp[j], {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: Authorization,
                                },
                            })
                            .then(response => {
                                if (response.data.codErro > 0) {
                                    RegistrarLogErro(
                                        response.data,
                                        "acknowledgmentsingle-buscarNovosPedidosNovo",
                                        resp[j]
                                    )
                                } else {
                                    allFila.push(resp[j])
                                }
                            })
                    }

                    if (resp.length > 0) {
                        realizarAFila(allFila)
                    }
                })
        } catch (error) {
            RegistrarLogErro(error, "buscarNovosPedidosNovo")
        }
    }

    const efeitoSonoro = tocar => {
        try {
            const myAudio = document.getElementById("audiopedido")
            console.log(
                "efeitoSonoro => ",
                tocar,
                recebedor.efeitoSonoro,
                recebedor.duracaoEfeitoSonoro
            )
            if (myAudio && tocar && recebedor.efeitoSonoro === true) {
                myAudio.play()
                if (!!recebedor.duracaoEfeitoSonoro && recebedor.duracaoEfeitoSonoro !== "0") {
                    window.setTimeout(() => {
                        efeitoSonoro(false)
                    }, 1000 * Number(recebedor.duracaoEfeitoSonoro))
                } else if (recebedor.prepararPedidoAutomatico === true) {
                    window.setTimeout(function () {
                        efeitoSonoro(false)
                    }, 5000)
                }
            } else if (myAudio) {
                myAudio.pause()
            }
        } catch (error) {
            setTimeout(function () {
                efeitoSonoro(tocar)
            }, 1000)
            console.error(error)
        }
    }

    if (tabStatus === "OFF") {
        efeitoSonoro(true)
    }

    const buscarInformacoesPedido = async (Authorization, pedido) => {
        //console.log('buscarInformacoesPedido', pedido)
        var retorno = {}
        try {
            await api
                .get(`integracao/orders/${pedido.correlationId}`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: Authorization,
                        //"fixed_token_ifood": hashInternaClienteFielIfood, //deprecated
                        // "ifood_integration_active": ativaIntegracaoIfood //deprecated
                    },
                })
                .then(response => {
                    //console.log("buscarInformacoesPedido", response.data)
                    response.data.evento = pedido
                    retorno = response.data
                })
        } catch (error) {
            RegistrarLogErro(error, "buscarInformacoesPedido", pedido)
        }
        return retorno
    }

    const buscarStatus = pedido => {
        /* 
      AGUARDANDO_ACEITACAO = 0;
      ACEITO = 1;
      CONFIRMACAO_VISUALIZADA = 2;
      EM_ENTREGA = 3;
      ENTREGUE = 4;
      PAGO = 5;
      PRONTO = 6;
      EM_PRODUCAO = 7;
      AGUARDANDO ATUALIZAR STATUS = 10;

      CANCELADO_PELO_DISTRIBUIDOR = -1;
      CANCELADO_PELO_CLIENTE = -2;
      NAO_ENTREGUE = -3;
      EXPIRADO = -4;
      CHARGEBACK = -10;
    */
        switch (pedido.status) {
            case 0:
                return "Aguardando Aceitação"
            case 1:
            case 2:
                return "Aceito"
            case 3:
                if (pedido.type === "1") return "Pronto P/ Retirada"
                return "Saiu P/ Entrega"
            case 4:
                return "Entregue"
            case 5:
                return "Pago"
            case 6:
                if (pedido.type === "2") return "Saiu P/ Entrega"
                return "Pronto P/ Retirada"
            case 7:
                return "Preparação"
            case -1:
                return "Loja Cancelou"
            case -2:
                return "Cliente Cancelou"
            case -3:
                return "Não Entregue"
            case -4:
                return "Expirado"
            case -10:
                return "ChargeBack"

            default:
                return "Não Identificado"
        }
    }

    const buscarIndex = (pedido, array) => {
        try {
            switch (array) {
                case 1:
                    for (let i = 0; i < pedidosAguardandoAceitacao.length; i++) {
                        if (pedidosAguardandoAceitacao[i].id === pedido.id) {
                            return i
                        }
                    }
                    for (let i = 0; i < pedidosAceitos.length; i++) {
                        if (pedidosAceitos[i].id === pedido.id) {
                            return i
                        }
                    }
                    break

                case 2:
                    for (let i = 0; i < pedidosEmPreparacao.length; i++) {
                        if (pedidosEmPreparacao[i].id === pedido.id) {
                            return i
                        }
                    }
                    break

                case 3:
                    for (let i = 0; i < pedidosEmEntrega.length; i++) {
                        if (pedidosEmEntrega[i].id === pedido.id) {
                            return i
                        }
                    }

                    break
                case 4:
                    for (let i = 0; i < pedidosFinalizados.length; i++) {
                        if (pedidosFinalizados[i].id === pedido.id) {
                            return i
                        }
                    }
                    break
            }
        } catch (error) {
            RegistrarLogErro(error, "buscarIndex", { pedido, array })
        }
        // nao pode acontecer
        return -1
    }

    const resumoDoDia = async () => {
        const res = await ResumoDia(autorizacao)

        // Necessário porque função de imprimir remove as duas primeiras quebras de linha
        let textos =
            "\n\n" +
            res.message
                .replaceAll(
                    "<hr/>",
                    `
-----------------------`
                )
                .replaceAll("<br/>", "\n")
                .replaceAll(/(-----)([^-])/g, "$1\n$2")

        console.log("asdasd mandar =>", textos)

        mandarImprimir({
            texto: textos,
            qtd: 1,
            isResumo: true,
        })
    }

    // TODO: Mover para RecebedorContext
    const mandarImprimir = async configs => {
        const {
            pedido = {},
            texto = "",
            qtd = 1,
            qrcode = {},
            QZObrigatorio = false,
            numeroDaImpressora = 0,
            imprimirNota = false,
            isResumo = false,
        } = configs
        try {
            console.log("mandarImprimir > configs", configs)

            if (parametros.assinaturaLimitada === true) {
                alertStart(
                    "Não foi impresso, porque sua assinatura está limitada por inadimplência.",
                    "info"
                )
                return false
            }

            console.log(">printer", printer)

            switch (tipoPainelImpressao) {
                case "navegador":
                    if (!QZObrigatorio) {
                        document.getElementById("rootPrint").innerHTML = texto.replace(
                            /(?:\r\n|\r|\n)/g,
                            "<br>"
                        )
                        window.setTimeout(function () {
                            if (parametros?.agruparViasImpressao) {
                                window.print()
                            } else {
                                for (var j = 0; j < qtd; j++) {
                                    window.print()
                                }
                            }
                        }, 200)
                    }
                    break

                case "plugin":
                    if (numeroDaImpressora === 0) {
                        const impressorasConfiguradas = getImpressoras(configuracoes)

                        console.log("getImpressoras", configuracoes, impressorasConfiguradas)

                        if (!impressorasConfiguradas.length) {
                            alertStart("Sua lista de impressoras está vazia", "warning")
                            return
                        }

                        let primeiroTextoAlternadoNaoImpresso = 0
                        impressorasConfiguradas.forEach(async (impressora, i) => {
                            configs.impressora = impressora

                            if (!isResumo && parametros?.impressaoAlternada) {
                                const textoImpressaoAlternada = pedido["texto" + (i + 1)]

                                // Se for impressão alternada, imprime somente na impressora que houver produtos referentes a ela
                                if (!textoImpressaoAlternada) {
                                    console.warn(
                                        `Impressão Alternada para impressora Nº${
                                            i + 1
                                        } falhou ao imprimir o pedido ${
                                            pedido.id
                                        }, pois não há o texto referente`
                                    )
                                    return
                                }

                                primeiroTextoAlternadoNaoImpresso = i + 1
                                configs.texto = textoImpressaoAlternada
                            }

                            await imprimirQz(configs)
                        })

                        // Impressão da comanda completa para impressão alternada
                        if (
                            parametros?.impressaoAlternada &&
                            configuracoes.printer.imprimirComandaCompleta
                        ) {
                            configs.impressora = configuracoes.printer.impressoraComandaCompleta
                            configs.texto = pedido.texto

                            await imprimirQz(configs)
                        }

                        // Checa se hovue textos não impressos por falta de impressora
                        if (pedido["texto" + (primeiroTextoAlternadoNaoImpresso + 1)]) {
                            console.error(
                                `Não foi possível completar a Impressão Alternada, pois não há impressora Nº${
                                    primeiroTextoAlternadoNaoImpresso + 1
                                }`
                            )
                            alertStart(
                                `Não foi possível completar a Impressão Alternada, pois não há impressora Nº${
                                    primeiroTextoAlternadoNaoImpresso + 1
                                }`,
                                "warning"
                            )

                            let maiorTextoImpressao = 0
                            for (let i = primeiroTextoAlternadoNaoImpresso + 1; true; i++) {
                                if (pedido["texto" + i]) maiorTextoImpressao = i
                                else break
                            }

                            for (
                                let i = primeiroTextoAlternadoNaoImpresso + 1;
                                i <= maiorTextoImpressao;
                                i++
                            ) {
                                mandarImprimir({
                                    texto: pedido["texto" + i],
                                    numeroDaImpressora: 1,
                                })
                            }
                        }
                    } else {
                        const impressorasConfiguradas = getImpressoras(configuracoes)
                        const impressora = impressorasConfiguradas[numeroDaImpressora - 1]

                        if (!impressora) {
                            alertStart(`${numeroDaImpressora}ª Impressora não selecionada`, "error")
                            return
                        }

                        configs.impressora = impressora

                        if (parametros?.impressaoAlternada) {
                            const textoImpressaoAlternada = pedido["texto" + numeroDaImpressora]

                            if (textoImpressaoAlternada) configs.texto = textoImpressaoAlternada
                        }

                        await imprimirQz(configs)
                    }
                    break

                case "software":
                    console.log(`01[${texto}]{${qtd}}`)
                    break

                default:
                    break
            }
        } catch (error) {
            console.error(error)
            alertStart(error, "error")
            RegistrarLogErro(error, "mandarImprimir", configs)
        }
    }

    // TODO: Mover para RecebedorContext
    const mudarStatusPara = async (status, autorizacao, pedido, index) => {
        console.log("status, autorizacao, pedido, index", status, autorizacao, pedido, index)
        try {
            switch (status) {
                case -1:
                    setConfirmarCancelamento(pedido)
                    break
                case 1:
                    await aceitarPedido(pedido)
                    break
                case 7:
                    emProducao(pedido)
                    break
                case 3:
                    prontoEntrega(pedido)
                    break
                case 6:
                    prontoRetirada(pedido)
                    break
                case 4:
                    finalizarPedido(pedido)
                    break

                default:
                    break
            }
        } catch (error) {
            RegistrarLogErro(error, "mudarStatusPara", { status, pedido })
        }
    }

    const removerPedidoListaAnterior = pedido => {
        try {
            switch (statusPedido(pedido.status)) {
                case "Aguardando aceitação":
                    setPedidosAguardandoAceitacao(prev => prev.filter(p => p.id !== pedido.id))
                    break
                case "Aceito":
                    setPedidosAceitos(prev => prev.filter(p => p.id !== pedido.id))
                    break
                case "Em preparação":
                    setPedidosEmPreparacao(prev => prev.filter(p => p.id !== pedido.id))
                    break
                case "Em entrega":
                    setPedidosEmEntrega(prev => prev.filter(p => p.id !== pedido.id))
                    break
                case "Finalizado":
                    setPedidosFinalizados(prev => prev.filter(p => p.id !== pedido.id))
                    break
            }
        } catch (error) {
            console.error(error)
            RegistrarLogErro(error, "removerPedidoListaAnterior", pedido)
        }
    }

    const finalizarPedido = async pedido => {
        setLoading(true)
        try {
            await api
                .post(
                    `integracao/orders/${pedido.id}/statuses/delivery`,
                    {},
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: autorizacao,
                            // "fixed_token_ifood": hashInternaClienteFielIfood, //deprecated
                            // "ifood_integration_active": ativaIntegracaoIfood //deprecated
                        },
                    }
                )
                .then(response => {
                    removerPedidoListaAnterior(pedido)

                    // TODO: Checar se está funcionando
                    switch (statusPedido(response.data.status)) {
                        case "Aceito":
                            setPedidosAceitos(prev => [response.data, ...prev])

                            break

                        case "Em preparação":
                            setPedidosEmPreparacao(prev => [response.data, ...prev])

                            break

                        case "Em entrega":
                            setPedidosEmEntrega(prev => [response.data, ...prev])
                            alertStart(`${response.data.descricaoStatus}`, "warning")

                            break

                        case "Finalizado":
                            setPedidosFinalizados(prev => [response.data, ...prev])

                            break
                    }

                    setModalInformacoesPedido({})
                })
            setLoading(false)
        } catch (error) {
            RegistrarLogErro(error, "finalizarPedido", { pedido })
            alertStart("Erro ao mudar status para finalizarPedido", "error")
            setLoading(false)
        }
    }

    const prontoRetirada = async pedido => {
        setLoading(true)
        try {
            await api
                .post(
                    `integracao/orders/${pedido.id}/statuses/readyToDeliver`,
                    {},
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: autorizacao,
                        },
                    }
                )
                .then(response => {
                    removerPedidoListaAnterior(pedido)

                    // TODO: Checar se está funcionando
                    switch (statusPedido(response.data.status)) {
                        case "Aceito":
                            setPedidosAceitos(prev => [response.data, ...prev])

                            break

                        case "Em preparação":
                            setPedidosEmPreparacao(prev => [response.data, ...prev])

                            break

                        case "Em entrega":
                            setPedidosEmEntrega(prev => [response.data, ...prev])

                            break

                        case "Finalizado":
                            setPedidosFinalizados(prev => [response.data, ...prev])
                            alertStart(`${response.data.descricaoStatus}`, "warning")

                            break
                    }

                    setModalInformacoesPedido({})
                })
            setLoading(false)
        } catch (error) {
            RegistrarLogErro(error, "prontoRetirada", { pedido })
            alertStart("Erro ao mudar status para prontoRetirada", "error")
            setLoading(false)
        }
    }

    const prontoEntrega = async pedido => {
        setLoading(true)
        try {
            await api
                .post(
                    `integracao/orders/${pedido.id}/statuses/dispatch`,
                    {},
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: autorizacao,
                        },
                    }
                )
                .then(response => {
                    removerPedidoListaAnterior(pedido)

                    // TODO: Checar se está funcionando
                    switch (statusPedido(response.data.status)) {
                        case "Aceito":
                            setPedidosAceitos(prev => [response.data, ...prev])

                            break

                        case "Em preparação":
                            setPedidosEmPreparacao(prev => [response.data, ...prev])

                            break

                        case "Em entrega":
                            setPedidosEmEntrega(prev => [response.data, ...prev])

                            break

                        case "Finalizado":
                            setPedidosFinalizados(prev => [response.data, ...prev])
                            alertStart(`${response.data.descricaoStatus}`, "warning")

                            break
                    }

                    setModalInformacoesPedido({})
                })
            setLoading(false)
        } catch (error) {
            RegistrarLogErro(error, "prontoEntrega", { pedido })
            alertStart("Erro ao mudar status para prontoEntrega", "error")
            setLoading(false)
        }
    }

    const emProducao = async pedido => {
        setLoading(true)
        try {
            await api
                .post(
                    `integracao/orders/${pedido.id}/statuses/preparation`,
                    {},
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: autorizacao,
                        },
                    }
                )
                .then(response => {
                    removerPedidoListaAnterior(pedido)

                    switch (statusPedido(response.data.status)) {
                        case "Aceito":
                            // TODO: Rever lógica

                            setPedidosAceitos(prev => [response.data, ...prev])

                            // TODO: Checar se está funcionando
                            if (recebedor.aceitarPedidoAutomatico) {
                                response.data.status = 7 //em producao
                                setPedidosEmPreparacao(prev => [response.data, ...prev])
                            } else {
                                setPedidosEmPreparacao(prev => [response.data, ...prev])
                            }

                            break

                        case "Em preparação":
                            setPedidosEmPreparacao(prev => [response.data, ...prev])

                            break

                        case "Em entrega":
                            setPedidosEmEntrega(prev => [response.data, ...prev])
                            alertStart(`${response.data.descricaoStatus}`, "warning")

                            break

                        case "Finalizado":
                            setPedidosFinalizados(prev => [response.data, ...prev])
                            alertStart(`${response.data.descricaoStatus}`, "warning")

                            break
                    }

                    setModalInformacoesPedido({})
                })
            setLoading(false)
        } catch (error) {
            console.error(error)
            RegistrarLogErro(error, "emProducao", { pedido })
            alertStart("Erro ao mudar status para emProducao", "error")
            setLoading(false)
        }
    }

    const aceitarPedido = async pedido => {
        // TODO: Tirar?
        if (!recebedor.aceitarPedidoAutomatico) {
            setLoading(true)
        }

        try {
            await api
                .post(
                    `integracao/orders/${pedido.id}/statuses/confirmation`,
                    {},
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: autorizacao,
                        },
                    }
                )
                .then(response => {
                    removerPedidoListaAnterior(pedido)

                    setModalInformacoesPedido({})

                    switch (statusPedido(response.data.status)) {
                        case "Aceito":
                            setPedidosAceitos(prev => [response.data, ...prev])

                            // TODO: Checar impressão
                            if (tipoPainelImpressao === "plugin") {
                                if (
                                    localStorage.getItem("ultimoImprimir") !== response.data.id &&
                                    response.data.imprimir !== false
                                ) {
                                    localStorage.setItem("ultimoImprimir", response.data.id)

                                    if (
                                        configuracoes.printer.imprimirComandasIfood === false &&
                                        response.data.plataforma === "ifood_integracao"
                                    )
                                        break

                                    mandarImprimir({
                                        pedido: response.data,
                                        texto: response.data.texto,
                                        qtd: parametros?.viasImpressao,
                                        qrcode: verificarPrintQRCode(response.data),
                                    })
                                }
                            }

                            break

                        case "Em preparação":
                            // TODO: Checar se precisa
                            response.data.status = 7

                            setPedidosEmPreparacao(prev => [response.data, ...prev])

                            // TODO: Checar impressão
                            if (
                                localStorage.getItem("ultimoImprimir") !== response.data.id &&
                                response.data.imprimir !== false
                            ) {
                                localStorage.setItem("ultimoImprimir", response.data.id)

                                if (
                                    configuracoes.printer.imprimirComandasIfood === false &&
                                    response.data.plataforma === "ifood_integracao"
                                )
                                    break

                                mandarImprimir({
                                    pedido: response.data,
                                    texto: response.data.texto,
                                    qtd: parametros?.viasImpressao,
                                    qrcode: verificarPrintQRCode(response.data),
                                })
                            }

                            break

                        case "Em entrega":
                            setPedidosEmEntrega(prev => [response.data, ...prev])
                            alertStart(`${response.data.descricaoStatus}`, "warning")

                            break

                        case "Finalizado":
                            setPedidosFinalizados(prev => [response.data, ...prev])
                            alertStart(`${response.data.descricaoStatus}`, "warning")

                            break
                    }
                })
            setLoading(false)
        } catch (error) {
            RegistrarLogErro(error, "aceitarPedido", { pedido })
            alertStart("Erro ao inesperado aceitar pedido", "error")
            setLoading(false)
        }

        // TODO: Checar se precisa de um setLoading(false) aqui e se está tudo certo nessas funções
    }

    const handleCloseEstabelecimentos = () => {
        const tempoEntregaElements = document.getElementsByClassName("tempoEntrega")
        for (let i = 0; i < tempoEntregaElements.length; i++) {
            const element = tempoEntregaElements[i].firstChild.firstChild

            if (element.value.length > 10) {
                alertStart("Tempo de entrega deve ter um máximo de 10 caracteres", "error")
                return
            }

            atualizarTempoEntregaEstabelecimento(
                element.value,
                estabelecimentos[element.dataset?.indexEstabelecimento],
                element.dataset?.indexEstabelecimento
            )
        }
        setOpenEstabelecimentos(false)
    }

    const agrupamentos = useMemo(
        () =>
            arrayTodosPedidos.reduce((acc, p) => {
                if (p.quantidadeRegistrosAgrupamento <= 1) return acc
                if (acc.find(a => a === p.agrupamento)) return acc

                acc.push(p.agrupamento)

                return acc
            }, []),
        [arrayTodosPedidos]
    )

    if (tabStatus === "OFF")
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#f4f4f4",
                    minHeight: "100vh",
                }}
            >
                <audio
                    src={RingAudio}
                    loop={!configuracoes.recebedor.prepararPedidoAutomatico}
                    id="audiopedido"
                />

                <Row
                    style={{
                        margin: 0,
                        padding: 0,
                    }}
                >
                    <Col
                        xs={12}
                        md={12}
                        lg={12}
                        style={{
                            textAlign: "center",
                        }}
                    >
                        <Typography
                            style={{
                                fontSize: "2rem",
                            }}
                        >
                            Você Foi Desconectado!
                        </Typography>
                        <Typography>
                            Outro computador ou navegador conectou nesse mesmo estabelecimento e
                            você foi desconectado de aceitar pedidos.
                        </Typography>
                    </Col>
                    <Col
                        xs={12}
                        md={12}
                        lg={12}
                        style={{
                            textAlign: "center",
                            margin: 0,
                            padding: 0,
                        }}
                    >
                        <img
                            style={{
                                width: "100%",
                                maxWidth: "600px",
                                maxHeight: "350px",
                            }}
                            src={desconected}
                        />
                    </Col>

                    <Col
                        xs={12}
                        md={4}
                        lg={4}
                        style={{
                            textAlign: "center",
                        }}
                    >
                        <Button
                            variant="contained"
                            color="default"
                            style={{ margin: "5px 0" }}
                            onClick={() => {
                                setTabStatus("CRUD")
                                window.name = ""
                            }}
                        >
                            Conectar Novamente
                        </Button>
                    </Col>

                    <Col
                        xs={12}
                        md={4}
                        lg={4}
                        style={{
                            textAlign: "center",
                        }}
                    >
                        <Button
                            variant="contained"
                            color="default"
                            style={{ background: "#28a745", color: "white", margin: "5px 0" }}
                            onClick={() => {
                                setTabStatus("VIEW")
                            }}
                        >
                            Modo Visualização
                        </Button>
                    </Col>

                    <Col
                        xs={12}
                        md={4}
                        lg={4}
                        style={{
                            textAlign: "center",
                        }}
                    >
                        <Button
                            variant="contained"
                            color="default"
                            style={{ margin: "5px 0" }}
                            onClick={() => {
                                history.push("/servicos")
                            }}
                        >
                            Serviços
                        </Button>
                    </Col>
                </Row>
            </div>
        )

    return (
        <RecebedorContextProvider atualizar={atualizar}>
            <Container
                component="main"
                maxWidth="lg"
                className="distanciaTop"
                style={{ minHeight: "120vh" }}
            >
                <Cabecalho
                    pagina="Recebedor de Pedidos"
                    avisoDoisRecebedores
                    botaoModoVisualizacao={tabStatus !== "VIEW"}
                    setTabStatus={setTabStatus}
                    alertas={alertas}
                    openAlertas={() => setAbrirAlertas(true)}
                />

                <Print
                    filaPrints={filaPrints}
                    setFilaPrints={setFilaPrints}
                    mandarImprimir={mandarImprimir}
                />

                <Loading loading={loading} />

                <Snackbar open={alert.status} autoHideDuration={6000} onClose={alertClose}>
                    <Alert onClose={alertClose} severity={alert.tipo}>
                        {alert.mesangem}
                    </Alert>
                </Snackbar>

                <CssBaseline />

                <Dialog
                    open={criarRegistradorModal}
                    fullScreen={true}
                    onClose={() => {
                        criarRegistradorModal(false)
                        setRegistradorModal(false)
                    }}
                >
                    <DialogTitle>
                        <IconButton
                            className="setaVoltarModal"
                            onClick={() => {
                                setCriarRegistradorModal(false)
                                setRegistradorModal(false)
                            }}
                        >
                            <ArrowForwardIosIcon />
                        </IconButton>
                        Registrador de Pedidos
                    </DialogTitle>
                    <DialogContent
                        style={{
                            padding: "0",
                            overflowY: "none",
                        }}
                    >
                        <iframe
                            style={{
                                height: "100%",
                                width: "100%",
                            }}
                            src={
                                "https://" +
                                window.location.host +
                                "/servicos/modulo/registrador-de-pedidos"
                            }
                        />
                    </DialogContent>
                </Dialog>

                <Dialog
                    open={contratar}
                    onClose={() => {
                        setContratar(false)
                    }}
                >
                    <DialogTitle>{"Contato para Contratar"}</DialogTitle>
                    <DialogContent>
                        <Row>
                            <Col xs={12} md={12} lg={12}>
                                <Button
                                    onClick={() => {
                                        window.open(
                                            "https://api.whatsapp.com/send?phone=5531994754366&text=Oi%20tudo%20bem?%20Gostaria%20de%20saber%20como%20funciona%20o%20registrador%20de%20pedidos%20para%20contratar.",
                                            "_blank"
                                        )
                                        setContratar(false)
                                    }}
                                    style={{ backgroundColor: "#075E54", color: "white" }}
                                    fullWidth
                                    variant="contained"
                                >
                                    Contactar por Whatsapp
                                </Button>
                            </Col>
                            <Col xs={12} md={12} lg={12}>
                                <Button
                                    onClick={() => {
                                        setContratar(false)
                                    }}
                                    fullWidth
                                    style={{ marginTop: "1em" }}
                                >
                                    Não quero
                                </Button>
                            </Col>
                        </Row>
                    </DialogContent>
                </Dialog>

                {tabStatus === "VIEW" ? (
                    <Row style={{ width: "100%", margin: "0" }}>
                        <AlertFixo
                            severity="warning"
                            style={{ width: "100%", marginBottom: "1em" }}
                        >
                            Você está conectado em <b>modo visualização</b> (não é possivel aceitar
                            pedidos)
                            <Button
                                color="default"
                                // style={{"background": "#28a745", "color":"white"}}
                                onClick={() => {
                                    setTabStatus("CRUD")
                                    window.name = ""
                                }}
                            >
                                Conectar Modo Aceitar Pedidos
                            </Button>
                        </AlertFixo>
                    </Row>
                ) : null}

                <Row style={{ flexDirection: "row", paddingInline: "15px", gap: "1rem" }}>
                    <TextField
                        style={{ background: "#fff", flex: "1 1 12rem" }}
                        id="procurar-pedido-alternativo"
                        onChange={e => {
                            procurarPedido(e, arrayTodosPedidos)
                        }}
                        label="Procurar"
                        variant="outlined"
                        size="small"
                    />
                    <BotoesUtilitarios
                        expandListaIntegracoes={expandListaIntegracoes}
                        setExpandListaIntegracoes={setExpandListaIntegracoes}
                        setCriarRegistradorModal={setCriarRegistradorModal}
                        resumoDoDia={resumoDoDia}
                        tabStatus={tabStatus}
                        handleBuscarDadosEstabelecimentos={handleBuscarDadosEstabelecimentos}
                        setModalConfiguracoes={setModalConfiguracoes}
                        setRegistradorModal={setRegistradorModal}
                        atualizar={atualizar}
                    />
                </Row>
                <Row>
                    {recebedor.prepararPedidoAutomatico != true ? (
                        <Col xs={12} md={tamanhoLista} lg={tamanhoLista}>
                            <Lista
                                tipoPainelImpressao={tipoPainelImpressao}
                                indexLista={1}
                                solicitarEntregaFuiDelivery={solicitarEntregaFuiDelivery}
                                buscarStatus={buscarStatus}
                                loading={loadingPedidos}
                                array={
                                    recebedor.aceitarPedidoAutomatico
                                        ? pedidosAceitos
                                        : pedidosAguardandoAceitacao
                                }
                                classes={classes}
                                autorizacao={autorizacao}
                                impressorasQZ={impressorasQZ}
                                mandarImprimir={mandarImprimir}
                                setModalInformacoesPedido={setModalInformacoesPedido}
                                tabStatus={tabStatus}
                                mudarStatusPara={mudarStatusPara}
                                imprimirNotaFiscal={imprimirNotaFiscal}
                                efeitoSonoro={efeitoSonoro}
                                prontoEntrega={prontoEntrega}
                                alertStart={alertStart}
                                agrupamentos={agrupamentos}
                            />
                        </Col>
                    ) : null}

                    <Col xs={12} md={tamanhoLista} lg={tamanhoLista}>
                        <Lista
                            tipoPainelImpressao={tipoPainelImpressao}
                            indexLista={2}
                            loading={loadingPedidos}
                            // TODO: Corrigir pedidos sumindo quando troca aceitarautomatico
                            array={
                                recebedor.aceitarPedidoAutomatico
                                    ? pedidosEmPreparacao
                                    : pedidosAceitos.concat(pedidosEmPreparacao)
                            }
                            solicitarEntregaFuiDelivery={solicitarEntregaFuiDelivery}
                            buscarStatus={buscarStatus}
                            configuracoes={configuracoes}
                            classes={classes}
                            autorizacao={autorizacao}
                            impressorasQZ={impressorasQZ}
                            mandarImprimir={mandarImprimir}
                            setModalInformacoesPedido={setModalInformacoesPedido}
                            tabStatus={tabStatus}
                            mudarStatusPara={mudarStatusPara}
                            imprimirNotaFiscal={imprimirNotaFiscal}
                            efeitoSonoro={efeitoSonoro}
                            prontoEntrega={prontoEntrega}
                            alertStart={alertStart}
                            agrupamentos={agrupamentos}
                        />
                    </Col>

                    <Col xs={12} md={tamanhoLista} lg={tamanhoLista}>
                        <Lista
                            tipoPainelImpressao={tipoPainelImpressao}
                            indexLista={3}
                            solicitarEntregaFuiDelivery={solicitarEntregaFuiDelivery}
                            buscarStatus={buscarStatus}
                            loading={loadingPedidos}
                            array={pedidosEmEntrega}
                            configuracoes={configuracoes}
                            classes={classes}
                            autorizacao={autorizacao}
                            impressorasQZ={impressorasQZ}
                            mandarImprimir={mandarImprimir}
                            setModalInformacoesPedido={setModalInformacoesPedido}
                            tabStatus={tabStatus}
                            mudarStatusPara={mudarStatusPara}
                            imprimirNotaFiscal={imprimirNotaFiscal}
                            efeitoSonoro={efeitoSonoro}
                            prontoEntrega={prontoEntrega}
                            alertStart={alertStart}
                            agrupamentos={agrupamentos}
                        />
                    </Col>

                    <Col xs={12} md={tamanhoLista} lg={tamanhoLista}>
                        <Lista
                            tipoPainelImpressao={tipoPainelImpressao}
                            indexLista={4}
                            solicitarEntregaFuiDelivery={solicitarEntregaFuiDelivery}
                            buscarStatus={buscarStatus}
                            loading={loadingPedidos}
                            array={pedidosFinalizados}
                            configuracoes={configuracoes}
                            classes={classes}
                            autorizacao={autorizacao}
                            impressorasQZ={impressorasQZ}
                            mandarImprimir={mandarImprimir}
                            setModalInformacoesPedido={setModalInformacoesPedido}
                            tabStatus={tabStatus}
                            mudarStatusPara={mudarStatusPara}
                            imprimirNotaFiscal={imprimirNotaFiscal}
                            efeitoSonoro={efeitoSonoro}
                            prontoEntrega={prontoEntrega}
                            alertStart={alertStart}
                            agrupamentos={agrupamentos}
                        />
                    </Col>

                    <DialogCancelamento
                        listaMotivoCancelamento={listaMotivoCancelamento}
                        confirmarCancelamento={confirmarCancelamento}
                        setConfirmarCancelamento={setConfirmarCancelamento}
                        removerPedidoListaAnterior={removerPedidoListaAnterior}
                        alertStart={alertStart}
                        setLoading={setLoading}
                    />

                    <Dialog
                        fullScreen={fullScreen}
                        open={modalIfoodConfig}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={() => {
                            setModalIfoodConfig(false)
                        }}
                    >
                        <DialogTitle>{"Dados iFood"}</DialogTitle>
                        <DialogContent>
                            <Col
                                xs={12}
                                md={12}
                                lg={12}
                                style={{ marginBottom: "1em", textAlign: "center" }}
                            >
                                <Typography>
                                    {statusLojaIfood?.title
                                        ? statusLojaIfood?.title
                                        : "--- Sem informações ---"}
                                </Typography>

                                <Typography>{statusLojaIfood?.message}</Typography>
                            </Col>

                            {statusLojaIfood?.extraInfo?.map((info, index) => (
                                <Col
                                    key={index}
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    style={{ marginBottom: "1em", textAlign: "center" }}
                                >
                                    <Typography>{info ? " * " + info : "---"}</Typography>
                                </Col>
                            ))}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    setModalIfoodConfig(false)
                                }}
                            >
                                Voltar
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        fullScreen={fullScreen}
                        open={modalConfiguracoes}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={() => {
                            setModalConfiguracoes(false)
                            if (mudancaConfiguracao) {
                                atualizar(true)
                                setMudancaConfiguracao(false)
                            }
                        }}
                    >
                        <DialogTitle>{"Configurações"}</DialogTitle>

                        <DialogContent>
                            <Row>
                                <Col xs={12} style={{ margin: "0.5em 0", textAlign: "center" }}>
                                    {/* <img src={RECEBEDOR_ICON} style={{"width": '70px', "height": '70px'}}/> */}
                                    <img
                                        src={
                                            configuracoes.user?.parametros.estabelecimento?.urlLogo
                                                ? configuracoes.user?.parametros.estabelecimento
                                                      ?.urlLogo
                                                : aplicativoDados.urlLogo
                                        }
                                        style={{ width: "70px", height: "70px" }}
                                        alt=""
                                    />
                                </Col>

                                <Col xs={12} md={6} lg={6} style={{ margin: "0.5em 0" }}>
                                    <TextField
                                        id="att-automatico-todos-pedidos"
                                        defaultValue={recebedor.buscarAutomaticamenteXSegundos}
                                        onChange={e => {
                                            if (e.target.value >= 60 || e.target.value <= 0) {
                                                var valor = e.target.value
                                                if (e.target.value <= 0) {
                                                    valor = 180
                                                    alertStart(
                                                        `Atualização automatica configurada a cada ${180} segundos!`,
                                                        "success"
                                                    )
                                                } else {
                                                    alertStart(
                                                        `Atualização automatica configurada a cada ${valor} segundos!`,
                                                        "success"
                                                    )
                                                }
                                                const tmp = JSON.parse(
                                                    JSON.stringify(configuracoes)
                                                )
                                                tmp.recebedor.buscarAutomaticamenteXSegundos = valor
                                                atualizarConfiguracoes(tmp)
                                                setMudancaConfiguracao(true)
                                            } else {
                                                alertStart(
                                                    "Para atualizar automatico necessário tempo acima de 60 segundos!",
                                                    "error"
                                                )
                                            }
                                        }}
                                        fullWidth
                                        placeholder={"120"}
                                        label="Atualizar em Segundos"
                                        variant="outlined"
                                    />
                                </Col>

                                <Col xs={12} md={6} lg={6} style={{ margin: "0.5em 0" }}>
                                    <TextField
                                        id="exibicao-pedidos-maximo"
                                        defaultValue={recebedor.exibicaoMaxima}
                                        onChange={e => {
                                            if (e.target.value >= 10) {
                                                var valor = e.target.value
                                                const tmp = JSON.parse(
                                                    JSON.stringify(configuracoes)
                                                )
                                                tmp.recebedor.exibicaoMaxima = valor
                                                atualizarConfiguracoes(tmp)
                                                setMudancaConfiguracao(true)
                                            } else {
                                                alertStart(
                                                    `A exibição mínima é de 10 pedidos`,
                                                    "error"
                                                )
                                            }
                                        }}
                                        fullWidth
                                        placeholder={"30"}
                                        label="Exibicação Máxima de Pedidos"
                                        variant="outlined"
                                    />
                                </Col>

                                <Col
                                    xs={12}
                                    md={6}
                                    lg={6}
                                    style={{ margin: "0.5em 0", display: "flex", gap: "0.25rem" }}
                                >
                                    <div style={{ flex: 1 }}>
                                        <TextField
                                            disabled
                                            defaultValue={parametros.viasImpressao}
                                            fullWidth
                                            label="Quant. de Vias"
                                            variant="outlined"
                                        />
                                    </div>
                                    {tipoPainelImpressao === "navegador" ? (
                                        <div style={{ flex: 1 }}>
                                            <FormControl
                                                variant="outlined"
                                                className={classes.formControl}
                                                fullWidth
                                            >
                                                <InputLabel>Tamanho Impr.</InputLabel>
                                                <Select
                                                    fullWidth
                                                    variant="outlined"
                                                    value={
                                                        configuracoes.printer.tamanhoFonteNavegador
                                                    }
                                                    defaultValue={"normal"}
                                                    onChange={e => {
                                                        const tmp = JSON.parse(
                                                            JSON.stringify(configuracoes)
                                                        )
                                                        tmp.printer.tamanhoFonteNavegador =
                                                            e.target.value
                                                        atualizarConfiguracoes(tmp)
                                                    }}
                                                    label="Tamanho Impr."
                                                >
                                                    <MenuItem value="normal">Normal</MenuItem>
                                                    <MenuItem value="grande">Grande</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    ) : null}
                                </Col>

                                <Col xs={12} md={6} lg={6} style={{ margin: "0.5em 0" }}>
                                    <>
                                        <FormControl
                                            variant="outlined"
                                            className={classes.formControl}
                                            style={{ width: "100%" }}
                                        >
                                            <InputLabel id="tipopainel">
                                                Painel com Impressão
                                            </InputLabel>
                                            <Select
                                                fullWidth
                                                value={tipoPainelImpressao}
                                                disabled
                                                label="Painel com Impressão"
                                            >
                                                <MenuItem value="navegador">
                                                    Navegador Nativo
                                                </MenuItem>
                                                <MenuItem value={"plugin"}>Plugin QZ</MenuItem>
                                                <MenuItem value={"software"}>Software EXE</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </>
                                </Col>

                                {exibirSeletorTamanho ? (
                                    <Col xs={12} md={6} lg={6} style={{ margin: "0.5em 0" }}>
                                        <>
                                            <FormControl
                                                variant="outlined"
                                                className={classes.formControl}
                                                style={{ width: "100%" }}
                                            >
                                                <InputLabel id="impressoras">
                                                    Tamanho da Fonte
                                                </InputLabel>
                                                <Select
                                                    fullWidth
                                                    value={tamanhoFonteSelect}
                                                    onChange={e => {
                                                        const tmp = JSON.parse(
                                                            JSON.stringify(configuracoes)
                                                        )
                                                        tmp.printer.tamanhoFonteSelect =
                                                            e.target.value
                                                        atualizarConfiguracoes(tmp)
                                                    }}
                                                    label="Tamanho da Fonte"
                                                >
                                                    <MenuItem value="pequena">
                                                        <em>Pequena</em>
                                                    </MenuItem>

                                                    <MenuItem value="media">
                                                        <em>Media</em>
                                                    </MenuItem>

                                                    <MenuItem value="grande">
                                                        <em>Grande</em>
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </>
                                    </Col>
                                ) : null}

                                {tipoPainelImpressao === "plugin" &&
                                    tipoPainelImpressao !== "software" &&
                                    exibirTamanhoFonte && (
                                        <Col xs={12} md={6} lg={6} style={{ margin: "0.5em 0" }}>
                                            <TextField
                                                id="tamanho-font"
                                                value={printer.tamanhoFonteImpressao}
                                                onChange={e => {
                                                    // if(e.target.value >= 10 && e.target.value <= 30){
                                                    var valor = e.target.value
                                                    // document.getElementsByTagName('HTML')[0].style.fontSize = `${valor}%`

                                                    const tmp = JSON.parse(
                                                        JSON.stringify(configuracoes)
                                                    )
                                                    tmp.printer.tamanhoFonteImpressao = valor
                                                    atualizarConfiguracoes(tmp)
                                                    setMudancaConfiguracao(true)
                                                    // }
                                                }}
                                                fullWidth
                                                placeholder={"14"}
                                                type={"number"}
                                                label="Tamanho Fonte Impressão"
                                                variant="outlined"
                                            />
                                        </Col>
                                    )}

                                <Col xs={12} md={6} lg={6} style={{ margin: "0.5em 0" }}>
                                    {!mobile &&
                                    tabStatus === "CRUD" &&
                                    tipoPainelImpressao === "plugin" ? (
                                        <>
                                            <FormControl
                                                variant="outlined"
                                                className={classes.formControl}
                                                style={{ width: "100%" }}
                                            >
                                                <InputLabel id="impressoras">
                                                    Tipo Printer Cliente Fiel
                                                </InputLabel>
                                                <Select
                                                    fullWidth
                                                    labelId="tipoImpressao"
                                                    id="tipoImpressao"
                                                    value={tipoPrinter}
                                                    // value={}
                                                    onChange={e => {
                                                        const tmp = JSON.parse(
                                                            JSON.stringify(configuracoes)
                                                        )
                                                        tmp.printer.tipoImpressao = e.target.value
                                                        atualizarConfiguracoes(tmp)
                                                    }}
                                                    label="Tipo Printer Cliente Fiel"
                                                >
                                                    <MenuItem value="code">
                                                        <em>Tipo CODE</em>
                                                    </MenuItem>

                                                    <MenuItem value="html">
                                                        <em>Tipo HTML</em>
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </>
                                    ) : null}
                                </Col>

                                <ListaImpressorasSelecionadas
                                    mobile={mobile}
                                    tabStatus={tabStatus}
                                    tipoPainelImpressao={tipoPainelImpressao}
                                    impressorasQZ={impressorasQZ}
                                    modalConfiguracoes={modalConfiguracoes}
                                />

                                {tipoPainelImpressao === "navegador" ? (
                                    <Col xs={12} md={6} lg={6} style={{ margin: "0.2em 0 1em 0" }}>
                                        <Button
                                            onClick={e => {
                                                window.open(
                                                    "https://ajuda.clientefiel.app/painel-cliente-fiel/ferramentas/formas-de-impressao",
                                                    "_blank"
                                                )
                                            }}
                                            variant="contained"
                                            style={{ backgroundColor: "#6c757d", color: "white" }}
                                        >
                                            Imprimir Comanda Automaticamente
                                        </Button>
                                    </Col>
                                ) : null}

                                {!mobile && tabStatus === "CRUD" ? (
                                    <Col xs={12} md={12} lg={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    defaultChecked={
                                                        recebedor.aceitarPedidoAutomatico
                                                    }
                                                    onChange={e => {
                                                        recebedor.aceitarPedidoAutomatico =
                                                            e.target.checked
                                                        if (!e.target.checked) {
                                                            recebedor.prepararPedidoAutomatico =
                                                                e.target.checked
                                                        }
                                                        atualizarConfiguracoes(configuracoes)
                                                        setMudancaConfiguracao(true)

                                                        if (recebedor.aceitarPedidoAutomatico) {
                                                            pedidosAguardandoAceitacao.forEach(
                                                                pedido => {
                                                                    if (pedido.status === 0)
                                                                        aceitarPedido(pedido)
                                                                }
                                                            )
                                                        }
                                                    }}
                                                    icon={
                                                        <CheckBoxOutlineBlankIcon fontSize="small" />
                                                    }
                                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                    name="aceite-automatico"
                                                />
                                            }
                                            label="Aceitar Automaticamente os Pedidos"
                                        />
                                    </Col>
                                ) : null}

                                {!mobile &&
                                    tabStatus === "CRUD" &&
                                    recebedor.aceitarPedidoAutomatico && (
                                        <>
                                            <Col xs={12} md={12} lg={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            // disabled
                                                            defaultChecked={
                                                                recebedor.prepararPedidoAutomatico
                                                            }
                                                            onChange={e => {
                                                                recebedor.prepararPedidoAutomatico =
                                                                    e.target.checked
                                                                atualizarConfiguracoes(
                                                                    configuracoes
                                                                )
                                                                setMudancaConfiguracao(true)
                                                            }}
                                                            icon={
                                                                <CheckBoxOutlineBlankIcon fontSize="small" />
                                                            }
                                                            checkedIcon={
                                                                <CheckBoxIcon fontSize="small" />
                                                            }
                                                            name="preparar-automatico"
                                                        />
                                                    }
                                                    label="Preparar Automaticamente os Pedidos"
                                                />
                                            </Col>
                                            {tipoPainelImpressao === "navegador" && (
                                                <Col xs={12} md={12} lg={12}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                defaultChecked={
                                                                    recebedor.imprimirPedidoAutomatico
                                                                }
                                                                onChange={e => {
                                                                    recebedor.imprimirPedidoAutomatico =
                                                                        e.target.checked
                                                                    atualizarConfiguracoes(
                                                                        configuracoes
                                                                    )
                                                                    setMudancaConfiguracao(true)
                                                                }}
                                                                icon={
                                                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                                                }
                                                                checkedIcon={
                                                                    <CheckBoxIcon fontSize="small" />
                                                                }
                                                                name="imprimir-automatico"
                                                            />
                                                        }
                                                        label="Imprimir Automaticamente os Pedidos"
                                                    />
                                                </Col>
                                            )}
                                        </>
                                    )}

                                {!mobile && tabStatus === "CRUD" && false ? (
                                    <Col xs={12} md={12} lg={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    defaultChecked={recebedor.alertaTempoEntrega}
                                                    onChange={e => {
                                                        recebedor.alertaTempoEntrega =
                                                            e.target.checked
                                                        atualizarConfiguracoes(configuracoes)
                                                        setMudancaConfiguracao(true)
                                                    }}
                                                    icon={
                                                        <CheckBoxOutlineBlankIcon fontSize="small" />
                                                    }
                                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                    name="preparar-automatico"
                                                />
                                            }
                                            label="Alertar que o pedido está atrasado"
                                        />
                                    </Col>
                                ) : null}

                                <Col xs={12} md={12} lg={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={recebedor.efeitoSonoro}
                                                onChange={e => {
                                                    // //console.log(e.target.checked)
                                                    recebedor.efeitoSonoro = e.target.checked
                                                    atualizarConfiguracoes(configuracoes)
                                                    setMudancaConfiguracao(true)

                                                    window.setCookie(
                                                        "efeito-sonoro-ativo",
                                                        e.target.checked,
                                                        9999
                                                    )
                                                }}
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                name="efeito-sonoro"
                                            />
                                        }
                                        label="Efeito Sonoro"
                                    />
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        value={recebedor.duracaoEfeitoSonoro}
                                        label="Duração"
                                        disabled={!recebedor.efeitoSonoro}
                                        style={{ maxWidth: "5.5rem" }}
                                        onChange={e => {
                                            recebedor.duracaoEfeitoSonoro = e.target.value.replace(
                                                /\D/g,
                                                ""
                                            )
                                            atualizarConfiguracoes(configuracoes)
                                            setMudancaConfiguracao(true)
                                        }}
                                    />
                                </Col>

                                <Col xs={12} md={12} lg={6}>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        value={recebedor.limitePedidosFinalizados}
                                        label="Limite Pedidos Finalizados"
                                        helperText="0 para não haver limite"
                                        onChange={e => {
                                            const numberLimite = Number(
                                                e.target.value.replace(/\D/g, "")
                                            )

                                            if (!isNaN(numberLimite)) {
                                                recebedor.limitePedidosFinalizados = numberLimite
                                            }

                                            atualizarConfiguracoes(configuracoes)
                                            setMudancaConfiguracao(true)
                                        }}
                                    />
                                </Col>

                                {exibirImprimirLogo ? (
                                    <Col xs={12} md={12} lg={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    defaultChecked={printer.imprimirLogo}
                                                    onChange={e => {
                                                        const tmp = JSON.parse(
                                                            JSON.stringify(configuracoes)
                                                        )
                                                        tmp.printer.imprimirLogo = e.target.checked
                                                        atualizarConfiguracoes(tmp)
                                                    }}
                                                    icon={
                                                        <CheckBoxOutlineBlankIcon fontSize="small" />
                                                    }
                                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                    name="imprimir-logo"
                                                />
                                            }
                                            label="Imprimir logo"
                                        />
                                    </Col>
                                ) : null}

                                <Col xs={12} md={6} lg={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                defaultChecked={recebedor.mostrarEndereco}
                                                onChange={e => {
                                                    // //console.log(e.target.checked)
                                                    recebedor.mostrarEndereco = e.target.checked
                                                    atualizarConfiguracoes(configuracoes)
                                                    setMudancaConfiguracao(true)
                                                }}
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                name="mostrar-endereco"
                                            />
                                        }
                                        label="Mostrar Endereço"
                                    />
                                </Col>
                                <Col xs={12} md={6} lg={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                defaultChecked={recebedor.mostrarEntregador}
                                                onChange={e => {
                                                    // //console.log(e.target.checked)
                                                    recebedor.mostrarEntregador = e.target.checked
                                                    atualizarConfiguracoes(configuracoes)
                                                    setMudancaConfiguracao(true)
                                                }}
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                name="mostrar-entregador"
                                            />
                                        }
                                        label="Mostrar Entregador"
                                    />
                                </Col>
                            </Row>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    setModalConfiguracoes(false)
                                    if (mudancaConfiguracao) {
                                        atualizar(true)
                                        setMudancaConfiguracao(false)
                                    }
                                }}
                            >
                                Voltar
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        fullScreen={fullScreen}
                        open={openEstabelecimentos}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseEstabelecimentos}
                    >
                        <DialogTitle>{"Configurações dos Estabelecimentos"}</DialogTitle>
                        <DialogContent>
                            {estabelecimentos.map((estabelecimento, index) => (
                                <Row key={index}>
                                    <Col
                                        xs={12}
                                        md={12}
                                        lg={12}
                                        style={{ marginBottom: "1em", textAlign: "center" }}
                                    >
                                        <Typography>{estabelecimento.nome}</Typography>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} style={{ marginBottom: "1em" }}>
                                        <FormControl
                                            variant="outlined"
                                            className={classes.formControl}
                                            fullWidth
                                        >
                                            <InputLabel htmlFor="outlined-age-native-simple">
                                                Status
                                            </InputLabel>
                                            <Select
                                                native
                                                value={
                                                    estabelecimento.situacao === 1 &&
                                                    estabelecimento.pausado
                                                        ? 2
                                                        : estabelecimento.situacao
                                                }
                                                fullWidth
                                                label="Status"
                                                style={{ padding: 0 }}
                                                onChange={e => {
                                                    atualizarStatusEstabelecimento(
                                                        e,
                                                        estabelecimento,
                                                        index
                                                    )
                                                }}
                                                endAdornment={
                                                    <InputAdornment
                                                        style={{
                                                            position: "absolute",
                                                            left: "15px",
                                                        }}
                                                    >
                                                        {estabelecimento.situacao === 1 &&
                                                        estabelecimento.pausado ? (
                                                            <PauseCircleFilledIcon
                                                                style={{ color: "#ffc107" }}
                                                            />
                                                        ) : estabelecimento.situacao === 0 ? (
                                                            <CancelIcon
                                                                style={{ color: "#dc3545" }}
                                                            />
                                                        ) : (
                                                            <PlayCircleFilledIcon
                                                                style={{ color: "#28a745" }}
                                                            />
                                                        )}
                                                    </InputAdornment>
                                                }
                                                inputProps={{
                                                    name: "Status",
                                                    id: "select-status-estabelecimento",
                                                }}
                                            >
                                                <option value={1}>Aberto</option>
                                                <option value={0}>Fechado</option>
                                                <option value={2}>Pausado</option>
                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={6} lg={6}>
                                        <TextField
                                            className="tempoEntrega"
                                            variant="outlined"
                                            placeholder="Tempo de entrega"
                                            helperText="Exemplo: 45-60 min"
                                            defaultValue={estabelecimento.tempoEntregaTexto}
                                            inputProps={{
                                                "data-index-estabelecimento": index,
                                            }}
                                        />
                                    </Col>
                                    <Divider className={"divisaoEstabelecimentos"}></Divider>
                                </Row>
                            ))}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseEstabelecimentos}>Voltar</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={openConfirmacaoPedidosRepetidosFui}
                        onClose={() => setOpenConfirmacaoPedidosRepetidosFui(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Informativo"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {messageOpenConfirmacaoPedidosRepetidosFui}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => setOpenConfirmacaoPedidosRepetidosFui(false)}
                                color="default"
                            >
                                Cancelar
                            </Button>
                            <Button
                                onClick={() => {
                                    calcularValorEntregaFuiApp()
                                    setOpenConfirmacaoPedidosRepetidosFui(false)
                                }}
                                color="primary"
                                autoFocus
                            >
                                Consultar mesmo assim
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        fullScreen={fullScreen}
                        open={modalEntregadorFuiDelivery?.openModal}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={() => {
                            setPedidosEntregaFuiApp([])
                            setModalEntregadorFuiDelivery({
                                ...modalEntregadorFuiDelivery,
                                openModal: false,
                                pedidoSelecionado: null,
                            })
                        }}
                    >
                        <DialogTitle>{"Fui App Delivery"}</DialogTitle>
                        <DialogContent>
                            <Row>
                                <>
                                    <Col xs={12} md={12} lg={12}>
                                        <Typography variant="body2" component="p">
                                            Agrupar com outros pedidos
                                        </Typography>

                                        {showCheckbox(
                                            modalEntregadorFuiDelivery?.pedidoSelecionado
                                        )}
                                    </Col>
                                </>
                            </Row>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    validarPedidosJaEnviadosFuiDelivery()
                                }}
                            >
                                Calcular Valor
                            </Button>
                            <Button
                                onClick={() => {
                                    setPedidosEntregaFuiApp([])
                                    setModalEntregadorFuiDelivery({
                                        ...modalEntregadorFuiDelivery,
                                        openModal: false,
                                        pedidoSelecionado: null,
                                    })
                                }}
                            >
                                Voltar
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {showDialogConfirmarValoresFui()}

                    <DialogInformacoesPedido
                        tabStatus={tabStatus}
                        mudarStatusPara={mudarStatusPara}
                        mandarImprimir={mandarImprimir}
                    />
                </Row>
                <ListaIntegracoes
                    expandListaIntegracoes={expandListaIntegracoes}
                    setExpandListaIntegracoes={setExpandListaIntegracoes}
                    statusLojaIfood={statusLojaIfood}
                />
                <DialogAlertas
                    alertas={alertas}
                    abrirAlertas={abrirAlertas}
                    setAbrirAlertas={setAbrirAlertas}
                />
            </Container>
        </RecebedorContextProvider>
    )
}
