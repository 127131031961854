import Container from "@material-ui/core/Container"
import React, { useContext } from "react"
import CaixaContext from "../../../contexts/caixaContext"

import IconButton from "@material-ui/core/IconButton"
import Paper from "@material-ui/core/Paper"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import AddIcon from "@material-ui/icons/Add"
import ReceiptIcon from "@material-ui/icons/Receipt"
import VisibilityIcon from "@material-ui/icons/Visibility"
import { Col, Row } from "react-bootstrap"

import Avatar from "@material-ui/core/Avatar"
import Divider from "@material-ui/core/Divider"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemText from "@material-ui/core/ListItemText"
import DialogAddRetirada from "../components/DialogAddRetirada"
import DialogViewRetiradas from "../components/DialogViewRetiradas"

const useStyles = makeStyles(theme => ({
    list: {
        width: "100%",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}))

export default function CamposInformativos(props) {
    const theme = useTheme()
    const classes = useStyles()
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

    const { caixaSelecionada, setCaixaSelecionada } = useContext(CaixaContext)

    const [openAddRetirada, setOpenAddRetirada] = React.useState(false)
    const [openViewRetiradas, setOpenViewRetiradas] = React.useState(false)

    const list1 = [
        {
            icon: <ReceiptIcon />,
            titulo: `Dinheiro Inicial`,
            descricao: Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(
                caixaSelecionada.dinheiroInicial
            ),
            // add: <IconButton onClick={() => {setOpenAddRetirada(true)}}><AddIcon style={{color: '#28a745'}}/></IconButton>,
            // view: <IconButton onClick={() => {setOpenAddRetirada(true)}}><AddIcon style={{color: '#28a745'}}/></IconButton>,
        },
        {
            icon: <ReceiptIcon />,
            titulo: `${caixaSelecionada.quantidadePedidos} Vendas`,
            descricao: Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(
                caixaSelecionada.somaVenda
            ),
            // add: <IconButton onClick={() => {setOpenAddRetirada(true)}}><AddIcon style={{color: '#28a745'}}/></IconButton>,
            // view: <IconButton onClick={() => {setOpenAddRetirada(true)}}><AddIcon style={{color: '#28a745'}}/></IconButton>,
        },
        {
            icon: <ReceiptIcon />,
            titulo: `Valor em Caixa`,
            descricao: Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(
                caixaSelecionada.somaDinheiroEmCaixa
            ),
            // add: <IconButton onClick={() => {setOpenAddRetirada(true)}}><AddIcon style={{color: '#28a745'}}/></IconButton>,
            // view: <IconButton onClick={() => {setOpenAddRetirada(true)}}><AddIcon style={{color: '#28a745'}}/></IconButton>,
        },
    ]

    const list2 = [
        {
            icon: <ReceiptIcon />,
            titulo: `${0} Notas Geradas`,
            descricao: "-",
            // add: <IconButton onClick={() => {setOpenAddRetirada(true)}}><AddIcon style={{color: '#28a745'}}/></IconButton>,
            // view: <IconButton onClick={() => {setOpenAddRetirada(true)}}><AddIcon style={{color: '#28a745'}}/></IconButton>,
        },
        {
            icon: <ReceiptIcon />,
            titulo: `${caixaSelecionada.quantidadeEntregadoresAssociados} Entregadores Associados`,
            descricao: "-",
            // add: <IconButton onClick={() => {setOpenAddRetirada(true)}}><AddIcon style={{color: '#28a745'}}/></IconButton>,
            // view: <IconButton onClick={() => {setOpenAddRetirada(true)}}><AddIcon style={{color: '#28a745'}}/></IconButton>,
        },
        {
            icon: <ReceiptIcon />,
            titulo: `${caixaSelecionada.quantidadeRetiradasDoCaixa} Alterações`,
            descricao: Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(
                caixaSelecionada.somaRetiradasDoCaixa
            ),
            add: (
                <IconButton
                    onClick={() => {
                        setOpenAddRetirada(true)
                    }}
                >
                    <AddIcon style={{ color: "#28a745" }} />
                </IconButton>
            ),
            view: (
                <IconButton
                    onClick={() => {
                        setOpenViewRetiradas(true)
                    }}
                >
                    <VisibilityIcon style={{ color: "#6d6d6d" }} />
                </IconButton>
            ),
        },
    ]

    return (
        <Paper>
            <Container>
                <Row>
                    <Col xs={12} md={12} lg={6}>
                        <List className={classes.list}>
                            {list1.map((item, index) => (
                                <React.Fragment key={index}>
                                    {/* <CardActionArea onClick={item.funcao}> */}
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>{item.icon}</Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={item.titulo}
                                            secondary={item.descricao}
                                        />
                                        {item.add}
                                        {item.view}
                                    </ListItem>
                                    {/* </CardActionArea> */}
                                    {index + 1 != list1.length && (
                                        <Divider variant="inset" component="li" />
                                    )}
                                </React.Fragment>
                            ))}
                        </List>
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                        <List className={classes.list}>
                            {list2.map((item, index) => (
                                <React.Fragment key={index}>
                                    {/* <CardActionArea onClick={item.funcao}> */}
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>{item.icon}</Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={item.titulo}
                                            secondary={item.descricao}
                                        />
                                        {item.add}
                                        {item.view}
                                    </ListItem>
                                    {/* </CardActionArea> */}
                                    {index + 1 != list2.length && (
                                        <Divider variant="inset" component="li" />
                                    )}
                                </React.Fragment>
                            ))}
                        </List>
                    </Col>
                </Row>
            </Container>

            {openAddRetirada && (
                <DialogAddRetirada
                    fullScreen={fullScreen}
                    open={openAddRetirada}
                    setOpen={setOpenAddRetirada}
                />
            )}

            {openViewRetiradas && (
                <DialogViewRetiradas
                    fullScreen={fullScreen}
                    open={openViewRetiradas}
                    setOpen={setOpenViewRetiradas}
                    retiradas={caixaSelecionada.retiradasDoCaixa}
                />
            )}
        </Paper>
    )
}
