import api from "./api"
import { RegistrarLogErro } from "./clientefiel"

function tratamentoResponse(response, chamada) {
    if (response.data.codErro > 0)
        return { retornoErro: response.data.codErro, mensagem: response.data.mensagem }
    else if (typeof response.data == "string" && response.data != "")
        return {
            retornoErro: 1,
            mensagem: chamada + " - Erro inesperado, entre em contato com os reponsáveis.",
        }
    else return response.data
}

const ErroRequisicao = function (data, aplicativoDados) {
    try {
        console.log("ErroRequisicao> ", data.request, data.message)
        if (data.message == "Network Error") {
            return "Verifique sua conexão!"
            // return 'Sem Internet ou sistema fora do ar!'
        } else if (data.message) {
            return "Erro: " + data.message
        }
        return false
    } catch (error) {
        RegistrarLogErro(error, "ErroRequisicao", data)
    }
}

export const ComumCep = async function (data, appNome) {
    try {
        const response = await api
            .get("comum/Cep/" + data, {
                headers: { "Content-Type": "application/json", app: appNome },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("comum/Cep/ => ", response)

        return tratamentoResponse(response, "ComumCep")
    } catch (error) {
        RegistrarLogErro(error, "ComumCep", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const CumumBairros = async function (data, appNome) {
    try {
        const response = await api
            .post("comum/Bairros", data, {
                headers: { "Content-Type": "application/json", app: appNome },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("CumumBairros => ", response)

        return tratamentoResponse(response, "CumumBairros")
    } catch (error) {
        RegistrarLogErro(error, "CumumBairros", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const CidadesAtendidasPorAplicativo = async function (data, aplicativoDados) {
    console.log("CidadesAtendidasPorAplicativo", data, aplicativoDados)
    try {
        const response = await api
            .post("comum/CidadesAtendidasPorAplicativo", data, {
                headers: { "Content-Type": "application/json", app: data.appNome },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("CidadesAtendidasPorAplicativo => ", response)

        return tratamentoResponse(response, "CidadesAtendidasPorAplicativo")
    } catch (error) {
        RegistrarLogErro(error, "CidadesAtendidasPorAplicativo", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}
