import React, { useContext, useState } from "react"
import ServicosContext from "../../contexts/servicosContext"

import api from "../../services/api"
import Loading from "../Componentes/Loading"

import { RegistrarLogErro } from "../../services/clientefiel"
import { verificarConfigsUsuario } from "../../services/functionsGerais"

import Button from "@material-ui/core/Button"
import CssBaseline from "@material-ui/core/CssBaseline"
import Snackbar from "@material-ui/core/Snackbar"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import MuiAlert from "@material-ui/lab/Alert"
import { Col, Row } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import background from "../../assets/background-min.jpg"
import "./styles.css"
const useStyles = makeStyles(theme => ({
    container: {
        margin: 0,
        padding: 0,
        minHeight: "100vh",
        width: "100%",
        background: `url('${background}') no-repeat center center fixed`,
        backgroundSize: "cover",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    rootAlert: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    root: {
        width: "100%",
        margin: "1em 0",
    },
    pos: {
        marginBottom: 12,
    },
}))

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default function Login() {
    const aplicativoDados = JSON.parse(localStorage.getItem("aplicativoCF"))

    const history = useHistory()
    const classes = useStyles()

    const [usuario, setUsuario] = useState("")
    const [senha, setSenha] = useState("")
    const [loading, setLoading] = React.useState(false)
    const [alert, setAlert] = useState({ status: false, tipo: "success", mesangem: "" })

    const { atualizarConfiguracoes, configuracoes, buscarParametros } = useContext(ServicosContext)

    if (localStorage.getItem("chaveSistema")) {
        history.push("/servicos")
    }

    const alertStart = (msg, tipo) => {
        setAlert({ status: true, tipo: tipo, mesangem: msg })
    }

    const alertClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setAlert({ status: false, tipo: alert.tipo, mesangem: alert.mesangem })
    }

    async function realizarLogin_UsuarioSenha(e = false) {
        if (e) {
            e.preventDefault()
        }

        setLoading(true)
        try {
            // alertStart("Fazendo login", "warning")
            setLoading(true)

            const fornecedor = {
                appNome: aplicativoDados?.appNome,
                email: usuario,
                hashSenha: senha,
                codigoDispositivo: "SistemaReact",
                token: "a~I?;;+_@!AL^",
            }
            await api
                .post(`fornecedor/LoginFornecedor`, fornecedor, {
                    headers: { "Content-Type": "application/json" },
                })
                .then(async response => {
                    console.log("LoginFornecedor", response)

                    if (typeof response.data == "string") {
                        alertStart("Erro nao esperado ao logar o fornecedor ", "error")
                    } else if (response.data.codErro > 0) {
                        if (response.data.mensagem === "2") {
                            alertStart("Usuário e/ou senha inválidos", "error")
                        }
                    } else {
                        const token = usuario + ":" + senha
                        const hash = "Basic " + btoa(unescape(encodeURIComponent(token)))
                        localStorage.setItem("chaveSistema", hash)

                        verificarConfigsUsuario(response.data)
                        await buscarParametros(hash)

                        history.push("/servicos")

                        window.dispatchEvent(new CustomEvent("login"))
                    }
                })

            setLoading(false)
        } catch (error) {
            RegistrarLogErro(error, "LoginGeral")
            alertStart("Erro nao identificado LoginGeral", "error")
            setLoading(false)
        }
    }

    return (
        <Row className={classes.container}>
            <Loading loading={loading} />

            <Snackbar open={alert.status} autoHideDuration={6000} onClose={alertClose}>
                <Alert onClose={alertClose} severity={alert.tipo}>
                    {alert.mesangem}
                </Alert>
            </Snackbar>

            <CssBaseline />

            <Col xs={12} md={12} lg={3}></Col>
            <Col xs={12} md={12} lg={6} style={{ height: "minContent" }}>
                <div
                    style={{
                        padding: "2em",
                        background: "white",
                        textAlign: "center",
                        borderRadius: "15px",
                    }}
                >
                    <img src={aplicativoDados.urlLogo} style={{ height: "200px" }} alt="" />

                    <form
                        className={(classes.form, "container")}
                        onSubmit={realizarLogin_UsuarioSenha}
                    >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            onChange={e => setUsuario(e.target.value)}
                            id="usuario"
                            value={usuario}
                            label="Email / Telefone"
                            name="usuario"
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            value={senha}
                            onChange={e => setSenha(e.target.value)}
                            name="senha"
                            label="Senha"
                            type="password"
                            id="senha"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Entrar
                        </Button>
                    </form>
                </div>
            </Col>

            <Col xs={12} md={12} lg={3}></Col>
        </Row>
    )
}
