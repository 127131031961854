import React, { useContext } from "react"

import RegistradorContext from "../../../../contexts/registradorContext"

import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Slide from "@material-ui/core/Slide"
import TextField from "@material-ui/core/TextField"
import { Col, Row } from "react-bootstrap"

import TelefoneMask from "../../../../masks/telefone"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function Index() {
    const { openCadastroCliente, setOpenCadastroCliente, cadastrarUsuario } =
        useContext(RegistradorContext)

    return openCadastroCliente ? (
        <Dialog
            open={openCadastroCliente}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpenCadastroCliente(false)}
        >
            <form onSubmit={cadastrarUsuario}>
                <DialogTitle id="alert-dialog-slide-title">{"Cadastro de Cliente"}</DialogTitle>
                <DialogContent>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <TextField
                                id="nome-cadastro"
                                required
                                fullWidth
                                label="Nome"
                                variant="outlined"
                                style={{ background: "#fff" }}
                            />
                        </Col>
                        <Col xs={12} md={12} lg={12} style={{ marginTop: "1em" }}>
                            <TextField
                                id="telefone-cadastro"
                                required
                                fullWidth
                                label="Telefone"
                                variant="outlined"
                                style={{ background: "#fff" }}
                                InputProps={{
                                    inputComponent: TelefoneMask,
                                }}
                            />
                        </Col>
                    </Row>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setOpenCadastroCliente(false)
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        type={"submit"}
                        onClick={cadastrarUsuario}
                        variant="contained"
                        color="primary"
                    >
                        Cadastrar
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    ) : null
}
