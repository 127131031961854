import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardMedia from "@material-ui/core/CardMedia"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { Col, Row } from "react-bootstrap"
import CAIXA_ICON from "../../../assets/icons/svg/010-rook.svg"
import Cabecalho from "../../Cabecalho"

const useStyles = makeStyles(theme => ({}))

export default function MostrarEstabelecimentos(props) {
    const { handle, estabelecimentos } = props

    const classes = useStyles()

    return (
        <>
            <Cabecalho pagina={"Estabelecimentos"} />
            <Row style={{ alignContent: "center", justifyContent: "center", marginTop: "4em" }}>
                <Col xs={12} md={12} lg={12} style={{ textAlign: "center" }}>
                    <Typography variant="h5" gutterBottom>
                        Estabelecimentos Disponíveis
                    </Typography>
                </Col>

                {estabelecimentos.map((estabelecimento, index) => (
                    <Col xs={12} md={6} lg={4} key={index} className="colCardServico">
                        <Card className={classes.root}>
                            <CardActionArea
                                onClick={() => {
                                    handle(estabelecimento)
                                }}
                                style={{
                                    height: "100%",
                                    flexFlow: "column-reverse",
                                    alignItems: "normal",
                                }}
                            >
                                <Row
                                    style={{
                                        margin: 0,
                                        textAlign: "",
                                        width: "100%",
                                        padding: "1em",
                                        alignItems: "center",
                                    }}
                                >
                                    <Col xs={2} md={2} lg={2} style={{ padding: 0 }}>
                                        <CardMedia
                                            component="img"
                                            className={"logoLojas"}
                                            style={{ opacity: "0.65" }}
                                            alt={estabelecimento.id}
                                            image={CAIXA_ICON}
                                            title={estabelecimento.id}
                                        />
                                    </Col>

                                    <Col xs={10} md={10} lg={10}>
                                        {/* <Typography variant="body2" color="textSecondary" component="p" >
                          {estabelecimento.nome}
                        </Typography> */}

                                        <Typography gutterBottom component="h2">
                                            {estabelecimento.nome}
                                        </Typography>
                                    </Col>
                                </Row>

                                <CardActions style={{ padding: "0" }}>
                                    <Typography
                                        variant={"button"}
                                        style={{ textAlign: "center", padding: "3px" }}
                                        className={
                                            (true ? "botaoVerde" : "botaoVermelho") +
                                            " naoArredondado"
                                        }
                                    >
                                        SELECIONAR
                                    </Typography>
                                </CardActions>
                            </CardActionArea>
                        </Card>
                    </Col>
                ))}
            </Row>
        </>
    )
}
