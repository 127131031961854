import { Button } from "@material-ui/core"
import RefreshIcon from "@material-ui/icons/Refresh"
import { useContext, useEffect, useState } from "react"
import ServicosContext from "../../../contexts/servicosContext"

export default function Segundos({ tabStatus, atualizar }) {
    const { configuracoes } = useContext(ServicosContext)

    const hasIfood = configuracoes.user.parametros.ativaIntegracaoIfood

    const resetTime =
        tabStatus === "VIEW"
            ? 60
            : hasIfood
            ? 30
            : configuracoes.recebedor.buscarAutomaticamenteXSegundos ?? 180

    const [time, setTime] = useState(resetTime)

    const buscarPedidos = (loading = false) => atualizar(loading)

    useEffect(() => {
        const timer = setInterval(() => {
            if (tabStatus !== "OFF") {
                setTime(prev => {
                    if (prev > 0) return prev - 1

                    buscarPedidos()
                    return resetTime
                })
            }
        }, 1000)

        return () => clearInterval(timer)
    }, [tabStatus])

    return (
        <Button onClick={() => buscarPedidos(true)} startIcon={<RefreshIcon fontSize="large" />}>
            {time}s
        </Button>
    )
}
