import { useState } from "react"

import {
    Button,
    ButtonGroup,
    Divider,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Radio,
    RadioGroup,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Typography,
} from "@material-ui/core"
import { Add as AddIcon, Remove as RemoveIcon } from "@material-ui/icons"
import { useContext } from "react"
import { Col, Row } from "react-bootstrap"
import registradorContext from "../../../../../contexts/registradorContext"
import ServicosContext from "../../../../../contexts/servicosContext"
import { RegistrarLogErro } from "../../../../../services/clientefiel"
import {
    calcularValorProduto,
    checkButton,
    quantidadeButton,
    radioButton,
} from "../../../functions"

const Produto = ({
    anchor,
    produtoSelecionado,
    setProdutoSelecionado,
    activeStep,
    setActiveStep,
    useStyles,
    alertStart,
    adicionarProduto,
    skipped,
    setSkipped,
    idCategoriaSelecionada,
    clsx,
    state,
    setState,
}) => {
    const classes = useStyles()
    const { configuracoes } = useContext(ServicosContext)
    const { cardapio } = useContext(registradorContext)

    const isStepOptional = step => {
        if (produtoSelecionado.opcoes[step].quantidadeMinima > 0) {
            return 0
        }
        return 1
    }

    const isStepSkipped = step => {
        return skipped.has(step)
    }

    const sortByOrdemExibicao = (a, b) => {
        if (a.ordemExib > b.ordemExib) {
            return 1
        } else if (a.ordemExib < b.ordemExib) {
            return -1
        }
        return 0
    }

    const adicionarGrupoAssociado = (produto, idGrupoAssociado, indexGrupo) => {
        try {
            const grupoAssociado = cardapio.categorias
                .find(categoria => categoria.id === idCategoriaSelecionada)
                ?.produtos.find(produto => produto.nome === "Adicionais Associados")
                ?.opcoes.find(grupoOpcoes => {
                    return grupoOpcoes.id === idGrupoAssociado
                })

            if (produto.opcoes[indexGrupo].id === idGrupoAssociado) return

            if (grupoAssociado) {
                grupoAssociado.grupoExtra = true

                return produto.opcoes.splice(
                    indexGrupo + 1,
                    0,
                    // Necessário para quando se adiciona mais de um grupo
                    JSON.parse(JSON.stringify(grupoAssociado))
                )
            }

            return produto
        } catch (error) {
            RegistrarLogErro(error, "adicionarGrupoAssociado", {
                produto,
                idGrupoAssociado,
                indexGrupo,
            })
            console.error(error)
        }
    }

    const checarAdicionaisAssociados = () => {
        try {
            const index = produtoSelecionado.opcoes.findIndex(
                opcao => opcao.id === grupoDeOpcoesSelecionado.id
            )

            grupoDeOpcoesSelecionado.opcoes.forEach(async opcao => {
                if (opcao.quantidade > 0 && opcao.idGrupoOpcaoAssociado) {
                    for (let i = 0; i < opcao.quantidade; i++) {
                        adicionarGrupoAssociado(
                            produtoSelecionado,
                            opcao.idGrupoOpcaoAssociado,
                            index
                        )
                    }
                }
            })

            return produtoSelecionado
        } catch (error) {
            RegistrarLogErro(error, "checarAdicionaisAssociados", produtoSelecionado)
            console.error(error)
        }
    }

    function selecionarCheckButton(opcaoAdd, grupoDeOpcoesSelecionado, activeStep) {
        try {
            setProdutoSelecionado(
                checkButton(opcaoAdd, grupoDeOpcoesSelecionado, produtoSelecionado, activeStep)
            )
        } catch (error) {
            console.error(error)
            RegistrarLogErro(error, "selecionarCheckButton", { opcaoAdd, grupoDeOpcoesSelecionado })
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    function selecionarQuantidadeButton(add, index, acao) {
        try {
            setProdutoSelecionado(quantidadeButton(add, index, acao, produtoSelecionado))
        } catch (error) {
            RegistrarLogErro(error, "selecionarQuantidadeButton", { add, index, acao })
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    function selecionarRadioButton(add, index) {
        try {
            setProdutoSelecionado(radioButton(add, index, produtoSelecionado))
        } catch (error) {
            RegistrarLogErro(error, "selecionarRadioButton", { add, index })
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    const handleInputDigitavel = (opcao, quantidade) => {
        setProdutoSelecionado(prev => {
            const produto = Object.assign({}, prev)
            const grupoOpcoes = prev.opcoes[activeStep]
            const opcaoSelecionada = grupoOpcoes.opcoes.find(op => op.id === opcao.id)
            const quantidadeAnterior = opcaoSelecionada.quantidade

            if (opcaoSelecionada === undefined) throw new Error("Opção não encontrada")

            opcaoSelecionada.quantidade = Number(quantidade)
            grupoOpcoes.quantidade += Number(quantidade) - quantidadeAnterior

            return produto
        })
    }

    const handleNext = grupoDeOpcoesSelecionado => {
        let newSkipped = skipped
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values())
            newSkipped.delete(activeStep)
        }

        setProdutoSelecionado(checarAdicionaisAssociados(grupoDeOpcoesSelecionado))

        setActiveStep(prevActiveStep => prevActiveStep + 1)
        setSkipped(newSkipped)
    }

    const removerGrupoOpcaoAssociado = grupo => {
        try {
            if (!grupo) return

            const opcoesSelecionadasComIdAssociado = grupo.opcoes.filter(
                opcao => opcao.quantidade !== 0 && opcao.idGrupoOpcaoAssociado
            )

            opcoesSelecionadasComIdAssociado.forEach(o => {
                setProdutoSelecionado(p => {
                    const copia = JSON.parse(JSON.stringify(p))

                    copia.opcoes = copia.opcoes.filter(
                        opcao => opcao.id !== o.idGrupoOpcaoAssociado
                    )

                    return copia
                })
            })
        } catch (error) {
            RegistrarLogErro(error, "removerGrupoOpcaoAssociado", grupo)
            console.error(error)
        }
    }

    const handleBack = () => {
        setValorPesquisa("")
        try {
            if (activeStep === 0) {
                setState({ ...state, bottom: false })
                // handleReset()
            } else {
                setActiveStep(prevActiveStep => prevActiveStep - 1)
            }

            const opcaoAnterior = produtoSelecionado.opcoes[activeStep - 1]
            removerGrupoOpcaoAssociado(opcaoAnterior)
        } catch (error) {
            console.error(error)
            alertStart(error, "error")
            RegistrarLogErro(error, "handleBack", { produtoSelecionado, activeStep })
        }
    }

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.")
        }

        setActiveStep(prevActiveStep => prevActiveStep + 1)
        setSkipped(prevSkipped => {
            const newSkipped = new Set(prevSkipped.values())
            newSkipped.add(activeStep)
            return newSkipped
        })
    }

    const handleReset = () => {
        const resetProdutoSelecionado = Object.assign({}, produtoSelecionado)
        resetProdutoSelecionado.quantidade = 1
        resetProdutoSelecionado.opcoes.forEach((grupo, index) => {
            grupo.quantidade = 0
            grupo.opcoes.forEach(opcao => {
                opcao.quantidade = 0
            })

            if (grupo.grupoExtra) {
                resetProdutoSelecionado.opcoes.splice(index, 1)
            }
        })
        setProdutoSelecionado(resetProdutoSelecionado)
        setActiveStep(0)
    }

    const grupoDeOpcoesSelecionado = produtoSelecionado?.opcoes?.length
        ? produtoSelecionado?.opcoes[activeStep]
        : {}

    const [valorPesquisa, setValorPesquisa] = useState("")
    const procurarProduto = e => {
        setValorPesquisa(e.target.value.toLowerCase())
    }

    function getPedidosflitrados() {
        return !valorPesquisa
            ? grupoDeOpcoesSelecionado?.opcoes.sort(sortByOrdemExibicao)
            : grupoDeOpcoesSelecionado?.opcoes
                  ?.filter(item => {
                      if (item.nome?.toLowerCase().includes(valorPesquisa)) return true
                      if (item.descricao?.toLowerCase().includes(valorPesquisa)) return true

                      return false
                  })
                  .sort(sortByOrdemExibicao)
    }

    return (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === "top" || anchor === "bottom",
            })}
            role="presentation"
            style={{
                padding: "2em 2em 0 2em",
                height: grupoDeOpcoesSelecionado?.opcoes?.length > 10 ? "100vh" : "",
            }}
        >
            <Stepper activeStep={activeStep} style={{ padding: 0, marginBottom: "2em" }}>
                {produtoSelecionado?.opcoes?.map((label, index) => {
                    const stepProps = {}
                    const labelProps = {}
                    if (isStepOptional(index)) {
                        labelProps.optional = <Typography variant="caption">Optional</Typography>
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false
                    }
                    return (
                        <Step key={`${index}-${label.id}`} {...stepProps}>
                            <StepLabel {...labelProps}>{label.nome}</StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
            <div>
                {activeStep === produtoSelecionado?.opcoes?.length ? (
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <Typography style={{ textAlign: "center" }}>
                                Quantos desse produto?
                            </Typography>
                        </Col>
                        <Col xs={12} md={12} lg={12} className="colOpcaoAdicionalProduto">
                            <ButtonGroup>
                                <Button
                                    aria-label="reduce"
                                    onClick={() => {
                                        if (produtoSelecionado.quantidade > 1) {
                                            setProdutoSelecionado({
                                                ...produtoSelecionado,
                                                quantidade: produtoSelecionado.quantidade - 1,
                                            })
                                        }
                                    }}
                                    style={{ background: "#dc3545", color: "white" }}
                                >
                                    <RemoveIcon fontSize="small" />
                                </Button>

                                <Button aria-label="increase">
                                    <span>{produtoSelecionado.quantidade}</span>
                                </Button>

                                <Button
                                    aria-label="increase"
                                    style={{ background: "#28a745", color: "white" }}
                                    onClick={() => {
                                        setProdutoSelecionado({
                                            ...produtoSelecionado,
                                            quantidade: produtoSelecionado.quantidade + 1,
                                        })
                                    }}
                                >
                                    <AddIcon fontSize="small" />
                                </Button>
                            </ButtonGroup>
                        </Col>

                        {produtoSelecionado.permiteObservacao ? (
                            <>
                                <Col xs={12} md={12} lg={12} style={{ marginTop: "1em" }}>
                                    <Typography style={{ textAlign: "center" }}>
                                        Alguma observação?
                                    </Typography>
                                </Col>
                                <Col xs={12} md={12} lg={12} style={{ marginBottom: "1em" }}>
                                    <TextField
                                        style={{ margin: "0" }}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id={`observacaoPedido_${produtoSelecionado.id}`}
                                        label="Observação"
                                        name="observacaoPedido"
                                        type="text"
                                    ></TextField>
                                </Col>
                            </>
                        ) : null}
                        <div style={{ padding: "1em" }}>
                            {produtoSelecionado?.opcoes?.length === 0 ? (
                                <Button onClick={handleBack} className={classes.button}>
                                    Voltar
                                </Button>
                            ) : (
                                <Button onClick={handleReset} className={classes.button}>
                                    Resetar
                                </Button>
                            )}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={adicionarProduto(anchor, false, produtoSelecionado)}
                                className={classes.button}
                            >
                                Adicionar{" "}
                                <span
                                    className={"valorFinalizarPedido"}
                                    style={{ color: "#000000de" }}
                                >
                                    {Intl.NumberFormat("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                    }).format(calcularValorProduto(produtoSelecionado))}
                                </span>
                            </Button>
                        </div>
                    </Row>
                ) : (
                    <>
                        {grupoDeOpcoesSelecionado?.opcoes?.length > 10 ? (
                            <TextField
                                type="text"
                                id="search-input"
                                variant="outlined"
                                label="Buscar adicional"
                                size="small"
                                style={{ marginBottom: "15px" }}
                                onChange={procurarProduto}
                                value={valorPesquisa}
                            />
                        ) : null}
                        <Row>
                            <Col xs={12} md={12} lg={12} className="" style={{ padding: "0 5em" }}>
                                <Typography>
                                    {!!grupoDeOpcoesSelecionado.quantidadeMaxima &&
                                        grupoDeOpcoesSelecionado.quantidadeMaxima !== 99999999 && (
                                            <span>
                                                Máximo: {grupoDeOpcoesSelecionado.quantidadeMaxima}
                                            </span>
                                        )}
                                    {!!grupoDeOpcoesSelecionado.quantidadeMaxima &&
                                        grupoDeOpcoesSelecionado.quantidadeMaxima != 99999999 &&
                                        !!grupoDeOpcoesSelecionado.quantidadeMinima &&
                                        grupoDeOpcoesSelecionado.quantidadeMinima != 99999999 && (
                                            <span> e </span>
                                        )}
                                    {!!grupoDeOpcoesSelecionado.quantidadeMinima && (
                                        <span>
                                            Minimo: {grupoDeOpcoesSelecionado.quantidadeMinima}
                                        </span>
                                    )}
                                </Typography>
                            </Col>
                        </Row>

                        {grupoDeOpcoesSelecionado?.adicional === 0 && ( //radio
                            <RadioGroup
                                aria-label={grupoDeOpcoesSelecionado.nome}
                                name={grupoDeOpcoesSelecionado.nome}
                            >
                                {getPedidosflitrados().map(opcao =>
                                    opcao.informativo ? (
                                        <Col
                                            xs={11}
                                            md={11}
                                            lg={11}
                                            style={{ margin: "0.75rem auto" }}
                                            key={`${grupoDeOpcoesSelecionado.id}-${opcao.id}`}
                                        >
                                            <Typography variant="h6">{opcao.nome}</Typography>
                                        </Col>
                                    ) : (
                                        <span
                                            className="row"
                                            key={`${grupoDeOpcoesSelecionado.id}-${opcao.id}`}
                                            style={{
                                                // "flexWrap": "nowrap",
                                                alignItems: "center",
                                                padding: "0.3em 0",
                                            }}
                                        >
                                            <Col
                                                xs={2}
                                                md={2}
                                                lg={1}
                                                style={{
                                                    cursor: "pointer",
                                                    textAlign: "right",
                                                }}
                                                className=""
                                            >
                                                <FormControlLabel
                                                    value={"#" + opcao.id}
                                                    style={{ margin: 0 }}
                                                    control={
                                                        <Radio
                                                            id={"#" + opcao.id}
                                                            color="primary"
                                                            checked={
                                                                opcao.quantidade ? true : false
                                                            }
                                                            onClick={() => {
                                                                selecionarRadioButton(
                                                                    opcao,
                                                                    activeStep
                                                                )
                                                            }}
                                                        />
                                                    }
                                                />
                                            </Col>
                                            <Col xs={7} md={7} lg={8} className="">
                                                <FormLabel
                                                    style={{ cursor: "pointer" }}
                                                    htmlFor={"#" + opcao.id}
                                                >
                                                    {opcao.nome}
                                                </FormLabel>
                                            </Col>
                                            {
                                                //se tiver valor
                                                opcao.valor && (
                                                    <Col xs={3} md={3} lg={3} className="">
                                                        <FormLabel
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            htmlFor={"#" + opcao.id}
                                                        >
                                                            {Intl.NumberFormat("pt-BR", {
                                                                style: "currency",
                                                                currency: "BRL",
                                                            }).format(opcao.valor)}
                                                        </FormLabel>
                                                    </Col>
                                                )
                                            }

                                            {
                                                //se tiver descricao
                                                opcao.descricao ? (
                                                    <>
                                                        <Col xs={12} md={12} lg={1}></Col>

                                                        <Col
                                                            xs={12}
                                                            md={12}
                                                            lg={11}
                                                            className="colDescricaoAdicional"
                                                        >
                                                            <FormLabel
                                                                htmlFor={"#" + opcao.id}
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                {opcao.descricao}
                                                            </FormLabel>
                                                        </Col>
                                                    </>
                                                ) : null
                                            }
                                        </span>
                                    )
                                )}
                            </RadioGroup>
                        )}

                        {grupoDeOpcoesSelecionado?.adicional === 1 && ( //CHECK BOX
                            <FormGroup aria-label="position" name={grupoDeOpcoesSelecionado?.id}>
                                {getPedidosflitrados().map(opcao =>
                                    opcao.informativo ? (
                                        <Col
                                            key={`${grupoDeOpcoesSelecionado.id}-${opcao.id}`}
                                            xs={11}
                                            md={11}
                                            lg={11}
                                            style={{ margin: "0.75rem auto" }}
                                        >
                                            <Typography variant="h6">{opcao.nome}</Typography>
                                        </Col>
                                    ) : (
                                        <span
                                            className="row"
                                            key={`${grupoDeOpcoesSelecionado.id}-${opcao.id}`}
                                            style={{
                                                // "flexWrap": "nowrap",
                                                alignItems: "center",
                                                padding: "0.3em 0",
                                            }}
                                        >
                                            <Col xs={2} md={2} lg={1} style={{ padding: 0 }}>
                                                <input
                                                    type="checkbox"
                                                    color="primary"
                                                    value={opcao.nome}
                                                    name={opcao.nome}
                                                    id={"#" + opcao.id}
                                                    checked={opcao.quantidade ? true : false}
                                                    onClick={() => {
                                                        selecionarCheckButton(
                                                            opcao,
                                                            grupoDeOpcoesSelecionado,
                                                            activeStep
                                                        )
                                                    }}
                                                    className={
                                                        "#OpcaoDe" + grupoDeOpcoesSelecionado?.id
                                                    }
                                                    style={{
                                                        width: "23px",
                                                        height: "23px",
                                                        cursor: "pointer",
                                                        float: "right",
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={7} md={7} lg={8} className="">
                                                <FormLabel
                                                    style={{
                                                        cursor: "pointer",
                                                        " margin": 0,
                                                    }}
                                                    htmlFor={"#" + opcao.id}
                                                    control={opcao.nome}
                                                >
                                                    {opcao.nome}
                                                </FormLabel>
                                            </Col>
                                            {
                                                //se tiver valor
                                                <Col xs={3} md={3} lg={3} className="">
                                                    {opcao.valor ? (
                                                        <FormLabel
                                                            htmlFor={"#" + opcao.id}
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            {Intl.NumberFormat("pt-BR", {
                                                                style: "currency",
                                                                currency: "BRL",
                                                            }).format(opcao.valor)}
                                                        </FormLabel>
                                                    ) : null}
                                                </Col>
                                            }

                                            {
                                                //se tiver descricao
                                                opcao.descricao ? (
                                                    <>
                                                        <Col xs={12} md={12} lg={1}></Col>

                                                        <Col
                                                            xs={12}
                                                            md={12}
                                                            lg={11}
                                                            className="colDescricaoAdicional"
                                                        >
                                                            <FormLabel
                                                                htmlFor={"#" + opcao.id}
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                {opcao.descricao}
                                                            </FormLabel>
                                                        </Col>
                                                    </>
                                                ) : null
                                            }
                                        </span>
                                    )
                                )}
                            </FormGroup>
                        )}
                        {grupoDeOpcoesSelecionado?.adicional === 2 && ( //QTD
                            <FormGroup
                                aria-label={grupoDeOpcoesSelecionado?.id}
                                name={grupoDeOpcoesSelecionado?.id}
                            >
                                {getPedidosflitrados().map(opcao =>
                                    opcao.informativo ? (
                                        <Col
                                            xs={11}
                                            md={11}
                                            lg={11}
                                            style={{ margin: "0.75rem auto" }}
                                            key={`${grupoDeOpcoesSelecionado.id}-${opcao.id}`}
                                        >
                                            <Typography variant="h6">{opcao.nome}</Typography>
                                        </Col>
                                    ) : (
                                        <span
                                            key={`${grupoDeOpcoesSelecionado.id}-${opcao.id}`}
                                            className="camposAddRemoveAdicionaisQuantitativos opcaoIndividual row"
                                            style={{
                                                // "flexWrap": "nowrap",
                                                alignItems: "center",
                                                padding: "0.3em 0",
                                            }}
                                        >
                                            <Col xs={4} md={3} lg={2} className="colOpcaoAdicional">
                                                <ButtonGroup style={{ float: "right" }}>
                                                    <Button
                                                        aria-label="reduce"
                                                        onClick={() => {
                                                            selecionarQuantidadeButton(
                                                                opcao,
                                                                activeStep,
                                                                "remove"
                                                            )
                                                        }}
                                                        style={{
                                                            background: "#dc3545",
                                                            color: "white",
                                                        }}
                                                    >
                                                        <RemoveIcon fontSize="small" />
                                                    </Button>

                                                    <Button aria-label="increase">
                                                        <span id={opcao.id}>
                                                            {
                                                                /*isNaN(opcao.quantidade) ? opcao.quantidade = 0 : opcao.quantidade = */ opcao.quantidade
                                                            }
                                                        </span>
                                                    </Button>

                                                    <Button
                                                        aria-label="increase"
                                                        style={{
                                                            background: "#28a745",
                                                            color: "white",
                                                        }}
                                                        onClick={() => {
                                                            selecionarQuantidadeButton(
                                                                opcao,
                                                                activeStep,
                                                                "add"
                                                            )
                                                        }}
                                                    >
                                                        <AddIcon fontSize="small" />
                                                    </Button>
                                                </ButtonGroup>
                                            </Col>
                                            <Col xs={5} md={6} lg={7} className="colNomeAdicional">
                                                <FormLabel>{opcao.nome}</FormLabel>{" "}
                                            </Col>

                                            {
                                                //se tiver valor
                                                <Col
                                                    xs={3}
                                                    md={3}
                                                    lg={2}
                                                    className="colValorAdicional"
                                                >
                                                    {(opcao.valor || opcao.valor > 0) && (
                                                        <FormLabel>
                                                            {Intl.NumberFormat("pt-BR", {
                                                                style: "currency",
                                                                currency: "BRL",
                                                            }).format(opcao.valor)}
                                                        </FormLabel>
                                                    )}
                                                </Col>
                                            }

                                            {
                                                //se tiver descricao
                                                opcao.descricao && (
                                                    <>
                                                        <Col xs={12} md={12} lg={2}></Col>
                                                        <Col
                                                            xs={12}
                                                            md={12}
                                                            lg={10}
                                                            className="colDescricaoAdicional"
                                                        >
                                                            <FormLabel>{opcao.descricao}</FormLabel>
                                                        </Col>
                                                    </>
                                                )
                                            }
                                            <Divider />
                                        </span>
                                    )
                                )}
                            </FormGroup>
                        )}

                        {grupoDeOpcoesSelecionado?.adicional === 3 && ( // Digitável
                            <FormGroup
                                name={grupoDeOpcoesSelecionado?.id}
                                style={{ marginBottom: "5em" }}
                            >
                                {grupoDeOpcoesSelecionado?.opcoes
                                    ?.sort((a, b) => sortByOrdemExibicao(a, b))
                                    .map(opcao =>
                                        opcao.informativo ? (
                                            <Col
                                                xs={11}
                                                md={11}
                                                lg={11}
                                                style={{ margin: "0.75rem auto" }}
                                                key={`${grupoDeOpcoesSelecionado.id}-${opcao.id}`}
                                            >
                                                <Typography variant="h6">{opcao.nome}</Typography>
                                            </Col>
                                        ) : (
                                            <span
                                                key={`${grupoDeOpcoesSelecionado.id}-${opcao.id}`}
                                                className="row"
                                                style={{
                                                    alignItems: "center",
                                                    padding: "0.3em 0",
                                                }}
                                            >
                                                <Col
                                                    xs={4}
                                                    md={3}
                                                    lg={2}
                                                    className="colOpcaoAdicional"
                                                >
                                                    <TextField
                                                        onChange={e =>
                                                            handleInputDigitavel(
                                                                opcao,
                                                                e.target.value
                                                            )
                                                        }
                                                        label="Quantidade"
                                                        variant="outlined"
                                                        type="number"
                                                    />
                                                </Col>
                                                <Col
                                                    xs={5}
                                                    md={6}
                                                    lg={7}
                                                    className="colNomeAdicional"
                                                >
                                                    <FormLabel>{opcao.nome}</FormLabel>
                                                </Col>
                                                <Col
                                                    xs={3}
                                                    md={3}
                                                    lg={2}
                                                    className="colValorAdicional"
                                                >
                                                    {(opcao.valor || opcao.valor > 0) && (
                                                        <FormLabel>
                                                            {Intl.NumberFormat("pt-BR", {
                                                                style: "currency",
                                                                currency: "BRL",
                                                            }).format(opcao.valor)}
                                                        </FormLabel>
                                                    )}
                                                </Col>
                                                {
                                                    //se tiver descricao
                                                    opcao.descricao && (
                                                        <>
                                                            <Col xs={12} md={12} lg={2}></Col>
                                                            <Col
                                                                xs={12}
                                                                md={12}
                                                                lg={10}
                                                                className="colDescricaoAdicional"
                                                            >
                                                                <FormLabel>
                                                                    {opcao.descricao}
                                                                </FormLabel>
                                                            </Col>
                                                        </>
                                                    )
                                                }
                                                <Divider />
                                            </span>
                                        )
                                    )}
                            </FormGroup>
                        )}

                        <div
                            style={{
                                position: "sticky",
                                bottom: 0,
                                padding: "1em",
                                width: "100%",
                                left: 0,
                                background: "white",
                            }}
                        >
                            <Button onClick={handleBack} className={classes.button}>
                                Voltar
                            </Button>
                            {isStepOptional(activeStep) ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSkip}
                                    className={classes.button}
                                >
                                    Pular
                                </Button>
                            ) : null}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setValorPesquisa("")
                                    handleNext(grupoDeOpcoesSelecionado)
                                }}
                                disabled={
                                    configuracoes?.user?.parametros
                                        ?.limitarQuantidadeItensCentral &&
                                    grupoDeOpcoesSelecionado?.quantidade <
                                        grupoDeOpcoesSelecionado.quantidadeMinima
                                }
                                className={classes.button}
                            >
                                {activeStep === produtoSelecionado?.opcoes?.length - 1
                                    ? "Finalizar"
                                    : "Próximo"}
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default Produto
