import React from "react"

import Button from "@material-ui/core/Button"
import InputBase from "@material-ui/core/InputBase"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import { Col, Row } from "react-bootstrap"
import backgroundMesaQRCode from "../../assets/backgroundMesaQRCode.jpg"

// import stories from '../../assets/geradorDeImagens/natal/1920x1080.jpg';

const BootstrapInput = withStyles(theme => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "10px 26px 10px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
            borderRadius: 4,
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}))(InputBase)

const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(1),
    },
}))

function download(imageURL) {
    const a = document.createElement("a")
    a.href = imageURL
    a.download = `mesa-${document.getElementById("opcaoMesa").value}-qrcode-1500x2200`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
}

export default class Tema1 extends React.Component {
    render(props) {
        const mesa = this.props.mesa
        const estabelecimento = this.props.estabelecimento

        const corPrimaria = this.props.corPrimaria
        const corSecundaria = this.props.corSecundaria

        const logoEstabelecimento = new Image()
        logoEstabelecimento.src = this.props.logo

        const backgroundQR = new Image()
        backgroundQR.src = backgroundMesaQRCode

        var s = 2
        var f = 2

        const aplicativoDados = JSON.parse(localStorage.getItem("aplicativoCF"))

        const configuracoes = JSON.parse(localStorage.getItem("configs"))

        async function criarImagem() {
            if (s >= 2) {
                try {
                    console.log("criar")
                    const canvas = document.createElement("canvas")
                    canvas.width = 1500
                    canvas.height = 2200
                    const context = canvas.getContext("2d")

                    var qr = (window.qr = await new window.QRious({
                        element: document.createElement("img"),
                        size: 1000,
                        value: `https://${configuracoes.user.parametros.urlSiteAplicativo}/login/?mesa=${mesa}&loja=${estabelecimento}&pedidoMesa=true`,
                    }))

                    console.log("qr,", qr)

                    // context.beginPath();
                    // context.fillStyle = 'green';
                    // context.arc(1500, 520, 250, 0, 2 * Math.PI, false);
                    // context.fill();

                    // context.fillStyle = "#f6f6f6";
                    // context.fillRect(0, 0, 1500, 2400);
                    context.drawImage(backgroundQR, 0, 0, 1500, 2400)

                    //inicio
                    context.fillStyle = `${corSecundaria}`
                    context.fillRect(0, 0, 1500, 350)

                    context.fillStyle = `${corPrimaria}`
                    context.fillRect(0, 0, 1500, 300)

                    context.drawImage(logoEstabelecimento, 550, 150, 400, 400)

                    // meio
                    context.fillStyle = `${corPrimaria}`
                    context.fillRect(0, 850, 1500, 350)

                    context.fillStyle = `${corPrimaria}`
                    context.fillRect(650, 730, 700, 700)
                    context.drawImage(qr.element, 675, 755, 650, 650)

                    //fim
                    context.fillStyle = `${corSecundaria}`
                    context.fillRect(0, 1870, 1500, 550)

                    context.fillStyle = `${corPrimaria}`
                    context.fillRect(0, 1900, 1500, 500)

                    context.font = "bold 80px Arial"
                    context.fillStyle = "white"
                    context.textAlign = "center"
                    context.fillText(`MESA ${mesa}`, 335, 980)

                    context.font = "bold 60px Arial"
                    context.fillStyle = "white"
                    context.textAlign = "center"
                    context.fillText("LEIA O QR CODE", 335, 1060)

                    context.font = "bold 60px Arial"
                    context.fillStyle = "white"
                    context.textAlign = "center"
                    context.fillText("e faça seu pedido", 335, 1130)

                    context.font = "bold 45px Arial"
                    context.fillStyle = `${corPrimaria}`
                    context.textAlign = "center"
                    context.fillText("OU DIGITE O CÓDIGO NO SITE", 1000, 1490)

                    context.font = "bold 80px Arial"
                    context.fillStyle = `${corPrimaria}`
                    context.textAlign = "center"
                    const codigo =
                        parseInt(
                            (mesa + "").substring(0, 1) +
                                Math.floor(Math.random() * (999 - 100 + 1) + 100) +
                                (mesa + "").substring(1, 2)
                        ) + 5555
                    context.fillText(codigo, 1000, 1580)

                    context.font = "bold 30px Arial"
                    context.fillStyle = "white"
                    context.textAlign = "center"
                    context.fillText("WIFI GRÁTIS", 750, 1980)

                    context.font = "bold 70px Arial"
                    context.fillStyle = "white"
                    context.textAlign = "center"
                    context.fillText("PROMOÇÕES EXCLUSIVAS", 750, 2065)

                    context.font = "bold 30px Arial"
                    context.fillStyle = "white"
                    context.textAlign = "center"
                    context.fillText(
                        `${configuracoes.user.parametros.urlSiteAplicativo}`,
                        750,
                        2135
                    )

                    context.fill()
                    const dataURL = canvas.toDataURL()
                    document.getElementById("imageGeradaStories").src = dataURL
                    // document.getElementById('loadingImageStories').style.display = "none"
                    // document.getElementById('notLoadingImageStories').style.display = "block"
                    // document.getElementById('notLoadingOpcoes').style.display = "block"
                } catch (error) {}
            }
        }

        window.setTimeout(function () {
            criarImagem()
        }, 1000)

        // backGroundImage1920x1080.onload = () => {s++; criarStories()}

        return (
            <Row>
                <Col xs={12} md={12} lg={12} style={{ margin: "1em 0" }}>
                    <Button
                        fullWidth
                        onClick={async () => {
                            download(await document.getElementById("imageGeradaStories").src)
                        }}
                        variant="contained"
                    >
                        Imagem (1500x2200)
                    </Button>
                    <img
                        ref="image"
                        style={{ height: "400px" }}
                        id="imageGeradaStories"
                        className="hidden"
                    />
                </Col>
            </Row>
        )
    }
}
