//INIT
import { useEffect, useState } from "react"

//FUNCTIONS \ REQUESTS

import {} from "../../pages/RegistradorDePedidos/functions"

import {} from "../../services/functionsGerais"

import {} from "../../services/comum"

import {} from "../../services/cliente"

//FILES
import Loading from "../Componentes/Loading"
import MostrarEstabelecimentos from "../Componentes/MostrarEstabelecimentos"
import "./styles.css"

//FRAMEWORK
import Container from "@material-ui/core/Container"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import { RegistrarLogErro } from "../../services/clientefiel"
import { buscarestabelecimentos } from "../../services/integracao"
import CardapioNovo from "./Cardapionovo"

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default function Cardapio() {
    const autorizacao = localStorage.getItem("chaveSistema")
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    )

    const history = useHistory()
    //states
    const [alert, setAlert] = useState({ status: false, tipo: "success", mesangem: "" })

    const [state, setState] = useState({
        estabelecimentos: [],
        estabelecimento: {},
        loading: true,
        cardapio: [],
    })

    const { estabelecimento, estabelecimentos, loading, categoriaEdit, produtoEdit, cardapio } =
        state

    const alertStart = (msg, tipo) => {
        setAlert({ status: true, tipo: tipo, mesangem: msg })
    }

    const alertClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setAlert({ status: false, tipo: alert.tipo, mesangem: alert.mesangem })
    }

    const handleBuscarDadosEstabelecimentos = async () => {
        let estabelecimentos = []
        let estabelecimento = {}

        try {
            const response = await buscarestabelecimentos(autorizacao)
            console.log("Parametros", response)

            if (response.retornoErro) {
                alertStart(response.mensagem, "error")

                setState({
                    ...state,
                    loading: false,
                })
            } else {
                estabelecimentos = response

                // if(response.length == 1){
                // if(true){
                //   estabelecimento = response[0]
                //   handleBuscarCardapio(estabelecimento, estabelecimentos)
                //   return true
                // }

                setState({
                    ...state,
                    estabelecimentos: estabelecimentos,
                    estabelecimento: estabelecimento,
                    loading: false,
                })

                return response
            }
        } catch (error) {
            RegistrarLogErro(error, "handleBuscarDadosEstabelecimentos")
        }
        return {}
    }

    const [estabelecimentoo, setEstabelecimentoo] = useState()
    const handleEstabelecimento = estabelecimento => {
        setEstabelecimentoo(estabelecimento)
        history.push("/servicos/ferramenta/edicao-cardapio/produtos")
    }

    useEffect(() => {
        handleBuscarDadosEstabelecimentos().then(estabelecimentos => {
            const estabelecimentoId =
                new URLSearchParams(window.location.search).get("estabelecimento") ?? undefined

            const estabelecimentoSearch = estabelecimentos.find(
                e => e.id === Number(estabelecimentoId)
            )

            if (estabelecimentoSearch) setEstabelecimentoo(estabelecimentoSearch)
        })
    }, [])

    return (
        <Container className="container-buscando">
            <Loading loading={loading} />

            <Snackbar open={alert.status} autoHideDuration={6000} onClose={alertClose}>
                <Alert onClose={alertClose} severity={alert.tipo}>
                    {alert.mesangem}
                </Alert>
            </Snackbar>

            <div style={{ textAlign: "center", paddingTop: "3em" }}>
                {!estabelecimentoo?.id && estabelecimentos.length ? (
                    <MostrarEstabelecimentos
                        estabelecimentos={estabelecimentos}
                        handle={handleEstabelecimento}
                    />
                ) : null}

                {estabelecimentoo?.id ? <CardapioNovo estabelecimento={estabelecimentoo} /> : null}
            </div>
        </Container>
    )
}
