import React, { useContext } from "react"
import { useHistory } from "react-router-dom"

import { useSnackbar } from "notistack"
import RegistradorContext from "../../../../contexts/registradorContext"
import ServicosContext from "../../../../contexts/servicosContext"

import AppBar from "@material-ui/core/AppBar"
import Button from "@material-ui/core/Button"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import Container from "@material-ui/core/Container"
import Dialog from "@material-ui/core/Dialog"
import IconButton from "@material-ui/core/IconButton"
import Slide from "@material-ui/core/Slide"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import CloseIcon from "@material-ui/icons/Close"
import PersonPinIcon from "@material-ui/icons/PersonPin"
import PhoneIcon from "@material-ui/icons/Phone"
import AlertFixo from "@material-ui/lab/Alert"
import Skeleton from "@material-ui/lab/Skeleton"
import { Card, Col, Row } from "react-bootstrap"

import { RegistrarLogErro } from "../../../../services/clientefiel"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles(theme => ({
    fixed: {
        position: "fixed",
        right: "2em",
        bottom: "2em",
        zIndex: 1000,
    },
    fixedMesa: {
        position: "fixed",
        right: "2em",
        bottom: "2em",
        zIndex: 2000,
    },
    fixedMesaFechar: {
        position: "fixed",
        right: "2em",
        bottom: "7em",
        zIndex: 2000,
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    rootAlert: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
    backdrop: {
        // zIndex: theme.zIndex.drawer + 999999,
        color: "#fff",
    },
    pos: {
        marginBottom: 12,
    },
    heading: {
        width: "100%",
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    rootList: {
        width: "100%!important",
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: "inline",
        width: "100%",
    },
    appBar: {
        position: "fixed",
    },
    appContainer: {
        paddingTop: "6em",
        backgroundColor: "#f4f4f4",
        // height: "100%",
        // minHeight: "-webkit-fill-available",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    list: {
        width: 250,
    },
    fullList: {
        width: "auto",
    },
}))

export default function Index() {
    const autorizacao = localStorage.getItem("chaveSistema")
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    )
    const classes = useStyles()
    const theme = useTheme()
    const history = useHistory()

    const {
        carrinho,
        setCarrinho,
        setOpenListaClientes,
        setLoadingClientes,
        setListaClientes,
        setOpenCadastroCliente,
        setOpenListaEnderecos,
        setOpenGerenciarMesa,
        setListaMesas,

        openListaClientes,
        loadingClientes,
        listaClientes,
        listaRepeticao,
        setInformacoesParaBuscarCardapio,
        informacoesParaBuscarCardapio,
        setLoadingEnderecos,
        handleSelecionarCliente,
        setEditarCliente,
    } = useContext(RegistradorContext)

    const { enqueueSnackbar } = useSnackbar()

    const { configuracoes } = useContext(ServicosContext)

    const alertStart = (msg, tipo) => {
        enqueueSnackbar(msg, { variant: tipo })
    }

    const handleEditarCliente = cliente => {
        console.log("||> handleEditarCliente", cliente)
        setEditarCliente(cliente)
    }

    const handleCloseCliente = () => {
        try {
            document.getElementById("procurar-cliente").value = ""
            setOpenListaClientes(false)
            setListaClientes([])
        } catch (error) {
            RegistrarLogErro(error, "handleCloseCliente")
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    return openListaClientes ? (
        <Dialog
            fullScreen
            open={openListaClientes}
            onClose={handleCloseCliente}
            TransitionComponent={Transition}
            style={{ backgroundColor: "#f4f4f4" }}
        >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleCloseCliente}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {!loadingClientes ? "(" + listaClientes.length + ")" : null} Clientes{" "}
                        {mobile ? "" : "Encontrados"}
                    </Typography>
                    <Button color="inherit" onClick={() => setOpenCadastroCliente(true)}>
                        Cadastrar {mobile ? "" : "Cliente"}
                    </Button>
                </Toolbar>
            </AppBar>

            <Container className={classes.appContainer}>
                <Row>
                    {loadingClientes ? (
                        <>
                            <Col xs={12} md={12} lg={12} style={{ marginBottom: "0.5em" }}>
                                <Skeleton
                                    variant="rect"
                                    width={"100%"}
                                    height={48}
                                    style={{ marginBottom: "1em" }}
                                />
                            </Col>
                            {listaRepeticao.map(chave => (
                                <Col
                                    xs={12}
                                    md={6}
                                    lg={4}
                                    key={chave}
                                    style={{ marginBottom: "0.5em" }}
                                >
                                    <Skeleton variant="rect" width={"100%"} height={77} />
                                </Col>
                            ))}
                        </>
                    ) : (
                        <>
                            <Col xs={12} md={12} lg={12} style={{ marginBottom: "0.5em" }}>
                                <AlertFixo
                                    severity="warning"
                                    style={{ width: "100%", marginBottom: "1em" }}
                                >
                                    Você buscou clientes com nome ou telefone igual a{" "}
                                    <b>{document.getElementById("procurar-cliente").value}</b>.
                                </AlertFixo>
                            </Col>
                            {listaClientes.length ? (
                                listaClientes.map((cliente, index) => (
                                    <Col
                                        xs={12}
                                        md={6}
                                        lg={4}
                                        key={index}
                                        style={{ marginBottom: "0.5em" }}
                                    >
                                        <Card className={classes.root} style={{ height: "100%" }}>
                                            <CardActionArea
                                                onClick={() => handleSelecionarCliente(cliente)}
                                                style={{
                                                    flexFlow: "column-reverse",
                                                    alignItems: "normal",
                                                }}
                                            >
                                                <Row
                                                    style={{
                                                        margin: 0,
                                                        textAlign: "",
                                                        width: "100%",
                                                        padding: "1em",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Col
                                                        xs={2}
                                                        md={2}
                                                        lg={2}
                                                        style={{
                                                            padding: 0,
                                                            textAlign: "center",
                                                            fontSize: "2em",
                                                        }}
                                                    >
                                                        <PersonPinIcon></PersonPinIcon>
                                                    </Col>
                                                    <Col xs={10} md={10} lg={10}>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                            component="p"
                                                        >
                                                            {cliente.telefone ? (
                                                                <>
                                                                    <PhoneIcon
                                                                        style={{ fontSize: "15px" }}
                                                                    ></PhoneIcon>{" "}
                                                                    {cliente.telefone}
                                                                </>
                                                            ) : (
                                                                "Sem Telefone"
                                                            )}
                                                        </Typography>
                                                        <Typography gutterBottom component="h2">
                                                            {cliente.nome
                                                                ? cliente.nome
                                                                : "Sem Nome"}
                                                        </Typography>
                                                        {/* <Typography className={"StatusEstabelecimento"} variant="body2"  component="p">
                                        </Typography> */}
                                                    </Col>
                                                </Row>
                                            </CardActionArea>

                                            <CardActions
                                                style={{ cursor: "pointer", padding: "0" }}
                                            >
                                                <Typography
                                                    variant={"button"}
                                                    style={{ textAlign: "center", padding: "3px" }}
                                                    className={"botaoVerde naoArredondado"}
                                                    onClick={() => handleSelecionarCliente(cliente)}
                                                >
                                                    Selecionar
                                                </Typography>

                                                <Typography
                                                    variant={"button"}
                                                    style={{ textAlign: "center", padding: "3px" }}
                                                    className={"botaoCinza naoArredondado"}
                                                    onClick={() => handleEditarCliente(cliente)}
                                                >
                                                    Editar
                                                </Typography>
                                            </CardActions>
                                        </Card>
                                    </Col>
                                ))
                            ) : (
                                <Col
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    style={{ textAlign: "center", marginBottom: "0.5em" }}
                                >
                                    <Typography> Nenhum usuário foi encontrado. </Typography>
                                </Col>
                            )}
                        </>
                    )}
                </Row>
            </Container>
        </Dialog>
    ) : (
        <React.Fragment></React.Fragment>
    )
}
