import React, { useEffect, useState } from "react"
import ServicosContext from "../../contexts/servicosContext"
import DiasComemorativos from "../../dados/diasComemorativos"
import Lives from "../../dados/lives.json"
import LiveComponent from "./Live"

export default function DivulgacoesPainel(props) {
    const {} = props

    const { autorizacao } = React.useContext(ServicosContext)

    const [live, setLive] = useState(null)
    const [diaComemorativo, setDiaComemorativo] = useState(null)

    useEffect(() => {
        let t = window.setInterval(function () {
            if (live === null) {
                let livesAtuais = Lives.filter(al => {
                    const hoje = new Date()
                    const { inicioDivulgacao, fimDivulgacao } = al
                    if (inicioDivulgacao <= hoje.getTime() && fimDivulgacao >= hoje.getTime()) {
                        return true
                    }
                    return false
                })

                // console.log('livesAtuais', livesAtuais)

                if (livesAtuais.length) {
                    for (let index = 0; index < livesAtuais.length; index++) {
                        const live = livesAtuais[index]
                        if (!window.getCookie(live.tag)) {
                            setLive(live)
                            break
                        }
                    }
                }
            }
        }, 20000)
        // },1000)
        return () => window.clearInterval(t)
    }, [live])

    useEffect(() => {
        let t = window.setInterval(function () {
            if (autorizacao && diaComemorativo === null) {
                let diasComemorativos = DiasComemorativos.filter(al => {
                    const hoje = new Date()
                    const { dataFixa, dataDinamica } = al
                    if (dataFixa) {
                        const data = new Date(dataFixa)
                        if (
                            data.getDate() === hoje.getDate() &&
                            data.getMonth() === hoje.getMonth()
                        ) {
                            return true
                        }
                        return false
                    } else if (dataDinamica) {
                        const tem = dataDinamica.filter(dt => {
                            const data = new Date(dt)
                            if (
                                data.getDate() === hoje.getDate() &&
                                data.getMonth() === hoje.getMonth()
                            ) {
                                return true
                            }
                            return false
                        })
                        return !!tem.length
                    }
                    return false
                })

                // console.log('diasComemorativos', diasComemorativos)

                if (diasComemorativos.length) {
                    for (let index = 0; index < diasComemorativos.length; index++) {
                        const dia = diasComemorativos[index]
                        if (!window.getCookie(dia.tag)) {
                            setDiaComemorativo(dia)
                            break
                        }
                    }
                }
            }
            // },20000)
        }, 1000)
        return () => window.clearInterval(t)
    }, [diaComemorativo, autorizacao])

    return <>{live === null ? <></> : <LiveComponent live={live} setLive={setLive} />}</>
}
