import {
    AppBar,
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Toolbar,
    Typography,
} from "@material-ui/core"
import { Add, CheckBox, CheckBoxOutlineBlank, Close, Print, Settings } from "@material-ui/icons"
import { useContext, useEffect, useState } from "react"

import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition"
import { useHistory } from "react-router-dom"
import LoadingContext from "../../../contexts/loadingContext"
import ServicosContext from "../../../contexts/servicosContext"
import { RegistrarLogErro } from "../../../services/clientefiel"
import { textoHTML } from "../../../services/functionsGerais"
import { BuscarFormaDePagamentos, FecharMesa, MudarNumeroMesa } from "../../../services/integracao"
import SelecaoFormaPagamento from "../../components/SelecaoFormaPagamento"
import { imprimirCode, imprimirHTML } from "../../RecebedorDePedidos/qzConfig"
import DialogConfiguracoes from "./DialogConfiguracoes"
import DialogPedido from "./DialogPedido"

const qz = require("qz-tray")

function CabecalhoMesa({
    classes,
    configuracoes,
    atualizarConfiguracoes,
    verificarSeTemQZ,
    mesa,
    handleClose,
    impressorasQZ,
}) {
    useEffect(() => {
        verificarSeTemQZ()
    }, [])

    return (
        <div className={classes.containerCabecalho}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <div className={classes.numeroDaMesa}>
                        <IconButton color="inherit" onClick={handleClose} aria-label="close">
                            <Close />
                        </IconButton>
                        <Typography variant="h6" component="span">
                            Mesa {mesa?.numeroMesa}
                        </Typography>
                    </div>
                    <div>
                        {!impressorasQZ.length > 0 && (
                            <div className={classes.botoesImpressoras}>
                                <Button
                                    onClick={e => {
                                        verificarSeTemQZ()
                                    }}
                                >
                                    Identificar Printer
                                </Button>
                                <Button
                                    onClick={e => {
                                        window.location.href =
                                            "https://clientefielsp.s3-sa-east-1.amazonaws.com/Cliente+Fiel+Printer.zip"
                                    }}
                                >
                                    Download Printer
                                </Button>
                            </div>
                        )}
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    )
}

const MeioDePagamento = ({
    index,
    formasDePagamento,
    setFormasDePagamento,
    setValorRestante,
    opcoesDePagamento,
    classes,
}) => {
    const [meioPagamento, setMeioPagamento] = useState(
        formasDePagamento[index]?.meioPagamento || ""
    )
    const [valor, setValor] = useState(formasDePagamento[index]?.valor || 0)
    const [valorAnterior, setValorAnterior] = useState(formasDePagamento[index]?.valor || 0)

    const handleMeioPagamento = e => {
        const novoMeio = e.target.value.id

        setMeioPagamento(novoMeio)

        setFormasDePagamento(prev => {
            const novasFormas = [...prev]
            novasFormas[index] = {
                ...novasFormas[index],
                formaPagamento: { id: novoMeio },
                id: null,
                versao: 0,
            }
            return novasFormas
        })
    }

    const handleValor = e => {
        const novoValor = e.target.value.replace(",", ".")
        setValor(novoValor)
        const valorNumerico = parseFloat(novoValor)
        if (!isNaN(valorNumerico)) {
            setValorRestante(prev => prev + valorAnterior - valorNumerico)
            setValorAnterior(valorNumerico)
            setFormasDePagamento(prev => {
                const novasFormas = [...prev]
                novasFormas[index] = {
                    ...novasFormas[index],
                    valorCentavos: Math.round(valorNumerico * 100),
                }
                return novasFormas
            })
        } else if (novoValor === "") {
            setValorRestante(prev => prev + valorAnterior)
            setValorAnterior(0)
            setFormasDePagamento(prev => {
                const novasFormas = [...prev]
                novasFormas[index] = { ...novasFormas[index], valorCentavos: 0 }
                return novasFormas
            })
        }
    }

    return (
        <div className={classes.containerDePagamento}>
            <div style={{ flex: 2 }}>
                <SelecaoFormaPagamento
                    formasPagamento={opcoesDePagamento}
                    handleSelecaoFormaDePagamento={handleMeioPagamento}
                />
            </div>
            <div style={{ flex: 1 }}>
                <TextField
                    label="Valor"
                    fullWidth
                    variant="outlined"
                    placeholder="R$ 00,00"
                    value={valor}
                    onChange={handleValor}
                    type="Number"
                />
            </div>
        </div>
    )
}

const DialogFinalizarMesa = ({
    mesa,
    openFinalizarMesa,
    setOpenFinalizarMesa,
    Transition,
    alertStart,
    useStyles,
    opcoesDePagamento,
}) => {
    const classes = useStyles()
    const { setLoading } = useContext(LoadingContext)
    const handleClose = () => setOpenFinalizarMesa(false)

    const fecharPedidoMesa = async mesa => {
        try {
            setLoading(true)
            const autorizacao = localStorage.getItem("chaveSistema")
            const response = await FecharMesa(mesa, autorizacao)

            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
                setLoading(false)
                return false
            }
            alertStart(`Mesa ${mesa.numeroMesa} fechada com sucesso!`, "success")
            window.location.reload()
            setLoading(false)
        } catch (error) {
            RegistrarLogErro(error, "fecharPedidoMesa")
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    const [formasDePagamento, setFormasDePagamento] = useState([])
    const [valorRestante, setValorRestante] = useState(0)

    useEffect(() => {
        if (mesa?.pedidosMesa?.length) {
            setValorRestante(
                mesa.pedidosMesa.reduce((total, pedido) => total + pedido.valorTotal, 0)
            )
        }
        setFormasDePagamento([])
    }, [openFinalizarMesa, mesa])

    const handleAdicionarFormaDePagamento = () => {
        setFormasDePagamento(prev => [...prev, { valorCentavos: 0 }])
    }

    const handleFecharMesa = () => {
        try {
            if (formasDePagamento.length == 0) {
                throw new Error("Adicione uma forma de pagamento")
            }
            if (
                (valorRestante ?? 0).toFixed(2) === "0.00" ||
                (valorRestante ?? 0).toFixed(2) === "-0.00"
            ) {
                const formasDePagamentoFiltradas = formasDePagamento.filter(
                    obj => Object.keys(obj).length !== 0
                )
                formasDePagamentoFiltradas.forEach(forma => {
                    if (!forma.formaPagamento) {
                        throw new Error("Preencha todas as formas de pagamento")
                    }
                })
                mesa.pagamentos = formasDePagamentoFiltradas
                fecharPedidoMesa(mesa)
            } else {
                alertStart("O valor restante deve ser igual a zero para fechar a mesa", "warning")
            }
        } catch (err) {
            alertStart(err.message, "warning")
        }
    }

    return (
        <Dialog
            open={openFinalizarMesa}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>{`Fechar Mesa ${mesa?.numeroMesa}`}</DialogTitle>
            <DialogContent className={classes.listaPedidos}>
                <div>
                    <Typography variant="h6">
                        Valor Restante:{" "}
                        {valorRestante?.toFixed(2) == -0.0 ? 0.0 : valorRestante?.toFixed(2)}R$
                    </Typography>
                </div>
                <div className={classes.listaPedidos}>
                    {formasDePagamento &&
                        formasDePagamento.map((_, index) => (
                            <MeioDePagamento
                                key={index}
                                index={index}
                                formasDePagamento={formasDePagamento}
                                setFormasDePagamento={setFormasDePagamento}
                                setValorRestante={setValorRestante}
                                opcoesDePagamento={opcoesDePagamento}
                                classes={classes}
                            />
                        ))}
                    <div>
                        <Button
                            color="primary"
                            onClick={handleAdicionarFormaDePagamento}
                            variant="contained"
                            fullWidth
                        >
                            Adicionar forma de pagamento
                        </Button>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleFecharMesa} className={classes.botaoFecharMesa}>
                    Fechar Mesa
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const LIMITE_NUMERO_IMPRESSORAS = 10

function getImpressoras(configuracoes) {
    if (configuracoes.printer.impressoras?.length) return configuracoes.printer.impressoras

    const impressoras = []

    if (configuracoes.printer.impressora) impressoras.push(configuracoes.printer.impressora)
    if (configuracoes.printer.impressora2) impressoras.push(configuracoes.printer.impressora2)

    return impressoras
}

const ListaImpressorasSelecionadas = ({
    mobile,
    tabStatus,
    tipoPainelImpressao,
    impressorasQZ,
    modalConfiguracoes,
}) => {
    const classes = useStyles()

    const { atualizarConfiguracoes, configuracoes } = useContext(ServicosContext)

    const [listaImpressoras, setListaImpressoras] = useState(getImpressoras(configuracoes))

    const [isImprimirComandaCompleta, setIsImprimirComandaCompleta] = useState(
        configuracoes?.printer?.imprimirComandaCompleta ?? false
    )
    const [impressoraComandaCompleta, setImpressoraComandaCompleta] = useState(
        configuracoes.printer.impressoraComandaCompleta ?? "Nenhuma"
    )

    const handleChangeImpressora = (e, i) => {
        listaImpressoras[i] = e.target.value

        const impressorasFiltradas = listaImpressoras.filter(
            i => i && impressorasQZ.find(nomeImpressora => nomeImpressora === i)
        )

        setListaImpressoras(impressorasFiltradas)

        atualizarConfiguracoes({
            ...configuracoes,
            printer: {
                ...configuracoes.printer,
                impressoras: impressorasFiltradas,
                imprimirComandaCompleta: isImprimirComandaCompleta,
                impressoraComandaCompleta:
                    impressoraComandaCompleta !== "Nenhuma" ? impressoraComandaCompleta : null,
            },
        })
    }

    if (tipoPainelImpressao !== "plugin") return null

    const adicionarImpressora = () => setListaImpressoras(prev => [...prev, "Nenhuma"])

    return (
        <>
            {listaImpressoras?.map((impressora, i) => (
                <Grid item xs={12} md={6} lg={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="impressoras">{`Imprimir ${i + 1}º Opção`}</InputLabel>
                        <Select
                            fullWidth
                            value={impressora}
                            onChange={e => handleChangeImpressora(e, i)}
                            label={`Imprimir ${i + 1}º Opção`}
                        >
                            <MenuItem value="Nenhuma">Nenhuma</MenuItem>
                            {impressorasQZ.map((printer, index) => (
                                <MenuItem key={index} value={printer}>
                                    {printer}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            ))}
            {listaImpressoras.length < LIMITE_NUMERO_IMPRESSORAS ? (
                <Grid item xs={12} md={6} lg={6}>
                    <Button
                        fullWidth
                        variant="outlined"
                        onClick={adicionarImpressora}
                        className={classes.adicionarImpressora}
                    >
                        Adicionar Impressora
                    </Button>
                </Grid>
            ) : null}
            {configuracoes?.user?.parametros?.impressaoAlternada ? (
                <>
                    <Grid item xs={12} md={6} lg={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isImprimirComandaCompleta}
                                    onChange={e => setIsImprimirComandaCompleta(e.target.checked)}
                                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                                    checkedIcon={<CheckBox fontSize="small" />}
                                />
                            }
                            label="Imprimir comanda completa"
                        />
                    </Grid>
                    {isImprimirComandaCompleta ? (
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl variant="outlined" style={{ width: "100%" }}>
                                <InputLabel id="impressoras">Impr. p/ comanda completa</InputLabel>
                                <Select
                                    fullWidth
                                    value={impressoraComandaCompleta}
                                    onChange={e => setImpressoraComandaCompleta(e.target.value)}
                                    label="Impr. p/ comanda completa"
                                >
                                    <MenuItem value="Nenhuma">Nenhuma</MenuItem>
                                    {impressorasQZ.map((printer, index) => (
                                        <MenuItem key={index} value={printer}>
                                            {printer}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    ) : null}{" "}
                </>
            ) : null}
        </>
    )
}

const ListaPedidosMesa = ({
    mesa,
    setPedidoSelecionado,
    handleClose,
    useStyles,
    Transition,
    impressorasQZ,
    setImpressorasQZ,
    alertStart,
    todasMesas,
    atualizarMesas,
}) => {
    const classes = useStyles()
    const history = useHistory()

    const [openFinalizarMesa, setOpenFinalizarMesa] = useState(false)
    const [mudancaConfiguracao, setMudancaConfiguracao] = useState(false)

    const { configuracoes, atualizarConfiguracoes } = useContext(ServicosContext)
    const { printer } = configuracoes

    const tipoPainelImpressao =
        impressorasQZ.length > 0
            ? "plugin" //qz
            : window.navigator.userAgent.includes("Electron")
            ? "software" //exe
            : "navegador" //padrao do navegador

    const parametros = configuracoes.user.parametros
    const tipoPrinter =
        tipoPainelImpressao === "plugin"
            ? printer.tipoImpressao
                ? printer.tipoImpressao
                : "code"
            : ""
    const tamanhoFonteSelect = printer.tamanhoFonteSelect ? printer.tamanhoFonteSelect : "media"

    function getImpressoras(configuracoes) {
        if (configuracoes.printer.impressoras?.length) return configuracoes.printer.impressoras

        const impressoras = []

        if (configuracoes.printer.impressora) impressoras.push(configuracoes.printer.impressora)
        if (configuracoes.printer.impressora2) impressoras.push(configuracoes.printer.impressora2)

        return impressoras
    }

    const exibirTamanhoFonte = tipoPrinter === "html"

    const handleSelecaoPedido = e => {
        if (!mesa.pedidosMesa) return

        const pedido = mesa.pedidosMesa[e.target.dataset.index]
        setPedidoSelecionado(pedido)

        textoHTML(pedido.textoImpressao)
    }

    const verificarSeTemQZ = () => {
        try {
            if (qz.websocket.isActive()) {
                buscarImpressoras()
            } else {
                qz.websocket
                    .connect()
                    .then(() => {
                        qz.printers
                            .details()
                            .then(data => {
                                for (var i = 0; i < data.length; i++) {
                                    console.log(
                                        data[i].name +
                                            " " +
                                            data[i].driver +
                                            " " +
                                            data[i].density +
                                            " " +
                                            data[i].trays
                                    )
                                }
                            })
                            .catch(function (e) {
                                console.error(e)
                            })

                        //console.log('Conectado QZ!');
                        buscarImpressoras()
                    })
                    .catch(err => {
                        console.error("Erro QZ", err.message)
                        // alertStart("Impressao automatica não identifica!", "error")
                        if (tipoPainelImpressao === "plugin") {
                            setImpressorasQZ([])
                        }
                    })
            }
        } catch (error) {
            RegistrarLogErro(error, "verificarSeTemQZ")
        }
    }

    const buscarImpressoras = async () => {
        try {
            if (!configuracoes.printer?.impressora) {
                configuracoes.printer.impressora = await qz.printers.getDefault()
                atualizarConfiguracoes(configuracoes)
                // localStorage.setItem("configuracoes", JSON.stringify(configuracoes))
            }

            const printers = await qz.printers.find()
            setImpressorasQZ(printers)
            //console.log('PRINTERS QZ >>>>>>> ', printers)
        } catch (error) {
            // RegistrarLogErro(error, "buscarImpressoras")
        }
    }

    const [opcoesDePagamento, setOpcoesDePagamento] = useState([])
    const buscarFormasDePagamento = async idEstabelecimento => {
        try {
            const response = await BuscarFormaDePagamentos(idEstabelecimento)

            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
                return false
            }
            setOpcoesDePagamento(response)
        } catch (error) {
            RegistrarLogErro(error, "BuscarFormaDePagamentos")
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    const [pedidoEnviar, setPedidoEnviar] = useState()

    const [openPedido, setOpenPedido] = useState(false)
    const handleOpenPedido = pedido => {
        setPedidoEnviar(pedido)
        setOpenPedido(true)
    }
    const handleClosePedido = () => {
        setOpenPedido(false)
    }

    const [fullWidth, setFullWidth] = useState(true)

    const [openConfiguracoes, setopenConfiguracoes] = useState(false)
    const handleCloseConfiguracoes = () => {
        setopenConfiguracoes(false)
    }

    const [numeroMesa, setNumeroMesa] = useState(mesa?.numeroMesa)
    const handleChangeNumeroMesa = e => {
        setNumeroMesa(e.target.value)
    }

    useEffect(() => {
        setNumeroMesa(mesa?.numeroMesa)
    }, [mesa])

    const mudarMesa = async numeroMesa => {
        const Authorization = localStorage.getItem("chaveSistema")
        mesa.numeroMesa = numeroMesa

        try {
            const response = await MudarNumeroMesa(mesa, Authorization)

            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
                return false
            }
            setOpcoesDePagamento(response)
        } catch (error) {
            RegistrarLogErro(error, "excluirItemDoPedidoMesa")
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    const impressao = configuracoes => {
        const {
            pedido = {},
            texto = "",
            qtd = 1,
            qrcode = {},
            QZObrigatorio = false,
            numeroDaImpressora = 0,
            impressora = "",
            imprimirNota = false,
        } = configuracoes

        console.log("printer", printer)
        console.log(">printer", printer)

        console.log("tipoImpressao", printer.tipoImpressao)

        configuracoes.qz = qz
        configuracoes.printer = printer

        if (printer.imprimirLogo) {
            configuracoes.logo = parametros?.estabelecimento?.urlLogo
        }

        if (printer.tipoImpressao == "code") {
            imprimirCode(configuracoes)
            // if (imprimirNota && user.parametros.ativarPlugNotas) imprimirNotaFiscal(pedido.id)
            return true
        }
        imprimirHTML(configuracoes)
        // if (imprimirNota && user.parametros.ativarPlugNotas) imprimirNotaFiscal(pedido.id)
    }

    async function imprimirQz(configuracao) {
        let {
            pedido = {},
            texto = "",
            qtd = 1,
            qrcode = {},
            QZObrigatorio = false,
            numeroDaImpressora = 0,
            impressora = "",
            imprimirNota = false,
        } = configuracao
        console.log("imprimirQz", texto)
        try {
            console.log("Impressão Qz - Recebedor de Pedidos")
            if (qz.websocket.isActive()) {
                if (!impressora || impressora == "vazio") {
                    impressora = await qz.printers.getDefault()
                }
                console.log("impressora()", impressora, texto, qtd)
                configuracao.impressora = impressora
                return impressao(configuracao)
            }

            qz.websocket
                .connect()
                .then(() => {
                    //console.log('Conectado QZ!');
                    // return qz.printers.find(); //array de impressoras
                    if (!impressora) {
                        impressora = qz.printers.getDefault()
                    }

                    const print = impressora //impressora padrao
                    localStorage.setItem("impressora", JSON.stringify(print))
                    return print
                })
                .then(printer => {
                    console.log("impressora()", printer, texto, qtd)

                    configuracao.impressora = printer
                    return impressao(configuracao)
                })
                .then(() => {})
                .catch(err => {
                    console.error("erro", err)
                })
        } catch (error) {
            RegistrarLogErro(error, "imprimirQz", configuracao)
        }
    }

    const mandarImprimir = async configs => {
        const {
            pedido = {},
            texto = "",
            qtd = 1,
            qrcode = {},
            QZObrigatorio = false,
            numeroDaImpressora = 0,
            imprimirNota = false,
            isResumo = false,
        } = configs
        try {
            console.log("mandarImprimir > configs", configs)

            if (parametros.assinaturaLimitada === true) {
                alertStart(
                    "Não foi impresso, porque sua assinatura está limitada por inadimplência.",
                    "info"
                )
                return false
            }

            console.log(">printer", printer)

            switch (tipoPainelImpressao) {
                case "navegador":
                    if (!QZObrigatorio) {
                        document.getElementById("rootPrint").innerHTML = texto.replace(
                            /(?:\r\n|\r|\n)/g,
                            "<br>"
                        )
                        window.setTimeout(function () {
                            if (parametros?.agruparViasImpressao) {
                                window.print()
                            } else {
                                for (var j = 0; j < qtd; j++) {
                                    window.print()
                                }
                            }
                        }, 200)
                    }
                    break

                case "plugin":
                    if (numeroDaImpressora === 0) {
                        const impressorasConfiguradas = getImpressoras(configuracoes)

                        console.log("getImpressoras", configuracoes, impressorasConfiguradas)

                        if (!impressorasConfiguradas.length) {
                            alertStart("Sua lista de impressoras está vazia", "warning")
                            return
                        }

                        impressorasConfiguradas.forEach(async (impressora, i) => {
                            configs.impressora = impressora

                            await imprimirQz(configs)
                        })
                    } else {
                        const impressorasConfiguradas = getImpressoras(configuracoes)
                        const impressora = impressorasConfiguradas[numeroDaImpressora - 1]

                        if (!impressora) {
                            alertStart(`${numeroDaImpressora}ª Impressora não selecionada`, "error")
                            return
                        }

                        configs.impressora = impressora

                        await imprimirQz(configs)
                    }
                    break

                case "software":
                    console.log(`01[${texto}]{${qtd}}`)
                    break

                default:
                    break
            }
        } catch (error) {
            console.error(error)
            alertStart(error, "error")
            RegistrarLogErro(error, "mandarImprimir", configs)
        }
    }

    return (
        <>
            <DialogConfiguracoes
                todasMesas={todasMesas}
                parametros={parametros}
                configuracoes={configuracoes}
                classes={classes}
                printer={printer}
                mesa={mesa}
                openConfiguracoes={openConfiguracoes}
                handleCloseConfiguracoes={handleCloseConfiguracoes}
                numeroMesa={numeroMesa}
                handleChangeNumeroMesa={handleChangeNumeroMesa}
                tipoPainelImpressao={tipoPainelImpressao}
                atualizarConfiguracoes={atualizarConfiguracoes}
                tamanhoFonteSelect={tamanhoFonteSelect}
                exibirTamanhoFonte={exibirTamanhoFonte}
                setMudancaConfiguracao={setMudancaConfiguracao}
                tipoPrinter={tipoPrinter}
                ListaImpressorasSelecionadas={ListaImpressorasSelecionadas}
                impressorasQZ={impressorasQZ}
                mudarMesa={mudarMesa}
            />
            <DialogPedido
                openPedido={openPedido}
                handleClosePedido={handleClosePedido}
                fullWidth={fullWidth}
                alertStart={alertStart}
                pedidoEnviar={pedidoEnviar}
                atualizarMesas={atualizarMesas}
                classes={classes}
                mesa={mesa}
            />
            <Dialog
                TransitionComponent={Transition}
                fullScreen
                open={
                    !!mesa?.numeroMesa
                    // true
                }
                onClose={handleClose}
            >
                <CabecalhoMesa
                    classes={classes}
                    configuracoes={configuracoes}
                    atualizarConfiguracoes={atualizarConfiguracoes}
                    verificarSeTemQZ={verificarSeTemQZ}
                    mesa={mesa}
                    handleClose={handleClose}
                    impressorasQZ={impressorasQZ}
                />
                <DialogFinalizarMesa
                    mesa={mesa}
                    handleClose={handleClose}
                    openFinalizarMesa={openFinalizarMesa}
                    setOpenFinalizarMesa={setOpenFinalizarMesa}
                    Transition={Transition}
                    alertStart={alertStart}
                    useStyles={useStyles}
                    opcoesDePagamento={opcoesDePagamento}
                />
                <div className={classes.barraControle}>
                    <IconButton
                        size="small"
                        onClick={() => {
                            setopenConfiguracoes(true)
                        }}
                    >
                        <Settings fontSize="large" />
                    </IconButton>
                </div>
                <Grid container className={classes.pedidoMesa}>
                    {mesa?.pedidosMesa
                        ? mesa?.pedidosMesa.map((pedido, index) => (
                              <Grid item key={index}>
                                  <Card
                                      className={classes.cartaoPedidoMesa}
                                      style={{ cursor: "pointer" }}
                                      data-index={index}
                                      onClick={() => {
                                          handleOpenPedido(pedido)
                                      }}
                                  >
                                      <CardContent
                                          className={classes.cartaoMesaDados}
                                          style={{ pointerEvents: "none" }}
                                      >
                                          <Typography variant="h2" component="span">
                                              {index + 1}º
                                          </Typography>
                                          <div className={classes.dadosPedidoMesa_container}>
                                              <Typography
                                                  className={classes.dadosPedidoMesa}
                                                  variant="body2"
                                                  color="textSecondary"
                                                  component="span"
                                              >
                                                  {pedido.strDataRealizacao}
                                              </Typography>
                                              <Typography
                                                  className={classes.dadosPedidoMesa}
                                                  component="span"
                                              >
                                                  {pedido.cliente?.nome}
                                              </Typography>
                                              <Typography
                                                  className={classes.dadosPedidoMesa}
                                                  variant="body2"
                                                  component="span"
                                              >
                                                  {Intl.NumberFormat("pt-BR", {
                                                      style: "currency",
                                                      currency: "BRL",
                                                  }).format(pedido.valorTotal)}
                                              </Typography>
                                          </div>
                                      </CardContent>
                                      <CardActions className={classes.cartaoMesaDesconto}>
                                          <Typography
                                              variant="button"
                                              align="center"
                                              className={[
                                                  classes.cartaoMesaDesconto_texto,
                                                  pedido.valorDesconto
                                                      ? classes.valorDesconto
                                                      : classes.semDesconto,
                                              ].join(" ")}
                                          >
                                              {pedido.valorDesconto
                                                  ? `DESCONTO DE ${Intl.NumberFormat("pt-BR", {
                                                        style: "currency",
                                                        currency: "BRL",
                                                    }).format(pedido.valorDesconto)}`
                                                  : `SEM DESCONTO`}
                                          </Typography>
                                      </CardActions>
                                  </Card>
                              </Grid>
                          ))
                        : null}
                </Grid>
                <Fab
                    variant="extended"
                    aria-label="add"
                    className={classes.botaoEstaticoClose}
                    // className={classes.fixedMesa}
                    onClick={async () => {
                        await buscarFormasDePagamento(mesa.estabelecimento.id)
                        setOpenFinalizarMesa(true)
                    }}
                >
                    <Close />
                    Fechar Mesa
                </Fab>
                <Fab
                    variant="extended"
                    aria-label="add"
                    className={classes.botaoEstaticoImpressao}
                    onClick={() => mandarImprimir({ texto: mesa.impressao })}
                >
                    <Print />
                    Imprimir todas
                </Fab>
                <Fab
                    variant="extended"
                    aria-label="add"
                    className={classes.botaoEstaticoAdd}
                    onClick={() =>
                        history.push(
                            "/servicos/modulo/registrador-de-pedidos?mesa=" + mesa.numeroMesa
                        )
                    }
                >
                    <Add />
                    Adicionar Pedido
                </Fab>
            </Dialog>
        </>
    )
}

export default ListaPedidosMesa
