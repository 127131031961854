import { RiArrowLeftLine } from "react-icons/ri"
import { useHistory } from "react-router-dom"
/*import { Button, Navbar, Nav, NavDropdown } from 'react-bootstrap';

import './styles.css';*/
import AppBar from "@material-ui/core/AppBar"
import Button from "@material-ui/core/Button"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Badge,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Tooltip,
} from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import {
    ArrowDropDown,
    ChevronLeft,
    ChevronRight,
    Menu,
    Notifications,
    Settings,
} from "@material-ui/icons"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import MenuIcon from "@material-ui/icons/Menu"
import { Alert } from "@material-ui/lab"
import clsx from "clsx"
import { useContext, useState } from "react"

import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import ServicosContext from "../../contexts/servicosContext"

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
    },
    appBar: {
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: "none",
    },
    drawer: {
        position: "fixed",
        zIndex: 3,
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: "space-between",
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    lista: {
        width: "100%",
    },
    tituloAccordion: {
        width: "100%",
        display: "flex",
        gap: "0.75rem",
        "& > button": {
            backgroundColor: "#6c757d",
            color: "white",
        },
        "& .no-mobile": {
            marginRight: "0.25rem",
            [theme.breakpoints.down("xs")]: {
                display: "none",
            },
        },
    },
    accordionDetails: {
        padding: 0,
    },
    scrollbarCustom: {
        "&::-webkit-scrollbar": {
            width: "8px",
        },
        "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
        },
    },
    botaoAviso: {
        color: "yellow",
        margin: "0 1rem",
        "& > span > span": {
            "& > span": {
                backgroundColor: "white",
                color: "black",
            },
        },
    },
}))

export default function Cabecalho(props) {
    const history = useHistory()
    const {
        cor,
        icone,
        pagina,
        subtitulo,
        voltar = true,
        desconect = true,
        desconectar,
        registrarPedidoMesa,
        setAbrirCaixa,
        trocarEstabelecimento,
        estabelecimentos,
        handleClose = history.goBack,
        gerarQRCode,
        avisoDoisRecebedores,
        botaoModoVisualizacao,
        setTabStatus = () => {},
        alertas,
        openAlertas,
        telasEdicao,
    } = props

    const {
        handleRegistradorDePedidos,
        handleAceitadorDePedidos,
        handleSistemaAdm,
        handleCaixa,
        handleNotaFiscal,
        handleIzzaBot,
        handleGeradorQrCode,
        handleCardapio,
        handlePedidoDeMesa,
        configuracoes,
        acessarImagensTematicas,
        // atualizarConfiguracoes,
        // buscarParametros,
        handleHome,
    } = useContext(ServicosContext)

    const classes = useStyles()

    const theme = useTheme()
    const [open, setOpen] = useState(false)

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    const { tela } = useParams()

    return (
        <div className={classes.root} id="cabecalhoApp">
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar
                    style={{ display: "flex", justifyContent: "space-between", maxWidth: "100vw" }}
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, open && classes.hide)}
                        >
                            <MenuIcon />
                        </IconButton>
                        {voltar && (
                            <div
                                className={classes.voltarIcon}
                                color="inherit"
                                // onClick={() => history.push("/servicos")}
                                onClick={handleClose}
                                aria-label="open drawer"
                            >
                                <RiArrowLeftLine style={{ width: "30px", height: "30px" }} />
                            </div>
                        )}
                        {icone && (
                            <div
                                className={classes.iconeImg}
                                color="inherit"
                                aria-label="open drawer"
                            >
                                <img
                                    src={icone}
                                    style={{
                                        height: "50px",
                                        borderRadius: "100%",
                                        // marginRight: "2em",
                                    }}
                                    alt=""
                                />
                            </div>
                        )}
                        <Typography
                            className={classes.title}
                            variant="h6"
                            noWrap
                            style={{ marginLeft: "1em", maxWidth: "50vw" }}
                        >
                            {pagina}{" "}
                            {subtitulo && (
                                <span style={{ fontSize: "50%", color: "grey" }}>{subtitulo}</span>
                            )}
                        </Typography>
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                        {desconectar && (
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={desconectar}
                            >
                                <ExitToAppIcon />
                            </IconButton>
                        )}
                        {registrarPedidoMesa && (
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={registrarPedidoMesa}
                            >
                                Registrar
                            </IconButton>
                        )}
                        {trocarEstabelecimento && (
                            <Button
                                onClick={() => history.push("/servicos/ferramenta/pedido-de-mesa")}
                                variant="contained"
                            >
                                <span className="no-mobile">Gerar</span> QRCodes
                            </Button>
                        )}

                        {avisoDoisRecebedores && (
                            <div id="aviso-dois-recebedores" style={{ display: "none" }}>
                                <Tooltip
                                    arrow
                                    title="Mais de um recebedor aberto pode impactar no recebimento automático dos seus pedidos."
                                >
                                    <Alert severity="info" elevation={3}>
                                        Há outro recebedor aberto
                                    </Alert>
                                </Tooltip>
                            </div>
                        )}

                        {alertas?.length && (
                            <IconButton className={classes.botaoAviso} onClick={openAlertas}>
                                <Button
                                    badgeContent={alertas.length}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                    }}
                                >
                                    {estabelecimentos?.selecionado?.nome}
                                </Button>
                            </IconButton>
                        )}
                        {gerarQRCode && (
                            <div className={classes.botaoQRCode}>
                                <Button
                                    onClick={() =>
                                        history.push("/servicos/ferramenta/pedido-de-mesa")
                                    }
                                    variant="contained"
                                >
                                    Gerar QRCodes
                                </Button>
                            </div>
                        )}
                        {avisoDoisRecebedores && (
                            <div id="aviso-dois-recebedores" style={{ display: "none" }}>
                                <Tooltip
                                    arrow
                                    title="Mais de um recebedor aberto pode impactar no recebimento automático dos seus pedidos."
                                >
                                    <Alert severity="info" elevation={3}>
                                        Há outro recebedor aberto
                                    </Alert>
                                </Tooltip>
                            </div>
                        )}
                        {alertas?.length && (
                            <IconButton className={classes.botaoAviso} onClick={openAlertas}>
                                <Badge
                                    badgeContent={alertas.length}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                    }}
                                >
                                    <Notifications fontSize="large" />
                                </Badge>
                            </IconButton>
                        )}
                        {botaoModoVisualizacao && (
                            <div className={classes.botaoQRCode}>
                                <Button
                                    variant="contained"
                                    color="default"
                                    onClick={() => setTabStatus("VIEW")}
                                >
                                    Ativar Modo Visualização
                                </Button>
                            </div>
                        )}
                        {telasEdicao && (
                            <div style={{ display: "flex", gap: "1em" }}>
                                <Button
                                    onClick={() =>
                                        history.push(
                                            "/servicos/ferramenta/edicao-cardapio/produtos"
                                        )
                                    }
                                    variant="contained"
                                    style={{
                                        backgroundColor: tela == "produtos" ? "#4FC3F7" : "#0288D1",
                                        color: "white",
                                    }}
                                >
                                    Produtos
                                </Button>
                                <Button
                                    onClick={() =>
                                        history.push("/servicos/ferramenta/edicao-cardapio/massa")
                                    }
                                    variant="contained"
                                    style={{
                                        backgroundColor: tela == "massa" ? "#4FC3F7" : "#0288D1",
                                        color: "white",
                                    }}
                                >
                                    Edição em Massa
                                </Button>
                            </div>
                        )}
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: clsx(classes.drawerPaper, classes.scrollbarCustom),
                }}
            >
                <div className={classes.drawerHeader}>
                    <Typography variant="h5" onClick={handleHome} style={{ cursor: "pointer" }}>
                        Menu
                    </Typography>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "ltr" ? <ChevronLeft /> : <ChevronRight />}
                    </IconButton>
                </div>

                <Accordion>
                    <AccordionSummary expandIcon={<ArrowDropDown />}>
                        <div className={classes.tituloAccordion}>
                            <Menu />
                            <Typography>Módulos</Typography>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <List className={classes.lista}>
                            <ListItem
                                button
                                key="Aceitador de Pedidos"
                                onClick={handleAceitadorDePedidos}
                            >
                                <ListItemText>Aceitador de Pedidos</ListItemText>
                            </ListItem>
                            <ListItem
                                button
                                key="Caixa"
                                onClick={() => {
                                    history.push("/servicos/modulo/caixa")
                                }}
                            >
                                <ListItemText>Caixa</ListItemText>
                            </ListItem>
                            <ListItem
                                button
                                key="Cardápio"
                                onClick={() => {
                                    history.push("/servicos/ferramenta/edicao-cardapio/produtos")
                                }}
                            >
                                <ListItemText>Cardápio</ListItemText>
                            </ListItem>
                            <ListItem button key="Nota Fiscal" onClick={handleNotaFiscal}>
                                <ListItemText>Nota Fiscal</ListItemText>
                            </ListItem>
                            <ListItem button key="Pedidos de Mesa" onClick={handlePedidoDeMesa}>
                                <ListItemText>Pedidos de Mesa</ListItemText>
                            </ListItem>
                            <ListItem
                                button
                                key="Registrador de Pedidos"
                                onClick={() => {
                                    handleRegistradorDePedidos()
                                }}
                            >
                                <ListItemText>Registrador de Pedido</ListItemText>
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ArrowDropDown />}>
                        <div className={classes.tituloAccordion}>
                            <Settings />
                            <Typography>Utilitários</Typography>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <List className={classes.lista}>
                            <ListItem button key="Atendente Virtual (Izza)" onClick={handleIzzaBot}>
                                <ListItemText>Atendente Virtual (Izza)</ListItemText>
                            </ListItem>
                            <ListItem button key="Gerador de QrCode" onClick={handleGeradorQrCode}>
                                <ListItemText>Gerador de QrCode</ListItemText>
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>
            </Drawer>
        </div>
    )
}
