import api from "./api"
import { RegistrarLogErro } from "./clientefiel"

function tratamentoResponse(response, chamada) {
    if (response.data.codErro > 0)
        return { retornoErro: response.data.codErro, mensagem: response.data.mensagem }
    else if (typeof response.data == "string" && response.data != "")
        return {
            retornoErro: 1,
            mensagem: chamada + " - Erro inesperado, entre em contato com os reponsáveis.",
        }
    else return response.data
}

const ErroRequisicao = function (data, aplicativoDados) {
    try {
        console.log("ErroRequisicao> ", data.request, data.message)
        if (data.message == "Network Error") {
            return "Verifique sua conexão!"
        } else if (data.message) {
            return "Erro: " + data.message
        }
        return false
    } catch (error) {
        RegistrarLogErro(error, "ErroRequisicao", data)
    }
}

export const AlertasPainel = async function () {
    try {
        const response = await api.get("fornecedor/AlertasPainel").catch(function (error) {
            const resp = ErroRequisicao(error)
            return { data: { codErro: 1, mensagem: resp } }
        })

        console.log("fornecedor/AlertasPainel/ => ", response)

        return tratamentoResponse(response, "AlertasPainel")
    } catch (error) {
        RegistrarLogErro(error, "AlertasPainel", {})
        return { retornoErro: 1, mensagem: error.message }
    }
}
