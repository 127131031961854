import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select } from "@material-ui/core"
import { useEffect, useState } from "react"
import ItemMassa from "./ItemMassa"

const useStyles = makeStyles(() => ({
    containerBotoes: {
        overflowX: "hidden",
    },
    botoesPaginacao: {
        marginTop: "17px",
    },
}))

const PaginatedList = ({
    items,
    resetPagination,
    handleInputChange,
    handleCadastrarImagem,
    setItems,
}) => {
    const [itensPorPagina, setItensPorPagina] = useState(10)
    const [paginaAtual, setPaginaAtual] = useState(1)

    useEffect(() => {
        if (resetPagination) {
            setPaginaAtual(1)
        }
    }, [resetPagination])

    const indexDoUltimoItem = paginaAtual * itensPorPagina
    const indexDoPrimeiroItem = indexDoUltimoItem - itensPorPagina
    const itensAtuais = items?.slice(indexDoPrimeiroItem, indexDoUltimoItem)

    const paginate = numeroDePagina => setPaginaAtual(numeroDePagina)

    const handleItensPorPaginaChange = event => {
        setItensPorPagina(parseInt(event.target.value, 10))
        setPaginaAtual(1)
    }

    const classes = useStyles()

    return (
        <Grid container className={classes.containerBotoes}>
            <Grid container alignItems="center" spacing={1}>
                <Grid container item xs={12} sm={12} md={2}>
                    <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel>Itens por página</InputLabel>
                        <Select
                            value={itensPorPagina}
                            label="Itens Por Pagina"
                            onChange={handleItensPorPaginaChange}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                            <MenuItem value={items?.length}>Todos</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid container item xs={12} sm={12} md={10}>
                    <Pagination
                        itensPorPagina={itensPorPagina}
                        totalItens={items?.length}
                        paginaAtual={paginaAtual}
                        paginate={paginate}
                    />
                </Grid>
            </Grid>
            {itensAtuais?.map((item, i) => (
                <ItemMassa
                    index={i}
                    key={item?.id}
                    produto={item}
                    resetPagination={resetPagination}
                    handleInputChange={handleInputChange}
                    handleCadastrarImagem={handleCadastrarImagem}
                    setItems={setItems}
                />
            ))}
        </Grid>
    )
}

const Pagination = ({ itensPorPagina, totalItens, paginaAtual, paginate }) => {
    const numerosDePagina = []
    for (let i = 1; i <= Math.ceil(totalItens / itensPorPagina); i++) {
        numerosDePagina.push(i)
    }

    let numerosDePaginasVisiveis = []
    if (paginaAtual <= 5) {
        numerosDePaginasVisiveis = numerosDePagina.slice(0, 9)
    } else if (paginaAtual >= numerosDePagina.length - 4) {
        numerosDePaginasVisiveis = numerosDePagina.slice(
            numerosDePagina.length - 9,
            numerosDePagina.length
        )
    } else {
        numerosDePaginasVisiveis = numerosDePagina.slice(paginaAtual - 5, paginaAtual + 4)
    }

    const classes = useStyles()

    return (
        <Grid className={classes.botoesPaginacao}>
            <ul className="pagination">
                <li className="page-item">
                    <button
                        onClick={() => paginate(paginaAtual - 1)}
                        disabled={paginaAtual === 1}
                        className="page-link"
                    >
                        Anterior
                    </button>
                </li>
                {numerosDePaginasVisiveis.map(numero => (
                    <li
                        key={numero}
                        className={`page-item ${paginaAtual === numero ? "active" : ""}`}
                    >
                        <button onClick={() => paginate(numero)} className="page-link">
                            {numero}
                        </button>
                    </li>
                ))}
                <li className="page-item">
                    <button
                        onClick={() => paginate(paginaAtual + 1)}
                        disabled={paginaAtual === Math.ceil(totalItens / itensPorPagina)}
                        className="page-link"
                    >
                        Próximo
                    </button>
                </li>
            </ul>
        </Grid>
    )
}

export default PaginatedList
