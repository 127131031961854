import api from "../../../../services/api"

export const solicitarEntregadorUber = async (idDoPedido, autorizacao) => {
    let resposta
    await api
        .post(
            `/entrega/uber/solicitar/${idDoPedido}`,
            {},
            {
                headers: { "Content-Type": "application/json", Authorization: autorizacao },
            }
        )
        .then(response => {
            resposta = response
        })
        .catch(error => {
            resposta = {
                data: {
                    mensagem: error.response.data,
                },
            }
        })
    return resposta
}

export const buscarDetalhesEntregaUber = async idDoPedido => {
    let detalhes
    await api
        .get(`entrega/uber/detalhe/${idDoPedido}`, {
            headers: { "Content-Type": "application/json" },
        })
        .then(response => {
            detalhes = response
        })
    return detalhes
}

export const cancelarEntregaUber = async (idDoPedido, autorizacao) => {
    let resposta
    await api
        .post(
            `/entrega/uber/cancelar/${idDoPedido}`,
            {},
            {
                headers: { "Content-Type": "application/json", Authorization: autorizacao },
            }
        )
        .then(response => {
            resposta = response
        })
    return resposta
}
