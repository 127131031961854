import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    makeStyles,
    Typography,
} from "@material-ui/core"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import MapIcon from "@material-ui/icons/Map"
import { useEffect, useState } from "react"
import CancelButton from "./CancelButton"

const useStyles = makeStyles({
    telaDetalhesEntrega: {
        alignItems: "center",
        textAlign: "center",
        maxWidth: "450px",
    },
    detalhesEntrega: {
        padding: "12px",
    },
    detalhesEntrega_botoes: {
        "& > *": {
            width: "100%",
            margin: "0.175rem 0",
        },
        "& > a > span:first-child": {
            margin: "0 0.75rem",
        },
        "& > a > span:first-child > span": {
            position: "absolute",
            right: "0.25rem",
        },
    },
    detalheEntregaUuid: {
        paddingBottom: "10px",
    },
})

export default function DetalhesEntregaUber({
    detalhesEntregaUber,
    abrirDetalhes,
    setAbrirDetalhes,
    setAbrirCancelamento,
    buscarDetalhes,
}) {
    const classes = useStyles()

    const [mostrarBotoes, setMostrarBotoes] = useState(false)

    const handleClose = () => {
        setAbrirDetalhes(false)
    }

    const foiEntregueOuCancelado = statusDoPedido => {
        setMostrarBotoes(true)
        if (!detalhesEntregaUber) setMostrarBotoes(false)
        const statusInvalidos = ["delivered", "canceled", "returned"]
        statusInvalidos.forEach(statusInvalido => {
            if (statusDoPedido === statusInvalido) {
                setMostrarBotoes(false)
            }
        })
    }

    useEffect(() => {
        if (abrirDetalhes) {
            buscarDetalhes()
        }
    }, [abrirDetalhes])

    useEffect(() => {
        foiEntregueOuCancelado(detalhesEntregaUber.status)
    }, [detalhesEntregaUber])

    return (
        <Dialog open={abrirDetalhes} onClose={handleClose}>
            <DialogTitle>
                <IconButton className="setaVoltarModal" onClick={handleClose}>
                    <ArrowForwardIosIcon />
                </IconButton>
                Detalhes
            </DialogTitle>
            <DialogContent className={classes.telaDetalhesEntrega}>
                <Grid container>
                    <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className={classes.detalheEntregaUuid}
                    >
                        <Typography variant="button">UUID</Typography>
                        <Typography variant="h5">
                            {detalhesEntregaUber.status &&
                                abrirDetalhes &&
                                detalhesEntregaUber.uuid.slice(-5)}
                        </Typography>
                    </Grid>
                    <Grid container direction="row" item lg={12} md={12} sm={12} xs={12}>
                        <Grid container item lg={6} md={6} sm={6} xs={12}>
                            <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                className={classes.detalhesEntrega}
                            >
                                <img
                                    width={120}
                                    height={115}
                                    src={detalhesEntregaUber.fotoEntregador}
                                />
                            </Grid>
                            {mostrarBotoes && (
                                <Grid
                                    item
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    className={classes.detalhesEntrega}
                                >
                                    <div className={classes.detalhesEntrega_botoes}>
                                        <Button
                                            variant="contained"
                                            href={detalhesEntregaUber.urlAcompanhamento}
                                            target={"_blank"}
                                            endIcon={<MapIcon />}
                                            maxWidth
                                            size="small"
                                        >
                                            Acompanhar
                                        </Button>
                                        <Button
                                            variant="contained"
                                            href={detalhesEntregaUber.urlAcompanhamento2}
                                            target={"_blank"}
                                            endIcon={<MapIcon />}
                                            maxWidth
                                            size="small"
                                        >
                                            Rastrear Entrega
                                        </Button>
                                        <CancelButton
                                            size="small"
                                            text="Cancelar entrega"
                                            handleClick={() => setAbrirCancelamento(true)}
                                        />
                                    </div>
                                </Grid>
                            )}
                        </Grid>
                        <Grid container item direction="row" lg={6} md={6} sm={6} xs={12}>
                            <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                className={classes.detalhesEntrega}
                            >
                                <Typography variant="button">Nome</Typography>
                                <Typography variant="body2">
                                    {detalhesEntregaUber !== {} &&
                                        detalhesEntregaUber.nomeEntregador}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                className={classes.detalhesEntrega}
                            >
                                <Typography variant="button">Telefone</Typography>
                                <Typography variant="body2">
                                    {detalhesEntregaUber !== {} &&
                                        detalhesEntregaUber.telefoneEntregador}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                className={classes.detalhesEntrega}
                            >
                                <Typography variant="button">Veículo</Typography>
                                <Typography variant="body2">
                                    {detalhesEntregaUber !== {} &&
                                        detalhesEntregaUber.veiculoEntregador}
                                </Typography>
                            </Grid>
                            {detalhesEntregaUber?.valorEntrega ? (
                                <Grid
                                    item
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    className={classes.detalhesEntrega}
                                >
                                    <Typography variant="button">Valor</Typography>
                                    <Typography variant="body2">
                                        {Intl.NumberFormat("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        }).format(detalhesEntregaUber.valorEntrega)}
                                    </Typography>
                                </Grid>
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>
                {detalhesEntregaUber.observacao && detalhesEntregaUber.status === "canceled" && (
                    <Grid
                        maxWidth
                        item
                        className={classes.detalhesEntrega}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                    >
                        <Typography variant="button">Observação</Typography>
                        <Typography variant="body1">{detalhesEntregaUber.observacao}</Typography>
                    </Grid>
                )}
            </DialogContent>
        </Dialog>
    )
}
