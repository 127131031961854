import { Button, makeStyles } from "@material-ui/core"
import { UberIcon } from "../assets"

const useStyles = makeStyles(theme => ({
    botaoSolicitarEntregador: {
        color: "#FFF",
        backgroundColor: "#000",
        padding: "8px",
        marginBottom: "4px",
        "&:hover": {
            backgroundColor: "#000",
        },
    },
}))

export default function SolicitarEntregadorButton({ chamarEntregador }) {
    const classes = useStyles()

    return (
        <Button
            variant="contained"
            onClick={chamarEntregador}
            endIcon={<UberIcon color="#FFF" />}
            className={classes.botaoSolicitarEntregador}
        >
            Solicitar entregador
        </Button>
    )
}
