import Package from "../../package.json"
import api from "./api"

function tratamentoResponse(response, chamada) {
    if (response.data.codErro > 0)
        return { retornoErro: response.data.codErro, mensagem: response.data.mensagem }
    else if (typeof response.data == "string" && response.data != "")
        return {
            retornoErro: 1,
            mensagem: chamada + " - Erro inesperado, entre em contato com os reponsáveis.",
        }
    else return response.data
}

const ErroRequisicao = function (data, aplicativoDados) {
    try {
        console.log("ErroRequisicao> ", data.request, data.message)
        if (data.message == "Network Error") {
            return "Verifique sua conexão!"
            // return 'Sem Internet ou sistema fora do ar!'
        } else if (data.message) {
            return "Erro: " + data.message
        }
        return false
    } catch (error) {
        RegistrarLogErro(error, "ErroRequisicao")
    }
}

export function RegistrarLogErro(erro, functionName, dataFuncao = "") {
    let stringErro = erro.message ? erro.message + "\n\n" + erro.stack : erro.stack ?? erro

    if (typeof stringErro === "object") stringErro = JSON.stringify(stringErro)

    if (dataFuncao !== "" && typeof dataFuncao === "string")
        stringErro = stringErro.concat("\n\ndata = ").concat(dataFuncao)
    if (dataFuncao !== "" && typeof dataFuncao === "object")
        stringErro = stringErro.concat("\n\ndata = ").concat(JSON.stringify(dataFuncao))

    const data = {
        identificador: functionName + " - V" + Package.version,
        erro: stringErro,
    }

    api.post("log/front/registrar", data, {
        headers: { "Content-Type": "application/json" },
    })
}

export const obterEstabelecimentos = async function (Authorization, data) {
    try {
        const response = await api.post("clientefiel/AcessoPorEndereco", data, {
            headers: { "Content-Type": "application/json", Authorization: Authorization },
        })

        console.log("obterEstabelecimentos => ", response)

        if (response.data.codErro > 0)
            return { retornoErro: response.data.codErro, mensagem: response.data.mensagem }
        else if (typeof response.data == "string")
            return {
                retornoErro: 1,
                mensagem: "Erro inesperado, entre em contato com os reponsáveis.",
            }
        else return response.data
    } catch (error) {
        RegistrarLogErro(error, "obterEstabelecimentos")

        return { retornoErro: 1, mensagem: error.message }
    }
}

export const EstabelecimentoAberto = async function (data, aplicativoDados) {
    try {
        const response = await api
            .get("clientefiel/EstabelecimentoAberto/" + data, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("EstabelecimentoAberto => ", response)

        return tratamentoResponse(response, "EstabelecimentoAberto")
    } catch (error) {
        RegistrarLogErro(error, "EstabelecimentoAberto")

        return { retornoErro: 1, mensagem: error.message }
    }
}

export const ObterCardapioCompletoV1 = async function (Authorization, data) {
    try {
        const response = await api.post("clientefiel/ObterCardapioCompletoV1", data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: Authorization,
                plataforma: "registrador de pedidos",
            },
        })

        console.log("ObterCardapioCompletoV1 => ", response)

        if (response.data.codErro > 0)
            return { retornoErro: response.data.codErro, mensagem: response.data.mensagem }
        else if (typeof response.data == "string")
            return {
                retornoErro: 1,
                mensagem: "Erro inesperado, entre em contato com os reponsáveis.",
            }
        else return response.data
    } catch (error) {
        RegistrarLogErro(error, "ObterCardapioCompletoV1")

        return { retornoErro: 1, mensagem: error.message }
    }
}

export const ObterCardapioNovo = async function (Authorization, data) {
    try {
        const response = await api.get(`cardapio/${data.idEstabelecimento}`, data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: Authorization,
                // plataforma: "registrador de pedidos",
            },
        })
        console.log("ObterCardapio => ", response)

        if (response.data.codErro > 0)
            return { retornoErro: response.data.codErro, mensagem: response.data.mensagem }
        else if (typeof response.data == "string")
            return {
                retornoErro: 1,
                mensagem: "Erro inesperado, entre em contato com os reponsáveis.",
            }
        else return response.data
    } catch (error) {
        RegistrarLogErro(error, "ObterCardapio")

        return { retornoErro: 1, mensagem: error.message }
    }
}

export const ObterCardapioPedidoMesa = async function (Authorization, data) {
    try {
        const response = await api.post("clientefiel/ObterCardapioPedidoMesa", data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: Authorization,
                plataforma: "registrador de pedidos",
            },
        })

        console.log("ObterCardapioPedidoMesa => ", response)

        if (response.data.codErro > 0)
            return { retornoErro: response.data.codErro, mensagem: response.data.mensagem }
        else if (typeof response.data == "string")
            return {
                retornoErro: 1,
                mensagem: "Erro inesperado, entre em contato com os reponsáveis.",
            }
        else return response.data
    } catch (error) {
        RegistrarLogErro(error, "ObterCardapioPedidoMesa")

        return { retornoErro: 1, mensagem: error.message }
    }
}

export const AcessoPorEnderecoEstabelecimentos = async function (Authorization, data) {
    try {
        const response = await api.post("clientefiel/AcessoPorEnderecoEstabelecimentos ", data, {
            headers: { "Content-Type": "application/json", Authorization: Authorization },
        })

        console.log("AcessoPorEnderecoEstabelecimentos  => ", response)

        if (response.data.codErro > 0)
            return { retornoErro: response.data.codErro, mensagem: response.data.mensagem }
        else if (typeof response.data == "string")
            return {
                retornoErro: 1,
                mensagem: "Erro inesperado, entre em contato com os reponsáveis.",
            }
        else return response.data
    } catch (error) {
        RegistrarLogErro(error, "AcessoPorEnderecoEstabelecimentos")

        return { retornoErro: 1, mensagem: error.message }
    }
}

export const salvarPermissoesCaixaFornecedor = async function (idFornecedor, basicUsuario) {
    try {
        const permissoes = {
            deliveryOnline: true,
            mesaOnline: true,
            retiradaOnline: true,
            registradorDelivery: true,
            registradorMesa: true,
            registradorRetirada: true,
        }

        const response = await api.post(
            `clientefiel/SalvarPermissoesCaixaFornecedor/${idFornecedor}`,
            permissoes,
            {
                headers: { "Content-Type": "application/json", Authorization: basicUsuario },
            }
        )

        console.log("salvarPermissoesCaixaFornecedor  => ", response)

        if (response.data.codErro > 0)
            return { retornoErro: response.data.codErro, mensagem: response.data.mensagem }
        else if (typeof response.data == "string")
            return {
                retornoErro: 1,
                mensagem: "Erro inesperado, entre em contato com os reponsáveis.",
            }
        else return response.data
    } catch (error) {
        RegistrarLogErro(error, "salvarPermissoesCaixaFornecedor")

        return { retornoErro: 1, mensagem: error.message }
    }
}

export const ListarEstabelecimentosPorEndereco = async function (Authorization, data) {
    try {
        const response = await api.post("clientefiel/ListarEstabelecimentosPorEndereco ", data, {
            headers: { "Content-Type": "application/json", Authorization: Authorization },
        })

        console.log("ListarEstabelecimentosPorEndereco  => ", response)

        if (response.data.codErro > 0)
            return { retornoErro: response.data.codErro, mensagem: response.data.mensagem }
        else if (typeof response.data == "string")
            return {
                retornoErro: 1,
                mensagem: "Erro inesperado, entre em contato com os reponsáveis.",
            }
        else return response.data
    } catch (error) {
        RegistrarLogErro(error, "ListarEstabelecimentosPorEndereco")

        return { retornoErro: 1, mensagem: error.message }
    }
}

export const listarClientes = async function (Authorization, data) {
    try {
        const response = await api.post("clientefiel/ListarClientesFiltro", data, {
            headers: { "Content-Type": "application/json", Authorization: Authorization },
        })

        console.log("listarClientes => ", response)

        return tratamentoResponse(response, "listarClientes")
    } catch (error) {
        RegistrarLogErro(error, "listarClientes")
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const verificarExistenciaCliente = async function (Authorization, data) {
    try {
        const response = await api.post("clientefiel/ValidarExistenciaUsarios", data, {
            headers: { "Content-Type": "application/json", Authorization: Authorization },
        })

        console.log("verificarExistenciaCliente => ", response)
        return tratamentoResponse(response, "verificarExistenciaCliente")
    } catch (error) {
        RegistrarLogErro(error, "verificarExistenciaCliente")
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const ObterParametrosEstabelecimento = async function (data, aplicativoDados) {
    try {
        const response = await api
            .get(
                "comum/parametros/" + data,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .catch(function (error) {
                const resp = ErroRequisicao(error, aplicativoDados, "comum/parametros", data)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("ObterParametrosEstabelecimento => ", response)

        return tratamentoResponse(response, "ObterParametrosEstabelecimento")
    } catch (error) {
        console.error(error)
        RegistrarLogErro(error, "ObterParametrosEstabelecimento", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export function isNumeric(str) {
    if (typeof str === "number") return true
    if (typeof str !== "string") return false

    var er = /^[0-9]+$/
    return er.test(str)
}

export const getJSONParametros = async function (data, aplicativoDados) {
    try {
        const arrayParametros = await ObterParametrosEstabelecimento(data, aplicativoDados)

        if (!arrayParametros || !Array.isArray(arrayParametros)) return {}

        return arrayParametros.reduce((obj, param) => {
            if (param.valor === "true" || param.valor === "false")
                obj[param.nome] = JSON.parse(param.valor)
            else if (isNumeric(param.valor)) obj[param.nome] = Number(param.valor)
            else obj[param.nome] = param.valor

            return obj
        }, {})
    } catch (error) {
        console.error(error)
        RegistrarLogErro(error, "getJSONParametros", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}
