import { useSnackbar } from "notistack"
import React, { useContext } from "react"
import Package from "../../../../package.json"
import LoadingContext from "../../../contexts/loadingContext"
import ServicosContext from "../../../contexts/servicosContext"

import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Typography from "@material-ui/core/Typography"
import semPedidos_IMG from "../../../assets/ilustration/Money Save Illustration.svg"
import RowPagamentos from "./RowPagamentos"

import VisualizarPedido from "./visualizarPedido"

import { RegistrarLogErro } from "../../../services/clientefiel"
import { buscarInformacoesPedido } from "../../../services/integracao"

const useStyles = makeStyles(theme => ({
    img: {},
    texto: {
        textAlign: "center",
        position: "relative",
        top: "-1rem",
    },
}))

export default function TablePagamentos(props) {
    const autorizacao = localStorage.getItem("chaveSistema")
    const { enqueueSnackbar } = useSnackbar()

    const { caixaSelecionada } = props

    const { configuracoes } = useContext(ServicosContext)

    const { setLoading } = useContext(LoadingContext)

    const [open, setOpen] = React.useState(-1)
    const [modalPedido, setModalPedido] = React.useState({})

    const classes = useStyles()

    const buscarPedido = async id => {
        try {
            setLoading(true)
            const pedido = await buscarInformacoesPedido(
                autorizacao,
                id,
                configuracoes.user.parametros
            )
            console.log("pedido", pedido)
            if (pedido.retornoErro) {
                enqueueSnackbar(pedido.mensagem)
            } else {
                setModalPedido(pedido)
            }
        } catch (error) {
            enqueueSnackbar(
                Package.version + "buscarInformacoesPedido - ${window.location.hostname} - ${error}"
            )
            RegistrarLogErro(error, "buscarInformacoesPedido", id)
        }
        setLoading(false)
    }

    return (
        <TableContainer component={Paper}>
            {caixaSelecionada.pagamentos.length > 0 ? (
                <Table aria-label="collapsible table">
                    <VisualizarPedido pedido={modalPedido} setModalPedido={setModalPedido} />
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Forma Pagamento</TableCell>
                            <TableCell align="right">Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {caixaSelecionada.pagamentos?.map((pagamento, index) => (
                            <RowPagamentos
                                buscarPedido={buscarPedido}
                                key={index}
                                index={index}
                                open={open}
                                setOpen={setOpen}
                                pagamento={pagamento}
                                pedidos={caixaSelecionada.pedidosDoCaixa.filter(
                                    pedido => pagamento.descricao === pedido.formaPagamento
                                )}
                            />
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <div>
                    <img
                        src={semPedidos_IMG}
                        className={classes.img}
                        alt="sem pedidos associados a esse caixa"
                    />
                    <Typography className={classes.texto}>
                        Você ainda não recebeu pedidos associados a esse caixa.
                    </Typography>
                </div>
            )}
        </TableContainer>
    )
}
