class PinnDeliveryRepresentante {
    getIdentifier() {
        return "pinn.delivery"
    }

    getUrlSistemaAdministrativo() {
        return "https://sistema.pinn.delivery"
    }

    isClienteFiel() {
        return false
    }
}

const classe = new PinnDeliveryRepresentante()
export default classe
