import { Button, makeStyles } from "@material-ui/core"
import { Edit, HomeWork, Receipt, Settings } from "@material-ui/icons"
import { useContext } from "react"
import ServicosContext from "../../../contexts/servicosContext"
import BotaoIntegracoes from "../Integracoes/BotaoIntegracoes"
import Segundos from "./Segundos"

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        justifyContent: "flex-end",
        flex: 2,
        "& > button": {
            color: "#5f5f5f",
        },
        [theme.breakpoints.down("sm")]: {
            "& .small-screen-hidden": {
                display: "none",
            },
            justifyContent: "space-between",
        },
        [theme.breakpoints.down("xs")]: {
            "& .mobile-hidden": {
                display: "none",
            },
            "& button": {
                minWidth: 0,
            },
            "& > button > span > span": {
                margin: 0,
            },
        },
    },
}))

const BotoesUtilitarios = ({
    setExpandListaIntegracoes,
    expandListaIntegracoes,
    setCriarRegistradorModal,
    tabStatus,
    resumoDoDia,
    atualizar,
    setRegistradorModal,
    handleBuscarDadosEstabelecimentos,
    setModalConfiguracoes,
}) => {
    const { configuracoes, handleRegistradorDePedidos } = useContext(ServicosContext)
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <BotaoIntegracoes
                setExpandListaIntegracoes={setExpandListaIntegracoes}
                expandListaIntegracoes={expandListaIntegracoes}
            />

            <Button
                onClick={() => {
                    setTimeout(() => {
                        setCriarRegistradorModal(true)
                        handleRegistradorDePedidos(() => setRegistradorModal(true))
                    }, 1000)
                }}
                startIcon={<Edit />}
            >
                <span className="mobile-hidden">Registrador</span>
            </Button>

            <Button onClick={handleBuscarDadosEstabelecimentos} startIcon={<HomeWork />}>
                <span className="small-screen-hidden">Estabelecimentos</span>
            </Button>

            {configuracoes?.user?.perfil !== "DELIVERY_BASICO" && (
                <Button onClick={resumoDoDia} startIcon={<Receipt />}>
                    <span className="small-screen-hidden">Resumo</span>
                </Button>
            )}

            <Segundos tabStatus={tabStatus} atualizar={atualizar} />

            <Button onClick={() => setModalConfiguracoes(true)}>
                <Settings />
            </Button>
        </div>
    )
}

export default BotoesUtilitarios
