import { useContext } from "react"
import CaixaContext from "../../../contexts/caixaContext"
import LoadingContext from "../../../contexts/loadingContext"
import ServicosContext from "../../../contexts/servicosContext"

import { makeStyles, useTheme } from "@material-ui/core/styles"
import { useSnackbar } from "notistack"

import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import Chip from "@material-ui/core/Chip"

import FormControl from "@material-ui/core/FormControl"
import InputAdornment from "@material-ui/core/InputAdornment"
import InputLabel from "@material-ui/core/InputLabel"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import clsx from "clsx"

import { abrirCaixa } from "../../../services/controlecaixa"

import { RegistrarLogErro } from "../../../services/clientefiel"
import { formatReal, getMoney } from "../../../services/functionsGerais"

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: "25ch",
    },
}))

export default function FormDialog(props) {
    const { setCaixaSelecionada, estabelecimentoSelecionado, handleBuscarCaixasAbertos } =
        useContext(CaixaContext)

    const { configuracoes } = useContext(ServicosContext)

    const theme = useTheme()
    const { setLoading } = useContext(LoadingContext)
    const { enqueueSnackbar } = useSnackbar()
    const classes = useStyles()
    const autorizacao = localStorage.getItem("chaveSistema")
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

    const { open, setOpen, tiposCaixasPermitidosParaAbrir, setTiposCaixasPermitidosParaAbrir } =
        props

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleAbrirCaixa = async () => {
        try {
            const data = {
                idEstabelecimento: estabelecimentoSelecionado.id,
                nomeEstabelecimento: estabelecimentoSelecionado.nome,
                // nomeAbertura: document.getElementById('nomeAbertura').value,
                dinheiroInicial: parseFloat(
                    document.getElementById("dinheiroInicial").value.replace(".", "")
                ),
                observacao: document.getElementById("observacao").value,
                idUsuarioAbriu: configuracoes.user.id,
                tiposDTO: [],
            }

            tiposCaixasPermitidosParaAbrir.forEach(tipo => {
                if (tipo.aberto) {
                    data.tiposDTO.push({
                        id: tipo.id,
                        nome: tipo.nome,
                    })
                }
            })

            if (data.tiposDTO.length === 0) {
                enqueueSnackbar("Você precisa selecionar o tipo do caixa!", { variant: "error" })
                return false
            }

            setLoading(true)

            const abrirCaixaResponse = await abrirCaixa(data, autorizacao)
            console.log("---abrirCaixa--", abrirCaixaResponse)
            if (abrirCaixaResponse.status >= 300) {
                enqueueSnackbar(abrirCaixaResponse.message, { variant: "error" })
            } else {
                enqueueSnackbar(abrirCaixaResponse.message, { variant: "success" })
                setCaixaSelecionada(abrirCaixaResponse.response)
                handleBuscarCaixasAbertos(estabelecimentoSelecionado)
                setOpen(false)
            }
        } catch (error) {
            RegistrarLogErro(error, "handleBuscarDadosEstabelecimentos")
        }
        setLoading(false)
    }

    const tiposAbertos = tiposCaixasPermitidosParaAbrir.filter(tipo => tipo.aberto)

    return (
        <div>
            {open && (
                <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Abrir Caixa</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Para abrir um caixa é necessario que você digite seu nome, valor do
                            dinheiro disponível no caixa atualmente e as plataformas que o caixa irá
                            tomar conta.
                        </DialogContentText>

                        <div className={classes.root}>
                            <FormControl
                                className={clsx(classes.margin)}
                                fullWidth
                                variant="outlined"
                            >
                                <InputLabel htmlFor="valor">Dinheiro Inicial</InputLabel>
                                <OutlinedInput
                                    id="dinheiroInicial"
                                    startAdornment={
                                        <InputAdornment position="start">R$</InputAdornment>
                                    }
                                    aria-describedby="Dinheiro Inicial"
                                    fullWidth
                                    defaultValue="0,00"
                                    onChange={e => {
                                        const valorFinal =
                                            e.target.value === ""
                                                ? "0,00"
                                                : formatReal(getMoney(e.target.value))
                                        e.target.value = valorFinal
                                    }}
                                    inputProps={{
                                        "aria-label": "valor",
                                    }}
                                />
                            </FormControl>

                            <FormControl
                                className={clsx(classes.margin)}
                                fullWidth
                                variant="outlined"
                            >
                                <InputLabel htmlFor="obs">Observação</InputLabel>
                                <OutlinedInput
                                    id="observacao"
                                    aria-describedby="obs"
                                    startAdornment={
                                        <InputAdornment position="start">PS</InputAdornment>
                                    }
                                    inputProps={{
                                        "aria-label": "obs",
                                    }}
                                    fullWidth
                                />
                            </FormControl>
                        </div>

                        <div className={classes.root}>
                            {tiposCaixasPermitidosParaAbrir.map((tipo, index) => (
                                <Chip
                                    key={index}
                                    className={classes.chip}
                                    label={tipo.nome}
                                    color={tipo.aberto ? "primary" : ""}
                                    onClick={() => {
                                        setTiposCaixasPermitidosParaAbrir(
                                            tiposCaixasPermitidosParaAbrir =>
                                                tiposCaixasPermitidosParaAbrir.map(item => ({
                                                    ...item,
                                                    aberto:
                                                        item.id === tipo.id
                                                            ? !item.aberto
                                                            : item.aberto,
                                                }))
                                        )
                                    }}
                                />
                            ))}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        {/* <Button onClick={handleClose} color="primary">
              Cancelar
            </Button> */}
                        <Button
                            onClick={handleAbrirCaixa}
                            disabled={!tiposAbertos.length}
                            color="primary"
                        >
                            Abrir
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    )
}
