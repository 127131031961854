import { makeStyles } from "@material-ui/core/styles"

import { parseReal } from "../../../services/functionsGerais"

import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        //   maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    section1: {
        margin: theme.spacing(3, 2),
    },
    section2: {
        margin: theme.spacing(2),
    },
    section3: {
        margin: theme.spacing(3, 1, 1),
    },
}))

export default function Retirada({ retirada }) {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className={classes.section1}>
                <Grid container alignItems="center">
                    <Grid item xs>
                        <Typography gutterBottom variant="h6">
                            {retirada.tipo == 0 ? "Retirada" : "Adicionado"}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography gutterBottom variant="h6">
                            {parseReal(retirada.valor)}
                        </Typography>
                    </Grid>
                </Grid>
                <Typography color="textSecondary" variant="body2">
                    {retirada.motivo}
                </Typography>
            </div>
            <Divider variant="middle" />
        </div>
    )
}
