import Box from "@material-ui/core/Box"
import Collapse from "@material-ui/core/Collapse"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import React from "react"

const useRowStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset",
        },
        cursor: "pointer",
    },
    tableRow: {
        cursor: "pointer",
    },
})

export default function RowPagamentos(props) {
    const { pagamento, pedidos, setOpen, open, index, buscarPedido } = props
    // const [open, setOpen] = React.useState(false);
    const classes = useRowStyles()

    return (
        <React.Fragment>
            <TableRow
                className={classes.root}
                aria-label="expand row"
                size="small"
                // onClick={() => setOpen(!open)}
                onClick={() => setOpen(open === index ? -1 : index)}
            >
                {/* <ButtonBase> */}
                <TableCell style={{ padding: "0px 16px" }}>
                    <IconButton>
                        {/* {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} */}
                        {open === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" style={{ padding: "0px 16px" }}>
                    {pagamento.quantidade}x {pagamento.descricao.toUpperCase()}
                </TableCell>
                {/* <TableCell align="right">{pagamento.quantidade}</TableCell> */}
                <TableCell align="right" style={{ padding: "0px 16px" }}>
                    {Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(
                        pagamento.total
                    )}
                </TableCell>
                {/* <TableCell align="right">{row.carbs}</TableCell>
            <TableCell align="right">{row.protein}</TableCell> */}
                {/* </ButtonBase> */}
            </TableRow>

            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    {/* <Collapse in={open} timeout="auto" unmountOnExit> */}
                    <Collapse in={open === index} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            {/* <Typography variant="p" style={{fontWeight: 500}} component="div">
                  Histórico
                </Typography> */}
                            <Table size="small" aria-label="purchases">
                                <TableHead
                                    style={{
                                        background: "#4e4e4e",
                                    }}
                                >
                                    <TableRow>
                                        {/* <TableCell></TableCell> */}

                                        <TableCell style={{ color: "white" }}>ID</TableCell>
                                        <TableCell style={{ color: "white" }}>Tipo</TableCell>
                                        <TableCell align="right" style={{ color: "white" }}>
                                            Plataforma
                                        </TableCell>
                                        <TableCell align="right" style={{ color: "white" }}>
                                            Valor
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pedidos.map((pedido, index) => (
                                        <TableRow
                                            className={classes.tableRow}
                                            onClick={() => {
                                                buscarPedido(pedido.id)
                                            }}
                                            key={pedido.id}
                                            style={{
                                                background: index % 2 ? "#efefef" : "white",
                                            }}
                                        >
                                            {/* <TableCell component="th" scope="row">
                          <IconButton size={"small"}>
                            <VisibilityIcon style={{fontSize:'18px'}}/>
                          </IconButton>
                        </TableCell> */}
                                            <TableCell component="th" scope="row">
                                                {pedido.id}
                                            </TableCell>
                                            <TableCell>{pedido.tipoPedido}</TableCell>
                                            <TableCell align="right">{pedido.plataforma}</TableCell>
                                            <TableCell align="right">
                                                {Intl.NumberFormat("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                }).format(pedido.valor)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}
