import { createContext, useContext, useState } from "react"

const CheckboxContext = createContext()

export const CheckboxProvider = ({ children }) => {
    const [checkedItems, setCheckedItems] = useState({})

    const toggleItem = id => {
        setCheckedItems(prevState => ({
            ...prevState,
            [id]: !prevState[id],
        }))
    }

    const selectAll = items => {
        const newCheckedItems = {}
        items.forEach(item => {
            newCheckedItems[item.id] = true
        })
        setCheckedItems(newCheckedItems)
    }

    const deselectAll = () => {
        setCheckedItems({})
    }

    return (
        <CheckboxContext.Provider value={{ checkedItems, toggleItem, selectAll, deselectAll }}>
            {children}
        </CheckboxContext.Provider>
    )
}

export const useCheckboxContext = () => {
    return useContext(CheckboxContext)
}
