import { Button, makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
    cancelarEntregaUber: {
        backgroundColor: "#dc3545",
        transition: "filter 0.2s",
        "&:hover": {
            backgroundColor: "#dc3545",
            filter: "brightness(1.05)",
            color: "#222",
        },
    },
})

export default function CancelButton({ handleClick, size, variant, text }) {
    const classes = useStyles()
    const buttonSize = size ? size : "medium"
    const buttonVariant = variant ? variant : "contained"
    const buttonText = text ? text : "Cancelar"

    return (
        <Button
            variant={buttonVariant}
            size={buttonSize}
            onClick={handleClick}
            className={classes.cancelarEntregaUber}
        >
            {buttonText}
        </Button>
    )
}
