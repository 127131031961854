import axios from "axios"
const url = localStorage.getItem("teste")
    ? localStorage.getItem("teste")
    : "https://ws.appclientefiel.com.br/rest/"
const api = axios.create({
    baseURL: url,
    //baseURL: 'https://wapi.appclientefiel.com.br/rest/',
    // baseURL: 'http://localhost:8100/clientefiel/rest/',
    // baseURL: 'https://ws.appclientefiel.com.br/rest/',
    // baseURL: 'http://preproducao.us-east-1.elasticbeanstalk.com/rest/',
})

export default api
