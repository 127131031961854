import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { verificarPrintQRCode } from "../../pages/RecebedorDePedidos/functions"

export default function Print(props) {
    const { filaPrints, setFilaPrints, mandarImprimir } = props

    const open = !!filaPrints[0]

    const handleClose = () => {
        mandarImprimir({
            texto: filaPrints[0],
            qtd: 1,
            qrcode: verificarPrintQRCode(filaPrints[0]),
        })
        const novo = filaPrints.filter(() => filaPrints != filaPrints[0])
        setFilaPrints(novo)
    }

    return (
        <div>
            <Dialog
                open={open}
                // onClose={handleClose}
                // aria-labelledby="alert-dialog-title"
                // aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Chegou uma mensagem</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {filaPrints[0]}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
