import React, { useContext } from "react"
import { useHistory } from "react-router-dom"

import { useSnackbar } from "notistack"
import RegistradorContext from "../../../../contexts/registradorContext"
import ServicosContext from "../../../../contexts/servicosContext"

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import LoadingContext from "../../../../contexts/loadingContext"

import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControl from "@material-ui/core/FormControl"
import IconButton from "@material-ui/core/IconButton"
import InputBase from "@material-ui/core/InputBase"
import NativeSelect from "@material-ui/core/NativeSelect"
import Slide from "@material-ui/core/Slide"
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import { Col, Row } from "react-bootstrap"

import { CidadesAtendidasPorAplicativo, ComumCep, CumumBairros } from "../../../../services/comum"

import { CadastrarEnderecoCliente } from "../../../../services/cliente"
import { RegistrarLogErro } from "../../../../services/clientefiel"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles(theme => ({
    fixed: {
        position: "fixed",
        right: "2em",
        bottom: "2em",
        zIndex: 1000,
    },
    fixedMesa: {
        position: "fixed",
        right: "2em",
        bottom: "2em",
        zIndex: 2000,
    },
    fixedMesaFechar: {
        position: "fixed",
        right: "2em",
        bottom: "7em",
        zIndex: 2000,
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    rootAlert: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
    backdrop: {
        // zIndex: theme.zIndex.drawer + 999999,
        color: "#fff",
    },
    pos: {
        marginBottom: 12,
    },
    heading: {
        width: "100%",
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    rootList: {
        width: "100%!important",
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: "inline",
        width: "100%",
    },
    appBar: {
        position: "fixed",
    },
    appContainer: {
        paddingTop: "6em",
        backgroundColor: "#f4f4f4",
        // height: "100%",
        // minHeight: "-webkit-fill-available",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    list: {
        width: 250,
    },
    fullList: {
        width: "auto",
    },
}))

const BootstrapInput = withStyles(theme => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "18.5px 14px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
            borderRadius: 4,
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}))(InputBase)

export default function Index() {
    const autorizacao = localStorage.getItem("chaveSistema")
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    )
    const classes = useStyles()
    const theme = useTheme()
    const history = useHistory()
    const [cidadesNaoSeiCep, setCidadesNaoSeiCep] = React.useState([])
    const [enderecoCadastro, setEnderecoCadastro] = React.useState({})
    const [bairrosCepUnico, setBairrosCepUnico] = React.useState([])

    const [bairrosNaoSeiCep, setBairrosNaoSeiCep] = React.useState([])

    const [uf, setUf] = React.useState("")
    const [cidade, setCidade] = React.useState("")

    const [endereco, setEndereco] = React.useState({
        uf: "",
        cidade: "",
        bairro: "",
    })

    const [emCep, setEmCep] = React.useState(true)
    const aplicativoDados = JSON.parse(localStorage.getItem("aplicativoCF"))

    const {
        setOpenCadastroEndereco,
        openCadastroEndereco,
        setListaEnderecos,
        listaEnderecos,
        carrinho,
        handleSelecionarEndereco,
    } = useContext(RegistradorContext)

    const { enqueueSnackbar } = useSnackbar()

    const { setLoading, loading } = useContext(LoadingContext)

    const { configuracoes } = useContext(ServicosContext)

    const alertStart = (msg, tipo) => {
        enqueueSnackbar(msg, { variant: tipo })
    }

    const limparCadastroEndereco = () => {
        try {
            setEndereco({
                ...endereco,
                cidade: "",
                bairro: "",
                uf: "",
            })
            if (document.getElementById("cadastroRua"))
                document.getElementById("cadastroRua").value = ""
            if (document.getElementById("cadastroNumero"))
                document.getElementById("cadastroNumero").value = ""
            if (document.getElementById("cepDigitando2"))
                document.getElementById("cepDigitando2").value = ""
            if (document.getElementById("cadastroComplemento"))
                document.getElementById("cadastroComplemento").value = ""
            if (document.getElementById("cadastroReferencia"))
                document.getElementById("cadastroReferencia").value = ""
            // setEnderecoCadastro({})
            setBairrosCepUnico([])
            setBairrosNaoSeiCep([])
            setCidadesNaoSeiCep([])
            setEmCep(true)
        } catch (error) {
            RegistrarLogErro(error, "limparCadastroEndereco")
            // alertStart("Procure os desenvolvedores! Erro: "+error.message, "error")
        }
    }

    const alterouCidadeNaoSeiCep = async e => {
        setLoading(true)
        try {
            await cidadesNaoSeiCep.forEach(async cidade => {
                if (cidade.nome === e.target.value) {
                    console.log("cidade", cidade)
                    setEndereco({
                        ...endereco,
                        uf: cidade.estado.sigla,
                        cidade: cidade.nome,
                    })
                    // document.getElementById('cadastroUf').value = cidade.estado.sigla

                    const retorno = await CumumBairros(
                        cidade,
                        configuracoes.user?.parametros?.appNome
                    )
                    if (retorno.retornoErro) {
                        alertStart(retorno.mensagem, "error")
                    } else {
                        setBairrosNaoSeiCep(retorno)
                    }
                }
            })
        } catch (error) {
            RegistrarLogErro(error, "alterouCidadeNaoSeiCep", e.target.value)
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
        setLoading(false)
    }

    const naoSeiCep = async e => {
        setLoading(true)
        try {
            const retorno = await CidadesAtendidasPorAplicativo(
                { appNome: configuracoes.user?.parametros?.appNome },
                aplicativoDados
            )
            if (retorno.retornoErro) {
                alertStart(retorno.mensagem, "error")
            } else {
                console.log("CidadesAtendidasPorAplicativo> ", retorno)
                setEmCep(false)
                setCidadesNaoSeiCep(retorno)
            }
        } catch (error) {
            RegistrarLogErro(error, "CidadesAtendidasPorAplicativo")
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
        setLoading(false)
    }

    const verificarCep = async e => {
        try {
            let valorInput = e.target.value
            valorInput = valorInput.replace(/-/g, "")
            valorInput = valorInput.replace(/ /g, "")
            valorInput = valorInput.replace(/\//g, "")
            // valorInput = valorInput.substring(0,8)
            console.log("valorInput", valorInput)
            if (valorInput.length == 8) {
                setLoading(true)
                //buscar dados do cep
                const retornoCep = await ComumCep(
                    valorInput,
                    configuracoes.user?.parametros?.appNome
                )

                if (retornoCep.retornoErro) {
                    alertStart(retornoCep.mensagem, "error")
                } else {
                    setEmCep(false)
                    if (retornoCep.id || retornoCep.logradouro) {
                        // setEnderecoCadastro(retornoCep)

                        setEndereco({
                            ...endereco,
                            bairro: retornoCep.bairro,
                            uf: retornoCep.uf,
                            cidade: retornoCep.localidade,
                        })

                        // document.getElementById('cadastroUf').value = retornoCep.uf
                        // document.getElementById('cadastroCidade').value = retornoCep.localidade
                        document.getElementById("cadastroRua").value = retornoCep.logradouro
                    } else if (retornoCep.bairros?.length >= 1) {
                        setBairrosCepUnico(retornoCep.bairros)
                    } else {
                        alertStart("CEP não encontrado", "error")
                        document.getElementById("cepDigitando2").value = ""
                        document.getElementById("cepDigitando2").focus()
                    }
                }
                setLoading(false)
            }
        } catch (error) {
            RegistrarLogErro(error, "verificarCep", e.target.value)
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    const selecionouBairroSelect = () => {
        try {
            const nomeBairro = document.getElementById("cadastroBairro").value
            if (nomeBairro === "-1") {
                setEndereco({
                    ...endereco,
                    uf: "",
                    cidade: "",
                    bairro: "-1",
                })
            } else {
                bairrosCepUnico.forEach(bairro => {
                    if (bairro.nome === nomeBairro) {
                        console.log("||> selecionouBairroSelect", bairro)
                        setEndereco({
                            ...endereco,
                            uf: bairro.cidade.estado.sigla,
                            cidade: bairro.cidade.nome,
                            bairro: bairro.nome,
                        })
                        // setEnderecoCadastro({...enderecoCadastro, localidade: bairro.cidade.nome, uf: bairro.cidade.estado.sigla})
                    }
                })
            }
        } catch (error) {
            RegistrarLogErro(error, "selecionouBairroSelect", {
                nomeBairro: document.getElementById("cadastroBairro").value,
            })
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    const cadastroEndereco = async e => {
        try {
            e.preventDefault()
            if (endereco.bairro === "" || endereco.bairro === "-1") {
                alertStart("Bairro é necessário para o endereço.", "error")
                return false
            } else if (document.getElementById("cadastroUf").value === "") {
                alertStart("Estado é necessário para o endereço.", "error")
                return false
            } else if (document.getElementById("cadastroCidade").value === "") {
                alertStart("Cidade é necessário para o endereço.", "error")
                return false
            } else if (document.getElementById("cadastroRua").value === "") {
                alertStart("Rua é necessário para o endereço.", "error")
                return false
            } else if (document.getElementById("cadastroNumero").value === "") {
                alertStart("Número é necessário para o endereço.", "error")
                return false
            }
            setLoading(true)
            setOpenCadastroEndereco(false)
            const data = {
                appNome: configuracoes.user.parametros.appNome,
                cep: document.getElementById("cepDigitando2").value
                    ? document.getElementById("cepDigitando2").value
                    : "00000000",
                uf: endereco.uf,
                cidade: endereco.cidade,
                bairro: endereco.bairro,
                logradouro: document.getElementById("cadastroRua").value,
                numero: document.getElementById("cadastroNumero").value,
                complemento: document.getElementById("cadastroComplemento").value,
                referencia: document.getElementById("cadastroReferencia").value,
                bairroEspecifico: configuracoes.user.parametros.tipoEntregaBairro,
                token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
            }

            if (carrinho?.cliente?.id) {
                data.idCliente = carrinho.cliente.id
            }

            console.log("data> ", data)
            const retornoEndereco = await CadastrarEnderecoCliente(autorizacao, data)
            if (retornoEndereco.retornoErro) {
                //algum erro EstabelecimentoAberto
                alertStart(retornoEndereco.mensagem, "error")
            } else {
                let enderecos = Object.assign([], listaEnderecos)
                enderecos.push(retornoEndereco)
                setListaEnderecos(enderecos)

                handleSelecionarEndereco(retornoEndereco)
                limparCadastroEndereco()
                alertStart("Endereço Cadastrado com sucesso.", "success")
            }
        } catch (error) {
            RegistrarLogErro(error, "cadastroEndereco")
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
        setLoading(false)
    }

    return (
        <Dialog
            open={openCadastroEndereco}
            TransitionComponent={Transition}
            fullScreen
            keepMounted
            onClose={() => {
                setOpenCadastroEndereco(false)
                limparCadastroEndereco()
            }}
            style={{ zIndex: "1399" }}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">
                <IconButton
                    className="setaVoltarModal"
                    onClick={() => {
                        setOpenCadastroEndereco(false)
                        limparCadastroEndereco()
                    }}
                >
                    <ArrowForwardIosIcon />
                </IconButton>
                {"Cadastro de Endereço"}
            </DialogTitle>
            <DialogContent>
                <Row style={emCep ? undefined : { display: "none" }}>
                    <Col xs={12} md={12} lg={12} style={{ marginTop: "1em" }}>
                        <TextField
                            fullWidth
                            name="CEP"
                            //value={cep}
                            id="cepDigitando2"
                            label="CEP"
                            style={{ background: "#fff" }}
                            onChange={verificarCep}
                            variant="outlined"
                        />
                    </Col>
                    <Col xs={12} md={12} lg={12} className="mt-3 mb-3">
                        <Button variant="contained" onClick={naoSeiCep} color="default">
                            Não Sei CEP
                        </Button>
                    </Col>
                </Row>
                <Row style={emCep ? { display: "none" } : undefined}>
                    {
                        <React.Fragment>
                            {bairrosCepUnico?.length ? (
                                <Col xs={12} md={12} lg={12}>
                                    <FormControl
                                        variant="outlined"
                                        style={{ width: "100%", margin: "0.5em 0" }}
                                    >
                                        {/* <InputLabel htmlFor="demo-customized-select-native-bairro">Bairros do Cep</InputLabel> */}
                                        <NativeSelect
                                            id="demo-customized-select-native-bairro"
                                            // preventDefault={-1}
                                            style={{ background: "#fff" }}
                                            id="cadastroBairro"
                                            onChange={selecionouBairroSelect}
                                            input={<BootstrapInput />}
                                            label="Bairros do Cep"
                                        >
                                            {bairrosCepUnico?.length ? (
                                                <option value={-1}>{"Selecionar Bairro"}</option>
                                            ) : null}
                                            {bairrosCepUnico.map((bairro, index) => (
                                                <option key={index} value={bairro.nome}>
                                                    {bairro.nome}
                                                </option>
                                            ))}
                                        </NativeSelect>
                                    </FormControl>
                                </Col>
                            ) : (
                                <>
                                    {cidadesNaoSeiCep?.length ? (
                                        <Col xs={12} md={12} lg={12}>
                                            <FormControl
                                                variant="outlined"
                                                style={{ width: "100%", margin: "0.5em 0" }}
                                            >
                                                <NativeSelect
                                                    id="demo-customized-select-native-bairro"
                                                    id="cadastroCidade"
                                                    style={{ background: "#fff" }}
                                                    defaultChecked={-1}
                                                    onChange={alterouCidadeNaoSeiCep}
                                                    input={<BootstrapInput />}
                                                    label="Selecione a Cidade"
                                                >
                                                    {bairrosNaoSeiCep?.length ? null : (
                                                        <option value={-1}>
                                                            {"Selecionar Cidade"}
                                                        </option>
                                                    )}

                                                    {cidadesNaoSeiCep.map((cidade, index) => (
                                                        <option key={cidade.id} value={cidade.nome}>
                                                            {cidade.nome}
                                                        </option>
                                                    ))}
                                                </NativeSelect>
                                            </FormControl>
                                        </Col>
                                    ) : null}

                                    {bairrosNaoSeiCep?.length ? (
                                        <Col xs={12} md={12} lg={12}>
                                            <FormControl
                                                variant="outlined"
                                                style={{ width: "100%", margin: "0.5em 0" }}
                                            >
                                                <NativeSelect
                                                    id="cadastroBairro"
                                                    style={{ background: "#fff" }}
                                                    value={endereco.bairro}
                                                    onChange={e => {
                                                        setEndereco({
                                                            ...endereco,
                                                            bairro: e.target.value,
                                                        })
                                                    }}
                                                    input={<BootstrapInput />}
                                                    label="Selecione o Bairro"
                                                >
                                                    {bairrosNaoSeiCep?.length ? (
                                                        <option value={-1}>
                                                            {"Selecionar Bairro"}
                                                        </option>
                                                    ) : null}
                                                    {bairrosNaoSeiCep.map((bairro, index) => (
                                                        <option key={index} value={bairro.nome}>
                                                            {bairro.nome}
                                                        </option>
                                                    ))}
                                                </NativeSelect>
                                            </FormControl>
                                        </Col>
                                    ) : null}
                                </>
                            )}

                            {!cidadesNaoSeiCep?.length && !bairrosCepUnico?.length ? (
                                <Col xs={12} md={12} lg={12} style={{ margin: "0.5em 0" }}>
                                    <TextField
                                        disabled
                                        style={{ background: "#fff" }}
                                        fullWidth
                                        value={endereco.bairro}
                                        onChange={e =>
                                            setEndereco({ ...endereco, bairro: e.target.value })
                                        }
                                        required
                                        name="BAIRRO"
                                        id="cadastroBairro"
                                        label="BAIRRO"
                                        variant="outlined"
                                    />
                                </Col>
                            ) : null}

                            <Col xs={12} md={6} lg={6} style={{ margin: "0.5em 0" }}>
                                <TextField
                                    disabled
                                    fullWidth
                                    required
                                    value={endereco.uf}
                                    onChange={e => setEndereco({ ...endereco, uf: e.target.value })}
                                    style={{ background: "#fff" }}
                                    name="UF"
                                    // value={enderecoCadastro.uf}
                                    id="cadastroUf"
                                    label="UF"
                                    variant="outlined"
                                />
                            </Col>
                            {!cidadesNaoSeiCep?.length ? (
                                <Col xs={12} md={6} lg={6} style={{ margin: "0.5em 0" }}>
                                    <TextField
                                        required
                                        fullWidth
                                        style={{ background: "#fff" }}
                                        disabled
                                        value={endereco.cidade}
                                        onChange={e =>
                                            setEndereco({ ...endereco, cidade: e.target.value })
                                        }
                                        name="CIDADE"
                                        id="cadastroCidade"
                                        label="CIDADE"
                                        variant="outlined"
                                    />
                                </Col>
                            ) : null}

                            <Col xs={12} md={6} lg={6} style={{ margin: "0.5em 0" }}>
                                <TextField
                                    required
                                    fullWidth
                                    disabled={
                                        bairrosCepUnico?.length || cidadesNaoSeiCep?.length
                                            ? false
                                            : true
                                    }
                                    name="RUA"
                                    style={{ background: "#fff" }}
                                    // value={enderecoCadastro.logradouro}
                                    onChange={e => {
                                        // setRua(e.target.value)
                                    }}
                                    // value={rua}
                                    id="cadastroRua"
                                    label="RUA"
                                    variant="outlined"
                                />
                            </Col>
                            <Col xs={12} md={6} lg={6} style={{ margin: "0.5em 0" }}>
                                <TextField
                                    fullWidth
                                    required
                                    style={{ background: "#fff" }}
                                    name="NUMERO"
                                    // value={numero}
                                    onChange={e => {
                                        // setNumero(e.target.value)
                                    }}
                                    id="cadastroNumero"
                                    label="NUMERO"
                                    variant="outlined"
                                    type="number"
                                />
                            </Col>
                            <Col xs={12} md={6} lg={6} style={{ margin: "0.5em 0" }}>
                                <TextField
                                    fullWidth
                                    style={{ background: "#fff" }}
                                    // value={complemento}
                                    onChange={e => {
                                        // setComplemento(e.target.value)
                                    }}
                                    name="COMPLEMENTO"
                                    id="cadastroComplemento"
                                    label="COMPLEMENTO"
                                    variant="outlined"
                                />
                            </Col>
                            <Col xs={12} md={6} lg={6} style={{ margin: "0.5em 0" }}>
                                <TextField
                                    fullWidth
                                    // value={referencia}
                                    onChange={e => {
                                        // setReferencia(e.target.value)
                                    }}
                                    name="REFERENCIA"
                                    id="cadastroReferencia"
                                    label="REFERENCIA"
                                    style={{ background: "#fff" }}
                                    variant="outlined"
                                />
                            </Col>
                        </React.Fragment>
                    }
                </Row>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        limparCadastroEndereco()
                        setOpenCadastroEndereco(false)
                    }}
                >
                    Cancelar
                </Button>
                <Button onClick={cadastroEndereco} variant="contained" color="primary">
                    Cadastrar
                </Button>
            </DialogActions>
        </Dialog>
    )
}
