//INIT
import React, { useContext, useState } from "react"
import ServicosContext from "../../contexts/servicosContext"
import PinnDeliveryRepresentante from "../../functions/PinnDeliveryRepresentante"
import RepresentanteSelector from "../../functions/RepresentanteSelector"

//FUNCTIONS \ REQUESTS
import Cabecalho from "../Cabecalho"

//FILES

//IMPORTS
import AppBar from "@material-ui/core/AppBar"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardMedia from "@material-ui/core/CardMedia"
import Container from "@material-ui/core/Container"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import Slide from "@material-ui/core/Slide"
import Snackbar from "@material-ui/core/Snackbar"
import { makeStyles } from "@material-ui/core/styles"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import CloseIcon from "@material-ui/icons/Close"
import HelpOutlineIcon from "@material-ui/icons/HelpOutline"
import MuiAlert from "@material-ui/lab/Alert"
import { Col, Row } from "react-bootstrap"
import { useHistory } from "react-router-dom"
// import sistemaAdmGif from '../../assets/sistemaAdmGif.gif';
import {
    default as ACEITADOR_ICON,
    default as REGISTRADOR_ICON,
} from "../../assets/icons/svg/023-relation.svg"
import CAIXA_ICON from "../../assets/icons/svg/026-presentation.svg"
import ATENDENTE_ICON from "../../assets/icons/svg/028-online business.svg"
import SISTEMA_ADM_ICON from "../../assets/icons/svg/033-admin.svg"
import HOLDING from "../../assets/icons/svg/060-holding.svg"
import PEDIDO_MESA_ICON from "../../assets/icons/svg/064-flag.svg"
import Loading from "../Componentes/Loading"
import "./styles.css"

import { Divider } from "@material-ui/core"
import { RegistrarLogErro } from "../../services/clientefiel"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles(theme => ({
    fixedImages: {
        position: "fixed",
        right: "2em",
        cursor: "pointer",
        bottom: "2em",
        width: "50px",
        height: "50px",
        zIndex: 1000,
        transition: "all .2s ease-in-out",
        "&:hover": {
            transform: "scale(1.2)",
        },
    },
    fixedQR: {
        position: "fixed",
        right: "2em",
        bottom: "7em",
        cursor: "pointer",
        width: "50px",
        height: "50px",
        zIndex: 1000,
        transition: "all .2s ease-in-out",
        "&:hover": {
            transform: "scale(1.2)",
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    rootAlert: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    root: {
        width: "100%",
        margin: "1em 0",
    },
    pos: {
        marginBottom: 12,
    },
}))

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default function Servicos() {
    const history = useHistory()
    const classes = useStyles()
    const autorizacao = localStorage.getItem("chaveSistema")
    const aplicativoDados = JSON.parse(localStorage.getItem("aplicativoCF"))

    const {
        handleRegistradorDePedidos,
        handleAceitadorDePedidos,
        handleSistemaAdm,
        handleCaixa,
        handleNotaFiscal,
        handleIzzaBot,
        handleCardapio,
        handlePedidoDeMesa,
        configuracoes,
        acessarImagensTematicas,
        // atualizarConfiguracoes,
        // buscarParametros,
    } = useContext(ServicosContext)

    const [help, setHelp] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    // const [loading, setLoading] = React.useState(configuracoes.user.parametros?.appPrincipal ? false : true);
    const [contratar, setContratar] = React.useState("")
    const [alert, setAlert] = useState({ status: false, tipo: "success", mesangem: "" })

    const teste =
        window.location.hostname.includes("localhost") ||
        window.location.hostname.includes("192.168")

    const alertStart = (msg, tipo) => {
        setAlert({ status: true, tipo: tipo, mesangem: msg })
    }

    const alertClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setAlert({ status: false, tipo: alert.tipo, mesangem: alert.mesangem })
    }

    const handleHelp = duvida => {
        setHelp(duvida)
    }
    const handleNotHelp = () => {
        setHelp({})
    }
    const desconectar = () => {
        try {
            localStorage.removeItem("chaveSistema")
            // localStorage.removeItem('configuracoes')
            localStorage.removeItem("dataUltimoServico")
            window.name = ""
            history.push("/login")
        } catch (error) {
            RegistrarLogErro(error, "desconectar")
        }
    }

    const servicosPainel = [
        {
            id: "SISTEMA_ADM",
            prioridade: 1,
            ativo: configuracoes.user?.perfil === "ADMINISTRADOR" ? "producao" : "escondido",
            permitido: configuracoes.user?.perfil === "ADMINISTRADOR" ? true : false,
            tipo: "Dashboard",
            icon: SISTEMA_ADM_ICON,
            nome: "Sistema Administrativo",
            descricaco: "Todas opções para configurações dos serviços.",
            funcao: handleSistemaAdm,
            tituloBotao:
                configuracoes.user?.perfil === "ADMINISTRADOR" ? "PERMITIDO" : "NÃO PERMITIDO",
            linkTutorial: [
                {
                    nome: "",
                    url: "https://www.youtube.com/embed/s787GjWQiWk",
                },
            ],
        },
        {
            id: "ACEITADOR",
            prioridade: 10,
            ativo: "producao",
            tipo: "Módulo",
            permitido: true,
            icon: ACEITADOR_ICON,
            nome: "Aceitador de Pedidos",
            descricaco: "Aceite e controle todos os pedidos.",
            funcao: handleAceitadorDePedidos,
            tituloBotao: "CONTRATADO",
            linkTutorial: [
                {
                    nome: "Recebedor de Pedidos",
                    url: "https://www.youtube.com/embed/videoseries?list=PLoSjpDNeiAff5Y9-3kaNTUCppseUoPQjx",
                },
            ],
        },
        {
            id: "REGISTRADOR",
            prioridade: 11,
            ativo: "producao",
            tipo: "Módulo",
            permitido: configuracoes.user?.parametros.exibirRegistroPedidos ? true : false,
            icon: REGISTRADOR_ICON,
            nome: "Registrador de Pedidos",
            descricaco: "Registre os pedidos de telefone, whatsapp e mesa.",
            funcao: handleRegistradorDePedidos,
            tituloBotao: configuracoes.user?.parametros.exibirRegistroPedidos
                ? "CONTRATADO"
                : "CONTRATAR",
            linkTutorial: [
                {
                    nome: "Registrador de Pedidos",
                    url: "https://www.youtube.com/embed/videoseries?list=PLoSjpDNeiAfcTvx_TpXJyfEPWwrcUo9cd",
                },
            ],
        },
        {
            id: "PEDIDO_MESA",
            prioridade: 11,
            ativo: "producao",
            tipo: "Módulo",
            permitido: configuracoes.user?.parametros.possuiModuloPedidosMesa ? true : false,
            icon: PEDIDO_MESA_ICON,
            nome: "Pedido de Mesa",
            descricaco: "Gere QRCode para seu cliente realizar pedidos da mesa.",
            funcao: handlePedidoDeMesa,
            tituloBotao: configuracoes.user?.parametros.possuiModuloPedidosMesa
                ? "PERMITIDO"
                : "CONTRATAR",
            linkTutorial: [
                {
                    nome: "Cliente Usando o Pedido pela Mesa",
                    url: "https://www.youtube.com/embed/FuXsc0jPA7M",
                },
            ],
        },

        {
            id: "CAIXA",
            prioridade: 11,
            ativo: "producao",
            tipo: "Módulo",
            permitido: true,
            dataLancamento: new Date("May 03 2021"),
            icon: CAIXA_ICON,
            nome: "Caixa",
            descricaco: "Controle seu caixa pela direto pela plataforma.",
            funcao: handleCaixa,
            tituloBotao: "ACESSAR",
            linkTutorial: [
                {
                    nome: "Módulo Caixa",
                    url: "https://www.youtube.com/embed/wATpnowNTAg",
                },
            ],
        },
        {
            id: "NOTA_FISCAL",
            prioridade: 11,
            ativo: "teste",
            tipo: "Módulo",
            permitido: true,
            icon: ATENDENTE_ICON,
            nome: "Nota Fiscal",
            descricaco: "Descrição",
            funcao: handleNotaFiscal,
            tituloBotao: "CONTRATADO",
            linkTutorial: [],
        },

        {
            id: "IZZA_BOT",
            prioridade: 20,
            ativo: "producao",
            tipo: "Ferramenta",
            permitido: configuracoes.user?.parametros.modoIzza ? true : false,
            icon: ATENDENTE_ICON,
            nome: "Atendente Virtual de Whatsapp",
            descricaco: "Deixe que a Atendente Virtual atenda seus clientes.",
            funcao: handleIzzaBot,
            tituloBotao: configuracoes.user?.parametros.modoIzza ? "CONTRATADO" : "CONTRATAR",
            linkTutorial: [
                {
                    nome: "Cliente Usando o Pedido pelo Whatsapp",
                    url: "https://www.youtube.com/embed/TAyh0IBVTLk",
                },
            ],
        },

        {
            id: "CARDAPIO",
            ativo: "producao",
            prioridade: 21,
            tipo: "Ferramenta",
            permitido: true,
            icon: HOLDING,
            nome: "Cardápio",
            descricaco: "Edite o seu cardápio.",
            funcao: handleCardapio,
            tituloBotao: "ATIVO",
            linkTutorial: [],
        },
    ]

    return (
        <Container style={{ padding: "0 0 3rem 0" }}>
            <Cabecalho
                pagina={configuracoes.user?.usuario}
                subtitulo={" - " + configuracoes.user?.perfil}
                cor={"#464646"}
                icone={
                    configuracoes.user?.parametros.estabelecimento?.urlLogo
                        ? configuracoes.user?.parametros.estabelecimento?.urlLogo
                        : aplicativoDados.urlLogo
                }
                voltar={false}
                desconectar={desconectar}
            />

            <Loading loading={loading} />

            <Snackbar open={alert.status} autoHideDuration={6000} onClose={alertClose}>
                <Alert onClose={alertClose} severity={alert.tipo}>
                    {alert.mesangem}
                </Alert>
            </Snackbar>

            {/* <img src={imagensTematicas} className={classes.fixedImages} onClick={acessarImagensTematicas}/> */}
            {/* <img src={qrCode} className={classes.fixedQR} onClick={handlePedidoDeMesa}/> */}

            <Row
                style={{
                    alignContent: "center",
                    justifyContent: "center",
                    marginTop: "5em",
                    marginInline: 0,
                }}
            >
                {
                    //limitação Pinn
                    servicosPainel
                        .filter(servicoPainel => {
                            //limita o que deve aparecer no painel Pinn
                            const representanteIdentifier =
                                RepresentanteSelector.getRepresentante().getIdentifier()
                            const pinnIdentifier = PinnDeliveryRepresentante.getIdentifier()
                            const isPinnDelivery = representanteIdentifier === pinnIdentifier

                            const paineisAceitos = ["SISTEMA_ADM", "ACEITADOR", "REGISTRADOR"]
                            if (isPinnDelivery) {
                                return paineisAceitos.includes(servicoPainel.id)
                            }

                            return true
                        })
                        .map(
                            (servicoPainel, index) =>
                                (servicoPainel.ativo === "producao" || teste) && (
                                    <Col
                                        xs={12}
                                        md={6}
                                        lg={4}
                                        key={index}
                                        className="colCardServico"
                                    >
                                        <Card className={classes.root}>
                                            {aplicativoDados.appNome === "painelclientefiel" &&
                                            servicoPainel?.linkTutorial?.length ? (
                                                // true
                                                <HelpOutlineIcon
                                                    className="helpCard"
                                                    onClick={() => {
                                                        handleHelp(servicoPainel)
                                                    }}
                                                />
                                            ) : null}

                                            {new Date() - servicoPainel.dataLancamento <
                                            2741143091 ? (
                                                <div className="newCard">NOVO</div>
                                            ) : null}

                                            <CardActionArea
                                                onClick={() => {
                                                    servicoPainel.funcao()
                                                }}
                                                style={{
                                                    height: "100%",
                                                    flexFlow: "column-reverse",
                                                    alignItems: "normal",
                                                }}
                                            >
                                                <Row
                                                    style={{
                                                        margin: 0,
                                                        textAlign: "",
                                                        width: "100%",
                                                        padding: "1em",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Col
                                                        xs={3}
                                                        md={3}
                                                        lg={3}
                                                        style={{ padding: 0 }}
                                                    >
                                                        <CardMedia
                                                            component="img"
                                                            className={"logoLojas"}
                                                            style={{ opacity: "0.65" }}
                                                            alt={servicoPainel.id}
                                                            image={servicoPainel.icon}
                                                            title={servicoPainel.id}
                                                        />
                                                    </Col>

                                                    <Col xs={9} md={9} lg={9}>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                            component="p"
                                                        >
                                                            {servicoPainel.tipo}
                                                        </Typography>

                                                        <Typography gutterBottom component="h2">
                                                            {servicoPainel.nome}
                                                        </Typography>

                                                        <Typography
                                                            className={"StatusEstabelecimento"}
                                                            variant="body2"
                                                            component="p"
                                                        >
                                                            {servicoPainel.descricaco}
                                                        </Typography>
                                                    </Col>
                                                </Row>

                                                <CardActions style={{ padding: "0" }}>
                                                    <Typography
                                                        variant={"button"}
                                                        style={{
                                                            textAlign: "center",
                                                            padding: "3px",
                                                        }}
                                                        className={
                                                            (servicoPainel.permitido
                                                                ? "botaoVerde"
                                                                : "botaoVermelho") +
                                                            " naoArredondado"
                                                        }
                                                    >
                                                        {servicoPainel.tituloBotao}
                                                    </Typography>
                                                </CardActions>
                                            </CardActionArea>
                                        </Card>
                                    </Col>
                                )
                        )
                }
            </Row>

            <Dialog
                open={contratar === "" ? false : true}
                onClose={() => {
                    setContratar("")
                }}
            >
                <DialogTitle>{"Contato para Contratar"}</DialogTitle>
                <DialogContent>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <Button
                                onClick={() => {
                                    window.open(
                                        `https://api.whatsapp.com/send?phone=5531994754366&text=Oi%20tudo%20bem?%20Gostaria%20de%20saber%20como%20funciona%20o%20${contratar}%20para%20contratar.`,
                                        "_blank"
                                    )
                                    setContratar("")
                                }}
                                style={{ backgroundColor: "#075E54", color: "white" }}
                                fullWidth
                                variant="contained"
                            >
                                Contactar por Whatsapp
                            </Button>
                        </Col>
                        <Col xs={12} md={12} lg={12}>
                            <Button
                                onClick={() => {
                                    setContratar("")
                                }}
                                fullWidth
                                style={{ marginTop: "1em" }}
                            >
                                Não quero
                            </Button>
                        </Col>
                    </Row>
                </DialogContent>
            </Dialog>

            {help.id && (
                <Dialog
                    fullScreen
                    open={!!help.id}
                    onClose={handleNotHelp}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleNotHelp}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {help.nome}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Container className={classes.appContainer}>
                        <Row>
                            <Col
                                xs={12}
                                md={12}
                                lg={12}
                                style={{ marginTop: "5em", textAlign: "center" }}
                            >
                                {help.linkTutorial.map(tutorial => (
                                    <>
                                        <Typography variant="h6" className={classes.title}>
                                            {tutorial.nome}
                                        </Typography>
                                        <iframe
                                            width="100%"
                                            height="400"
                                            src={tutorial.url}
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen
                                        ></iframe>
                                        <Divider></Divider>
                                    </>
                                ))}
                            </Col>
                        </Row>
                    </Container>
                </Dialog>
            )}
        </Container>
    )
}
