import { useSnackbar } from "notistack"
import { useContext, useEffect } from "react"
import LoadingContext from "../../contexts/loadingContext"
import ServicosContext from "../../contexts/servicosContext"

import Container from "@material-ui/core/Container"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 999999,
        color: "#fff",
    },

    divChip: {
        marginBottom: "1em",
        textAlign: "center",
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    tipo: {
        display: "flex",
        flexDirection: "column",
    },
    appContainer: {
        paddingTop: "6em",
        backgroundColor: "#f4f4f4",
        // height: "100%",
        // minHeight: "-webkit-fill-available",
    },
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    appBar: {
        position: "fixed",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    trocarEstabelecimento: {
        marginTop: "1em",
        textAlign: "center",
    },

    list: {
        width: "100%",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}))

export default function Caixa() {
    const { configuracoes } = useContext(ServicosContext)
    const theme = useTheme()
    const classes = useStyles()
    const autorizacao = localStorage.getItem("chaveSistema")
    const search = window.location.search
    const params = new URLSearchParams(search)
    const urlLogo = params.get("urlLogo")
    const urlDominio = params.get("urlDominio")
    const corFundo = params.get("corFundo")
    const corSecundaria = params.get("corSecundaria")

    const { setLoading } = useContext(LoadingContext)
    const { enqueueSnackbar } = useSnackbar()

    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

    useEffect(() => {}, [])

    function logarNoFacebook() {
        // FB.login(function (response) {
        //   if (response.status === 'connected') {
        //     $('#facebookId').val(response.authResponse.userID);
        //     FB.api('/me', function (response) {
        //       $('#facebookNome').val(response.name);
        //       $('#facebookEmail').val(response.email);
        //       $('#login-form').validate().cancelSubmit = true;
        //       $('#login-form').submit();
        //     });
        //     // Logged into your app and Facebook.
        //   } else if (response.status != 'unknown') {
        //     someLoading();
        //     exibeMensagemErro(response.status);
        //   }
        // }, { scope: 'public_profile,email' });
    }

    //HTML

    return (
        <Container component="main">
            <div className="row">
                <div className="col-md-12">
                    <div className="form-actions">
                        <div
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <a
                                className="btn btn-circle btn-lg blue"
                                onClick={logarNoFacebook}
                                style={{
                                    padding: "1em",
                                    margin: "2px 0px",
                                    width: "100%",
                                }}
                            >
                                <i className="fa fa-facebook-square"></i>
                                Facebook
                            </a>
                        </div>
                        <hr></hr>
                        <div
                            className="row"
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <a
                                href="#"
                                className="btn btn-sm default"
                                style={{
                                    width: "15em",
                                    borderRadius: "10rem",
                                    padding: "1em",
                                    margin: "2px 0",
                                }}
                            >
                                Voltar
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}
