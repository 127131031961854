import { verificarConfigsParametros } from "../services/functionsGerais"
import api from "./api"
import { RegistrarLogErro } from "./clientefiel"

function tratamentoResponse(response, chamada) {
    if (response.data.codErro > 0)
        return { retornoErro: response.data.codErro, mensagem: response.data.mensagem }
    else if (typeof response.data == "string" && response.data != "")
        return {
            retornoErro: 1,
            mensagem: chamada + " - Erro inesperado, entre em contato com os reponsáveis.",
        }
    else return response.data
}

const ErroRequisicao = function (data, aplicativoDados) {
    try {
        console.log("ErroRequisicao> ", data.request, data.message)
        if (data.message == "Network Error") {
            return "Verifique sua conexão!"
            // return 'Sem Internet ou sistema fora do ar!'
        } else if (data.message) {
            return "Erro: " + data.message
        }
        return false
    } catch (error) {
        RegistrarLogErro(error, "ErroRequisicao", data)
    }
}

/*
    Para abrir um caixa, você precisa enviar um JSON
    {
        idEstabelecimento: 92348923,
        nomeEstabelecimento: 'Apresentacao', 
        nomeAbertura: 'Marco',
        observacao: 'Estou com dificuldades hoje.'
        dinheiroInicial: 30.0,
        idUsuarioAbriu: 823974,
        tipo: [
            {
                id: 'DELIVERY_ONLINE',
            },
            {
                id: 'RETIRADA_ONLINE',
            },
        ],
    }

    e você recebera um json de resposta

    sucesso: status request entre 200 e 299
    falha: status >= 300 
    retorno será {status: 401, mensagem: ‘algo deu errado’}
*/
export const abrirCaixa = function (caixaRecebido, basicUsuario) {
    return {
        id: 1,
        idEstabelecimento: caixaRecebido.idEstabelecimento,
        nomeEstabelecimento: caixaRecebido.nomeEstabelecimento,
        abertoEm: new Date(),
        fechadoEm: null,
        nomeAbertura: caixaRecebido.nomeAbertura,
        dinheiroInicial: caixaRecebido.dinheiroInicial,
        idUsuarioAbriu: caixaRecebido.idUsuarioAbriu,
        statusFuncionamento: 1, //aberto(1), fechado(0),

        pedidosDoCaixa: [],

        tipo: caixaRecebido.tipo,

        pagamentos: [],

        retiradasDoCaixa: [],

        somaVenda: 0.0,
        somaVendaDinheiro: 0.0,
        somaRetiradasDoCaixa: 0.0,
        somaDinheiroEmCaixa: 0.0,
        somaQuebraDePedidos: 0.0,

        quantidadeEntregadoresAssociados: 0,
        quantidadePedidos: 0,
        quantidadeRetiradasDoCaixa: 0,
    }
}

/*
    Para fechar um caixa, você precisa enviar um JSON
    {
        idCaixa: 92348923,
        observacao: 'eu e marco ficou por conta do caixa hoje'
    }

    e você recebera um json de resposta

    sucesso: status request entre 200 e 299
    falha: status >= 300 
    retorno será {status: 401, mensagem: ‘algo deu errado’}
*/
export const fecharCaixa = function (data, basicUsuario) {
    return {
        status: 200,
        mensagem: "Caixa fechado com sucesso!",
    }
}

/*
    Para buscar notas fiscais
    você precisa apenas mandar um json com os filtros selecionados

    filtro = {
        statusNotaFiscal: 'em processo',
        de: 12/03/2021,
        ate: 15/03/2021,
        idPedido: 583475,
        nomePedido: 'Larissa',
    }
    
    (filtro, basic do usuario) 
    
    e você vai receber a informação de todas as notas fiscais do filtro

    sucesso: status request entre 200 e 299
    falha: status >= 300 
    retorno será {status: 401, mensagem: ‘algo deu errado’}
*/
export const buscarNotasFiscais = function (data, basicUsuario) {
    return [
        {
            id: 13234,
            dataStatus: "12/12/2012",
            statusNotaFiscal: "não processado",

            idEstabelecimento: 2203,
            idPedido: 583475,
            nomePedido: "Larissa",
            dataPedido: "12/12/2012",
            valorPedido: 50.0,
            plataformaPedido: "iOS",
        },
        {
            id: 13234,
            dataStatus: "12/12/2012",
            statusNotaFiscal: "em processo",

            idEstabelecimento: 2203,
            idPedido: 583475,
            nomePedido: "Larissa",
            dataPedido: "12/12/2012",
            valorPedido: 50.0,
            plataformaPedido: "iOS",
        },
        {
            id: 13234,
            dataStatus: "12/12/2012",
            statusNotaFiscal: "em processo",

            idEstabelecimento: 2203,
            idPedido: 583475,
            nomePedido: "Joao",
            dataPedido: "12/12/2012",
            valorPedido: 50.0,
            plataformaPedido: "Site",
        },
        {
            id: 13234,
            dataStatus: "12/12/2012",
            statusNotaFiscal: "processado",

            idEstabelecimento: 2203,
            idPedido: 583475,
            nomePedido: "Marco",
            dataPedido: "12/12/2012",
            valorPedido: 50.0,
            plataformaPedido: "iOS",
        },
        {
            id: 13234,
            dataStatus: "12/12/2012",
            statusNotaFiscal: "em processo",

            idEstabelecimento: 2203,
            idPedido: 583475,
            nomePedido: "Larissa",
            dataPedido: "12/12/2012",
            valorPedido: 50.0,
            plataformaPedido: "Site",
        },
        {
            id: 13234,
            dataStatus: "12/12/2012",
            statusNotaFiscal: "não processado",

            idEstabelecimento: 2203,
            idPedido: 583475,
            nomePedido: "Paulo",
            dataPedido: "12/12/2012",
            valorPedido: 50.0,
            plataformaPedido: "Android",
        },
        {
            id: 13234,
            dataStatus: "12/12/2012",
            statusNotaFiscal: "não processado",

            idEstabelecimento: 2203,
            idPedido: 583475,
            nomePedido: "Pablo",
            dataPedido: "12/12/2012",
            valorPedido: 50.0,
            plataformaPedido: "Site",
        },
        {
            id: 13234,
            dataStatus: "12/12/2012",
            statusNotaFiscal: "processado",

            idEstabelecimento: 2203,
            idPedido: 583475,
            nomePedido: "Amanda",
            dataPedido: "12/12/2012",
            valorPedido: 50.0,
            plataformaPedido: "Android",
        },
    ]
}

/*
    Para emitir a nota fiscal, vc deve enviar
    
    (idNotaFiscal, basic do usuario) 
    
    e você vai receber se foi aprovado e o novo status da nota

    sucesso: status request entre 200 e 299
    falha: status >= 300 
    retorno será {status: 401, mensagem: ‘algo deu errado’}
*/
export const emitirNotaFiscal = function (idNotaFiscal, basicUsuario) {
    return {
        id: 13234,
        dataStatus: "12/12/2012",
        statusNotaFiscal: "em processo",

        idEstabelecimento: 2203,
        idPedido: 583475,
        nomePedido: "Pablo",
        dataPedido: "12/12/2012",
        valorPedido: 50.0,
        plataformaPedido: "Site",
    }
}

export const controleAcessoVerificar = async function (idEstabelecimento, idAba) {
    try {
        const response = await api
            .get(`integracao/controleacesso/verify/${idEstabelecimento}/${idAba}`, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        // console.log('controleAcessoVerificar => ', response)

        return tratamentoResponse(response, "controleAcessoVerificar")
    } catch (error) {
        RegistrarLogErro(error, "controleAcessoVerificar", { idEstabelecimento, idAba })
        return { retornoErro: 1, mensagem: "erro controleAcessoVerificar" }
    }
}

export const controleAcessoAtualizar = async function (idEstabelecimento, idAba) {
    try {
        const response = await api
            .get(`integracao/controleacesso/update/${idEstabelecimento}/${idAba}`, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("controleAcessoAtualizar => ", response)

        return tratamentoResponse(response, "controleAcessoAtualizar")
    } catch (error) {
        RegistrarLogErro(error, "controleAcessoAtualizar", { idEstabelecimento, idAba })
        return { retornoErro: 1, mensagem: "erro controleAcessoAtualizar" }
    }
}

export const resumodiaentregadores = async function (data) {
    try {
        const response = await api
            .get("integracao/entregadores/resumodia", {
                headers: { "Content-Type": "application/json", Authorization: data },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("resumodia => ", response)

        return tratamentoResponse(response, "resumodiaentregadores")
    } catch (error) {
        RegistrarLogErro(error, "resumodia", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const ResumoDia = async Authorization => {
    try {
        const response = await api.get("/integracao/geral/resumodia", {
            headers: {
                "Content-Type": "application/json",
                Authorization: Authorization,
            },
        })

        return tratamentoResponse(response, "ResumoDia")
    } catch (error) {
        console.error(error)
        RegistrarLogErro(error, "ResumoDia", {})
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const Parametros = async function (Authorization, atualizarConfiguracoes, configuracoes) {
    try {
        const response = await api.post(
            "integracao/parametros",
            {},
            {
                headers: { "Content-Type": "application/json", Authorization: Authorization },
            }
        )

        console.log("parametros => ", response)

        if (response.data.codErro > 0)
            return { retornoErro: response.data.codErro, mensagem: response.data.mensagem }
        else if (typeof response.data == "string")
            return {
                retornoErro: 1,
                mensagem: "Erro inesperado, entre em contato com os reponsáveis.",
            }
        else return verificarConfigsParametros(response.data)
    } catch (error) {
        RegistrarLogErro(error, "Parametros")
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const acknowledgmentsingle = async function (data, Authorization) {
    try {
        const response = await api
            .post("integracao/events/acknowledgmentsingle", data, {
                headers: { "Content-Type": "application/json", Authorization: Authorization },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("acknowledgmentsingle => ", response)

        return tratamentoResponse(response, "acknowledgmentsingle")
    } catch (error) {
        RegistrarLogErro(error, "acknowledgmentsingle", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const atualizarUsuarioBasico = async function (Authorization, data) {
    try {
        const response = await api.post(
            `/integracao/atualizarUsuario/${data.idUsuario}/${data.novoNome}/${data.novoTelefone}`,
            {},
            {
                headers: { "Content-Type": "application/json", authorization: Authorization },
            }
        )

        console.log("atualizarUsuario => ", response)

        if (response.data.codErro > 0)
            return { retornoErro: response.data.codErro, mensagem: response.data.mensagem }
        else if (typeof response.data == "string")
            return {
                retornoErro: 1,
                mensagem: "Erro inesperado, entre em contato com os reponsáveis.",
            }
        else return response.data
    } catch (error) {
        RegistrarLogErro(error, "atualizarUsuarioBasico", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}
export const inserirPedidoMesa = async function (Authorization, pedido) {
    try {
        const response = await api.post(
            `integracao/inserirpedidomesa/${pedido.id}/${pedido.mesa}`,
            {},
            {
                headers: { "Content-Type": "application/json", authorization: Authorization },
            }
        )

        console.log("inserirpedidomesa => ", response)

        if (response.data.codErro > 0)
            return { retornoErro: response.data.codErro, mensagem: response.data.mensagem }
        else if (typeof response.data == "string")
            return {
                retornoErro: 1,
                mensagem: "Erro inesperado, entre em contato com os reponsáveis.",
            }
        else return response.data
    } catch (error) {
        RegistrarLogErro(error, "inserirPedidoMesa", pedido)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const atualizarTempoEntrega = async function (Authorization, data) {
    try {
        const response = await api.post(`integracao/atualizartempodeentrega`, data, {
            headers: { "Content-Type": "application/json", Authorization: Authorization },
        })

        console.log("atualizartempodeentrega => ", response)

        if (response.data.codErro > 0)
            return { retornoErro: response.data.codErro, mensagem: response.data.mensagem }
        else if (typeof response.data == "string")
            return {
                retornoErro: 1,
                mensagem: "Erro inesperado, entre em contato com os reponsáveis.",
            }
        else return response.data
    } catch (error) {
        RegistrarLogErro(error, "atualizarTempoEntrega", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}
export const atualizarStatus = async function (Authorization, data) {
    try {
        const response = await api.post(
            `integracao/atualizarsituacao/${data.idEstabelecimento}/${data.situacao}`,
            {},
            {
                headers: { "Content-Type": "application/json", Authorization: Authorization },
            }
        )

        console.log("atualizarStatus => ", response)

        if (response.data.codErro > 0)
            return { retornoErro: response.data.codErro, mensagem: response.data.mensagem }
        else if (typeof response.data == "string")
            return {
                retornoErro: 1,
                mensagem: "Erro inesperado, entre em contato com os reponsáveis.",
            }
        else return response.data
    } catch (error) {
        RegistrarLogErro(error, "ParametrosatualizarStatus", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const buscarInformacoesPedido = async function (Authorization, id, parametros) {
    try {
        const { hashInternaClienteFielIfood, ativaIntegracaoIfood } = parametros
        const response = await api
            .get(`integracao/orders/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: Authorization,
                },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })
        return tratamentoResponse(response, "buscarInformacoesPedido")
    } catch (error) {
        RegistrarLogErro(error, "buscarInformacoesPedido", id)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const buscarestabelecimentos = async function (Authorization) {
    try {
        const response = await api.get("integracao/buscarestabelecimentos", {
            headers: { "Content-Type": "application/json", Authorization: Authorization },
        })

        console.log("buscarestabelecimentos => ", response)

        if (response.data.codErro > 0)
            return { retornoErro: response.data.codErro, mensagem: response.data.mensagem }
        else if (typeof response.data == "string")
            return {
                retornoErro: 1,
                mensagem: "Erro inesperado, entre em contato com os reponsáveis.",
            }
        else return response.data
    } catch (error) {
        RegistrarLogErro(error, "buscarEstabelecimentos")
        return { retornoErro: 1, mensagem: error.message }
    }
}
export const fecharmesa = async function (Authorization, data) {
    try {
        const response = await api.post(
            `integracao/fecharmesa/${data.numeroMesa}`,
            {},
            {
                headers: { "Content-Type": "application/json", Authorization: Authorization },
            }
        )

        console.log("fecharmesa => ", response)

        if (response.data.codErro > 0)
            return { retornoErro: response.data.codErro, mensagem: response.data.mensagem }
        else if (typeof response.data == "string")
            return {
                retornoErro: 1,
                mensagem: "Erro inesperado, entre em contato com os reponsáveis.",
            }
        else return response.data
    } catch (error) {
        RegistrarLogErro(error, "fecharMesa", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}
export const BuscarMesas = async function (Authorization) {
    try {
        const response = await api.get("integracao/buscarmesas", {
            headers: { "Content-Type": "application/json", Authorization: Authorization },
        })

        console.log("BuscarMesas => ", response)

        if (response.data.codErro > 0)
            return { retornoErro: response.data.codErro, mensagem: response.data.mensagem }
        else if (typeof response.data == "string")
            return {
                retornoErro: 1,
                mensagem: "Erro inesperado, entre em contato com os reponsáveis.",
            }
        else return response.data
    } catch (error) {
        RegistrarLogErro(error, "BuscarMesas")
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const ObterStatusLojaIfood = async function (estabelecimento, Authorization) {
    try {
        const response = await api
            .post(`ifood/merchant/status`, estabelecimento, {
                headers: { "Content-Type": "application/json", Authorization: Authorization },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("statusLojaIfood => ", response)

        return tratamentoResponse(response, "statusLojaIfood")
    } catch (error) {
        RegistrarLogErro(error, "statusLojaIfood", estabelecimento)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export async function getNotaFiscal(data) {
    try {
        const response = await api.get("notafiscal/obterNota/" + data, {
            headers: { "Content-Type": "application/json" },
        })

        console.warn("getNotaFiscal =>", response)

        return response.data
    } catch (error) {
        RegistrarLogErro(error, "getNotaFiscal", data)
    }
}

export async function cancelarNotaFiscal(data) {
    try {
        const response = await api.post("notafiscal/cancelarNotaConsumidor/", data, {
            headers: { "Content-Type": "application/json" },
        })

        console.warn("cancelarNotaFiscal =>", response)

        return tratamentoResponse(response, "cancelarNotaConsumidor")
    } catch (error) {
        RegistrarLogErro(error, "cancelarNotaConsumidor", data)
        console.error(error)
    }
}

export async function selecionarMotoqueiroPedido(idPedido, motoqueiroSelecionado, Authorization) {
    try {
        const response = await api.post(
            "integracao/entregadores/selecionar/" + idPedido + "/" + motoqueiroSelecionado,
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: Authorization,
                },
            }
        )

        console.warn("selecionarMotoqueiroPedido =>", response)

        return tratamentoResponse(response, "selecionarMotoqueiroPedido")
    } catch (error) {
        console.error(error)
        RegistrarLogErro(error, "selecionarMotoqueiroPedido", { idPedido, motoqueiroSelecionado })
    }
}

export async function listarMotoqueirosEstabelecimento(Authorization) {
    try {
        const response = await api.post(
            "integracao/entregadores/listar",
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: Authorization,
                },
            }
        )

        console.warn("listarMotoqueirosEstabelecimento =>", response)

        return tratamentoResponse(response, "listarMotoqueirosEstabelecimento")
    } catch (error) {
        console.error(error)
        RegistrarLogErro(error, "listarMotoqueirosEstabelecimento", {})
    }
}

export async function cancelarPedido(
    data,
    pedido,
    Authorization,
    hashInternaClienteFielIfood,
    ativaIntegracaoIfood
) {
    try {
        const response = await api.post(
            `integracao/orders/${pedido.id}/statuses/cancellationRequestedV2`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization,
                    fixed_token_ifood: hashInternaClienteFielIfood,
                    ifood_integration_active: ativaIntegracaoIfood,
                },
            }
        )

        console.warn("cancelarPedido =>", response)

        return tratamentoResponse(response, "cancelarPedido")
    } catch (error) {
        console.log(error)
        RegistrarLogErro(error, "cancelarPedido", {
            data,
            pedido,
            Authorization,
            hashInternaClienteFielIfood,
            ativaIntegracaoIfood,
        })
    }
}

export const salvarEdicaoEmMassa = async (data, Authorization) => {
    try {
        const response = await api.post("integracao/cardapio/update/lista", data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: Authorization,
            },
        })

        console.warn("salvarEdicaoEmMassa =>", response)

        return tratamentoResponse(response, "salvarEdicaoEmMassa")
    } catch (error) {
        console.error(error)
        RegistrarLogErro(error, "salvarEdicaoEmMassa", {})
        return { retornoErro: 1, mensagem: error.mensagem }
    }
}
export const BuscarFormaDePagamentos = async idEstabelecimento => {
    try {
        const response = await api
            .get("integracao/formaspagamento/" + idEstabelecimento, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("BuscarFormaDePagamentos => ", response)

        return tratamentoResponse(response, "BuscarFormaDePagamentos")
    } catch (error) {
        RegistrarLogErro(error, "BuscarFormaDePagamentos")
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const FecharMesa = async (data, Authorization) => {
    try {
        const response = await api
            .post("integracao/fecharmesa/", data, {
                headers: { "Content-Type": "application/json", Authorization: Authorization },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("FecharMesa => ", response)

        return tratamentoResponse(response, "FecharMesa")
    } catch (error) {
        RegistrarLogErro(error, "FecharMesa")
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const MudarNumeroMesa = async (data, Authorization) => {
    try {
        const response = await api
            .post("integracao/alterarmesa/", data, {
                headers: { "Content-Type": "application/json", Authorization: Authorization },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("MudarNumeroMesa => ", response)

        return tratamentoResponse(response, "MudarNumeroMesa")
    } catch (error) {
        RegistrarLogErro(error, "MudarNumeroMesa")
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const ExcluirItemDoPedidoMesa = async (idItemPedido, data, Authorization) => {
    try {
        const response = await api
            .post("integracao/excluiritempedido/" + idItemPedido, data, {
                headers: { "Content-Type": "application/json", Authorization: Authorization },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("ExlcuirItemDoPedidoMesa => ", response)

        return tratamentoResponse(response, "ExlcuirItemDoPedidoMesa")
    } catch (error) {
        RegistrarLogErro(error, "ExlcuirItemDoPedidoMesa")
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const EditarPedidoMesa = async (idPedido, data, Authorization) => {
    try {
        const response = await api
            .post("integracao/editaritempedido/" + idPedido, data, {
                headers: { "Content-Type": "application/json", Authorization: Authorization },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("EditarPedidoMesa => ", response)

        return tratamentoResponse(response, "EditarPedidoMesa")
    } catch (error) {
        RegistrarLogErro(error, "EditarPedidoMesa")
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const InserirItemPedidoMesa = async (idPedido, data, Authorization) => {
    try {
        const response = await api
            .post("integracao/inseriritempedido/" + idPedido, data, {
                headers: { "Content-Type": "application/json", Authorization: Authorization },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("InserirItemPedidoMesa => ", response)

        return tratamentoResponse(response, "InserirItemPedidoMesa")
    } catch (error) {
        RegistrarLogErro(error, "InserirItemPedidoMesa")
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const alterarEstabelecimentoDoPedido = async function (
    idPedido,
    idEstabelecimento,
    Authorization
) {
    const data = { id: idEstabelecimento }
    try {
        const response = await api.post("integracao/alterarestabelecimento/" + idPedido, data, {
            headers: { "Content-Type": "application/json", Authorization: Authorization },
        })

        console.log("AlterarEstabelecimentoDoPedido => ", response)

        if (response.data.codErro > 0)
            return { retornoErro: response.data.codErro, mensagem: response.data.mensagem }
        else if (typeof response.data == "string")
            return {
                retornoErro: 1,
                mensagem: "Erro inesperado, entre em contato com os reponsáveis.",
            }
        else return response.data
    } catch (error) {
        RegistrarLogErro(error, "AlterarEstabelecimentoDoPedido")
        return { retornoErro: 1, mensagem: error.message }
    }
}
