//import Head from './Head';
//import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react"
import ReactDOM from "react-dom"
import App from "./App"
import "./global.css"
import { registerServiceWorker } from "./serviceWorker"

Sentry.init({
    dsn: "https://274681b6e9cc4310828777bd009fdc6f@o436862.ingest.sentry.io/5532659",
    ignoreErrors: [
        "Error: NotSupportedError: The element has no supported sources.",
        "NotSupportedError: The element has no supported sources.",
        "play() failed because the user didn't interact with the document first.",
        "The play() request was interrupted by a new load request",
        "The element has no supported sources",
        /^NotSupportedError: The element has no supported sources.$/,
        /^Não encontrou o aplicativo$/,
        /^Cannot read property 'promise' of null$/,
    ],
})

registerServiceWorker()

ReactDOM.render(<App />, document.getElementById("root"))

//serviceWorker.register();
