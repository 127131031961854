import api from "./api"
import { tratarResposta } from "./functionsGerais"

export const dataAtualFormatada = data => {
    const dia = data.getDate().toString()
    const diaF = dia.length == 1 ? "0" + dia : dia
    const mes = (data.getMonth() + 1).toString() //+1 pois no getMonth Janeiro começa com zero.
    const mesF = mes.length == 1 ? "0" + mes : mes
    const anoF = data.getFullYear()
    return diaF + "/" + mesF + "/" + anoF
}

export const obterTiposCaixaParaAbrir = async function (idEstabelecimento, basicUsuario) {
    const response = await api
        .get(`controlecaixa/obterTiposCaixaParaAbrir/${idEstabelecimento}`, {
            headers: { "Content-Type": "application/json", Authorization: basicUsuario },
        })
        .then(data => {
            console.log("data", data)
            return data
        })
        .catch(err => {
            console.log("err", err)
            if (err.response) {
                return err.response
            } else {
                return err
            }
        })
    console.log("obterTiposCaixaParaAbrir => ", response)
    return tratarResposta(response, "Obter Tipos Caixa")
}

export const obterCaixasAbertos = async function (idEstabelecimento, basicUsuario) {
    const response = await api
        .get(`controlecaixa/obterCaixasAbertos/${idEstabelecimento}`, {
            headers: { "Content-Type": "application/json", Authorization: basicUsuario },
        })
        .then(data => {
            console.log("data", data)
            return data
        })
        .catch(err => {
            console.log("err", err)
            if (err.response) {
                return err.response
            } else {
                return err
            }
        })
    console.log("obterCaixasAbertos => ", response)
    return tratarResposta(response, "Obter Caixas")
}
export const abrirCaixa = async function (data, basicUsuario) {
    const response = await api
        .post(`controlecaixa/abrirCaixa/${data.idEstabelecimento}`, data, {
            headers: { "Content-Type": "application/json", Authorization: basicUsuario },
        })
        .then(data => {
            console.log("data", data)
            return data
        })
        .catch(err => {
            console.log("err", err)
            if (err.response) {
                return err.response
            } else {
                return err
            }
        })
    console.log("abrirCaixa => ", response)
    return tratarResposta(response, "Abrir Caixa")
}

export const fecharCaixa = async function (idCaixa, basicUsuario) {
    const response = await api
        .post(
            `controlecaixa/fecharCaixa/${idCaixa}`,
            {},
            {
                headers: { "Content-Type": "application/json", Authorization: basicUsuario },
            }
        )
        .then(data => {
            console.log("data", data)
            return data
        })
        .catch(err => {
            console.log("err", err)
            if (err.response) {
                return err.response
            } else {
                return err
            }
        })
    console.log("fecharCaixa => ", response)
    return tratarResposta(response, "Fechar Caixa")
}

export const buscarCaixa = async function (idCaixa, basicUsuario) {
    const response = await api
        .post(
            `controlecaixa/buscarCaixa/${idCaixa}`,
            {},
            {
                headers: { "Content-Type": "application/json", Authorization: basicUsuario },
            }
        )
        .then(data => {
            console.log("data", data)
            return data
        })
        .catch(err => {
            console.log("err", err)
            if (err.response) {
                return err.response
            } else {
                return err
            }
        })
    console.log("buscarCaixa => ", response)
    return tratarResposta(response, "Buscar Caixa")
}

export const fazerRetiradaCaixa = async function (data, basicUsuario) {
    const response = await api
        .post("controlecaixa/fazerRetiradaCaixa", data, {
            headers: { "Content-Type": "application/json", Authorization: basicUsuario },
        })
        .then(data => {
            console.log("data", data)
            return data
        })
        .catch(err => {
            console.log("err", err)
            if (err.response) {
                return err.response
            } else {
                return err
            }
        })
    console.log("fazerRetiradaCaixa => ", response)
    return tratarResposta(response, "Fazer Retirada Caixa")
}
