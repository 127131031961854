import React, { useContext } from "react"
import { useHistory } from "react-router-dom"

import { useSnackbar } from "notistack"
import RegistradorContext from "../../../../contexts/registradorContext"
import ServicosContext from "../../../../contexts/servicosContext"

import ChipPagamentoPercentual from "./ChipPagamentoPercentual"

import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormLabel from "@material-ui/core/FormLabel"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import PaymentIcon from "@material-ui/icons/Payment"

import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import Slide from "@material-ui/core/Slide"

import { calcularValorTotal } from "../../functions"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles(theme => ({
    fixed: {
        position: "fixed",
        right: "2em",
        bottom: "2em",
        zIndex: 1000,
    },
    fixedMesa: {
        position: "fixed",
        right: "2em",
        bottom: "2em",
        zIndex: 2000,
    },
    fixedMesaFechar: {
        position: "fixed",
        right: "2em",
        bottom: "7em",
        zIndex: 2000,
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    rootAlert: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
    backdrop: {
        // zIndex: theme.zIndex.drawer + 999999,
        color: "#fff",
    },
    pos: {
        marginBottom: 12,
    },
    heading: {
        width: "100%",
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    rootList: {
        width: "100%!important",
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: "inline",
        width: "100%",
    },
    appBar: {
        position: "fixed",
    },
    appContainer: {
        paddingTop: "6em",
        backgroundColor: "#f4f4f4",
        // height: "100%",
        // minHeight: "-webkit-fill-available",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    list: {
        width: 250,
    },
    fullList: {
        width: "auto",
    },
}))

export default function Index() {
    const autorizacao = localStorage.getItem("chaveSistema")
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    )
    const classes = useStyles()
    const theme = useTheme()
    const history = useHistory()

    const {
        setOpenFinalizacaoPedido,
        openFinalizacaoPedido,
        isPedidoMesa,
        numeroMesas,
        isRetiradaNoLocal,
        isDelivery,
        setCarrinho,
        cardapio,
        carrinho,
        setCardapio,
        valorDescontoManual,
        setValorDescontoManual,
        taxaServico,
        isFreteGratisMinimoEntrega,
        setOpenFormasDePagamento,
        ativarDesativarDesconto,
        ativoMinimoEntregaGratis,
        ativarMinimoEntregaGratis,
        descontoAtivo,
        setDescontoAtivo,
        openFormasDePagamento,
        descontoAtivoPagamento,
        setOpenCardapio,
        EstabelecimentoAberto,
        enderecoRetiradaNoLocal,
    } = useContext(RegistradorContext)

    const { enqueueSnackbar } = useSnackbar()

    const { configuracoes } = useContext(ServicosContext)

    const alertStart = (msg, tipo) => {
        enqueueSnackbar(msg, { variant: tipo })
    }

    const selecionarFormaDePagamento = formaDePagamento => {
        setOpenFormasDePagamento(false)
        const cart = Object.assign({}, carrinho)
        cart.formaPagamento = formaDePagamento

        setCarrinho(
            calcularValorTotal({
                carrinho: cart,
                cardapio,
                descontoAtivo,
                isDelivery,
                ativoMinimoEntregaGratis,
                valorDescontoManual,
                descontoAtivoPagamento,
                taxaServico,
            })
        )

        setOpenFinalizacaoPedido(true)
    }

    return carrinho.itens.length && cardapio?.id ? (
        <Dialog
            open={openFormasDePagamento}
            TransitionComponent={Transition}
            keepMounted
            fullScreen={mobile}
            onClose={() => setOpenFormasDePagamento(false)}
            style={{ zIndex: "1301" }}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="responsive-dialog-pagamento">
                <IconButton
                    className="setaVoltarModal"
                    onClick={() => {
                        setOpenFormasDePagamento(false)
                    }}
                >
                    <ArrowForwardIosIcon />
                </IconButton>
                {"Formas de Pagamento"}
            </DialogTitle>
            <DialogContent>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Selecione a melhor opção</FormLabel>
                    <RadioGroup
                        aria-label="formaDePagamento"
                        id="groupFormaDePagamento"
                        name="formaDePagamento"
                        value={carrinho?.formaPagamento?.codigo}
                    >
                        {cardapio?.formasPagamento.map((formaDePagamento, index) => (
                            // <span key={formaDePagamento.id} >
                            //   {formaDePagamento.urlImagem ? <img src={formaDePagamento.urlImagem} alt={formaDePagamento.descricao} className="bandeiraCartao"/> : <PaymentIcon className="bandeiraCartao"/>}
                            //   <FormControlLabel value={"forma"+index} control={<Radio />} label={formaDePagamento.descricao} />
                            // </span>

                            <ListItem
                                key={index}
                                button
                                style={{ padding: 0 }}
                                onClick={() => {
                                    selecionarFormaDePagamento(formaDePagamento)
                                }}
                            >
                                <ListItemText
                                    style={{
                                        padding: "0",
                                        margin: "0",
                                    }}
                                    primary={
                                        <div
                                            key={formaDePagamento.id}
                                            className="spanFormasDePagamento"
                                        >
                                            {formaDePagamento.urlImagem ? (
                                                <img
                                                    src={formaDePagamento.urlImagem}
                                                    alt={formaDePagamento.descricao}
                                                    className="bandeiraCartao"
                                                />
                                            ) : (
                                                <PaymentIcon className="bandeiraCartao" />
                                            )}
                                            <FormControlLabel
                                                style={{ margin: "0!important" }}
                                                value={formaDePagamento.codigo}
                                                control={<Radio />}
                                                label={
                                                    <>
                                                        {formaDePagamento.descricao}
                                                        <ChipPagamentoPercentual
                                                            valor={formaDePagamento.desconto}
                                                        />
                                                    </>
                                                }
                                            />
                                        </div>
                                    }
                                />
                            </ListItem>
                        ))}
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenFormasDePagamento(false)}>Voltar</Button>
                <Button
                    onClick={() => {
                        selecionarFormaDePagamento()
                    }}
                    variant="contained"
                    color="primary"
                >
                    Selecionar
                </Button>
            </DialogActions>
        </Dialog>
    ) : (
        <React.Fragment></React.Fragment>
    )
}
