import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    TextField,
} from "@material-ui/core"
import { useContext, useState } from "react"
import ServicosContext from "../../../contexts/servicosContext"
import { RegistrarLogErro } from "../../../services/clientefiel"
import { cancelarPedido } from "../../../services/integracao"

export default function DialogCancelamento(props) {
    const [motivoCancelamento, setMotivoCancelamento] = useState(null)
    const [observacao, setObservacao] = useState("")

    const { configuracoes } = useContext(ServicosContext)

    const autorizacao = localStorage.getItem("chaveSistema")

    const handleChange = e => setObservacao(e.target.value)

    const handleClose = () => {
        setObservacao("")
        setMotivoCancelamento(null)
        props.setConfirmarCancelamento(null)
    }

    const handleCancelar = async () => {
        const data = {
            observacao,
            motivoCancelamentoPedido: props.listaMotivoCancelamento[motivoCancelamento],
        }

        try {
            props.setLoading(true)

            if (observacao.length <= 3) {
                props.alertStart(
                    "O motivo do cancelamento deve ter mais de 3 caracteres",
                    "warning"
                )
                props.setLoading(false)
                return
            }

            const { hashInternaClienteFielIfood, ativaIntegracaoIfood } =
                configuracoes.user.parametros

            const response = await cancelarPedido(
                data,
                props.confirmarCancelamento,
                autorizacao,
                hashInternaClienteFielIfood,
                ativaIntegracaoIfood
            )

            if (response.retornoErro) {
                props.alertStart(response.mensagem, "error")
                props.setLoading(false)
                return
            }

            handleClose()
            props.removerPedidoListaAnterior(props.confirmarCancelamento)

            props.setLoading(false)
        } catch (error) {
            RegistrarLogErro(error, "cancelarPedido", {
                data,
                pedido: props.confirmarCancelamento,
                autorizacao,
            })
            props.alertStart("Erro ao inesperado cancelarPedido", "error")
        }
    }

    return (
        <Dialog open={props.confirmarCancelamento} onClose={handleClose} maxWidth="xs">
            <DialogTitle>Confirmação</DialogTitle>
            <DialogContent>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel>Motivo</InputLabel>
                    <Select
                        native
                        fullWidth
                        value={motivoCancelamento}
                        onChange={e => setMotivoCancelamento(e.target.value)}
                        label="Motivo"
                    >
                        {Object.keys(props.listaMotivoCancelamento).map((motivoIndex, index) => {
                            const motivo = props.listaMotivoCancelamento[motivoIndex]

                            return <option value={index}>{motivo.description}</option>
                        })}
                    </Select>
                </FormControl>
                <TextField
                    label="Observação"
                    multiline
                    fullWidth
                    minRows={4}
                    variant="outlined"
                    value={observacao}
                    onChange={handleChange}
                    margin="normal"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} fullWidth variant="contained">
                    NÃO CANCELAR
                </Button>
                <Button
                    onClick={handleCancelar}
                    variant="contained"
                    fullWidth
                    style={{
                        backgroundColor: "#dc3545",
                        color: "white",
                    }}
                >
                    CANCELAR
                </Button>
            </DialogActions>
        </Dialog>
    )
}
