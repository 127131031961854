import {
    Button,
    Collapse,
    Divider,
    List,
    ListItem,
    makeStyles,
    Paper,
    Typography,
} from "@material-ui/core"
import { useContext, useEffect, useState } from "react"
import iconIfood from "../../../assets/ifood-logo.png"
import ServicosContext from "../../../contexts/servicosContext"
import { UberLogo } from "../IntegracaoUber/assets"

const useStyles = makeStyles(theme => ({
    botao: {
        color: "#5f5f5f",
    },
    listaIntegracoes: {
        backgroundColor: "#fff",
        position: "absolute",
        zIndex: 5,
    },
    lista: {
        padding: "4px",
        display: "flex",
        flexDirection: "column",
        "& > li": {
            flex: "1 0 100px",
        },
        "& > li > imagem": {
            height: "100%",
        },
    },
    itemLista: {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "175px",
        padding: 0,
        "& p": { margin: "0.125rem 0.25rem", borderBottom: "1px black solid" },
        "& p:last-child": {
            borderBottom: "none",
        },
    },
    botaoIfood: {
        width: "100%",
        height: "100%",
        padding: "1.25rem 0",
    },
    botaoUber: {
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        padding: 0,
    },
}))

export default function ListaIntegracoes({
    expandListaIntegracoes,
    setExpandListaIntegracoes,
    statusLojaIfood,
}) {
    const classes = useStyles()

    const { configuracoes } = useContext(ServicosContext)

    const [posicaoBotao, setPosicaoBotao] = useState({})
    const [posicaoPainel, setPosicaoPainel] = useState(150)

    useEffect(() => {
        setPosicaoBotao(document.getElementById("botao-lista-integracoes")?.getBoundingClientRect())
    }, [expandListaIntegracoes])

    useEffect(() => {
        if (document.getElementById("painel-integracoes")) {
            const widthPainel = document
                .getElementById("painel-integracoes")
                ?.getBoundingClientRect().width
            let pos =
                window.screen.width > 600 ? posicaoBotao.right - widthPainel : posicaoBotao.left
            setPosicaoPainel([pos, posicaoBotao.bottom])
        }
    }, [posicaoBotao])

    const integradoComUber = configuracoes.user?.parametros?.possuiIntegracaoUber
    const integradoComIfood = configuracoes.user?.parametros?.ativaIntegracaoIfood

    if (!integradoComUber && !integradoComIfood) return null

    return (
        <Collapse
            in={expandListaIntegracoes}
            id="painel-integracoes"
            className={classes.listaIntegracoes}
            style={{ left: posicaoPainel[0], top: posicaoPainel[1] }}
        >
            <Paper elevation={4}>
                <List className={classes.lista}>
                    {integradoComUber && (
                        <>
                            <ListItem className={classes.itemLista}>
                                <Button className={classes.botaoUber}>
                                    <UberLogo height={50} />
                                </Button>
                            </ListItem>
                            <Divider style={{ height: "2px" }} />
                        </>
                    )}
                    {integradoComIfood && (
                        <ListItem className={classes.itemLista}>
                            <Button className={classes.botaoIfood}>
                                <img
                                    src={iconIfood}
                                    style={{ height: "58px" }}
                                    alt="iFood Integração"
                                />
                            </Button>
                            <Paper elevation={4}>
                                {statusLojaIfood?.message ? (
                                    <Typography variant="body2">
                                        {statusLojaIfood?.message}
                                    </Typography>
                                ) : (
                                    <></>
                                )}
                                {statusLojaIfood?.extraInfo?.map(info => (
                                    <Typography variant="body2">{info}</Typography>
                                ))}
                            </Paper>
                        </ListItem>
                    )}
                </List>
            </Paper>
        </Collapse>
    )
}
