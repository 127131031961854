import {
    Avatar,
    Chip,
    FormControl,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from "@material-ui/core"
import { Add, Payment, Remove } from "@material-ui/icons"
import { useState } from "react"

const useStyles = makeStyles(theme => ({
    formaPagamentoImagem: {
        maxWidth: "2rem",
        marginRight: "0.25rem",
    },
    formaPagamentoItemLista: {
        display: "flex",
        justifyContent: "space-between",
        // width: '20rem'
    },
    ChipValorPromocional_positivo: {
        marginLeft: "0.75rem",
        backgroundColor: "#28a74596",
        display: "flex",
        justifyContent: "space-around",
        "& > div": {
            // Avatar
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
            backgroundColor: "#28a745",
            color: "#fff!important",
        },
    },
    ChipValorPromocional_negativo: {
        backgroundColor: "#dc354596",
        display: "flex",
        justifyContent: "space-around",
        "& > div": {
            // Avatar
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
            backgroundColor: "#dc3545",
            color: "#fff!important",
        },
    },
    ChipValorPromocional_icone: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
        // backgroundColor: isPositivo > 0 ? "#28a745" : "#dc3545",
        color: "#fff!important",
    },
    selectFormaPagemento: {
        // height: "4rem",
        "& > div": {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            flexWrap: "nowrap",
        },
    },
}))

const ChipValorPromocional = ({ valor }) => {
    const classes = useStyles()

    const isPositivo = valor > 0

    if (!valor) return null

    return (
        <Chip
            className={
                isPositivo
                    ? classes.ChipValorPromocional_positivo
                    : classes.ChipValorPromocional_negativo
            }
            size="small"
            avatar={<Avatar className={classes.icone}>{isPositivo ? <Remove /> : <Add />}</Avatar>}
            label={
                <Typography component="span" variant="subtitle1" className={classes.porcentagem}>
                    {Math.abs(valor)}%
                </Typography>
            }
        />
    )
}

export default function SelecaoFormaPagamento({
    handleSelecaoFormaDePagamento,
    carrinho,
    formasPagamento,
    pagamentoEditar,
}) {
    const classes = useStyles()

    const disabled = !formasPagamento.length

    const [formasFiltradas] = useState(
        formasPagamento.filter(forma => forma.ativo && !forma.online)
    )

    return (
        <Tooltip
            arrow
            title={
                disabled
                    ? "Formas de pagamento desativadas para pedidos de mesa, para ativar acesse a tela de configurações do sistema administrativo."
                    : ""
            }
        >
            <FormControl fullWidth variant="outlined" disabled={disabled}>
                <InputLabel style={{ textWrap: "nowrap" }}>Forma de Pagamento</InputLabel>
                <Select
                    disabled={pagamentoEditar ? true : false}
                    id="formaPagamento"
                    label="Forma de Pagamento"
                    fullWidth
                    value={
                        pagamentoEditar?.id
                            ? pagamentoEditar?.id
                            : carrinho?.formaPagamento?.id
                            ? carrinho.formaPagamento
                            : undefined
                    }
                    onChange={handleSelecaoFormaDePagamento}
                    className={classes.selectFormaPagemento}
                >
                    {formasFiltradas.map(forma => (
                        <MenuItem
                            key={forma.id}
                            value={forma}
                            className={classes.formaPagamentoItemLista}
                        >
                            <div className={classes.formaPagamento_nome}>
                                {forma.urlImagem ? (
                                    <img
                                        src={forma.urlImagem}
                                        alt={forma.descricao}
                                        className={classes.formaPagamentoImagem}
                                        id="forma-pagamento-imagem"
                                    />
                                ) : (
                                    <Payment className={classes.formaPagamentoImagem} />
                                )}
                                {forma.descricao}
                            </div>
                            <ChipValorPromocional valor={forma?.desconto} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Tooltip>
    )
}
