import {
    Collapse,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    makeStyles,
    Menu,
    MenuItem,
    TextField,
    Typography,
} from "@material-ui/core"
import { Add, AddAPhoto, ExpandMoreOutlined, MoreVert } from "@material-ui/icons"
import { useSnackbar } from "notistack"
import { useContext, useRef, useState } from "react"
import LoadingContext from "../../../contexts/loadingContext"
import { inativarProduto } from "../../../services/cardapio"
import DisponibilidadeSwitch from "./DisponibilidadeSwitch"
import ItemPasso from "./ItemPasso"

const ITEM_HEIGHT = 48

const useStyles = makeStyles(() => ({
    container: {
        width: "100%",
    },
    containerImg: {
        margin: "auto",
        maxWidth: "80px",
        height: "80px",
        backgroundColor: "#eee",
        border: "2px dashed #ccc",
        cursor: "pointer",
        overflow: "hidden",
        borderRadius: "10px",
    },
    addAPhoto: {
        margin: "auto",
        display: "block",
        width: "40%",
        height: "40%",
        color: "rgba(0,0,0,0.5)",
    },
    input: {
        display: "none",
    },
    collapse: {
        width: "100%",
    },
    // dragIndicator: {
    //     cursor: "move",
    // },
}))

export default function ItemProduto({
    conteudo,
    setOpenproduto,
    setModalProduto,
    setCadastrar,
    setModalPasso,
    setOpenPassoDeMontagem,
    setDraggingProduto,
    categoria,
    setProdutoAtual,
    handleDuplicarProduto,
    handleDuplicarPassoDeMontagem,
    handleExcluirProduto,
    handleExcluirGrupoDeOpcao,
    handleCadastrarOpcao,
    handleExcluirOpcao,
    handleIdsEditar,
    handleToggleDuplicarGrupoDeMontagem,
    handleCadastrarImagem,
}) {
    const { setLoading } = useContext(LoadingContext)
    const { enqueueSnackbar } = useSnackbar()

    const alertStart = (msg, tipo) => {
        enqueueSnackbar(msg, { variant: tipo })
    }

    const [expanded, setExpanded] = useState(false)
    const handleExpandClick = () => {
        setExpanded(!expanded)
    }
    const [formats, setFormats] = useState(() => ["bold", "italic"])
    const handleFormat = (event, newFormats) => {
        setFormats(newFormats)
    }

    const [anchorEl, setAnchorEl] = useState(null)

    const openMoreProduto = Boolean(anchorEl)
    const handleClickMoreProduto = event => {
        setCadastrar(true)
        setAnchorEl(event.currentTarget)
    }
    const handleCloseMoreProduto = () => {
        setAnchorEl(null)
    }

    const fileInputRef = useRef(null)
    const handleDivClick = () => {
        fileInputRef.current.click()
    }
    const handleFileChange = event => {
        const file = event.target.files[0]

        if (file) {
            const nomeArquivo = file.name
            const idProduto = conteudo.id
            const idOpcao = null
            const idPasso = null

            handleCadastrarImagem(nomeArquivo, idProduto, idPasso, idOpcao, file)
        }
    }

    const handleClickOpenProduto = () => {
        setOpenproduto(true)
    }

    const handleChangeNome = e => {
        handleIdsEditar(conteudo.id)
        conteudo.nome = e.target.value
    }
    const handleChangeDescricao = e => {
        handleIdsEditar(conteudo.id)
        conteudo.descricao = e.target.value
    }
    const handleChangeValor = e => {
        handleIdsEditar(conteudo.id)
        conteudo.valor = e.target.value
    }

    const handleChangeDisponibilidadeProduto = async e => {
        setLoading(true)
        const response = await inativarProduto(conteudo.id, conteudo.ativo)
        if (response.retornoErro) {
            console.error(response)
            alertStart(response.mensagem, "error")
        } else {
            conteudo.ativo = !conteudo.ativo
            console.warn("inativarProduto => ", response)
            alertStart("Disponibilidade do produto alterada com sucesso", "success")
        }
        setLoading(false)
    }

    const [open, setOpen] = useState(false)
    const handleClickPassoDeMontagem = () => {
        setOpenPassoDeMontagem(true)
    }

    const classes = useStyles()

    // const handleDragStart = index => {
    //     setDraggingProduto(index)
    // }
    // const handleDragEnd = () => {
    //     setDraggingProduto(null)
    // }

    // const [draggingOpcoes, setDraggingOpcoes] = useState(null)
    // const handleDragOver = index => {
    //     if (draggingOpcoes === null) return
    //     if (draggingOpcoes === index) return
    //     const itens = [...passos]
    //     const [removido] = itens.splice(draggingOpcoes, 1)
    //     itens.splice(index, 0, removido)
    //     itens.forEach((item, idx) => {
    //         if (item) {
    //             item.ordemExib = idx
    //         }
    //     })
    //     setDraggingOpcoes(index)
    //     setPassos(itens)
    // }

    return (
        <div
            // draggable={!expanded}
            // onDragStart={e => handleDragStart(index)}
            // onDragEnd={handleDragEnd}
            className={classes.container}
        >
            <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                {/* <IconButton>
                    <DragIndicator className={classes.dragIndicator} />
                </IconButton> */}
                <Grid
                    className={classes.containerImg}
                    item
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    onClick={handleDivClick}
                    xs={12}
                    sm={6}
                    md={1}
                >
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        {conteudo.urlImagem ? (
                            <img
                                style={{
                                    borderRadius: "10px",
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    objectPosition: "center",
                                }}
                                src={conteudo.urlImagem}
                                alt="Imagem"
                            />
                        ) : (
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <AddAPhoto className={classes.addAPhoto} />
                            </Grid>
                        )}
                    </Grid>
                    <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        className={classes.input}
                        accept="image/*"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2} container id="texto">
                    <TextField
                        fullWidth
                        type="text"
                        variant="outlined"
                        label="Produto"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        defaultValue={conteudo.nome}
                        onChange={handleChangeNome}
                    />
                </Grid>

                <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    item
                    xs={12}
                    sm={12}
                    md={3}
                >
                    <TextField
                        fullWidth
                        type="text"
                        variant="outlined"
                        label="Descição"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        defaultValue={conteudo.descricao}
                        onChange={handleChangeDescricao}
                    />
                </Grid>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    item
                    xs={12}
                    sm={12}
                    md={2}
                >
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Preço"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                        }}
                        inputProps={{
                            style: { textAlign: "right" },
                        }}
                        defaultValue={conteudo.valor}
                        onChange={handleChangeValor}
                    />
                </Grid>
                <Grid item container xs={8} sm={8} md={2} justifyContent="center">
                    <DisponibilidadeSwitch
                        produto={true}
                        disponivel={conteudo.ativo}
                        handleChangeDisponibilidadeProduto={handleChangeDisponibilidadeProduto}
                    />
                </Grid>
                <Grid
                    item
                    container
                    xs={4}
                    sm={4}
                    md={1}
                    alignItems="center"
                    justifyContent="center"
                >
                    <IconButton onClick={handleClickMoreProduto}>
                        <MoreVert fontSize="medium" />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={openMoreProduto}
                        onClose={handleCloseMoreProduto}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: "20ch",
                            },
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                handleClickOpenProduto()
                                setModalProduto(conteudo)
                                setCadastrar(false)
                            }}
                        >
                            Editar Produto
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleDuplicarProduto(conteudo.id, categoria.id)
                            }}
                        >
                            Duplicar Produto
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleExcluirProduto(conteudo.id, categoria.id)
                            }}
                        >
                            Excluir Produto
                        </MenuItem>
                    </Menu>
                    <IconButton onClick={handleExpandClick}>
                        <ExpandMoreOutlined />
                    </IconButton>
                </Grid>

                <Collapse className={classes.collapse} in={expanded} timeout="auto" unmountOnExit>
                    <Divider />
                    <Grid container direction="row" spacing={1}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Grid fullWidth>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    container
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Typography>Passos de montagem</Typography>
                                    <IconButton
                                        onClick={() => {
                                            setCadastrar(true)
                                            setModalPasso(null)
                                            handleClickPassoDeMontagem()
                                            setProdutoAtual(conteudo)
                                        }}
                                    >
                                        <Add fontSize="small" />
                                    </IconButton>
                                </Grid>
                                <Grid>
                                    <List>
                                        {conteudo?.opcoes.map((opcao, index) => (
                                            <ListItem
                                                key={opcao.id}
                                                //   onDragOver={() => handleDragOver(index)}
                                            >
                                                <ItemPasso
                                                    opcao={opcao}
                                                    setOpen={setOpen}
                                                    setCadastrar={setCadastrar}
                                                    setModalPasso={setModalPasso}
                                                    setOpenPassoDeMontagem={setOpenPassoDeMontagem}
                                                    //   setDraggingOpcoes={setDraggingOpcoes}
                                                    setProdutoAtual={setProdutoAtual}
                                                    conteudo={conteudo}
                                                    handleExcluirGrupoDeOpcao={
                                                        handleExcluirGrupoDeOpcao
                                                    }
                                                    handleCadastrarOpcao={handleCadastrarOpcao}
                                                    handleExcluirOpcao={handleExcluirOpcao}
                                                    handleDuplicarPassoDeMontagem={
                                                        handleDuplicarPassoDeMontagem
                                                    }
                                                    handleIdsEditar={handleIdsEditar}
                                                    handleToggleDuplicarGrupoDeMontagem={
                                                        handleToggleDuplicarGrupoDeMontagem
                                                    }
                                                    handleCadastrarImagem={handleCadastrarImagem}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Collapse>
            </Grid>
        </div>
    )
}
