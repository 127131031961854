import { Paper, makeStyles } from "@material-ui/core"
import { useContext } from "react"
import ServicosContext from "../../../contexts/servicosContext"

const useStyles = makeStyles({
    impressao: {
        display: "flex",
        justifyContent: "center",
        marginTop: "1rem",
        "& > div": {
            padding: "0.75rem",
        },
    },
})

const removerValores = texto =>
    texto.replace(
        /(R\$\s*)?(\()?([0-9]+[.,]{1}[0-9])(.*)/g, // RegEx para remover valores
        ""
    )

export default function TextoComandaPedido({ pedido }) {
    const { configuracoes } = useContext(ServicosContext)
    const classes = useStyles()

    if (!pedido?.texto) return null

    const textoCensurado =
        configuracoes.user.perfil === "DELIVERY_BASICO"
            ? removerValores(pedido.texto)
            : pedido.texto

    const texto = textoCensurado
        .replace(/(?:\r\n|\r|\n)/g, "<br>")
        .replace("<br>", "")
        .replace("<br>", "")
        .replace("<br>", "")

    return (
        <div className={classes.impressao}>
            <Paper dangerouslySetInnerHTML={{ __html: texto }} />
        </div>
    )
}
