import React, { useContext } from "react"
import { useHistory } from "react-router-dom"

import { useSnackbar } from "notistack"
import RegistradorContext from "../../../../contexts/registradorContext"
import ServicosContext from "../../../../contexts/servicosContext"

import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Slide from "@material-ui/core/Slide"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Col, Row } from "react-bootstrap"

import { mandarImprimir, textoHTML } from "../../../../services/functionsGerais"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles(theme => ({
    fixed: {
        position: "fixed",
        right: "2em",
        bottom: "2em",
        zIndex: 1000,
    },
    fixedMesa: {
        position: "fixed",
        right: "2em",
        bottom: "2em",
        zIndex: 2000,
    },
    fixedMesaFechar: {
        position: "fixed",
        right: "2em",
        bottom: "7em",
        zIndex: 2000,
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    rootAlert: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
    backdrop: {
        // zIndex: theme.zIndex.drawer + 999999,
        color: "#fff",
    },
    pos: {
        marginBottom: 12,
    },
    heading: {
        width: "100%",
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    rootList: {
        width: "100%!important",
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: "inline",
        width: "100%",
    },
    appBar: {
        position: "fixed",
    },
    appContainer: {
        paddingTop: "6em",
        backgroundColor: "#f4f4f4",
        // height: "100%",
        // minHeight: "-webkit-fill-available",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    list: {
        width: 250,
    },
    fullList: {
        width: "auto",
    },
}))

export default function Index() {
    const autorizacao = localStorage.getItem("chaveSistema")
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    )
    const classes = useStyles()
    const theme = useTheme()
    const history = useHistory()

    const {
        openCadastroCliente,
        setOpenCadastroCliente,
        visualizarPedido,
        setVisualizarPedido,
        cadastrarUsuario,
    } = useContext(RegistradorContext)

    const { enqueueSnackbar } = useSnackbar()

    const { configuracoes } = useContext(ServicosContext)

    const alertStart = (msg, tipo) => {
        enqueueSnackbar(msg, { variant: tipo })
    }

    return visualizarPedido?.id ? (
        <Dialog
            open={visualizarPedido?.id ? true : false}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setVisualizarPedido({})}
            // style={{"zIndex": "999999"}}
        >
            <form onSubmit={cadastrarUsuario}>
                <DialogTitle>{`Pedido #${visualizarPedido.id} - Mesa ${visualizarPedido.mesa}`}</DialogTitle>
                <DialogContent>
                    <Row>
                        <Col xs={12} md={12} lg={12} id="textoHTML">
                            {textoHTML(visualizarPedido?.textoImpressao)}
                        </Col>
                    </Row>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setVisualizarPedido({})
                        }}
                    >
                        Fechar
                    </Button>
                    <Button
                        onClick={() => {
                            // setModalInformacoes(false)
                            mandarImprimir(visualizarPedido?.textoImpressao, 1)
                        }}
                        style={{ color: "#28a745" }}
                    >
                        Imprimir
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    ) : (
        <React.Fragment></React.Fragment>
    )
}
