import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
} from "@material-ui/core"
import { ArrowForwardIos } from "@material-ui/icons"
import { useState } from "react"

const DialogAlertas = props => {
    const [step, setStep] = useState(0)

    const handleClose = () => props.setAbrirAlertas(false)
    const handleAnterior = () => setStep(prev => prev - 1)
    const handleProximo = () => setStep(prev => prev + 1)

    return (
        <Dialog open={props.abrirAlertas} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>
                <IconButton className="setaVoltarModal" onClick={handleClose}>
                    <ArrowForwardIos />
                </IconButton>
                Avisos
            </DialogTitle>
            <DialogContent style={{ minHeight: "12rem" }}>
                <div dangerouslySetInnerHTML={{ __html: props.alertas[step] }} />
            </DialogContent>
            {props.alertas.length > 1 ? (
                <DialogActions>
                    <Button onClick={handleAnterior} disabled={!step}>
                        Anterior
                    </Button>
                    <Button onClick={handleProximo} disabled={step === props.alertas.length - 1}>
                        Próximo
                    </Button>
                </DialogActions>
            ) : null}
        </Dialog>
    )
}

export default DialogAlertas
