import Button from "@material-ui/core/Button"
import Collapse from "@material-ui/core/Collapse"
import IconButton from "@material-ui/core/IconButton"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import CloseIcon from "@material-ui/icons/Close"
import AlertFixo from "@material-ui/lab/Alert"
import { useEffect, useState } from "react"
import { iniciouLive } from "../../services/functionsGerais"

export default function Live({ live, setLive }) {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const [open, setOpen] = useState(false)

    useEffect(() => {
        window.setTimeout(function () {
            if (!window.getCookie(live.tag)) {
                setOpen(true)
            }
        }, 1000)
    }, [])

    return (
        <Collapse
            in={open}
            style={{
                position: "fixed",
                bottom: "0",
                zIndex: "1399",
                width: "100%",
            }}
        >
            <AlertFixo
                severity={live.exibicao}
                action={
                    <>
                        <Button
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={() => {
                                setOpen(false)
                                window.open(live.url, "_blank")
                                window.setCookie(live.tag, "true", 1)
                                window.setTimeout(function () {
                                    setLive(null)
                                }, 1000)
                            }}
                        >
                            {iniciouLive(live)}
                        </Button>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            style={{ marginLeft: "0.5em" }}
                            onClick={() => {
                                setOpen(false)
                                window.setCookie(live.tag, "true", 1)
                                window.setTimeout(function () {
                                    setLive(null)
                                }, 1000)
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    </>
                }
            >
                <b style={{ textTransform: "uppercase" }}>{live.tipo}:</b>{" "}
                {!mobile ? live.descricao : live.titulo}
            </AlertFixo>
        </Collapse>
    )
}
