import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { mandarImprimir } from "../../../services/functionsGerais"

const DetalhesPedido = ({ pedidoSelecionado, handleClose, Transition, close, useStyles }) => {
    const classes = useStyles()

    return (
        <Dialog
            open={!close && pedidoSelecionado.mesa}
            onClose={handleClose}
            style={{ zIndex: "999999" }}
            TransitionComponent={Transition}
        >
            <DialogTitle>{`Pedido #${pedidoSelecionado.id} - Mesa ${pedidoSelecionado.mesa}`}</DialogTitle>
            <DialogContent id="textoHTML">
                <Skeleton height={140} />
                <Skeleton height={40} />
                <Skeleton height={80} />
                <Skeleton height={120} />
                <Skeleton height={40} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Fechar</Button>
                <Button
                    onClick={() => {
                        mandarImprimir(pedidoSelecionado?.textoImpressao, 1)
                        handleClose()
                    }}
                    className={classes.botaoImprimir}
                >
                    Imprimir
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DetalhesPedido
