class ClienteFielRepresentante {
    getIdentifier() {
        return "clientefiel.app"
    }

    getUrlSistemaAdministrativo() {
        return "https://sistema.appclientefiel.com.br"
    }

    isClienteFiel() {
        return true
    }
}

const classe = new ClienteFielRepresentante()
export default classe
