//INIT
import { useContext, useEffect, useState } from "react"
import { useDrop } from "react-dnd"

import ServicosContext from "../../../contexts/servicosContext"

import MotorcycleIcon from "@material-ui/icons/Motorcycle"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"

import CircularProgress from "@material-ui/core/CircularProgress"
import List from "@material-ui/core/List"
import AlertFixo from "@material-ui/lab/Alert"
import { totalArray } from "../../../pages/RecebedorDePedidos/functions"

import {
    Avatar,
    Button,
    Card,
    CardHeader,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    IconButton,
    Paper,
    Radio,
    RadioGroup,
    Typography,
} from "@material-ui/core"
import { Add, Delete, Remove } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"
import { useSnackbar } from "notistack"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import LoadingContext from "../../../contexts/loadingContext"
import useRecebedor from "../../../contexts/RecebedorContext"
import { RegistrarLogErro } from "../../../services/clientefiel"
import {
    EditarPedidoMesa,
    ExcluirItemDoPedidoMesa,
    listarMotoqueirosEstabelecimento,
    selecionarMotoqueiroPedido,
} from "../../../services/integracao"
import RingAudio from "../audios/ring.mp3"
import PedidoCard from "../cards/PedidoCard"

const useStyles = makeStyles({
    lista_container: { marginTop: "10px" },
    lista: { padding: "0.5rem" },
    lista_nenhumPedido: { margin: "0.5rem 0" },
    botoesAvancar_container: {
        display: "inline-flex",
        float: "right",
        gap: "8px",
        "& *": {
            padding: "0px",
        },
    },
    lista_cabecalho: {
        padding: "0.75rem 1rem",
        color: "white",
        background: props => {
            switch (props.indexLista) {
                case 1:
                    return "#f0bb1f"
                case 2:
                    return "#fd7e14"
                case 3:
                    return "#0080ff"
                case 4:
                    return "#51a351"
                default:
                    return "#fff"
            }
        },
    },
    lista_cabecalho_avatar: {
        backgroundColor: "white",
        color: props => {
            switch (props.indexLista) {
                case 1:
                    return "#f0bb1f"
                case 2:
                    return "#fd7e14"
                case 3:
                    return "#0080ff"
                case 4:
                    return "#51a351"
                default:
                    return "#fff"
            }
        },
    },
    lista_cabecalho_action: {
        margin: 0,
        alignSelf: "center",
    },
    lista_checkbox_todos: {
        padding: "0px",
        margin: "2px 8px 0 8px",
        float: "right",
    },
    container_expandButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& > button": {
            color: "black",
        },
    },
    circuloProgresso: { display: "block", margin: "auto" },
})

const DialogMotoqueiros = props => {
    const [motoqueiros, setMotoqueiros] = useState([])
    const [motoqueiroSelecionado, setMotoqueiroSelecionado] = useState(0)
    const [loadingMotoqueiro, setLoadingMotoqueiro] = useState(false)

    const [isPedidoIfood, setIsPedidoIfood] = useState(false)

    const { configuracoes } = useContext(ServicosContext)

    const classes = useStyles()

    const Authorization = localStorage.getItem("chaveSistema")

    useEffect(() => {
        if (props.abrirDialogMotoqueiros) procurarMotoqueiros()
        else if (props.listaPedidosSelecionados.length === 1) props.setListaPedidosSelecionados([])
    }, [props.abrirDialogMotoqueiros])

    const atualizarSelecaoMotoqueiro = async motoqueiroSelecionado => {
        try {
            setLoadingMotoqueiro(true)

            props.listaPedidosSelecionados.forEach(async idPedido => {
                const pedido = props.listaPedidos.find(
                    pedidoNaLista => pedidoNaLista.id === idPedido
                )

                const isPedidoDelivery = pedido?.type === "0"
                if (!isPedidoDelivery) return

                const pedidoAtualizado = await selecionarMotoqueiroPedido(
                    idPedido,
                    motoqueiroSelecionado,
                    Authorization
                )

                // Atualiza nome de entregador instantaneamente
                // Não foi possível usar state para evitar queda de performance
                const elementNomeEntregador = document.getElementById(
                    `entregador-pedido-${idPedido}`
                )

                if (elementNomeEntregador)
                    elementNomeEntregador.textContent =
                        "Entregador: " + pedidoAtualizado.nomeEntregador

                // Necessário para que ao tentar mudar de entregador nesse mesmo pedido, o entregador esteja atualizado
                pedido.nomeEntregador = pedidoAtualizado.nomeEntregador

                if (configuracoes.user.parametros?.atualizarEtapaAoAssociarEntregador) {
                    props.prontoEntrega(pedido)
                }
            })

            props.setAbrirDialogMotoqueiros(false)
        } catch (error) {
            console.error(error)
            RegistrarLogErro(error, "atualizarSelecaoMotoqueiro", {
                listaPedidosSelecionados: props.listaPedidosSelecionados,
                motoqueiroSelecionado,
            })
        }
    }

    const selecionarMotoqueiro = e => {
        setMotoqueiroSelecionado(Number(e.target.value))
        atualizarSelecaoMotoqueiro(Number(e.target.value))
    }

    const procurarMotoqueiros = async () => {
        try {
            setLoadingMotoqueiro(true)

            const listaMotoqueiros = window.getCookie("listaMotoqueiros")

            const response = listaMotoqueiros
                ? JSON.parse(listaMotoqueiros)
                : await listarMotoqueirosEstabelecimento(Authorization)

            window.setCookie("listaMotoqueiros", JSON.stringify(response), 0.01)

            if (response.codErro > 0) {
                props.alertStart(response.mensagem, "error")
                console.error(response)
                RegistrarLogErro(response, "procurarMotoqueiros", {})
                return
            }

            setLoadingMotoqueiro(false)
            setMotoqueiros(response)

            const nomeEntregadorSelecionado =
                props.listaPedidosSelecionados.length === 1
                    ? props.listaPedidos.find(p => p.id === props.listaPedidosSelecionados[0])
                          ?.nomeEntregador
                    : null

            if (nomeEntregadorSelecionado)
                setMotoqueiroSelecionado(
                    response.find(m => m.nome === nomeEntregadorSelecionado)?.identifier
                )
            else setMotoqueiroSelecionado(0)

            // Checa se algum dos pedidos selecionados é iFood
            const temPedidoIFood =
                props.listaPedidos.findIndex(
                    p =>
                        p.plataforma === "ifood_integracao" &&
                        props.listaPedidosSelecionados.includes(p.id)
                ) !== -1
            if (temPedidoIFood) setIsPedidoIfood(true)
            else setIsPedidoIfood(false)
        } catch (error) {
            console.error(error)
            RegistrarLogErro(error, "procurarMotoqueiros", {})
        }
    }

    return (
        <Dialog
            open={props.abrirDialogMotoqueiros}
            keepMounted
            onClose={() => props.setAbrirDialogMotoqueiros(false)}
        >
            <DialogTitle>Entregadores Cadastrados</DialogTitle>
            <DialogContent>
                {isPedidoIfood ? (
                    <AlertFixo severity="warning" style={{ width: "100%", marginBottom: "1em" }}>
                        Esse é um pedido <b>IFOOD</b>, selecione apenas se realmente você for
                        entregar com motoqueiro próprio.
                    </AlertFixo>
                ) : null}
                {loadingMotoqueiro ? (
                    <CircularProgress className={classes.circuloProgresso} color="inherit" />
                ) : motoqueiros.length > 0 ? (
                    <FormControl component="fieldset">
                        <RadioGroup value={motoqueiroSelecionado} onChange={selecionarMotoqueiro}>
                            {motoqueiros.map((motoqueiro, index) => (
                                <FormControlLabel
                                    key={index}
                                    value={motoqueiro.identifier}
                                    control={<Radio />}
                                    label={motoqueiro.nome + " - " + motoqueiro.telefone}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                ) : (
                    <Typography>Nenhum motoqueiro encontrado.</Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.setAbrirDialogMotoqueiros(false)}>Voltar</Button>
            </DialogActions>
        </Dialog>
    )
}

const QuantidadePedidos = props => {
    const classes = useStyles({ indexLista: props.indexLista })

    const textoQuantidadePedidos = ("0" + props.quantidadePedidos).slice(
        -Math.max(props.quantidadePedidos.toString().length, 2)
    )

    return (
        <Avatar className={classes.lista_cabecalho_avatar}>
            {props.loading ? (
                <CircularProgress color="inherit" size="1.25rem" />
            ) : (
                <Typography>{textoQuantidadePedidos}</Typography>
            )}
        </Avatar>
    )
}

const TextoCabecalhoLista = ({ indexLista }) => {
    const { configuracoes } = useContext(ServicosContext)

    const tituloLista = (() => {
        switch (indexLista) {
            case 1:
                if (configuracoes.recebedor?.aceitarPedidoAutomatico) {
                    return "Aceito"
                } else {
                    return "Aguardando Aceitação"
                }
            case 2:
                if (configuracoes.recebedor?.aceitarPedidoAutomatico) {
                    return "Em Preparação"
                } else {
                    return "Aceito"
                }
            case 3:
                return "Em Entrega"
            case 4:
                return "Finalizado"
            default:
                return ""
        }
    })()

    return <Typography>{tituloLista}</Typography>
}

const RingAudioPlayer = ({ indexLista, prepararPedidoAutomatico }) => {
    if (indexLista !== 1 && !prepararPedidoAutomatico) return null
    if (indexLista !== 2 && prepararPedidoAutomatico) return null

    const loop = !prepararPedidoAutomatico

    return (
        <audio
            src={RingAudio}
            style={{ width: "100%" }}
            loop={loop}
            id="audiopedido"
            controls="controls"
        />
    )
}

const BotaoAvancar = ({
    indexLista,
    listaPedidosSelecionados,
    setListaPedidosSelecionados,
    setAbrirDialogMotoqueiros,
    arrayPedidos,
    mudarStatus,
}) => {
    const { configuracoes } = useContext(ServicosContext)
    const integradoComUber = configuracoes.user?.parametros?.possuiIntegracaoUber

    const classes = useStyles()

    if (indexLista === 4) return null

    return (
        <div className={classes.botoesAvancar_container}>
            {indexLista !== 3 && !integradoComUber && (
                <IconButton
                    onClick={e => {
                        e.stopPropagation()
                        setAbrirDialogMotoqueiros(true)
                    }}
                >
                    <MotorcycleIcon />
                </IconButton>
            )}
            <IconButton
                style={{ padding: "0px", backgroundColor: "white" }}
                onClick={e => {
                    e.stopPropagation()

                    arrayPedidos.forEach((pedido, index) => {
                        if (listaPedidosSelecionados.includes(pedido.id)) {
                            mudarStatus(pedido, index)
                        }
                    })

                    setListaPedidosSelecionados([])
                }}
            >
                <NavigateNextIcon
                    className="pedido-next-varios"
                    data-quantidade={listaPedidosSelecionados.length.toString()}
                />
            </IconButton>
        </div>
    )
}

const MoverVarios = props => {
    const { configuracoes } = useContext(ServicosContext)
    const classes = useStyles()

    const mostrarBotaoAvancar = props.listaPedidosSelecionados.length

    const valorTotalFormatado = Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
    }).format(totalArray(1, props.arrayPedidos))

    return (
        <span>
            {props.arrayPedidos.length > 0 && (props.indexLista === 2 || props.indexLista === 3) ? (
                <Checkbox
                    checked={mostrarBotaoAvancar}
                    onChange={e => {
                        e.persist()
                        if (mostrarBotaoAvancar) props.setListaPedidosSelecionados([])
                        else
                            props.arrayPedidos.forEach(pedido =>
                                props.adicionarPedidoSelecionado(pedido)
                            )
                    }}
                    className={classes.lista_checkbox_todos}
                    size="small"
                />
            ) : null}
            {mostrarBotaoAvancar ? (
                <BotaoAvancar
                    autorizacao={props.autorizacao}
                    indexLista={props.indexLista}
                    listaPedidosSelecionados={props.listaPedidosSelecionados}
                    setListaPedidosSelecionados={props.setListaPedidosSelecionados}
                    setAbrirDialogMotoqueiros={props.setAbrirDialogMotoqueiros}
                    mudarStatusPara={props.mudarStatusPara}
                    mudarStatus={props.mudarStatus}
                    arrayPedidos={props.arrayPedidos}
                    tabStatus={props.tabStatus}
                    integradoComUber={props.user?.parametros?.possuiIntegracaoUber}
                />
            ) : null}
            {!props.loading &&
            props.indexLista === 4 &&
            configuracoes?.user?.perfil !== "DELIVERY_BASICO" ? (
                <span className="totalP">{valorTotalFormatado}</span>
            ) : null}
        </span>
    )
}

const CabecalhoLista = props => {
    const classes = useStyles({ indexLista: props.indexLista })

    return (
        <CardHeader
            className={classes.lista_cabecalho}
            disableTypography
            avatar={
                <QuantidadePedidos
                    quantidadePedidos={props.arrayPedidos.length}
                    loading={props.loading}
                    indexLista={props.indexLista}
                />
            }
            title={<TextoCabecalhoLista indexLista={props.indexLista} />}
            action={
                <MoverVarios
                    arrayPedidos={props.arrayPedidos}
                    adicionarPedidoSelecionado={props.adicionarPedidoSelecionado}
                    setAbrirDialogMotoqueiros={props.setAbrirDialogMotoqueiros}
                    indexLista={props.indexLista}
                    autorizacao={props.autorizacao}
                    listaPedidosSelecionados={props.listaPedidosSelecionados}
                    setListaPedidosSelecionados={props.setListaPedidosSelecionados}
                    mudarStatusPara={props.mudarStatusPara}
                    mudarStatus={props.mudarStatus}
                    tabStatus={props.tabStatus}
                    integradoComUber={props.user?.parametros?.possuiIntegracaoUber}
                />
            }
            classes={{ action: classes.lista_cabecalho_action }}
        />
    )
}

export default function Lista(props) {
    const { configuracoes } = useContext(ServicosContext)
    const { atualizar } = useRecebedor()

    const {
        loading,
        array,
        autorizacao,
        solicitarEntregaFuiDelivery,
        buscarStatus,
        impressorasQZ,
        mandarImprimir,
        setModalInformacoes,
        setModalInformacoesPedido,
        setModalInformacoesPedidoIndex,
        tabStatus,
        mudarStatusPara,
        indexLista,
        tipoPainelImpressao,
        imprimirNotaFiscal,
        efeitoSonoro,
        prontoEntrega,
        agrupamentos,
    } = props

    const classes = useStyles({ indexLista: props.indexLista })

    const [abrirDialogMotoqueiros, setAbrirDialogMotoqueiros] = useState(false)
    const [listaPedidosSelecionados, setListaPedidosSelecionados] = useState([])

    const { setLoading } = useContext(LoadingContext)

    const adicionarPedidoSelecionado = pedido =>
        setListaPedidosSelecionados(prev => {
            const index = prev.findIndex(p => p === pedido.id)

            if (index === -1) prev.push(pedido.id)

            return [...prev]
        })

    const removerPedidoSelecionado = pedido =>
        setListaPedidosSelecionados(prev => {
            const index = prev.findIndex(p => p === pedido.id)

            if (index !== -1) prev.splice(index, 1)

            return [...prev]
        })

    const [expandList, setExpandList] = useState(!(indexLista === 4))
    const handleExpand = () => setExpandList(p => !p)
    const listaPedidos =
        indexLista === 4 && !expandList && configuracoes.recebedor.limitePedidosFinalizados !== 0
            ? array.slice(0, configuracoes.recebedor.limitePedidosFinalizados ?? 10)
            : array

    const listaStatusPrincipal = (indexLista, pedido) => {
        if (indexLista === 1) {
            return 0
        } else if (indexLista === 2) {
            return 7
        } else if (indexLista === 3) {
            // if(pedido.deliveryAddress?.streetName?.includes("Buscar no local")){
            if (pedido.type === "0") {
                //delivery
                return 3 //saiu para entrega
            } else {
                // mesa ou retirada
                return 6 //Pronto P/ Retirada
            }
        } else if (indexLista === 4) {
            return 4
        } else {
            return -1
        }
    }

    const NenhumPedidoTexto = () => {
        const nenhumPedidoTexto = (() => {
            switch (indexLista) {
                case 1:
                    if (configuracoes.recebedor.aceitarPedidoAutomatico) {
                        return "Nenhum Pedido Aceito"
                    } else {
                        return "Nenhum Pedido Aguardando Aceitação"
                    }
                case 2:
                    if (configuracoes.recebedor.aceitarPedidoAutomatico) {
                        return "Nenhum Pedido Em Preparação"
                    } else {
                        return "Nenhum Pedido Aceito"
                    }
                case 3:
                    return "Nenhum Pedido Em Entrega"
                case 4:
                    return "Nenhum Pedido Finalizado"
                default:
                    return ""
            }
        })()

        return (
            <Typography variant="body2" align="center" className={classes.lista_nenhumPedido}>
                {nenhumPedidoTexto}
            </Typography>
        )
    }

    const [, dropRef] = useDrop({
        accept: "CARD",
        drop(props, monitor, component) {
            const indexListaNova = indexLista

            if (props.indexListaAtual == indexListaNova) {
                return
            }

            mudarStatusPara(
                listaStatusPrincipal(indexListaNova, props.pedido),
                autorizacao,
                props.pedido,
                props.indexPedido
            )

            // Evento drag-and-drop GTM
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({ event: "pedido-next-dnd" })
        },
    })

    const proximoStatus = (pedido, indexLista) => {
        switch (indexLista) {
            case 2:
                if (pedido.type == "0") {
                    return 3
                } else {
                    return 6
                }
            case 3:
                return 4
            default:
                return null
        }
    }

    const mudarStatus = (pedido, indexPedido) => {
        mudarStatusPara(proximoStatus(pedido, indexLista), autorizacao, pedido, indexPedido)
    }

    const [pedidoEditar, setPedidoEditar] = useState(null)

    const { enqueueSnackbar } = useSnackbar()
    const alertStart = (msg, tipo) => {
        enqueueSnackbar(msg, { variant: tipo })
    }

    const [openPedido, setOpenPedido] = useState(false)
    const handleTogglePedido = () => {
        setOpenPedido(prev => !prev)
    }

    const [dialogExcluir, setDialogExcluir] = useState(false)
    const handleToggleDialogExcluir = () => {
        setDialogExcluir(prev => !prev)
    }

    const [itemExcluir, setItemExcluir] = useState(null)
    const [indexExcluir, setIndexExcluir] = useState(null)

    const handleExcluirItemDoPedidoMesa = async (itemId, pedidoEditar) => {
        try {
            setLoading(true)
            const response = await ExcluirItemDoPedidoMesa(itemId, pedidoEditar, autorizacao)
            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
                return false
            } else {
                setPedidoEditar(prev => {
                    const pedidoAtualizado = { ...prev }
                    pedidoAtualizado.itens = pedidoAtualizado.itens.filter(
                        item => item.id !== response.id
                    )
                    return pedidoAtualizado
                })
                alertStart("Pedido excluído com sucesso", "success")
            }
        } catch (error) {
            RegistrarLogErro(error, "excluirItemDoPedidoMesa")
            alertStart(`Procure os desenvolvedores! Erro: ${error.message}`, "error")
        } finally {
            setLoading(false)
        }
    }

    const handleEditarPedidoMesa = async (pedido, item) => {
        const data = {
            id: item.id,
            produto: { id: item.produto.id },
            valorProdutoHistorico:
                item.valorProdutoHistorico - item.valorProdutoHistorico / item.quantidade,
            quantidade: item.quantidade - 1,
        }
        try {
            setLoading(true)
            const response = await EditarPedidoMesa(pedido.id, data, autorizacao)
            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
                return false
            } else {
                setPedidoEditar(prev => {
                    const pedidoAtualizado = { ...prev }
                    pedidoAtualizado.itens = pedidoAtualizado.itens.map((item, index) =>
                        index === indexExcluir ? { ...item, quantidade: item.quantidade - 1 } : item
                    )
                    return pedidoAtualizado
                })
                alertStart("Pedido excluído com sucesso", "success")
            }
        } catch (error) {
            RegistrarLogErro(error, "editarPedidoMesa")
            alertStart(`Procure os desenvolvedores! Erro: ${error.message}`, "error")
        } finally {
            setLoading(false)
        }
    }

    const handleClickExcluir = item => {
        if (item.quantidade >= 2) {
            handleEditarPedidoMesa(pedidoEditar, item)
            return
        } else if (pedidoEditar?.items?.length === 1) {
            throw new Error(
                "Não é permitido excluir um produto de um pedido que possui apenas um item. A operação recomendada é excluir o pedido."
            )
        } else {
            handleExcluirItemDoPedidoMesa(item.id, pedidoEditar)
            return
        }
    }

    const history = useHistory()

    return (
        <>
            <Dialog open={dialogExcluir} onClose={handleToggleDialogExcluir}>
                <DialogTitle>Confirmação de exclusão</DialogTitle>
                <DialogContent>
                    <Typography>
                        Essa operação é irreversível, tem certeza que deseja excluir esse item?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        style={{ color: "white", backgroundColor: "#dc3545" }}
                        onClick={handleToggleDialogExcluir}
                    >
                        Descartar
                    </Button>
                    <Button
                        style={{ color: "white", backgroundColor: "#28a745" }}
                        onClick={() => {
                            handleClickExcluir(itemExcluir)
                            handleToggleDialogExcluir()
                        }}
                    >
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openPedido}
                onClose={() => {
                    handleTogglePedido()
                    atualizar()
                }}
                fullWidth
            >
                <DialogTitle>Pedido</DialogTitle>
                <DialogContent>
                    {pedidoEditar?.itens?.map((pedido, index) => {
                        if (pedido.quantidade > 1) {
                            const elements = []
                            for (let i = 0; i < pedido.quantidade; i++) {
                                elements.push(
                                    <Paper
                                        key={`${pedido.produto?.id}-${i}`}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            paddingLeft: "1em",
                                            marginBottom: "0.5em",
                                        }}
                                    >
                                        {pedido?.produto?.nome}
                                        <IconButton
                                            onClick={() => {
                                                setItemExcluir(pedido)
                                                setIndexExcluir(index)
                                                handleToggleDialogExcluir()
                                            }}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </Paper>
                                )
                            }
                            return elements
                        } else {
                            return (
                                <Paper
                                    key={pedido.produto?.id}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        paddingLeft: "1em",
                                        marginBottom: "0.5em",
                                    }}
                                >
                                    {pedido?.produto?.nome}
                                    <IconButton
                                        onClick={() => {
                                            setItemExcluir(pedido)
                                            setIndexExcluir(index)
                                            handleToggleDialogExcluir()
                                        }}
                                    >
                                        <Delete />
                                    </IconButton>
                                </Paper>
                            )
                        }
                    })}
                </DialogContent>
                <DialogActions>
                    <div style={{ display: "flex", gap: "0.5em" }}>
                        <Button
                            style={{ backgroundColor: "#28a745", color: "white" }}
                            onClick={() => {
                                history.push(
                                    "/servicos/modulo/registrador-de-pedidos?idPedido=" +
                                        pedidoEditar.id
                                )
                            }}
                        >
                            Adicionar Item
                        </Button>
                        <Button
                            onClick={() => {
                                handleTogglePedido()
                                atualizar()
                            }}
                            style={{ backgroundColor: "#dc3545", color: "white" }}
                        >
                            Fechar
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
            <Card ref={dropRef} className={classes.lista_container}>
                <CabecalhoLista
                    arrayPedidos={array}
                    autorizacao={autorizacao}
                    indexLista={indexLista}
                    loading={loading}
                    listaPedidosSelecionados={listaPedidosSelecionados}
                    setListaPedidosSelecionados={setListaPedidosSelecionados}
                    adicionarPedidoSelecionado={adicionarPedidoSelecionado}
                    setAbrirDialogMotoqueiros={setAbrirDialogMotoqueiros}
                    mudarStatusPara={mudarStatusPara}
                    mudarStatus={mudarStatus}
                    tabStatus={tabStatus}
                />
                <List className={classes.lista}>
                    <RingAudioPlayer
                        indexLista={indexLista}
                        prepararPedidoAutomatico={configuracoes.recebedor.prepararPedidoAutomatico}
                    />
                    {loading ? (
                        <CircularProgress color="inherit" />
                    ) : array.length === 0 ? (
                        <NenhumPedidoTexto />
                    ) : (
                        <>
                            {listaPedidos.map((pedido, index) => (
                                <PedidoCard
                                    tipoPainelImpressao={tipoPainelImpressao}
                                    key={index}
                                    indexLista={indexLista}
                                    pedido={pedido}
                                    buscarStatus={buscarStatus}
                                    indexPedido={index}
                                    solicitarEntregaFuiDelivery={solicitarEntregaFuiDelivery}
                                    autorizacao={autorizacao}
                                    mudarStatusPara={mudarStatusPara}
                                    mudarStatus={mudarStatus}
                                    mandarImprimir={mandarImprimir}
                                    impressorasQZ={impressorasQZ}
                                    tabStatus={tabStatus}
                                    setModalInformacoes={setModalInformacoes}
                                    setModalInformacoesPedidoIndex={setModalInformacoesPedidoIndex}
                                    setModalInformacoesPedido={setModalInformacoesPedido}
                                    listaPedidosSelecionados={listaPedidosSelecionados}
                                    setListaPedidosSelecionados={setListaPedidosSelecionados}
                                    adicionarPedidoSelecionado={adicionarPedidoSelecionado}
                                    removerPedidoSelecionado={removerPedidoSelecionado}
                                    setAbrirDialogMotoqueiros={setAbrirDialogMotoqueiros}
                                    useStyles={useStyles}
                                    efeitoSonoro={efeitoSonoro}
                                    imprimirNotaFiscal={imprimirNotaFiscal}
                                    agrupamentos={agrupamentos}
                                    handleTogglePedido={handleTogglePedido}
                                    setPedidoEditar={setPedidoEditar}
                                />
                            ))}
                            {configuracoes.recebedor.limitePedidosFinalizados !== 0 &&
                            indexLista === 4 &&
                            array.length >
                                (configuracoes.recebedor.limitePedidosFinalizados ?? 10) ? (
                                <div className={classes.container_expandButton}>
                                    <IconButton onClick={handleExpand}>
                                        {expandList ? (
                                            <Remove fontSize="large" />
                                        ) : (
                                            <Add fontSize="large" />
                                        )}
                                    </IconButton>
                                </div>
                            ) : null}
                        </>
                    )}
                </List>
            </Card>
            <DialogMotoqueiros
                listaPedidos={listaPedidos}
                abrirDialogMotoqueiros={abrirDialogMotoqueiros}
                setAbrirDialogMotoqueiros={setAbrirDialogMotoqueiros}
                listaPedidosSelecionados={listaPedidosSelecionados}
                setListaPedidosSelecionados={setListaPedidosSelecionados}
                prontoEntrega={prontoEntrega}
            />
        </>
    )
}
