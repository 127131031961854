import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    List,
    ListItem,
    makeStyles,
    MenuItem,
    Select,
    TextField,
} from "@material-ui/core"
import { useState } from "react"
import Categorias from "./Categorias"
import DialogCategorias from "./DialogCategorias"
import DialogDuplicarGrupoDeOpcao from "./DialogDuplicarGrupoDeOpcao"
import DialogPassoDeMontagem from "./DialogPassoDeMontagem"
import DialogProdutos from "./DialogProdutos"

const useStyles = makeStyles(() => ({
    container: {
        width: "calc(100vw - 17px)",
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
    },
    cabecalho: {
        position: "fixed",
        top: "63px",
        padding: "14px 33px 14px 33px",
        backgroundColor: "#f4f4f4",
        zIndex: 2,
    },
    botaoCadastrar: {
        width: "100%",
        backgroundColor: "red",
        color: "white",
    },
    botaoSalvar: {
        backgroundColor: "#14A44D",
        color: "white",
        '&:hover': {
          backgroundColor: "#28B76C", 
        },
      },
    containerItens: {
        flexGrow: 1,
        padding: "0px 16px",
    },
    listItem: {
        paddingLeft: "0",
        paddingRight: "0",
    },
}))

export default function NovaTelaProdutos({
    cardapio,
    estabelecimento,
    handleSalvarProduto,
    handleSalvarCategoria,
    cadastrar,
    setCadastrar,
    categorias,
    setCategorias,
    handleDuplicarCategoria,
    handleDuplicarProduto,
    handleDuplicarPassoDeMontagem,
    handleExcluirCategoria,
    handleExcluirProduto,
    handleCadastrarGrupoDeOpcao,
    handleExcluirGrupoDeOpcao,
    handleCadastrarOpcao,
    handleExcluirOpcao,
    handleSalvarCardapioInteiro,
    handleIdsEditar,
    handleCadastrarImagem,
}) {
    const classes = useStyles()

    const [categoriaAtual, setCategoriaAtual] = useState("")
    const [produtoAtual, setProdutoAtual] = useState("")

    const [valorPesquisa, setValorPesquisa] = useState("")

    const [openCategoria, setOpenCategoria] = useState(false)
    const handleClickOpenCategoria = () => {
        setOpenCategoria(true)
    }

    const [openProduto, setOpenproduto] = useState(false)
    const handleCloseProduto = () => {
        setOpenproduto(false)
    }
    const [openPassoDeMontagem, setOpenPassoDeMontagem] = useState(false)

    const [modalCategoria, setModalCategoria] = useState(null)
    const handleCloseCategoria = () => {
        setOpenCategoria(false)
    }

    const [idPasso, setIdPasso] = useState(null)
    const [openDuplicarGrupoDeMontagem, setOpenDuplicarGrupoDeMontagem] = useState(false)
    const handleToggleDuplicarGrupoDeMontagem = data => {
        setIdPasso(data)
        setOpenDuplicarGrupoDeMontagem(prev => !prev)
    }

    const [modalProduto, setModalProduto] = useState(null)
    const [modalPasso, setModalPasso] = useState(null)

    const handleChangeCategoria = e => {
        if (!e.target.value) {
            setCategorias(cardapio.categorias)
        } else {
            setCategorias(
                cardapio.categorias.filter(cat => cat.exibicaoCategoriaEnum === e.target.value)
            )
        }
    }

    const procurarProduto = e => {
        setValorPesquisa(e.target.value.toLowerCase())
        setCategorias(
            cardapio.categorias.filter(busca =>
                busca.nome.toLowerCase().includes(e.target.value.toLowerCase())
            )
        )
    }

    // const [draggingItem, setDraggingItem] = useState(null)

    // const handleDragOver = index => {
    //     if (draggingItem === null) return
    //     if (draggingItem === index) return
    //     const itens = categorias.filter((_, idx) => idx !== draggingItem)
    //     itens.splice(index, 0, categorias[draggingItem])
    //     itens.forEach((item, idx) => {
    //         item.ordemExib = idx
    //     })
    //     setCategorias(itens)
    //     setDraggingItem(index)
    // }

    return (
        <>
            <DialogCategorias
                open={openCategoria}
                cadastrar={cadastrar}
                modalCategoria={modalCategoria}
                estabelecimento={estabelecimento}
                handleCloseCategoria={handleCloseCategoria}
                handleSalvarCategoria={handleSalvarCategoria}
            />
            <DialogProdutos
                open={openProduto}
                modalProduto={modalProduto}
                cadastrar={cadastrar}
                handleCloseProduto={handleCloseProduto}
                handleSalvarProduto={handleSalvarProduto}
                categorias={categorias}
                categoriaAtual={categoriaAtual}
                handleCadastrarImagem={handleCadastrarImagem}
                estabelecimento={estabelecimento}
            />
            <DialogPassoDeMontagem
                open={openPassoDeMontagem}
                setOpenPassoDeMontagem={setOpenPassoDeMontagem}
                modalPasso={modalPasso}
                cadastrar={cadastrar}
                handleCadastrarGrupoDeOpcao={handleCadastrarGrupoDeOpcao}
                produtoAtual={produtoAtual}
            />
            <DialogDuplicarGrupoDeOpcao
                open={openDuplicarGrupoDeMontagem}
                handleToggleDuplicarGrupoDeMontagem={handleToggleDuplicarGrupoDeMontagem}
                categorias={categorias}
                handleDuplicarPassoDeMontagem={handleDuplicarPassoDeMontagem}
                idPasso={idPasso}
            />
            <Grid className={classes.container}>
                <Grid container>
                    <Grid
                        item
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        className={classes.cabecalho}
                    >
                        <Grid item container xs={12} sm={12} md={4} spacing={1}>
                            <Grid item container xs={12} sm={12} md={6}>
                                <Button
                                    className={classes.botaoCadastrar}
                                    onClick={() => {
                                        handleClickOpenCategoria()
                                        setCadastrar(true)
                                        setModalCategoria(null)
                                    }}
                                >
                                    Cadastrar Categoria
                                </Button>
                            </Grid>
                            <Grid item container xs={12} sm={12} md={6}>
                                <FormControl fullWidth size="small" variant="outlined">
                                    <InputLabel>Tipo Categoria</InputLabel>
                                    <Select
                                        label="Tipo Categoria"
                                        defaultValue={null}
                                        onChange={handleChangeCategoria}
                                        style={{ backgroundColor: "#fff" }}
                                    >
                                        <MenuItem value={null}>Todos</MenuItem>
                                        <MenuItem value="DELIVERY">Delivery</MenuItem>
                                        <MenuItem value="PEDIDO_MESA">Mesa</MenuItem>
                                        <MenuItem value="REGISTRADOR">Estabelecimento</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            sm={12}
                            md={6}
                            alignItems="center"
                            justifyContent="flex-end"
                            spacing={1}
                        >
                            <Grid item>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    label="Procurar Categoria"
                                    placeholder="Buscar"
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                    onChange={procurarProduto}
                                    value={valorPesquisa}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={() => {
                                        handleSalvarCardapioInteiro()
                                    }}
                                    className={classes.botaoSalvar}
                                >
                                    Salvar
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.containerItens}>
                    <List fullWidth>
                        {categorias?.map((categoria, index) => (
                            <ListItem
                                className={classes.listItem}
                                key={categoria.id}
                                // onDragOver={() => handleDragOver(index)}
                            >
                                <Categorias
                                    setOpenCategoria={setOpenCategoria}
                                    categoria={categoria}
                                    cardapio={cardapio}
                                    setOpenproduto={setOpenproduto}
                                    setCadastrar={setCadastrar}
                                    setModalCategoria={setModalCategoria}
                                    setModalProduto={setModalProduto}
                                    setModalPasso={setModalPasso}
                                    setOpenPassoDeMontagem={setOpenPassoDeMontagem}
                                    // setDraggingItem={setDraggingItem}
                                    setCategoriaAtual={setCategoriaAtual}
                                    setProdutoAtual={setProdutoAtual}
                                    handleSalvarProduto={handleSalvarProduto}
                                    handleDuplicarCategoria={handleDuplicarCategoria}
                                    handleDuplicarProduto={handleDuplicarProduto}
                                    handleDuplicarPassoDeMontagem={handleDuplicarPassoDeMontagem}
                                    handleExcluirCategoria={handleExcluirCategoria}
                                    handleExcluirProduto={handleExcluirProduto}
                                    handleCadastrarGrupoDeOpcao={handleCadastrarGrupoDeOpcao}
                                    handleExcluirGrupoDeOpcao={handleExcluirGrupoDeOpcao}
                                    handleCadastrarOpcao={handleCadastrarOpcao}
                                    handleExcluirOpcao={handleExcluirOpcao}
                                    handleIdsEditar={handleIdsEditar}
                                    handleToggleDuplicarGrupoDeMontagem={
                                        handleToggleDuplicarGrupoDeMontagem
                                    }
                                    handleCadastrarImagem={handleCadastrarImagem}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            </Grid>
        </>
    )
}
