import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@material-ui/core"
import { useEffect, useRef, useState } from "react"

const useStyles = makeStyles(() => ({
    botaoExcluir: {
        backgroundColor: "#dc3545",
        color: "white",
    },
    botaoSalvar: {
        backgroundColor: "#28a745",
        color: "white",
    },
}))

export default function DialogPassoDeMontagem({
    open,
    setOpenPassoDeMontagem,
    modalPasso,
    cadastrar,
    handleCadastrarGrupoDeOpcao,
    produtoAtual,
}) {
    const handleClosePassoDeMontagem = () => {
        setOpenPassoDeMontagem(false)
    }

    const id = modalPasso ? modalPasso?.id : null
    const versao = modalPasso ? modalPasso?.versao : null

    const ordemExibRef = useRef(modalPasso?.ordemExib)
    const nomeRef = useRef(modalPasso?.nome)
    const qtdMinRef = useRef(modalPasso?.quantidadeMinima)
    const qtdMaxRef = useRef(modalPasso?.quantidadeMaxima)
    const ativoRef = useRef(modalPasso?.ativo)
    const considerarMaiorValorRef = useRef(modalPasso?.considerarMaiorValor)

    const [diaSemana, setDiaSemana] = useState(null)
    const handleChangeDiaSemana = e => {
        setDiaSemana(e.target.value)
    }
    const [adicional, setAdicional] = useState(1)
    const handleChangeAdicional = e => {
        setAdicional(e.target.value)
    }

    useEffect(() => {
        if (!cadastrar) {
            setDiaSemana(modalPasso?.diaSemana)
            setAdicional(modalPasso?.adicional)
        }
    }, [modalPasso])

    const classes = useStyles()

    return (
        <>
            {open && (
                <Dialog open={open} onClose={handleClosePassoDeMontagem} maxWidth="md">
                    <DialogTitle id="alert-dialog-title">
                        {cadastrar ? "Cadastrar Passo de Montagem" : "Editar Passo de Montagem"}
                    </DialogTitle>
                    <form
                        onSubmit={e => {
                            e.preventDefault()
                            const data = {
                                id,
                                versao,

                                nome: nomeRef?.current?.value,
                                ordemExib: ordemExibRef?.current?.value,
                                quantidadeMinima: qtdMinRef?.current?.value,
                                quantidadeMaxima: qtdMaxRef?.current?.value,
                                ativo: ativoRef?.current?.checked,
                                considerarMaiorValor: considerarMaiorValorRef?.current?.checked,

                                diaSemana,
                                adicional,
                            }

                            handleCadastrarGrupoDeOpcao(produtoAtual.id, data)
                            handleClosePassoDeMontagem()
                        }}
                    >
                        <DialogContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={4} md={2}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Ordem"
                                        InputLabelProps={{ shrink: true }}
                                        placeholder="000"
                                        inputRef={ordemExibRef}
                                        defaultValue={modalPasso?.ordemExib ?? 0}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={8} md={10}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Nome"
                                        InputLabelProps={{ shrink: true }}
                                        placeholder="Ex: Escolha o Adicional"
                                        inputRef={nomeRef}
                                        defaultValue={modalPasso?.nome}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Quantidade Mínima"
                                        InputLabelProps={{ shrink: true }}
                                        placeholder="000"
                                        inputRef={qtdMinRef}
                                        defaultValue={modalPasso?.quantidadeMinima}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Quantidade Máxima"
                                        InputLabelProps={{ shrink: true }}
                                        placeholder="000"
                                        inputRef={qtdMaxRef}
                                        defaultValue={modalPasso?.quantidadeMaxima}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel>Dia da Semana</InputLabel>
                                        <Select
                                            label="Dia da Semana"
                                            value={diaSemana}
                                            onChange={handleChangeDiaSemana}
                                        >
                                            <MenuItem value={"DOMINGO"}>Domingo</MenuItem>
                                            <MenuItem value={"SEGUNDA"}>Segunda</MenuItem>
                                            <MenuItem value={"TERCA"}>Terça</MenuItem>
                                            <MenuItem value={"QUARTA"}>Quarta</MenuItem>
                                            <MenuItem value={"QUINTA"}>Quinta</MenuItem>
                                            <MenuItem value={"SEXTA"}>Sexta</MenuItem>
                                            <MenuItem value={"SABADO"}>Sabado</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel>Grupo de Adicionais</InputLabel>
                                        <Select
                                            label="Grupo de Adicionais"
                                            value={adicional}
                                            onChange={handleChangeAdicional}
                                        >
                                            <MenuItem value={0}>0 - Definidor de Preço</MenuItem>
                                            <MenuItem value={1}>1 - Adiconal Optativo</MenuItem>
                                            <MenuItem value={2}>
                                                2 - Adicional Quantitativo
                                            </MenuItem>
                                            <MenuItem value={3}>3 - Adicional Digitável</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} container alignItems="center">
                                    <Checkbox
                                        inputRef={ativoRef}
                                        defaultChecked={cadastrar ? true : modalPasso?.ativo}
                                    />
                                    <Typography>Disponível</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} container alignItems="center">
                                    <Checkbox
                                        inputRef={considerarMaiorValorRef}
                                        defaultChecked={
                                            cadastrar ? false : modalPasso?.considerarMaiorValor
                                        }
                                    />
                                    <Typography>Considerar Maior Valor</Typography>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                className={classes.botaoExcluir}
                                onClick={handleClosePassoDeMontagem}
                            >
                                Voltar
                            </Button>
                            <Button type="submit" className={classes.botaoSalvar}>
                                Salvar
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            )}
        </>
    )
}
