import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    IconButton,
    List,
    ListItem,
    makeStyles,
    Menu,
    MenuItem,
    Typography,
} from "@material-ui/core"
import { Add, ExpandMore, MoreVert } from "@material-ui/icons"
import { useSnackbar } from "notistack"
import { useState } from "react"
import ItemAdicional from "./ItemAdicional"

const ITEM_HEIGHT = 48

const useStyles = makeStyles({
    container: {
        width: "100%",
    },
    lista: {
        width: "100%",
        "& > *:nth-child(even)": {
            backgroundColor: "rgba(255,0,255,0.04)",
        },
    },
    cadastrarItem: {
        height: "50px",
        display: "flex",
        justifyContent: "space-between",
    },
    botaoSalvar: {
        backgroundColor: "#14A44D",
        color: "white",
    },
    // dragIndicator: {
    //     cursor: "move",
    // },
})

export default function Passo({
    opcao,
    setCadastrar,
    setModalPasso,
    setOpenPassoDeMontagem,
    setDraggingOpcoes,
    setProdutoAtual,
    conteudo,
    handleExcluirGrupoDeOpcao,
    handleDuplicarPassoDeMontagem,
    handleCadastrarOpcao,
    handleExcluirOpcao,
    handleIdsEditar,
    handleToggleDuplicarGrupoDeMontagem,
    handleCadastrarImagem,
}) {
    const { enqueueSnackbar } = useSnackbar()
    const alertStart = (msg, tipo) => {
        enqueueSnackbar(msg, { variant: tipo })
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const openMoreItemDeMontagem = Boolean(anchorEl)

    const handleClickMoreItemDeMontagem = e => {
        setAnchorEl(e.currentTarget)
        e.stopPropagation()
    }
    const handleCloseMoreItemDeMontagem = e => {
        setAnchorEl(null)
        e.stopPropagation()
    }

    const classes = useStyles()

    const handleClickPassoDeMontagem = () => {
        setOpenPassoDeMontagem(true)
    }

    // const handleDragStart = index => {
    //     setDraggingOpcoes(index)
    // }
    // const handleDragEnd = () => {
    //     setDraggingOpcoes(null)
    // }

    const [expanded, setExpanded] = useState(false)

    const handleExpansion = () => {
        setExpanded(prevExpanded => !prevExpanded)
    }

    const [sortedAdicionais, setSortedAdicioanis] = useState(opcao?.opcoes)

    // const [draggingAdicionais, setDraggingAdicionais] = useState(null)
    // const handleDragOver = index => {
    //     if (draggingAdicionais === null) return
    //     if (draggingAdicionais === index) return
    //     const itens = [...adicionais]
    //     const [removed] = itens.splice(draggingAdicionais, 1)
    //     itens.splice(index, 0, removed)
    //     itens.forEach((item, idx) => {
    //         if (item) {
    //             item.ordemExib = idx
    //         }
    //     })
    //     setDraggingAdicionais(index)
    //     setAdicionais(itens)
    // }

    const handleClickAdicionarItem = () => {
        handleCadastrarOpcao(conteudo?.id, opcao?.id)
    }

    return (
        <Accordion
            className={classes.container}
            expanded={expanded}
            onChange={handleExpansion}
            // draggable={!expanded}
            // onDragStart={e => handleDragStart(index)}
            // onDragEnd={handleDragEnd}
        >
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Grid container alignItems="center">
                    {/* <IconButton>
                        <DragIndicator fontSize="small" className={classes.dragIndicator} />
                    </IconButton> */}
                    <Typography>{opcao?.nome}</Typography>
                </Grid>
                <IconButton onClick={handleClickMoreItemDeMontagem}>
                    <MoreVert fontSize="small" />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={openMoreItemDeMontagem}
                    onClose={handleCloseMoreItemDeMontagem}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: "20ch",
                        },
                    }}
                >
                    <MenuItem
                        onClick={e => {
                            e.stopPropagation()
                            handleClickPassoDeMontagem()
                            setCadastrar(false)
                            setModalPasso(opcao)
                            setProdutoAtual(conteudo)
                        }}
                    >
                        Editar Passo
                    </MenuItem>
                    <MenuItem
                        onClick={e => {
                            e.stopPropagation()
                            handleToggleDuplicarGrupoDeMontagem(opcao.id)
                        }}
                    >
                        Duplicar Passo
                    </MenuItem>
                    <MenuItem
                        onClick={e => {
                            e.stopPropagation()
                            handleExcluirGrupoDeOpcao(conteudo?.id, opcao?.id)
                        }}
                    >
                        Excluir Passo
                    </MenuItem>
                </Menu>
            </AccordionSummary>
            <AccordionDetails>
                <List className={classes.lista}>
                    <ListItem divider className={classes.cadastrarItem}>
                        <Typography>Cadastrar Item</Typography>
                        <Grid>
                            <IconButton onClick={handleClickAdicionarItem}>
                                <Add />
                            </IconButton>
                        </Grid>
                    </ListItem>
                    {expanded &&
                        opcao?.opcoes
                            ?.sort((a, b) => a.ordemExib - b.ordemExib)
                            .map((item, index) => (
                                <ListItem
                                    divider
                                    // onDragOver={() => handleDragOver(index)}
                                >
                                    <ItemAdicional
                                        produto={conteudo}
                                        passo={opcao}
                                        item={item}
                                        // setDraggingAdicionais={setDraggingAdicionais}
                                        index={index}
                                        handleExcluirOpcao={handleExcluirOpcao}
                                        handleIdsEditar={handleIdsEditar}
                                        handleCadastrarImagem={handleCadastrarImagem}
                                    />
                                </ListItem>
                            ))}
                </List>
            </AccordionDetails>
        </Accordion>
    )
}
