import { removeAcento } from "../../services/functionsGerais"

import { RegistrarLogErro } from "../../services/clientefiel"

export function carrinhoNovo() {
    const data = {
        entregaImediata: false,
        formaPagamento: {},
        itens: [],
        taxaEntrega: 0,
        tokenCartaoCredito: null,
        troco: 0,
        valorDesconto: 0,
        valorEntrega: 0,
        valorTotal: 0,
        token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
    }
    return Object.assign({}, data)
}

export function enderecoClienteNaLoja() {
    const date = {
        cep: "",
        uf: "",
        cidade: "a",
        bairro: "a",
        numero: "b",
        complemento: "c",
        referencia: "",
        taxaEntrega: 0,
        bairroEspecifico: 0,
        id: 0,
        ativo: true,
        logradouro: "Cliente na Mesa",
        token: "a~I?;;+_@!AL^",
    }
    return Object.assign({}, date)
}

export function enderecoRetiradaNoLocal() {
    const date = {
        cep: "",
        uf: "",
        cidade: "a",
        bairro: "a",
        numero: "b",
        complemento: "c",
        referencia: "",
        taxaEntrega: 0,
        bairroEspecifico: 0,
        id: -1,
        ativo: true,
        logradouro: "Buscar no local",
        token: "a~I?;;+_@!AL^",
    }
    return Object.assign({}, date)
}

export function calcularValorAPartirDe(prod, valorPromocional = false) {
    try {
        const produto = Object.assign({}, prod)
        if (valorPromocional) {
            produto.valor = produto.valorDe
        }
        var valorProdutoHistorico = produto.valor ? produto.valor : 0.0
        produto.opcoes.forEach(grupo => {
            if (grupo.quantidadeMinima > 0) {
                const verificarMaiorValor =
                    grupo.opcoes.length > 0 ? grupo.opcoes[0].considerarMaiorValor : false
                var maiorValorDoGrupo = 0

                const verificarMediaValor =
                    grupo.opcoes.length > 1 ? grupo.opcoes[0].considerarMedia : false
                var valorTotalDasOpcoes = 0
                var quantidadeDeOpcoes = 0

                grupo.opcoes.sort(function (a, b) {
                    if (a.valor > b.valor) {
                        return 1
                    }
                    if (a.valor < b.valor) {
                        return -1
                    }
                    return 0
                })

                var qtd = 0
                grupo.opcoes.forEach(opcao => {
                    if (qtd < grupo.quantidadeMinima) {
                        qtd++

                        let quantidadeSelecionada =
                            opcao.adicional === 2 ? grupo.quantidadeMinima : 1
                        if (quantidadeSelecionada > 1) {
                            qtd = grupo.quantidadeMinima
                        }

                        if (verificarMaiorValor && opcao.valor > maiorValorDoGrupo) {
                            maiorValorDoGrupo = opcao.valor
                        } else if (verificarMediaValor && valorTotalDasOpcoes >= 0) {
                            valorTotalDasOpcoes += opcao.valor * quantidadeSelecionada
                            quantidadeDeOpcoes += quantidadeSelecionada
                        }

                        if (opcao.adicional === 0 && opcao.quantidade === 1 && opcao.valor > 0) {
                            valorProdutoHistorico =
                                valorProdutoHistorico - produto.valor + opcao.valor
                        } else if (!verificarMaiorValor && !verificarMediaValor) {
                            valorProdutoHistorico += opcao.valor * quantidadeSelecionada
                        }
                    }
                })

                if (verificarMaiorValor) {
                    valorProdutoHistorico += maiorValorDoGrupo
                } else if (verificarMediaValor && valorTotalDasOpcoes >= 0) {
                    valorProdutoHistorico += valorTotalDasOpcoes / quantidadeDeOpcoes
                }
            } else if (grupo.adicional === 0) {
                valorProdutoHistorico += grupo.opcoes[0].valor
            }
        })
        return valorProdutoHistorico
    } catch (error) {
        RegistrarLogErro(error, "calcularValorAPartirDe")
    }
    return prod.valor
}

export function valorTotalDaMesa(array) {
    let i = 0
    try {
        for (let index = 0; index < array?.length; index++) {
            i += array[index]?.valorTotal
        }
    } catch (error) {
        RegistrarLogErro(error, "valorTotalDaMesa")
    }
    return i
}

// export function qtdMesasAbertas(array){
//   let i = 0
//   try {
//     for (let index = 0; index < array.length; index++) {
//       if(array[index]?.length) i++
//     }
//   } catch (error) {
//     RegistrarLogErro(error, 'qtdMesasAbertas')
//   }
//   return i
// }

export function procurarCategoria(e) {
    try {
        console.log("||> procurarCategoria", e)
        const valorPesqusa = e.target.value
        const categorias = document.getElementsByClassName("CardCategoriaCol")
        for (let j = 0; j < categorias.length; j++) {
            const categoria = categorias[j]
            if (valorPesqusa === -1) {
                categoria.style.display = ""
            } else {
                if (categoria.id === "primerioC" + valorPesqusa) {
                    categoria.style.display = ""
                } else {
                    categoria.style.display = "none"
                }
            }
        }
    } catch (error) {
        RegistrarLogErro(error, "procurarCategoria")
    }
}

export function procurarProdutoPromocional() {
    try {
        console.log("||> procurarProdutoPromocional")
        const categorias = document.getElementsByClassName("CardCategoriaCol")
        for (let j = 0; j < categorias.length; j++) {
            const produtos = categorias[j].getElementsByClassName("CardProdutoCol")
            var qtd = 0

            for (let i = 0; i < produtos.length; i++) {
                if (document.getElementById("produtosPromocionais").checked) {
                    if (produtos[i].innerHTML.includes("CardProdutoColPromocional")) {
                        produtos[i].style.display = ""
                        qtd++
                    } else {
                        produtos[i].style.display = "none"
                    }
                } else {
                    produtos[i].style.display = ""
                }
            }

            if (!document.getElementById("produtosPromocionais").checked) {
                categorias[j].style.display = ""
            } else if (qtd > 0) {
                categorias[j].style.display = ""
            } else {
                categorias[j].style.display = "none"
            }
        }
    } catch (error) {
        RegistrarLogErro(error, "procurarProdutoPromocional")
    }
}

function compararBusca(texto, busca, idProduto) {
    const textoSemAcento = removeAcento(texto).toLocaleLowerCase()

    const produtoSelecionado = document.querySelector(`[data-id-produto='${idProduto}']`)

    if (!produtoSelecionado) return false

    if (textoSemAcento.includes(busca)) {
        produtoSelecionado.style.display = ""
        return true
    } else {
        produtoSelecionado.style.display = "none"
        return false
    }
}

export function procurarProduto(textoBusca, cardapio, permitirBuscaPorAdicionais) {
    const buscaSemAcento = removeAcento(textoBusca).toLocaleLowerCase()

    try {
        for (let i = 0; i < cardapio.categorias.length; ++i) {
            let categoriaIncluida = false

            for (let j = 0; j < cardapio.categorias[i].produtos.length; ++j) {
                // PRODUTO:
                const produto = cardapio.categorias[i].produtos[j]
                let textoComDados = cardapio.categorias[i].nome.concat(produto.nome)

                if (produto.descricao) textoComDados = textoComDados.concat(produto.descricao)

                if (compararBusca(textoComDados, buscaSemAcento, produto.id)) {
                    categoriaIncluida = true
                    continue
                }

                if (permitirBuscaPorAdicionais && produto.opcoes.length) {
                    for (let k = 0; k < produto.opcoes.length; ++k) {
                        // ADICIONAL
                        const adicional = produto.opcoes[k]
                        textoComDados = textoComDados.concat(adicional.nome)

                        if (compararBusca(textoComDados, buscaSemAcento, produto.id)) {
                            categoriaIncluida = true
                            continue
                        }

                        for (let l = 0; l < adicional.opcoes.length; ++l) {
                            // OPÇÃO DO ADICIONAL
                            textoComDados = textoComDados.concat(adicional.opcoes[l].nome)
                        }
                    }

                    if (compararBusca(textoComDados, buscaSemAcento, produto.id)) {
                        categoriaIncluida = true
                    }
                }
            }

            const categoriaSelecionada = document.querySelector(
                `[data-id-categoria='${cardapio.categorias[i].id}']`
            )

            if (!categoriaSelecionada) continue

            if (categoriaIncluida) {
                categoriaSelecionada.style.display = ""
            } else {
                categoriaSelecionada.style.display = "none"
            }
        }
    } catch (error) {
        console.error("procurarProduto =>", error)
        RegistrarLogErro(error, "procurarProduto")
    }
}

export function radioButton(add, index, produtoSelecionado) {
    const novoProduto = Object.assign({}, produtoSelecionado)

    try {
        novoProduto.opcoes.forEach((grupo, indexGrupo) => {
            if (index === indexGrupo) {
                grupo.opcoes.forEach((opcao, indexGrupo) => {
                    if (opcao.id === add.id) {
                        opcao.quantidade = 1
                        grupo.quantidade = 1
                    } else if (opcao.quantidade) {
                        opcao.quantidade = 0
                    }
                })
            }
        })
    } catch (error) {
        RegistrarLogErro(error, "radioButton")
    }

    return novoProduto
}

export function quantidadeButton(add, index, acao, produtoSelecionado) {
    console.log("||> selecionarQuantidadeButton")
    const novoProduto = Object.assign({}, produtoSelecionado)
    try {
        const grupo = novoProduto.opcoes.filter((grupo, indexGrupo) => indexGrupo === index)[0]
        console.log("grupo", grupo)
        const opcao = grupo.opcoes.filter(adicinal => adicinal.id === add.id)[0]
        console.log("opcao", opcao)

        if (!grupo.quantidade) grupo.quantidade = 0
        if (!grupo.quantidade) opcao.quantidade = 0

        if (
            acao === "add" &&
            (grupo.quantidadeMaxima == null || grupo.quantidade < grupo.quantidadeMaxima)
        ) {
            if (!opcao.quantidade) {
                opcao.quantidade = 1
                grupo.quantidade++
            } else if (opcao.quantidade) {
                opcao.quantidade++
                grupo.quantidade++
            }
        } else if (acao === "remove") {
            if (!opcao.quantidade) {
                opcao.quantidade = 0
            } else if (opcao.quantidade) {
                opcao.quantidade--
                grupo.quantidade--
            }
        }
    } catch (error) {
        console.error(error)
        RegistrarLogErro(error, "quantidadeButton")
    }
    return novoProduto
}

export function checkButton(opcaoAdd, grupoDeOpcoesSelecionado, produtoSelecionado, indexGrupo) {
    const novoProduto = Object.assign({}, produtoSelecionado)

    try {
        const grupoSelecionado = novoProduto.opcoes[indexGrupo]

        grupoSelecionado.opcoes.forEach(opcao => {
            if (opcao.id === opcaoAdd.id) {
                if (!opcao.quantidade) {
                    if (
                        (grupoSelecionado.quantidade ?? 0) < grupoSelecionado.quantidadeMaxima ||
                        !grupoSelecionado.quantidadeMaxima
                    ) {
                        opcao.quantidade = 1
                        grupoSelecionado.quantidade = grupoSelecionado.quantidade ?? 0
                        grupoSelecionado.quantidade = grupoSelecionado.quantidade + 1
                    }
                } else {
                    opcao.quantidade = 0
                    grupoSelecionado.quantidade--
                }
            }
        })
    } catch (error) {
        console.error(error)
        RegistrarLogErro(error, "checkButton")
    }

    return novoProduto
}

export const calcularValorProduto = produtoSelecionado => {
    const produtoSele = JSON.parse(JSON.stringify(produtoSelecionado))
    console.log("produtoSele", produtoSele)

    //deletar valores pre calculados no cardapio
    delete produtoSele.valorDeCalculado
    delete produtoSele.valorRealCalculado

    const produtoProCarrinho = {
        id: null,
        produto: produtoSele,
        quantidade: produtoSelecionado.quantidade,
        valorProdutoHistorico: produtoSele.valor,
        itensOpcaoProduto: [],
        observacao: produtoSelecionado.permiteObservacao ? produtoSelecionado.observacao : "",
        token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
    }

    produtoProCarrinho.produto.opcoes.forEach(grupo => {
        //considerar maior  valor - prioridade 1
        const verificarMaiorValor =
            grupo.opcoes.length > 0 ? grupo.opcoes[0].considerarMaiorValor : false
        var maiorValorDoGrupo = 0

        //considerar media dos valores - prioridade 2
        const verificarMediaValor =
            grupo.opcoes.length > 1 ? grupo.opcoes[0].considerarMedia : false
        var valorTotalDasOpcoes = 0
        var quantidadeDeOpcoes = 0

        grupo.opcoes.forEach(opcao => {
            if (opcao.quantidade > 0) {
                const temp = {
                    id: null,
                    opcaoProduto: {},
                    valorHistorico: 0,
                    quantidade: 0,
                    token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
                }

                temp.opcaoProduto = opcao
                temp.quantidade = opcao.quantidade
                temp.valorHistorico = opcao.valor * opcao.quantidade

                console.log("valorHistorico", temp.valorHistorico)

                if (verificarMaiorValor && opcao.valor > maiorValorDoGrupo) {
                    maiorValorDoGrupo = opcao.valor
                } else if (verificarMediaValor) {
                    valorTotalDasOpcoes += temp.valorHistorico
                    quantidadeDeOpcoes += opcao.quantidade
                }

                if (opcao.adicional === 0 && opcao.quantidade === 1 && opcao.valor > 0) {
                    produtoProCarrinho.valorProdutoHistorico =
                        produtoProCarrinho.valorProdutoHistorico - produtoSele.valor + opcao.valor
                } else if (!verificarMaiorValor && !verificarMediaValor) {
                    produtoProCarrinho.valorProdutoHistorico += temp.valorHistorico
                }
            }
        })

        if (verificarMaiorValor) {
            produtoProCarrinho.valorProdutoHistorico += maiorValorDoGrupo
        } else if (verificarMediaValor && valorTotalDasOpcoes > 0) {
            produtoProCarrinho.valorProdutoHistorico += valorTotalDasOpcoes / quantidadeDeOpcoes
        }
    })

    produtoProCarrinho.valorProdutoHistorico *= produtoProCarrinho.quantidade
    console.log("produtoProCarrinho", produtoProCarrinho)
    return produtoProCarrinho.valorProdutoHistorico
}

export function produtoProntoParaCarrinho(produto) {
    try {
        const produtoSelecionado = Object.assign({}, produto)

        delete produtoSelecionado.valorRealCalculado
        delete produtoSelecionado.valorDeCalculado
        delete produtoSelecionado.quantidade

        const novoProdutoCarrinho = {
            id: null,
            produto: produtoSelecionado,
            quantidade: produto.quantidade,
            valorProdutoHistorico: produtoSelecionado.valor,
            itensOpcaoProduto: [],
            observacao: produto.permiteObservacao
                ? document.getElementById(`observacaoPedido_${produtoSelecionado.id}`).value
                : "",
            token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
        }

        var etapa = 0
        novoProdutoCarrinho.produto.opcoes.forEach(grupo => {
            //considerar maior  valor - prioridade 1
            const verificarMaiorValor =
                grupo.opcoes.length > 0 ? grupo.opcoes[0].considerarMaiorValor : false
            var maiorValorDoGrupo = 0

            //considerar media dos valores - prioridade 2
            const verificarMediaValor =
                grupo.opcoes.length > 1 ? grupo.opcoes[0].considerarMedia : false
            var valorTotalDasOpcoes = 0
            var quantidadeDeOpcoes = 0
            var etapaAtualizada = false

            grupo.opcoes.forEach(opcao => {
                if (opcao.quantidade > 0) {
                    if (etapaAtualizada === false) {
                        etapaAtualizada = true
                        etapa += 1
                    }

                    const temp = {
                        id: null,
                        opcaoProduto: {},
                        valorHistorico: 0,
                        quantidade: 0,
                        etapa,
                        token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
                    }

                    temp.opcaoProduto = opcao
                    temp.quantidade = opcao.quantidade
                    temp.valorHistorico = opcao.valor * opcao.quantidade

                    if (verificarMaiorValor && opcao.valor > maiorValorDoGrupo) {
                        maiorValorDoGrupo = opcao.valor
                    } else if (verificarMediaValor) {
                        valorTotalDasOpcoes += temp.valorHistorico
                        quantidadeDeOpcoes += opcao.quantidade
                    }

                    if (opcao.adicional === 0 && opcao.quantidade === 1 && opcao.valor > 0) {
                        novoProdutoCarrinho.valorProdutoHistorico =
                            novoProdutoCarrinho.valorProdutoHistorico -
                            produtoSelecionado.valor +
                            opcao.valor
                    } else if (!verificarMaiorValor && !verificarMediaValor) {
                        novoProdutoCarrinho.valorProdutoHistorico += temp.valorHistorico
                    }

                    delete temp.opcaoProduto.quantidade
                    novoProdutoCarrinho.itensOpcaoProduto.push(temp)
                }
                delete opcao.quantidade
            })

            if (verificarMaiorValor) {
                novoProdutoCarrinho.valorProdutoHistorico += maiorValorDoGrupo
            } else if (verificarMediaValor && valorTotalDasOpcoes > 0) {
                novoProdutoCarrinho.valorProdutoHistorico +=
                    valorTotalDasOpcoes / quantidadeDeOpcoes
            }

            delete grupo.grupoExtra
            delete grupo.quantidade
        })
        novoProdutoCarrinho.valorProdutoHistorico *= novoProdutoCarrinho.quantidade

        return novoProdutoCarrinho
    } catch (error) {
        RegistrarLogErro(error, "produtoProntoParaCarrinho")
    }
}

export function calcularValorTotal({
    carrinho,
    cardapio,
    descontoAtivo,
    isDelivery,
    ativoMinimoEntregaGratis = false,
    valorDescontoManual = 0.0,
    descontoAtivoPagamento = false,
    taxaServico = 0,
}) {
    try {
        console.log("||> calcularValorTotal")
        var valorTotalItens = 0
        let valorProdutosPromocionais = 0

        //calculando valores dos items do carrinho
        carrinho.itens.forEach(item => {
            valorTotalItens += item.valorProdutoHistorico
            if (item.produto.promocional) {
                valorProdutosPromocionais += item.valorProdutoHistorico
            }
        })

        let taxaDeEntrega = isDelivery ? carrinho.end.taxaEntrega : 0
        if (
            taxaDeEntrega > 0 &&
            valorTotalItens > cardapio?.minimoEntregaGratis &&
            cardapio?.minimoEntregaGratis > 0 &&
            ativoMinimoEntregaGratis
        ) {
            // NAO FOR RETIRADA NO LOCAL e PEDIDO MESA
            taxaDeEntrega = 0
        }

        if (
            taxaDeEntrega > 0 &&
            descontoAtivo &&
            cardapio.informacao &&
            cardapio.informacao.includes("frete grátis")
        ) {
            taxaDeEntrega = 0
        }

        var valorFinalProdutos = 0

        if (
            cardapio?.percentualDesconto &&
            (cardapio?.valorDesconto === 0 || !cardapio?.valorDesconto)
        ) {
            //desconto percetual - apenas em produtos nao promocionais
            var descontoPercentual =
                ((valorTotalItens - valorProdutosPromocionais) * cardapio?.percentualDesconto) / 100

            //maximo que pode dar de desconto
            if (cardapio?.maximoDesconto && descontoPercentual > cardapio?.maximoDesconto) {
                descontoPercentual = cardapio?.maximoDesconto
            }

            if (descontoAtivo) {
                carrinho.valorDesconto = descontoPercentual
            } else {
                carrinho.valorDesconto = 0
                carrinho.backUpValorDesconto = descontoPercentual
            }

            valorFinalProdutos = valorTotalItens - carrinho.valorDesconto
        } else {
            //desconto fixo - apenas em produtos nao promocionais
            var descontoFixo = carrinho.valorDesconto
                ? carrinho.valorDesconto
                : cardapio?.valorDesconto
            // maximo que pode dar de desconto
            if (cardapio?.maximoDesconto && descontoFixo > cardapio?.maximoDesconto) {
                descontoFixo = cardapio?.maximoDesconto
            }

            if (descontoAtivo) {
                carrinho.valorDesconto = descontoFixo
            } else {
                carrinho.valorDesconto = 0
                carrinho.backUpValorDesconto = descontoFixo
            }

            let produtosNaoPromocionais = valorTotalItens - valorProdutosPromocionais
            if (descontoAtivo) {
                produtosNaoPromocionais -= descontoFixo
            }

            valorFinalProdutos =
                valorProdutosPromocionais +
                (produtosNaoPromocionais > 0 ? produtosNaoPromocionais : 0)
        }

        if (valorFinalProdutos < 0) {
            carrinho.valorTotal = taxaDeEntrega
        } else {
            carrinho.valorTotal = valorFinalProdutos + taxaDeEntrega
        }

        if (carrinho?.formaPagamento && carrinho?.formaPagamento?.id) {
            const formaPagamentoSelecionado = carrinho.formaPagamento
            if (formaPagamentoSelecionado.desconto) {
                if (formaPagamentoSelecionado.desconto < 0) {
                    carrinho.descontoFormaPagamento =
                        (Math.abs(formaPagamentoSelecionado.desconto) / 100) *
                        carrinho.valorTotal *
                        -1
                } else {
                    carrinho.descontoFormaPagamento =
                        (formaPagamentoSelecionado.desconto / 100) * carrinho.valorTotal
                }
            } else {
                carrinho.descontoFormaPagamento = 0
            }
        }

        if (descontoAtivoPagamento) {
            carrinho.valorTotal -= carrinho.descontoFormaPagamento
        }

        if (valorDescontoManual) {
            carrinho.valorTotal -= valorDescontoManual
        }

        if (carrinho.valorTotal < 0) {
            carrinho.valorTotal = 0.0
        }

        carrinho.valorTotal = carrinho.valorTotal * (1 + taxaServico / 100)
        carrinho.taxaServico = taxaServico

        const parametros = JSON.parse(localStorage.getItem("custom-params") ?? "{}")
        const taxaAdicional = parametros.PUBLIC_TAXA_ADICIONAL

        if (taxaAdicional) {
            const taxa = Number(taxaAdicional)

            carrinho.valorTotal += taxa
            carrinho.taxaAdicional = taxa
        } else {
            carrinho.taxaAdicional = 0
        }

        return carrinho
    } catch (error) {
        console.error("error", error)
        RegistrarLogErro(error, "calcularValorTotal")
    }
}
