import { Button, ButtonGroup } from "@material-ui/core"

function DisponibilidadeSwitch({
    produto,
    disponivel,
    handleChangeDisponibilidadeProduto,
    handleChangeDisponibilidadeOpcao,
}) {
    return (
        <ButtonGroup>
            <Button
                variant={disponivel ? "contained" : "outlined"}
                style={{
                    backgroundColor: disponivel ? "#14A44D" : "white",
                    color: disponivel ? "white" : "black",
                    fontSize: "9px",
                }}
                onClick={
                    produto ? handleChangeDisponibilidadeProduto : handleChangeDisponibilidadeOpcao
                }
            >
                Disponível
            </Button>
            <Button
                variant={!disponivel ? "contained" : "outlined"}
                style={{
                    backgroundColor: !disponivel ? "#DC4C64" : "white",
                    color: !disponivel ? "white" : "black",
                    fontSize: "9px",
                }}
                onClick={
                    produto ? handleChangeDisponibilidadeProduto : handleChangeDisponibilidadeOpcao
                }
            >
                Indisponível
            </Button>
        </ButtonGroup>
    )
}

export default DisponibilidadeSwitch
