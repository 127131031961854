import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    IconButton,
    List,
    ListItem,
    makeStyles,
    Menu,
    MenuItem,
    Typography,
} from "@material-ui/core"
import { Add, ExpandMore, MoreVert } from "@material-ui/icons"
import { useState } from "react"
import ItemProduto from "./ItemProduto"

const ITEM_HEIGHT = 48

const useStyles = makeStyles(() => ({
    container: {
        width: "100%",
    },
    accordion: {
        width: "100%",
    },
    cadastrarProduto: {
        height: "50px",
        display: "flex",
        justifyContent: "center",
    },
    lista: {
        width: "100%",
    },
    // dragIndicator: {
    //     cursor: "move",
    // },
}))

export default function Categorias({
    categoria,
    setOpenproduto,
    setOpenCategoria,
    setCadastrar,
    setModalCategoria,
    setModalProduto,
    setModalPasso,
    setOpenPassoDeMontagem,
    setDraggingItem,
    setCategoriaAtual,
    handleSalvarProduto,
    setProdutoAtual,
    handleDuplicarCategoria,
    handleDuplicarProduto,
    handleDuplicarPassoDeMontagem,
    handleExcluirCategoria,
    handleExcluirProduto,
    handleExcluirGrupoDeOpcao,
    handleCadastrarOpcao,
    handleExcluirOpcao,
    handleIdsEditar,
    handleToggleDuplicarGrupoDeMontagem,
    handleCadastrarImagem,
}) {
    const [anchorEl, setAnchorEl] = useState(null)
    const [openAcordion, setOpenAcordion] = useState(false)
    // const [draggingProduto, setDraggingProduto] = useState(null)

    const classes = useStyles()

    const handleAccordionToggle = () => {
        setCategoriaAtual(categoria)
        setOpenAcordion(prevOpen => !prevOpen)
    }

    // const handleDragStart = index => {
    //     setDraggingProduto(index)
    // }

    // const handleDragOver = index => {
    //     if (draggingProduto === null || draggingProduto === index) return

    //     const updatedProdutos = [...produtosExibicao]
    //     const [movedItem] = updatedProdutos.splice(draggingProduto, 1)
    //     updatedProdutos.splice(index, 0, movedItem)
    //     updatedProdutos.forEach((item, idx) => {
    //         item.ordemExib = idx
    //     })
    //     setProdutosExibicao(updatedProdutos)
    //     setDraggingProduto(index)
    // }

    // const handleDragEnd = () => {
    //     setDraggingProduto(null)
    //     Persistir a nova ordem dos produtos
    //     const updatedCategoria = {
    //        ...categoria,
    //        produtosExibicao,
    //     }
    //     handleSalvarCategoria(updatedCategoria)
    // }

    const handleClickMoreItemDeMontagem = e => {
        setAnchorEl(e.currentTarget)
        e.stopPropagation()
    }

    const handleCloseMoreItemDeMontagem = e => {
        setAnchorEl(null)
        e.stopPropagation()
    }

    return (
        <div className={classes.container}>
            <Accordion
                className={classes.accordion}
                expanded={openAcordion}
                onChange={handleAccordionToggle}
            >
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    // draggable={true}
                    // onDragStart={() => setDraggingItem(index)}
                    // onDragEnd={handleDragEnd}
                >
                    <Grid container alignItems="center">
                        {/* <IconButton>
                            <DragIndicator className={classes.dragIndicator} />
                        </IconButton> */}
                        <Typography>{categoria.nome}</Typography>
                    </Grid>
                    <IconButton onClick={handleClickMoreItemDeMontagem}>
                        <MoreVert fontSize="small" />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMoreItemDeMontagem}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: "20ch",
                            },
                        }}
                    >
                        <MenuItem
                            onClick={e => {
                                setOpenCategoria(true)
                                setCadastrar(false)
                                setModalCategoria(categoria)
                                e.stopPropagation()
                            }}
                        >
                            Editar Categoria
                        </MenuItem>
                        <MenuItem
                            onClick={e => {
                                e.stopPropagation()
                                setCadastrar(true)
                                handleDuplicarCategoria(categoria.id)
                            }}
                        >
                            Duplicar Categoria
                        </MenuItem>
                        <MenuItem
                            onClick={e => {
                                e.stopPropagation()
                                handleExcluirCategoria(categoria.id)
                            }}
                        >
                            Excluir Categoria
                        </MenuItem>
                    </Menu>
                </AccordionSummary>
                <AccordionDetails>
                    <List className={classes.lista} fullWidth>
                        <ListItem className={classes.cadastrarProduto}>
                            <Typography>Cadastrar Produto</Typography>
                            <IconButton aria-label="Add" size="small">
                                <Add
                                    fontSize="large"
                                    onClick={() => {
                                        setCategoriaAtual(categoria)
                                        setOpenproduto(true)
                                        setCadastrar(true)
                                        setModalProduto(null)
                                    }}
                                />
                            </IconButton>
                        </ListItem>
                        {categoria?.produtos
                            ?.slice()
                            .sort((a, b) => a.ordemExib - b.ordemExib)
                            .map((produto, index) => (
                                <ListItem
                                    key={produto.id}
                                    divider
                                    // draggable
                                    // onDragStart={() => handleDragStart(index)}
                                    // onDragOver={e => {
                                    //     e.preventDefault()
                                    //     handleDragOver(index)
                                    // }}
                                    // onDragEnd={handleDragEnd}
                                >
                                    <ItemProduto
                                        conteudo={produto}
                                        setOpenproduto={setOpenproduto}
                                        setModalProduto={setModalProduto}
                                        setCadastrar={setCadastrar}
                                        setModalPasso={setModalPasso}
                                        setOpenPassoDeMontagem={setOpenPassoDeMontagem}
                                        // setDraggingProduto={setDraggingProduto}
                                        categoria={categoria}
                                        setProdutoAtual={setProdutoAtual}
                                        handleSalvarProduto={handleSalvarProduto}
                                        handleDuplicarProduto={handleDuplicarProduto}
                                        handleDuplicarPassoDeMontagem={
                                            handleDuplicarPassoDeMontagem
                                        }
                                        handleExcluirProduto={handleExcluirProduto}
                                        handleExcluirGrupoDeOpcao={handleExcluirGrupoDeOpcao}
                                        handleCadastrarOpcao={handleCadastrarOpcao}
                                        handleExcluirOpcao={handleExcluirOpcao}
                                        handleIdsEditar={handleIdsEditar}
                                        handleToggleDuplicarGrupoDeMontagem={
                                            handleToggleDuplicarGrupoDeMontagem
                                        }
                                        handleCadastrarImagem={handleCadastrarImagem}
                                    />
                                </ListItem>
                            ))}
                    </List>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}
