import api from "./api"
import { RegistrarLogErro } from "./clientefiel"

const autorizacao = localStorage.getItem("chaveSistema")

function tratamentoResponse(response, chamada) {
    if (response.data.codErro > 0)
        return { retornoErro: response.data.codErro, mensagem: response.data.mensagem }
    else if (typeof response.data == "string" && response.data != "")
        return {
            retornoErro: 1,
            mensagem: chamada + " - Erro inesperado, entre em contato com os reponsáveis.",
        }
    else return response.data
}

const ErroRequisicao = function (data, aplicativoDados) {
    try {
        console.log("ErroRequisicao> ", data.request, data.message)
        if (data.message == "Network Error") {
            return "Verifique sua conexão!"
            // return 'Sem Internet ou sistema fora do ar!'
        } else if (data.message) {
            return "Erro: " + data.message
        }
        return false
    } catch (error) {
        RegistrarLogErro(error, "ErroRequisicao", data)
    }
}

//Cadastrar
export const cadastrarCategoria = async (idCardapio, data) => {
    try {
        const response = await api
            .post(`cardapio/categoria/cadastro/` + idCardapio, data, {
                headers: { "Content-Type": "application/json", Authorization: autorizacao },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("cadastrarCategoria  => ", response)

        return tratamentoResponse(response, "cadastrarCategoria")
    } catch (error) {
        RegistrarLogErro(error, "cadastrarCategoria", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const cadastrarProduto = async (idCategoria, data) => {
    try {
        const response = await api
            .post("cardapio/produto/cadastro/" + idCategoria, data, {
                headers: { "Content-Type": "application/json", Authorization: autorizacao },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })
        console.log("cadastrarProduto  => ", response)

        return tratamentoResponse(response, "cadastrarProduto")
    } catch (error) {
        RegistrarLogErro(error, "cadastrarProduto", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const cadastrarGrupoDeOpcao = async (idProduto, data) => {
    try {
        const response = await api
            .post("cardapio/grupoopcao/cadastro/" + idProduto, data, {
                headers: { "Content-Type": "application/json", Authorization: autorizacao },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })
        console.log("cadastrarGrupoDeOpcao  => ", response)

        return tratamentoResponse(response, "cadastrarGrupoDeOpcao")
    } catch (error) {
        RegistrarLogErro(error, "cadastrarGrupoDeOpcao", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const cadastrarOpcao = async (idGrupoOpcao, data) => {
    try {
        const response = await api
            .post("cardapio/opcao/cadastro/" + idGrupoOpcao, data, {
                headers: { "Content-Type": "application/json", Authorization: autorizacao },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })
        console.log("cadastrarOpcao  => ", response)

        return tratamentoResponse(response, "cadastrarOpcao")
    } catch (error) {
        RegistrarLogErro(error, "cadastrarOpcao", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

//Duplicar
export const duplicarCategoria = async data => {
    try {
        const response = await api
            .post("cardapio/categoria/duplicar", data, {
                headers: { "Content-Type": "application/json", Authorization: autorizacao },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("duplicarCategoria  => ", response)

        return tratamentoResponse(response, "duplicarCategoria")
    } catch (error) {
        RegistrarLogErro(error, "duplicarCategoria", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const duplicarProduto = async data => {
    try {
        const response = await api
            .post("cardapio/produto/duplicar", data, {
                headers: { "Content-Type": "application/json", Authorization: autorizacao },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("duplicarProduto  => ", response)

        return tratamentoResponse(response, "duplicarProduto")
    } catch (error) {
        RegistrarLogErro(error, "duplicarProduto", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const duplicarGrupoDeOpcao = async (data, idProduto) => {
    try {
        const response = await api
            .post("cardapio/grupoopcao/duplicar/" + idProduto, data, {
                headers: { "Content-Type": "application/json", Authorization: autorizacao },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("duplicarGrupoDeOpcao  => ", response)

        return tratamentoResponse(response, "duplicarGrupoDeOpcao")
    } catch (error) {
        RegistrarLogErro(error, "duplicarGrupoDeOpcao", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

// Excluir
export const excluirCategoria = async data => {
    try {
        const response = await api
            .post("cardapio/categoria/excluir", data, {
                headers: { "Content-Type": "application/json", Authorization: autorizacao },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("excluirCategoria  => ", response)

        return tratamentoResponse(response, "excluirCategoria")
    } catch (error) {
        RegistrarLogErro(error, "excluirCategoria", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const excluirProduto = async data => {
    try {
        const response = await api
            .post("cardapio/produto/excluir", data, {
                headers: { "Content-Type": "application/json", Authorization: autorizacao },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("excluirProduto  => ", response)

        return tratamentoResponse(response, "excluirProduto")
    } catch (error) {
        RegistrarLogErro(error, "excluirProduto", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const excluirGrupoDeOpcao = async data => {
    try {
        const response = await api
            .post("cardapio/grupoopcao/excluir", data, {
                headers: { "Content-Type": "application/json", Authorization: autorizacao },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("excluirGrupoDeOpcao  => ", response)

        return tratamentoResponse(response, "excluirGrupoDeOpcao")
    } catch (error) {
        RegistrarLogErro(error, "excluirGrupoDeOpcao", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const excluirOpcao = async data => {
    try {
        const response = await api
            .post("cardapio/opcao/excluir", data, {
                headers: { "Content-Type": "application/json", Authorization: autorizacao },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("excluirOpcao  => ", response)

        return tratamentoResponse(response, "excluirOpcao")
    } catch (error) {
        RegistrarLogErro(error, "excluirOpcao", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

// Inativar

export const inativarProduto = async (idProduto, boolean) => {
    try {
        const response = await api
            .post(`cardapio/produto/ativardesativar/${idProduto}/${!boolean}`, {
                headers: { "Content-Type": "application/json", Authorization: autorizacao },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("inativarProduto  => ", response)

        return tratamentoResponse(response, "inativarProduto")
    } catch (error) {
        RegistrarLogErro(error, "inativarProduto")
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const inativarOpcao = async (idOpcao, boolean) => {
    try {
        const response = await api
            .post(`cardapio/opcao/ativardesativar/${idOpcao}/${!boolean}`, {
                headers: { "Content-Type": "application/json", Authorization: autorizacao },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("inativarOpcao  => ", response)

        return tratamentoResponse(response, "inativarOpcao")
    } catch (error) {
        RegistrarLogErro(error, "inativarOpcao")
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const ativarInativarMassa = async (idEstabelecimento, data) => {
    try {
        const response = await api
            .post(`cardapiosimplificado/ativardesativar/lista/` + idEstabelecimento, data, {
                headers: { "Content-Type": "application/json", Authorization: autorizacao },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("inativarOpcao  => ", response)

        return tratamentoResponse(response, "inativarOpcao")
    } catch (error) {
        RegistrarLogErro(error, "inativarOpcao")
        return { retornoErro: 1, mensagem: error.message }
    }
}

//obter cardapio

export const obterCardapioParaEdicaoEmMassa = async (idEstabelecimento, Authorization) => {
    try {
        const response = await api.get("cardapiosimplificado/" + idEstabelecimento, {
            headers: {
                "Content-Type": "application/json",
                Authorization: Authorization,
            },
        })

        console.warn("obterCardapioParaEdicaoEmMassa =>", response)

        return tratamentoResponse(response, "obterCardapioParaEdicaoEmMassa")
    } catch (error) {
        console.error(error)
        RegistrarLogErro(error, "obterCardapioParaEdicaoEmMassa", {})
    }
}

//Salvar Cardapio completo

export const salvarCardapioCompleto = async (idEstabelecimento, data, Authorization) => {
    try {
        const response = await api.post("cardapio/salvar/" + idEstabelecimento, data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: Authorization,
            },
        })

        console.warn("salvarCardapioCompleto =>", response)

        return tratamentoResponse(response, "salvarCardapioCompleto")
    } catch (error) {
        console.error(error)
        RegistrarLogErro(error, "salvarCardapioCompleto", {})
    }
}

//Cadastrar Imagem

export const cadastrarImagem = async (
    idEstabelecimento,
    nomeArquivo,
    idProduto,
    idOpcao,
    file,
    Authorization
) => {
    try {
        const response = await api.put(
            `cardapio/photo/${idEstabelecimento}/${nomeArquivo}` +
                (idOpcao ? `?idOpcao=${idOpcao}` : idProduto ? `?idProduto=${idProduto}` : ""),
            file,
            {
                headers: {
                    "Content-Type": "application/octet-stream",
                    Authorization: Authorization,
                },
            }
        )

        console.warn("cadastrarImagem =>", response)

        return tratamentoResponse(response, "cadastrarImagem")
    } catch (error) {
        console.error(error)
        RegistrarLogErro(error, "cadastrarImagem", {})
    }
}

// export const ObterImagens = async (idEstabelecimento, Authorization) => {
//     try {
//         const response = await api.get("cardapio/photos/" + idEstabelecimento, {
//             headers: {
//                 "Content-Type": "application/json",
//                 Authorization: Authorization,
//             },
//         })

//         console.warn("ObterImagens =>", response)

//         return tratamentoResponse(response, "ObterImagens")
//     } catch (error) {
//         console.error(error)
//         RegistrarLogErro(error, "ObterImagens", {})
//     }
// }
