import { Box, Button, makeStyles, Paper, TextField, Typography } from "@material-ui/core"
import { useSnackbar } from "notistack"
import QRCode from "qrcode"
import { useState } from "react"
import QrCodeIcon from "../../assets/QrCodeIcon.png"
import Cabecalho from "../Cabecalho"

const useStyles = makeStyles(theme => ({
    root: {
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f5f5f5",
    },
    container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#fff",
        padding: theme.spacing(4),
        borderRadius: theme.spacing(1),
        boxShadow: theme.shadows[3],
        width: "80%",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            width: "95%",
        },
        gap: "1em",
    },
    inputContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            alignItems: "center",
        },
    },
    textField: {
        marginBottom: theme.spacing(2),
        width: "100%",
    },
    qrCodeContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(2),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.spacing(1),
        backgroundColor: "#fafafa",
        width: "100%",
    },
    qrCode: {
        width: 200,
        height: 200,
        marginBottom: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(2),
    },
}))

export default function GeradorDeQrCode() {
    const classes = useStyles()
    const [link, setLink] = useState("")
    const [qrCodeSrc, setQrCodeSrc] = useState(null)

    const { enqueueSnackbar } = useSnackbar()

    const handleGenerateQrCode = () => {
        QRCode.toDataURL(link, { width: 250, height: 250 }, (err, url) => {
            if (err) {
                console.error(err)
                return
            }
            setQrCodeSrc(url)
        })
    }

    const handleDownloadQrCode = () => {
        if (qrCodeSrc) {
            const link = document.createElement("a")
            link.href = qrCodeSrc
            link.download = "qrcode.png"
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        } else {
            enqueueSnackbar("Nehum QR code foi gerado")
        }
    }

    return (
        <div className={classes.root}>
            <Cabecalho pagina={"Gerar Qr Code"} />
            <Paper className={classes.container}>
                <Box className={classes.inputContainer}>
                    <Typography variant="h6">Insira o link</Typography>
                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        placeholder="https://"
                        fullWidth
                        value={link}
                        onChange={e => setLink(e.target.value)}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleGenerateQrCode}
                        className={classes.button}
                    >
                        Gerar QrCode
                    </Button>
                </Box>
                <Box className={classes.qrCodeContainer}>
                    <img
                        className={classes.qrCode}
                        src={qrCodeSrc === null ? QrCodeIcon : qrCodeSrc}
                        alt="QR Code"
                    />
                    <Button variant="contained" color="default" onClick={handleDownloadQrCode}>
                        Fazer download da imagem
                    </Button>
                </Box>
            </Paper>
        </div>
    )
}
