import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import { cancelarEntregaUber } from "../services"
import CancelButton from "./CancelButton"

export default function ConfirmacaoCancelamentoUber({
    pedido,
    autorizacao,
    setLoading,
    abrirCancelamento,
    setAbrirCancelamento,
    setAbrirDetalhes,
    setExpandIntegracaoUber,
    buscarDetalhes,
}) {
    const cancelarEntrega = async () => {
        setLoading(true)
        await cancelarEntregaUber(pedido.id, autorizacao)
        await buscarDetalhes()
        setLoading(false)
        setAbrirCancelamento(false)
        setAbrirDetalhes(false)
        setExpandIntegracaoUber(false)
    }

    return (
        <Dialog
            open={abrirCancelamento}
            onClose={() => {
                setAbrirCancelamento(false)
            }}
        >
            <DialogTitle>
                <IconButton
                    className="setaVoltarModal"
                    onClick={() => {
                        setAbrirCancelamento(false)
                    }}
                >
                    <ArrowForwardIosIcon />
                </IconButton>
                Cancelar
            </DialogTitle>
            <DialogContent>
                <DialogContentText>Deseja mesmo cancelar a entrega?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <CancelButton text="Cancelar entrega" handleClick={cancelarEntrega} />
                <Button
                    variant="contained"
                    onClick={() => {
                        setAbrirCancelamento(false)
                    }}
                >
                    Não cancelar
                </Button>
            </DialogActions>
        </Dialog>
    )
}
