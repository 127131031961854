import "bootstrap/dist/css/bootstrap.min.css"
import { SnackbarProvider } from "notistack"
import React, { useEffect, useState } from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { ErrorBoundary } from "react-error-boundary"
import { BrowserRouter } from "react-router-dom"

import { Button } from "@material-ui/core"

import LoadingContext from "./contexts/loadingContext"
import LoadingComponent from "./pages/Componentes/Loading"
import Permissoes from "./permissoes"

import flatErro from "./assets/fail-flat.svg"
import { RegistrarLogErro } from "./services/clientefiel"

import TagManager from "react-gtm-module"
import { ParametrosContextProvider } from "./contexts/ParametrosContext"

const tagManagerArgs = { gtmId: "GTM-P7ZLCTD" }

function ErrorHandler({ error }) {
    useEffect(() => {
        RegistrarLogErro(error, "Render - Recebedor de Pedidos")
    }, [])

    const [today] = useState(new Date())
    const [date] = useState(
        today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate()
    )
    const [time] = useState(today.getHours() + ":" + today.getMinutes())

    function handleClick() {
        localStorage.clear()
        window.location.href = window.location.href
    }

    return (
        <div
            style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                padding: "2em",
                position: "absolute",
            }}
        >
            <img
                src={flatErro}
                alt={"loading erro"}
                className="pretoEBranco"
                style={{ width: "350px", marginBottom: "1em" }}
            />
            <h6>Houve um erro no aplicativo!</h6>
            <p>
                Tire um print dessa tela e envie para os desenvolvedores, após isso resete o app
                clicando no botão "RESETAR" para voltar ao normal.
            </p>
            <p>Erro: {error.message}</p>
            <span style={{ marginBottom: "24px" }}>
                {date} - {time}
            </span>
            <Button variant="outlined" color="default" onClick={handleClick}>
                RESETAR
            </Button>
        </div>
    )
}

function App() {
    const [loading, setLoading] = React.useState(false)

    useEffect(() => {
        TagManager.initialize(tagManagerArgs)
    }, [])

    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <ParametrosContextProvider>
                <LoadingComponent loading={loading} />
                <LoadingContext.Provider
                    value={{
                        loading,
                        setLoading,
                    }}
                >
                    <SnackbarProvider maxSnack={3}>
                        <DndProvider backend={HTML5Backend}>
                            <BrowserRouter>
                                <Permissoes />
                            </BrowserRouter>
                        </DndProvider>
                    </SnackbarProvider>
                </LoadingContext.Provider>
            </ParametrosContextProvider>
        </ErrorBoundary>
    )
}

export default App
