import { Button } from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { useContext } from "react"
import ServicosContext from "../../../contexts/servicosContext"

export default function BotaoIntegracoes({ setExpandListaIntegracoes, expandListaIntegracoes }) {
    const { configuracoes } = useContext(ServicosContext)

    if (
        !configuracoes.user?.parametros?.possuiIntegracaoUber &&
        !configuracoes.user?.parametros?.ativaIntegracaoIfood
    )
        return null

    return (
        <Button
            onClick={() => {
                setExpandListaIntegracoes(!expandListaIntegracoes)
            }}
            id="botao-lista-integracoes"
            startIcon={<ExpandMoreIcon />}
        >
            <span className="small-screen-hidden">Integrações</span>
        </Button>
    )
}
