import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { makeStyles } from "@material-ui/core/styles"

import Retirada from "./Retirada"

const useStyles = makeStyles(theme => ({}))

export default function DialogAddRetirada(props) {
    const autorizacao = localStorage.getItem("chaveSistema")
    const classes = useStyles()

    const { retiradas, open, fullScreen, setOpen } = props

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-alteracoes-caixa"
                scroll={"body"}
            >
                <form>
                    <DialogTitle id="form-dialog-alteracoes-caixa">
                        Existe {retiradas.length} Retiradas no Caixa
                    </DialogTitle>
                    <DialogContent>
                        {retiradas.map((r, index) => (
                            <Retirada key={index} retirada={r} />
                        ))}
                    </DialogContent>
                    {/* <DialogActions>
            <Button 
              type="submit" 
              onClick={handleFazerRetiradaCaixa} 
              disabled={!tipoAtivo} 
              color="primary"
            >
              SELECIONAR
            </Button>
          </DialogActions> */}
                </form>
            </Dialog>
        </div>
    )
}
