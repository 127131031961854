//INIT
import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import ServicosContext from "../../contexts/servicosContext"

//FUNCTIONS \ REQUESTS

import {} from "../../pages/RegistradorDePedidos/functions"

import {} from "../../services/functionsGerais"

import {} from "../../services/comum"

import {} from "../../services/clientefiel"

//FILES
import api from "../../services/api"
import Cabecalho from "../Cabecalho"
import Loading from "../Componentes/Loading"
import "./styles.css"

//FRAMEWORK
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import InputBase from "@material-ui/core/InputBase"
import NativeSelect from "@material-ui/core/NativeSelect"
import Slide from "@material-ui/core/Slide"
import Snackbar from "@material-ui/core/Snackbar"
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles"
import MuiAlert from "@material-ui/lab/Alert"
import { Col, Row } from "react-bootstrap"

import Tema1 from "./tema1"

const BootstrapInput = withStyles(theme => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "10px 26px 10px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
            borderRadius: 4,
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}))(InputBase)

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    rootAlert: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    root: {
        width: "100%",
        margin: "1em 0",
    },
    pos: {
        marginBottom: 12,
    },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default function RecebedorDePedidos() {
    const aplicativoDados = JSON.parse(localStorage.getItem("aplicativoCF"))
    const autorizacao = localStorage.getItem("chaveSistema")
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    )

    //imports
    const theme = useTheme()
    const history = useHistory()
    const classes = useStyles()

    //states

    const { configuracoes, handleRegistradorDePedidos } = useContext(ServicosContext)

    const [loading, setLoading] = React.useState(true)
    const [alert, setAlert] = useState({ status: false, tipo: "success", mesangem: "" })
    const [dados, setDados] = useState({ logo64: "", estabelecimentos: [] })
    console.log("dados", dados)

    const [openImage, setOpenImage] = useState(false)
    const [criarImagem, setCriarImagem] = useState("")
    // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const numeroMesas = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
        49, 50,
    ]

    const [confImagem, setConfImage] = useState({
        mesa: 1,
        cor1: aplicativoDados.corPadrao,
        cor2: aplicativoDados.corPadraoSecundaria,
    })

    const alertStart = (msg, tipo) => {
        setAlert({ status: true, tipo: tipo, mesangem: msg })
    }

    const alertClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setAlert({ status: false, tipo: alert.tipo, mesangem: alert.mesangem })
    }

    useEffect(() => {
        get64Logo()
    }, [])

    const get64Logo = async () => {
        var logo64 = dados.logo64
        if (!dados.logo64) {
            logo64 = await api.get("siteutil/LogoBase64/" + configuracoes.user.parametros.appNome, {
                headers: { "Content-Type": "application/json" },
            })
        }

        var estabelecimentos = dados.estabelecimentos
        if (!dados.estabelecimentos.length) {
            estabelecimentos = await api.get("integracao/buscarestabelecimentos", {
                headers: { "Content-Type": "application/json", Authorization: autorizacao },
            })
        }

        if (!dados.estabelecimentos || !dados.logo64) {
            setDados({ estabelecimentos: estabelecimentos.data, logo64: logo64.data })
        }

        window.setTimeout(function () {
            setLoading(false)
        }, 2000)
    }

    return (
        <Container component="main" style={{ marginTop: "6em" }} className="container-buscando">
            <Cabecalho
                pagina={"QRCodes de Mesas"}
                registrarPedidoMesa={() => {
                    handleRegistradorDePedidos()
                }}
            />

            <Loading loading={loading} />

            <Snackbar open={alert.status} autoHideDuration={6000} onClose={alertClose}>
                <Alert onClose={alertClose} severity={alert.tipo}>
                    {alert.mesangem}
                </Alert>
            </Snackbar>

            <Row style={{ textAlign: "center" }}>
                <Col xs={12} md={4} lg={4} style={{ margin: "1em 0" }}>
                    <Row>
                        <Col xs={12} md={12} lg={12} style={{ margin: "1em 0" }}>
                            <NativeSelect
                                fullWidth
                                defaultValue={confImagem.mesa}
                                id="opcaoMesa"
                                input={<BootstrapInput />}
                            >
                                {numeroMesas.map((mesa, index) => (
                                    <option key={index} value={`${mesa}`}>
                                        Mesa {mesa}
                                    </option>
                                ))}
                            </NativeSelect>
                        </Col>
                        {dados.estabelecimentos.length > 0 ? (
                            <Col xs={12} md={12} lg={12} style={{ margin: "1em 0" }}>
                                <NativeSelect
                                    fullWidth
                                    defaultValue={dados.estabelecimentos[0].id}
                                    id="opcaoEstabelecimento"
                                    input={<BootstrapInput />}
                                >
                                    {dados.estabelecimentos.map((estabelecimento, index) => (
                                        <option key={index} value={`${estabelecimento.id}`}>
                                            {estabelecimento.nome}
                                        </option>
                                    ))}
                                </NativeSelect>
                            </Col>
                        ) : null}

                        <Col xs={12} md={12} lg={12} style={{ margin: "1em 0" }}>
                            <input
                                type="color"
                                id="opcaoCor1"
                                name="cor1"
                                defaultValue={configuracoes.user.parametros.corPrimaria}
                            />
                        </Col>

                        <Col xs={12} md={12} lg={12} style={{ margin: "1em 0" }}>
                            <input
                                type="color"
                                id="opcaoCor2"
                                name="cor2"
                                defaultValue={configuracoes.user.parametros.corSecundaria}
                            />
                        </Col>

                        <Col xs={12} md={12} lg={12} style={{ margin: "1em 0" }}>
                            <Button
                                onClick={() => {
                                    setConfImage({
                                        mesa: document.getElementById("opcaoMesa").value,
                                        estabelecimento:
                                            document.getElementById("opcaoEstabelecimento").value,
                                        cor1: document.getElementById("opcaoCor1").value,
                                        cor2: document.getElementById("opcaoCor2").value,
                                    })
                                }}
                            >
                                Atualizar
                            </Button>
                        </Col>
                    </Row>
                </Col>

                <Col xs={12} md={7} lg={7} style={{ margin: "1em 0" }}>
                    {dados.logo64 && dados.estabelecimentos.length ? (
                        <Tema1
                            logo={dados.logo64}
                            mesa={confImagem.mesa}
                            estabelecimento={confImagem.estabelecimento}
                            corPrimaria={confImagem.cor1}
                            corSecundaria={confImagem.cor2}
                        />
                    ) : null}
                </Col>
            </Row>
        </Container>
    )
}
