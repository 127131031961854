import { RegistrarLogErro } from "../../services/clientefiel"
import { removeAcento } from "../../services/functionsGerais"

export const verificarPrintQRCode = pedido => {
    const qrCode = { descricao: "", texto: "" }

    if (pedido && pedido.payments) {
        if (pedido.payments.codigoQrCodePix) {
            qrCode.texto = pedido.payments.codigoQrCodePix
        }
        if (pedido.payments.tipoChavePix && pedido.payments.chavePix) {
            qrCode.descricao = `PIX ${pedido.payments.chavePix}: ${pedido.payments.tipoChavePix}`
        }
    }

    return qrCode
}

export const procurarPedido = (e, pedidos, popUp = true) => {
    const pesquisa = removeAcento(
        e.target.value ?? document.getElementById("procurar-pedido-alternativo").value
    )
    try {
        pedidos.forEach(pedido => {
            const item = document.getElementById(`pedido-${pedido.id}`)
            const divider = document.getElementById(`divider-${pedido.id}`)

            const pedidoDadosString = removeAcento(
                pedido.id.toString() + (pedido.customer?.name ?? "") + pedido.customer?.phone
            )

            if (!pesquisa || pedidoDadosString.includes(pesquisa)) {
                if (item?.style) item.style.display = ""
                if (divider?.style) divider.style.display = ""
            } else {
                if (item?.style) item.style.display = "none"
                if (divider?.style) divider.style.display = "none"
            }
        })
    } catch (error) {
        RegistrarLogErro(error, "procurarPedido", pesquisa)
    }
}

export const totalArray = (tipo, arrayQuarto) => {
    try {
        //tipo 1 = verde
        //tipo 0 = vermelho
        var totalPriceP = 0.0
        var totalPriceN = 0.0

        arrayQuarto.forEach(pedido => {
            if (pedido.status > 0) {
                totalPriceP += pedido.totalPrice
            } else {
                totalPriceN += pedido.totalPrice
            }
        })

        if (tipo === 0) {
            return totalPriceN
        } else {
            return totalPriceP
        }
    } catch (error) {
        RegistrarLogErro(error, "totalArray", { tipo, arrayQuarto })
    }
}
