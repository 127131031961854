import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@material-ui/core"
import { useState } from "react"

const DialogDuplicarGrupoDeOpcao = ({
    open,
    handleToggleDuplicarGrupoDeMontagem,
    categorias,
    handleDuplicarPassoDeMontagem,
    idPasso,
}) => {
    const [indexCategoria, setIndexCategoria] = useState("")
    const handleIndexCategoria = e => {
        setIndexCategoria(e.target.value)
    }

    const [idProduto, setIdProduto] = useState("")
    const handleIndexProduto = e => {
        setIdProduto(e.target.value)
    }

    return (
        <>
            {open && (
                <Dialog
                    open={open}
                    onClose={handleToggleDuplicarGrupoDeMontagem}
                    style={{ maxWidth: "500px", margin: "auto" }}
                >
                    <DialogTitle
                        style={{
                            textAlign: "center",
                        }}
                    >
                        <Typography variant="h5">
                            Onde deseja duplicar o grupo de montagem?
                        </Typography>
                    </DialogTitle>
                    <DialogContent
                        style={{ display: "flex", flexDirection: "column", gap: "0.5em" }}
                    >
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Categoria</InputLabel>
                            <Select
                                label="Categoria"
                                value={indexCategoria}
                                onChange={handleIndexCategoria}
                            >
                                {categorias.map((categoria, i) => {
                                    return (
                                        <MenuItem key={i} value={i}>
                                            {categoria.nome}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        {indexCategoria !== "" && (
                            <FormControl fullWidth variant="outlined">
                                <InputLabel>Produto</InputLabel>
                                <Select
                                    label="Produto"
                                    value={idProduto}
                                    onChange={handleIndexProduto}
                                >
                                    {categorias[indexCategoria]?.produtos.map((produto, i) => {
                                        return (
                                            <MenuItem key={i} value={produto.id}>
                                                {produto.nome}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            style={{ backgroundColor: "#dc3545", color: "white" }}
                            onClick={handleToggleDuplicarGrupoDeMontagem}
                        >
                            Cancelar
                        </Button>
                        <Button
                            style={{ backgroundColor: "#28a745", color: "white" }}
                            onClick={async () => {
                                await handleDuplicarPassoDeMontagem(idProduto, idPasso)
                                handleToggleDuplicarGrupoDeMontagem()
                            }}
                        >
                            Confirmar
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    )
}

export default DialogDuplicarGrupoDeOpcao
