import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"

import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

const useStyles = makeStyles(theme => ({}))

export default function DialogFiltro(props) {
    const { filtroAberto, setFiltroAberto } = props

    const { open } = filtroAberto

    const handleClose = () => {
        setFiltroAberto({
            tipo: "",
            valor: "",
            open: false,
        })
    }
    const handleFilter = e => {
        setFiltroAberto({
            ...filtroAberto,
            valor: document.getElementById("valorFiltro").value,
            open: false,
        })
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Filtrar por {filtroAberto.tipo.toUpperCase()}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Utilize o campo abaixo para filtrar por <b>{filtroAberto.tipo.toUpperCase()}</b>{" "}
                    e depois clique em filtrar.
                </DialogContentText>

                <TextField
                    label={`Inisira o ${filtroAberto.tipo}`}
                    fullWidth
                    id="valorFiltro"
                    variant={"outlined"}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="default">
                    Voltar
                </Button>
                <Button onClick={handleFilter} color="primary">
                    Filtrar
                </Button>
            </DialogActions>
        </Dialog>
    )
}
