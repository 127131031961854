import Avatar from "@material-ui/core/Avatar"
import Chip from "@material-ui/core/Chip"

import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"

export default function ChipPagamentoPercentual({ valor }) {
    return valor ? (
        <Chip
            style={{
                position: "absolute",
                right: "5px",
                top: "5px",
                background: `${valor > 0 ? "#28a74596" : "#dc354596"}`,
            }}
            avatar={
                <Avatar
                    style={{
                        fontSize: "14px",
                        color: "white",
                        background: `${valor > 0 ? "#28a745" : "#dc3545"}`,
                    }}
                >
                    {valor > 0 ? <AddIcon /> : <RemoveIcon />}
                </Avatar>
            }
            label={
                <div
                    style={{
                        display: "grid",
                        padding: "0px 10px",
                        textAlign: "center",
                        lineHeight: "14px",
                    }}
                >
                    <span
                        style={{
                            padding: "0",
                            fontSize: "0.6rem",
                        }}
                    >
                        {valor > 0 ? "Desconto" : "Acrescimo"}
                    </span>

                    <span
                        style={{
                            padding: 0,
                            fontSize: "1rem",
                        }}
                    >
                        {Math.abs(valor)} %
                    </span>
                </div>
            }
            color={"default"}
        />
    ) : null
}
