import { useSnackbar } from "notistack"
import React, { useContext } from "react"
import CaixaContext from "../../contexts/caixaContext"
import ServicosContext from "../../contexts/servicosContext"

import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import CreateIcon from "@material-ui/icons/Create"
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck"
import RefreshIcon from "@material-ui/icons/Refresh"
import { Col, Row } from "react-bootstrap"

import Chip from "@material-ui/core/Chip"
import Dialog from "@material-ui/core/Dialog"
import Slide from "@material-ui/core/Slide"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import CamposInformativos from "./components/CamposInformativos"
import Header from "./components/Header"
import TablePagamentos from "./components/TablePagamentos"

const useStyles = makeStyles(theme => ({
    divChip: {
        marginBottom: "1em",
        textAlign: "center",
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    appContainer: {
        paddingTop: "6em",
        backgroundColor: "#f4f4f4",
    },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function ModalCaixa(props) {
    const theme = useTheme()
    const classes = useStyles()
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

    const { enqueueSnackbar } = useSnackbar()

    const {
        caixaSelecionada,
        setCaixaSelecionada,
        estabelecimentoSelecionado,
        handleBuscarCaixa,
        handleBuscarCaixasAbertos,
    } = useContext(CaixaContext)

    const { handleAceitadorDePedidos, handleRegistradorDePedidos } = useContext(ServicosContext)

    return (
        <div>
            {!!caixaSelecionada.id && (
                <Dialog
                    fullScreen
                    open={!!caixaSelecionada.id}
                    onClose={() => {
                        setCaixaSelecionada({})
                    }}
                    TransitionComponent={Transition}
                >
                    <Header
                        handleBuscarCaixasAbertos={handleBuscarCaixasAbertos}
                        estabelecimentoSelecionado={estabelecimentoSelecionado}
                    />

                    <Container className={classes.appContainer}>
                        <Row>
                            <Col xs={12} md={12} lg={12}>
                                <div className={classes.divChip}>
                                    {caixaSelecionada.valoresPorTipoCaixa.map((tipo, index) => (
                                        <Chip
                                            key={index}
                                            className={classes.chip}
                                            label={tipo.nome}
                                            color="default"
                                        />
                                    ))}

                                    <Button
                                        size="small"
                                        className={classes.button}
                                        startIcon={<PlaylistAddCheckIcon />}
                                        onClick={() => {
                                            handleAceitadorDePedidos()
                                        }}
                                    >
                                        Recebedor
                                    </Button>
                                    <Button
                                        size="small"
                                        className={classes.button}
                                        startIcon={<CreateIcon />}
                                        onClick={() => {
                                            handleRegistradorDePedidos()
                                        }}
                                    >
                                        Registrador
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            handleBuscarCaixa(caixaSelecionada.id)
                                        }}
                                        size="small"
                                        className={classes.button}
                                        startIcon={<RefreshIcon />}
                                    >
                                        Atualizar
                                    </Button>
                                </div>
                            </Col>
                            <Col xs={12} md={12} lg={5}>
                                <TablePagamentos caixaSelecionada={caixaSelecionada} />
                            </Col>
                            <Col xs={12} md={12} lg={7}>
                                <CamposInformativos />
                            </Col>
                        </Row>
                    </Container>
                </Dialog>
            )}
        </div>
    )
}
