import { useSnackbar } from "notistack"
import React, { useContext } from "react"
import CaixaContext from "../../../contexts/caixaContext"
import LoadingContext from "../../../contexts/loadingContext"

import AppBar from "@material-ui/core/AppBar"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import CloseIcon from "@material-ui/icons/Close"
import ConfirmarFechamento from "./ConfirmarFechamento"

import { RegistrarLogErro } from "../../../services/clientefiel"
import { dataAtualFormatada, fecharCaixa } from "../../../services/controlecaixa"

const useStyles = makeStyles(theme => ({
    appBar: {
        position: "fixed",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}))

export default function Header({ handleBuscarCaixasAbertos, estabelecimentoSelecionado }) {
    const { caixaSelecionada, setCaixaSelecionada } = useContext(CaixaContext)

    const { setLoading } = useContext(LoadingContext)
    const { enqueueSnackbar } = useSnackbar()
    const autorizacao = localStorage.getItem("chaveSistema")
    const classes = useStyles()

    const [confirmarFechamento, setConfirmarFechamento] = React.useState(false)

    const handleFecharCaixa = async () => {
        try {
            setLoading(true)
            const fecharCaixaResponse = await fecharCaixa(caixaSelecionada.id, autorizacao)
            console.log("---fecharCaixa--", fecharCaixaResponse)
            if (fecharCaixaResponse.status >= 300) {
                enqueueSnackbar(fecharCaixaResponse.message, { variant: "error" })
                setLoading(false)
            } else {
                enqueueSnackbar(fecharCaixaResponse.message, { variant: "success" })
                setCaixaSelecionada({})
                handleBuscarCaixasAbertos(estabelecimentoSelecionado)
            }
        } catch (error) {
            RegistrarLogErro(error, "handleFecharCaixa", caixaSelecionada)
            setLoading(false)
        }
    }

    return (
        <AppBar className={classes.appBar}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => {
                        setCaixaSelecionada({})
                    }}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    {dataAtualFormatada(new Date(caixaSelecionada.abertoEm))}
                </Typography>
                <Button
                    autoFocus
                    color="inherit"
                    onClick={() => {
                        setConfirmarFechamento(true)
                    }}
                >
                    ENCERRAR CAIXA
                </Button>
            </Toolbar>

            {confirmarFechamento && (
                <ConfirmarFechamento
                    open={confirmarFechamento}
                    setOpen={setConfirmarFechamento}
                    handleFecharCaixa={handleFecharCaixa}
                />
            )}
        </AppBar>
    )
}
