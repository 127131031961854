import { makeStyles } from "@material-ui/core"
import createSvgIcon from "@material-ui/icons/utils/createSvgIcon"

const IconeUberSVG = createSvgIcon(
    <svg width="24px" height="24px" viewBox="0 0 16 16" version="1.1">
        <path
            xmlns="http://www.w3.org/2000/svg"
            d="M8 0C3.758 0 .286 3.322.025 7.5H5.5V6a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H6a.5.5 0 0 1-.5-.5V8.5H.025C.286 12.678 3.758 16 8 16c4.411 0 8-3.589 8-8s-3.589-8-8-8z"
        />
    </svg>
)

const useStyles = makeStyles(theme => ({
    iconeUberPendente: {
        animation: "$rotacionar 3s infinite linear, $pulsar 2s infinite linear",
    },
    "@keyframes rotacionar": {
        from: {
            transform: "rotate(0deg)",
        },
        to: {
            transform: "rotate(360deg)",
        },
    },
    "@keyframes pulsar": {
        "0%": {
            fill: "#000",
        },
        "50%": {
            fill: "#ccc",
        },
        "100%": {
            fill: "#000",
        },
    },
}))

export default function UberIcon({ color, pendente }) {
    const classes = useStyles()

    const fill = color ? color : "#000"

    return (
        <IconeUberSVG
            className={pendente ? classes.iconeUberPendente : null}
            style={{ fill: `${fill}` }}
        />
    )
}
