import React, { useContext } from "react"
import { useHistory } from "react-router-dom"

import { useSnackbar } from "notistack"
import LoadingContext from "../../../../contexts/loadingContext"
import RegistradorContext from "../../../../contexts/registradorContext"
import ServicosContext from "../../../../contexts/servicosContext"

import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Slide from "@material-ui/core/Slide"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import PrintIcon from "@material-ui/icons/Print"
import { Col, Row } from "react-bootstrap"

import { fecharmesa } from "../../../../services/integracao"

import { RegistrarLogErro } from "../../../../services/clientefiel"

const qz = require("qz-tray")

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles(theme => ({
    fixed: {
        position: "fixed",
        right: "2em",
        bottom: "2em",
        zIndex: 1000,
    },
    fixedMesa: {
        position: "fixed",
        right: "2em",
        bottom: "2em",
        zIndex: 2000,
    },
    fixedMesaFechar: {
        position: "fixed",
        right: "2em",
        bottom: "7em",
        zIndex: 2000,
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    rootAlert: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
    backdrop: {
        // zIndex: theme.zIndex.drawer + 999999,
        color: "#fff",
    },
    pos: {
        marginBottom: 12,
    },
    heading: {
        width: "100%",
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    rootList: {
        width: "100%!important",
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: "inline",
        width: "100%",
    },
    appBar: {
        position: "fixed",
    },
    appContainer: {
        paddingTop: "6em",
        backgroundColor: "#f4f4f4",
        // height: "100%",
        // minHeight: "-webkit-fill-available",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    list: {
        width: 250,
    },
    fullList: {
        width: "auto",
    },
}))

export default function Index() {
    const autorizacao = localStorage.getItem("chaveSistema")
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    )
    const classes = useStyles()
    const theme = useTheme()
    const history = useHistory()

    const { setLoading, loading } = useContext(LoadingContext)

    const {
        finalizarMesa,
        setFinalizarMesa,
        cadastrarUsuario,
        impressorasQZ,
        setOpenGerenciarMesa,
        setOpenGerenciarPedidosMesa,
        setListaMesas,
        setPedidosDaMesa,
        pedidosDaMesa,
    } = useContext(RegistradorContext)

    const mandarTodosImprimir = async arrayPedidos => {
        console.log("mandarTodosImprimir", arrayPedidos)
        try {
            arrayPedidos.forEach(element => {
                mandarImprimir(element.textoImpressao, 1)
            })
        } catch (error) {
            RegistrarLogErro(error, "mandarTodosImprimir")
        }
    }

    const mandarImprimir = async (texto, qtd) => {
        try {
            if (configuracoes.user?.parametros.assinaturaLimitada === true) {
                alertStart(
                    "Não foi impresso, porque sua assinatura está limitada por inadimplência.",
                    "info"
                )
                return false
            }

            /*if (impressorasQZ.length > 0 && !mobile) {
        console.log("Impressora QZ: ", configuracoes.printer.impressora )
        console.log(configuracoes)
        if (!!configuracoes.printer.impressora && configuracoes.printer.impressora !== "vazio") {
          await imprimirQz(texto, qtd, configuracoes.printer.impressora)
          return true
        }

      }*/

            document.getElementById("rootPrint").innerHTML = texto.replace(
                /(?:\r\n|\r|\n)/g,
                "<br>"
            )

            for (var j = 0; j < qtd; j++) {
                window.print()
            }
        } catch (error) {
            console.log("Não foi possível imprimir", error)
            RegistrarLogErro(error, "mandarImprimir")
        }
    }

    const fecharPedidoMesa = async pedidosDaMesa => {
        try {
            setLoading(true)
            const response = await fecharmesa(autorizacao, pedidosDaMesa)

            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
                setLoading(false)
                return false
            }
            alertStart(`Mesa ${pedidosDaMesa.numeroMesa} fechada com sucesso!`, "success")

            setFinalizarMesa(false)
            setPedidosDaMesa({})
            setListaMesas([])
            setOpenGerenciarPedidosMesa(false)
            setOpenGerenciarMesa(false)
            setLoading(false)
        } catch (error) {
            RegistrarLogErro(error, "fecharPedidoMesa")
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    async function imprimirQz(texto, qtd, impressora = "") {
        try {
            if (qz.websocket.isActive()) {
                if (!impressora) {
                    impressora = await qz.printers.getDefault()
                }

                console.log("Impressão Qz - Pedido de mesa")

                // MODO TEXTO DO HTML
                // if(!localStorage.getItem("qz")){

                let config = qz.configuracoes.create(impressora, {
                    copies: 1,
                    scaleContent: true,
                    margins: { top: 0, right: 0, bottom: 0, left: 0 },
                    orientation: "portrait",
                })

                console.log(config)

                const tamanhoFonte =
                    !configuracoes.printer.tamanhoFonteImpressao ||
                    configuracoes.printer.tamanhoFonteImpressao < 6
                        ? 14
                        : configuracoes.printer.tamanhoFonteImpressao

                console.log("tamanho fonte: ", tamanhoFonte)

                for (var i = 0; i < qtd; i++) {
                    let data = [
                        {
                            type: "html",
                            format: "plain", // or 'plain' if the data is raw HTML
                            data: `<span 
                      style="position:absolute;
                            color:#0c0d0e;
                            top:0;
                            width:100%;
                            margin:0;
                            padding:0;
                            padding-left: 1em;
                            font-weight: 700;
                            letter-spacing: 0;
                            line-height: normal;
                            font-size: ${tamanhoFonte}px;
                            font-family: Arial,sans-serif;
                        ">
                        ${texto.replace(/\n/g, "<br>")}
                      </span>`,
                        },
                    ]
                    qz.print(config, data)
                }

                return true
                // }

                // MODO TEXTO DO QZ

                // let config = qz.configuracoes.create(impressora, {copies:1, scaleContent: true, margins: { top: 0, right: 0, bottom: 0, left: 0 } , orientation: 'portrait'});
                // let imprimir = "";
                // for(var i = 0; i < qtd; i ++){
                //   imprimir += `${texto}\x0A\x0A\x0A\x0A\x0A\x0A\x0A\x1B\x69`
                // }
                // return qz.print(config, [{
                //   data: imprimir
                // }]);
            }

            qz.websocket
                .connect()
                .then(() => {
                    //console.log('Conectado QZ!');
                    // return qz.printers.find(); //array de impressoras
                    if (!impressora) {
                        impressora = qz.printers.getDefault()
                    }

                    const print = impressora //impressora padrao
                    localStorage.setItem("impressora", JSON.stringify(print))
                    return print
                })
                .then(printer => {
                    if (!localStorage.getItem("qz")) {
                        let config = qz.configuracoes.create(impressora, {
                            copies: 1,
                            scaleContent: true,
                            margins: { top: 0, right: 0, bottom: 0, left: 0 },
                            orientation: "portrait",
                        })

                        const tamanhoFonte =
                            !configuracoes.printer.tamanhoFonteImpressao ||
                            configuracoes.printer.tamanhoFonteImpressao < 6
                                ? 14
                                : configuracoes.printer.tamanhoFonteImpressao

                        for (var i = 0; i < qtd; i++) {
                            let data = [
                                {
                                    type: "html",
                                    format: "plain", // or 'plain' if the data is raw HTML
                                    data: `<span 
                      style="position:absolute;
                            color:#0c0d0e;
                            top:0;
                            width:100%;
                            margin:0;
                            padding:0;
                            font-weight: 700;
                            line-height: normal;
                            font-size: ${tamanhoFonte}px;
                            font-family: Arial,sans-serif;
                        ">
                        ${texto.replace(/\n/g, "<br>")}
                      </span>`,
                                },
                            ]
                            qz.print(config, data)
                        }

                        return true
                    }

                    let config = qz.configuracoes.create(printer, {
                        copies: 1,
                        scaleContent: true,
                        margins: { top: 0, right: 0, bottom: 0, left: 0 },
                        orientation: "portrait",
                    })
                    let imprimir = ""
                    for (var i = 0; i < qtd; i++) {
                        imprimir += `${texto}\x0A\x0A\x0A\x0A\x0A\x0A\x0A\x1B\x69`
                    }
                    return qz.print(config, [
                        {
                            data: imprimir,
                        },
                    ])
                })
                .then(() => {})
                .catch(err => {
                    console.error("erro", err)
                })
        } catch (error) {
            console.error(error)
            RegistrarLogErro(error, "imprimirQz")
        }
    }

    const { enqueueSnackbar } = useSnackbar()

    const { configuracoes } = useContext(ServicosContext)

    const alertStart = (msg, tipo) => {
        enqueueSnackbar(msg, { variant: tipo })
    }

    return finalizarMesa ? (
        <Dialog
            open={finalizarMesa}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setFinalizarMesa(false)}
        >
            <form onSubmit={cadastrarUsuario}>
                <DialogTitle>{`Fechar Mesa ${pedidosDaMesa.numeroMesa}`}</DialogTitle>
                <DialogContent>
                    {pedidosDaMesa.pedidosMesa.map((pedido, index) => (
                        <Row style={{ margin: "7px 0" }} key={index}>
                            <Col xs={12} md={12} lg={12}>
                                <Button
                                    style={{ background: "#d8d8d8" }}
                                    fullWidth
                                    onClick={() => {
                                        mandarImprimir(pedido?.textoImpressao, 1)
                                    }}
                                >
                                    <PrintIcon style={{ marginRight: "10px" }} />
                                    {index + 1}º Pedido{" "}
                                    {Intl.NumberFormat("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                    }).format(pedido.valorTotal)}
                                </Button>
                            </Col>
                        </Row>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setFinalizarMesa(false)
                        }}
                    >
                        cancelar
                    </Button>
                    <Button
                        onClick={() => {
                            mandarImprimir(pedidosDaMesa.impressao, 1)
                        }}
                        style={{ color: "#28a745" }}
                    >
                        Imprimir Todas
                    </Button>

                    <Button
                        onClick={() => {
                            fecharPedidoMesa(pedidosDaMesa)
                        }}
                        style={{ color: "white", background: "#dc3545" }}
                    >
                        Fechar Mesa
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    ) : (
        <React.Fragment></React.Fragment>
    )
}
