import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    makeStyles,
    useMediaQuery,
    useTheme,
} from "@material-ui/core"
import AccountCircleIcon from "@material-ui/icons/AccountCircle"
import React, { useContext } from "react"
import iconClienteFiel from "../../../assets/icon-512x512.png"
import iconIfood from "../../../assets/ifood-logo.png"
import useRecebedor from "../../../contexts/RecebedorContext"
import ServicosContext from "../../../contexts/servicosContext"
import RepresentanteSelector from "../../../functions/RepresentanteSelector"
import { verificarPrintQRCode } from "../../../pages/RecebedorDePedidos/functions"
import TextoComandaPedido from "./TextoComandaPedido"
import Transition from "./Transition"

const useStyles = makeStyles(theme => ({
    botoesContainer: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "0.5rem",
        "& > button": {
            color: "white",
        },
        [theme.breakpoints.down("xs")]: {
            gridTemplateColumns: "1fr",
        },
    },
}))

export default function DialogInformacoesPedido({ tabStatus, mudarStatusPara, mandarImprimir }) {
    const classes = useStyles()
    const { modalPedido, closeModalPedido, autorizacao } = useRecebedor()
    const { configuracoes } = useContext(ServicosContext)

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"))

    const pedido = modalPedido
    const open = !!pedido.id
    const isPainelAtivo = tabStatus === "CRUD"

    const openPerfilCliente = () => {
        window.open(
            "https://sistema.appclientefiel.com.br/web/historicoclientefornecedor/" +
                pedido.customer.id,
            "_blank"
        )
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            TransitionComponent={Transition}
            maxWidth="xs"
            fullWidth
            keepMounted
            onClose={closeModalPedido}
        >
            <DialogTitle>
                Informações do Pedido
                {!(configuracoes.user.perfil === "ADMINISTRADOR") ? (
                    <IconButton
                        onClick={openPerfilCliente}
                        style={{ marginLeft: "1em", float: "right" }}
                    >
                        <AccountCircleIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent>
                {isPainelAtivo ? (
                    pedido.status === 0 ? (
                        <div className={classes.botoesContainer}>
                            <Button
                                onClick={() => {
                                    mudarStatusPara(1, autorizacao, pedido)
                                }}
                                fullWidth
                                variant="contained"
                                style={{ backgroundColor: "#28a745" }}
                            >
                                Aceitar
                            </Button>
                            <Button
                                onClick={() => {
                                    mudarStatusPara(-1, autorizacao, pedido)
                                }}
                                fullWidth
                                variant="contained"
                                style={{ backgroundColor: "#dc3545" }}
                            >
                                Cancelar
                            </Button>
                        </div>
                    ) : pedido.status > 0 ? (
                        <div className={classes.botoesContainer}>
                            <Button
                                onClick={() => {
                                    mudarStatusPara(-1, autorizacao, pedido)
                                    closeModalPedido()
                                }}
                                fullWidth
                                variant="contained"
                                style={{ backgroundColor: "#dc3545" }}
                            >
                                Cancelar
                            </Button>
                            <Button
                                fullWidth
                                onClick={() => {
                                    mudarStatusPara(7, autorizacao, pedido)
                                    closeModalPedido()
                                }}
                                variant="contained"
                                style={{ backgroundColor: "#fd7e14" }}
                            >
                                Em Preparação
                            </Button>
                            <Button
                                fullWidth
                                onClick={() => {
                                    mudarStatusPara(
                                        pedido.type === "1" || pedido.type === "2" ? 6 : 3,
                                        autorizacao,
                                        pedido
                                    )
                                    closeModalPedido()
                                }}
                                variant="contained"
                                style={{ backgroundColor: "#0080ff" }}
                            >
                                {pedido.type === "1" || pedido.type === "2"
                                    ? "Pronto p/ Retirada"
                                    : "Saiu p/ Entrega"}
                            </Button>
                            <Button
                                fullWidth
                                onClick={() => {
                                    mudarStatusPara(4, autorizacao, pedido)
                                    closeModalPedido()
                                }}
                                variant="contained"
                                style={{ backgroundColor: "#28a745" }}
                            >
                                Finalizado
                            </Button>
                        </div>
                    ) : null
                ) : null}

                {pedido.plataforma === "ifood_integracao" ? (
                    <img src={iconIfood} style={{ width: "100px" }} alt="pedido ifood" />
                ) : null}
                <TextoComandaPedido pedido={pedido} />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => {
                        mandarImprimir({
                            pedido: pedido,
                            texto: pedido.texto,
                            qtd: 1,
                            qrcode: verificarPrintQRCode(pedido),
                            imprimirNota: true,
                        })
                    }}
                >
                    Imprimir
                </Button>
                <Button variant="contained" onClick={closeModalPedido}>
                    Voltar
                </Button>
            </DialogActions>
        </Dialog>
    )
}
