import { useSnackbar } from "notistack"
import React, { useContext, useState } from "react"
import CaixaContext from "../../contexts/caixaContext"

import Button from "@material-ui/core/Button"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { Col, Row } from "react-bootstrap"

import DialogAddCaixa from "./components/DialogAddCaixa"

import Cabecalho from "../Cabecalho"
import CardAddCaixa from "./components/CardAddCaixa"
import CardCaixas from "./components/CardCaixas"

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 999999,
        color: "#fff",
    },

    divChip: {
        marginBottom: "1em",
        textAlign: "center",
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    tipo: {
        display: "flex",
        flexDirection: "column",
    },
    appContainer: {
        paddingTop: "6em",
        backgroundColor: "#f4f4f4",
        // height: "100%",
        // minHeight: "-webkit-fill-available",
    },
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    appBar: {
        position: "fixed",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    trocarEstabelecimento: {
        marginTop: "1em",
        textAlign: "center",
    },

    list: {
        width: "100%",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}))

export default function MostrarCaixas(props) {
    const theme = useTheme()
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()
    const [openDialogAddCaixa, setOpenDialogAddCaixa] = React.useState(false)
    const autorizacao = localStorage.getItem("chaveSistema")
    const [tiposCaixasPermitidosParaAbrir, setTiposCaixasPermitidosParaAbrir] = useState([]) //quando clicar no caixa
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

    const {
        handleBuscarCaixasAbertos,
        estabelecimentos,
        estabelecimentoSelecionado,
        setCaixasAbertos,
        setControladorTela,
        caixasAbertos,
    } = useContext(CaixaContext)

    return (
        <>
            <Cabecalho pagina={"Caixas"} setAbrirCaixa={() => {}} />
            <Row style={{ alignContent: "center", justifyContent: "center", marginTop: "2em" }}>
                <Col xs={12} md={12} lg={12} style={{ textAlign: "center", marginBottom: "1em" }}>
                    <Typography variant="h5" gutterBottom>
                        {estabelecimentoSelecionado.nome}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        Esses são os caixas abertos do estabelecimento.
                    </Typography>
                </Col>
                {caixasAbertos.map((caixaAberto, index) => (
                    <Col xs={12} md={6} lg={4} key={index} className="colCardServico">
                        <CardCaixas caixaAberto={caixaAberto} />
                    </Col>
                ))}

                <Col xs={12} md={6} lg={4} className="colCardServico">
                    <CardAddCaixa
                        setOpenDialogAddCaixa={setOpenDialogAddCaixa}
                        setTiposCaixasPermitidosParaAbrir={setTiposCaixasPermitidosParaAbrir}
                    />
                </Col>

                {estabelecimentos.length > 1 && (
                    <Col xs={12} md={12} lg={12} className={classes.trocarEstabelecimento}>
                        <Button
                            onClick={() => {
                                setControladorTela(1)
                                setCaixasAbertos([])
                            }}
                            color="primary"
                        >
                            Trocar Estabelecimento
                        </Button>
                    </Col>
                )}

                <DialogAddCaixa
                    open={openDialogAddCaixa}
                    setOpen={setOpenDialogAddCaixa}
                    tiposCaixasPermitidosParaAbrir={tiposCaixasPermitidosParaAbrir}
                    setTiposCaixasPermitidosParaAbrir={setTiposCaixasPermitidosParaAbrir}
                />
            </Row>
        </>
    )
}
