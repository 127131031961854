import {
    Button,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@material-ui/core"
import { Add, Remove, Search } from "@material-ui/icons"
import { useSnackbar } from "notistack"
import { useContext, useEffect, useState } from "react"
import LoadingContext from "../../../contexts/loadingContext"
import { ativarInativarMassa } from "../../../services/cardapio"
import { salvarEdicaoEmMassa } from "../../../services/integracao"
import { useCheckboxContext } from "./CheckboxContext"
import DialogConfirmacaoMassa from "./DialogConfirmacaoMassa"
import PaginatedList from "./paginacao"

const useStyles = makeStyles(() => ({
    container: {
        width: "99vw",
        height: "calc(100vh - 88px)",
        backgroundColor: "white",
        borderRadius: "10px",
        boxShadow: "0px 0px 5px grey",
        textAlign: "left",
        padding: "1em",
        display: "flex",
        flexDirection: "column",
        marginBottom: "1rem",
        marginTop: "-3.5em",
    },

    botaoSalvar: {
        backgroundColor: "#14A44D",
        color: "white",
        "&:hover": {
            backgroundColor: "#28B76C",
        },
    },
    containerList: {
        flexGrow: 1,
        overflow: "auto",
    },
}))

export default function EdicaoEmMassa({ items, setItems, handleCadastrarImagem, estabelecimento }) {
    const Authorization = localStorage.getItem("chaveSistema")

    const { setLoading } = useContext(LoadingContext)

    const [valorPesquisa, setValorPesquisa] = useState("")
    const [resetPagination, setResetPagination] = useState(false)
    const [novaAtividade, setNovaAtividade] = useState(null)

    const procurarProduto = () => {
        const newBuscarMassa = document.getElementById("buscarMassa").value.toLowerCase()
        setValorPesquisa(newBuscarMassa)
        setResetPagination(!resetPagination)
    }

    const handleInputChange = (id, newValue) => {
        const numericValue = newValue.replace(/\D/g, "")

        const formattedValue = numericValue ? (parseInt(numericValue, 10) / 100).toFixed(2) : "0.00"

        const updatedItems = items.map(item =>
            item.id === id ? { ...item, valor: formattedValue } : item
        )
        setItems(updatedItems)
    }

    const [categoriaSelecionada, setCategoriaSelecionada] = useState(null)

    const handleChangeCategoriaSelecionada = e => {
        setCategoriaSelecionada(e.target.value)
    }

    const [itensMostrar, setItensMostrar] = useState(items)

    useEffect(() => {
        if (categoriaSelecionada) {
            if (valorPesquisa) {
                setItensMostrar(
                    items.filter(
                        item =>
                            item.categoria === categoriaSelecionada &&
                            item.nome.toLowerCase().includes(valorPesquisa.toLowerCase())
                    )
                )
            } else {
                setItensMostrar(items.filter(item => item.categoria === categoriaSelecionada))
            }
        } else {
            if (valorPesquisa) {
                setItensMostrar(
                    items.filter(item =>
                        item.nome.toLowerCase().includes(valorPesquisa.toLowerCase())
                    )
                )
            } else {
                setItensMostrar(items)
            }
        }
    }, [categoriaSelecionada, items, valorPesquisa])

    const campoVazio = e => {
        if (!e.target.value) {
            procurarProduto()
        }
        return
    }

    const classes = useStyles()
    const { checkedItems, selectAll, deselectAll } = useCheckboxContext()

    const handleSelectAll = () => {
        selectAll(itensMostrar)
    }
    const handleDeselectAll = () => {
        deselectAll()
    }

    const [alterarValor, setAlterarValor] = useState()
    const handleChangeAlterarValor = e => {
        const value = e.target.value

        if ((!isNaN(value) && parseFloat(value) >= 0) || value === "") {
            setAlterarValor(value)
        }
    }

    const acrescentar = () => {
        const updatedItems = items.map(item => {
            if (item.tipo === "P" || item.tipo === "O") {
                if (checkedItems[item.id] === true) {
                    const novoValor = (Number(item.valor) + Number(alterarValor)).toFixed(2)
                    return { ...item, valor: novoValor }
                }
            }
            return item
        })

        setItems(updatedItems)
    }

    const decrementar = () => {
        const updatedItems = items.map(item => {
            if (item.tipo === "P" || item.tipo === "O") {
                if (checkedItems[item.id] === true) {
                    const novoValor = (Number(item.valor) - Number(alterarValor)).toFixed(2)
                    return { ...item, valor: novoValor }
                }
            }
            return item
        })

        setItems(updatedItems)
    }

    const desativar = async () => {
        setLoading(true)

        const data = items.reduce((acc, item) => {
            if (checkedItems[item.id] && item) {
                acc.push({ id: item.id, tipo: item.tipo, ativo: false })
            }
            return acc
        }, [])

        if (data.length > 0) {
            const response = await ativarInativarMassa(estabelecimento.id, data, Authorization)
            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
            } else {
                const idsToUpdate = new Set(data.map(itemData => itemData.id))

                items.forEach(item => {
                    if (item?.id && idsToUpdate.has(item.id)) {
                        item.ativo = false
                    } else if (item?.id && idsToUpdate.has(item?.id)) {
                        item.ativo = false
                    }
                })
                alertStart("Disponibilidade dos itens alterada com sucesso", "success")
            }
        }

        setLoading(false)
    }

    const ativar = async () => {
        setLoading(true)

        const data = items.reduce((acc, item) => {
            if (checkedItems[item.id] && item) {
                acc.push({ id: item.id, tipo: item.tipo, ativo: true })
            }
            return acc
        }, [])

        if (data.length > 0) {
            const response = await ativarInativarMassa(estabelecimento.id, data, Authorization)
            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
            } else {
                const idsToUpdate = new Set(data.map(itemData => itemData.id))

                items.forEach(item => {
                    if (item?.id && idsToUpdate.has(item.id)) {
                        item.ativo = true
                    } else if (item?.id && idsToUpdate.has(item.id)) {
                        item.ativo = true
                    }
                })
                alertStart("Disponibilidade dos itens alterada com sucesso", "success")
            }
        }

        setLoading(false)
    }

    const [openMassa, setOpenMassa] = useState(false)
    const handleModalAlteracao = () => {
        if (Object.values(checkedItems).filter(valor => valor === true).length >= 1) {
            setOpenMassa(prev => !prev)
        }
    }

    const handleClickAcrescentar = () => {
        setNovaAtividade("acrescentar")
        handleModalAlteracao()
    }
    const handleClickDecrementar = () => {
        setNovaAtividade("decrementar")
        handleModalAlteracao()
    }

    const handleClickDesativar = () => {
        setNovaAtividade("desativar")
        handleModalAlteracao()
    }

    const handleClickAtivar = () => {
        setNovaAtividade("ativar")
        handleModalAlteracao()
    }

    const { enqueueSnackbar } = useSnackbar()
    const alertStart = (msg, tipo) => {
        enqueueSnackbar(msg, { variant: tipo })
    }

    const handleSalvar = async () => {
        setLoading(true)

        const response = await salvarEdicaoEmMassa(items, Authorization)
        if (response.retornoErro) {
            alertStart("Erro ao salvar os produtos", "error")
        } else {
            alertStart("Produtos salvos com sucesso", "success")
        }

        setLoading(false)
    }

    const categorias = [...new Set(items?.map(item => item.categoria))]
    return (
        <>
            <DialogConfirmacaoMassa
                open={openMassa}
                handleModalAlteracao={handleModalAlteracao}
                quantidade={Object.values(checkedItems).filter(valor => valor === true).length}
                acrescentar={acrescentar}
                decrementar={decrementar}
                ativar={ativar}
                desativar={desativar}
                novaAtividade={novaAtividade}
                setNovaAtividade={setNovaAtividade}
                checkedItems={checkedItems}
            />
            <Grid className={classes.container}>
                <Grid container alignItems="center" direction="row">
                    <Grid item container xs={12} sm={12} md={2}>
                        <Typography variant="h6">Edição em Massa</Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        sm={12}
                        md={3}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item>
                            <TextField
                                type="text"
                                variant="outlined"
                                label="Acrésc./Decrés."
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                    style: { textAlign: "right" },
                                }}
                                value={alterarValor}
                                onChange={handleChangeAlterarValor}
                            />
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleClickAcrescentar}>
                                <Add />
                            </IconButton>
                            <IconButton onClick={handleClickDecrementar}>
                                <Remove />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        alignItems="center"
                        justifyContent="center"
                        xs={12}
                        sm={12}
                        md={4}
                        spacing={1}
                    >
                        <Grid item>
                            <Button variant="contained" onClick={handleClickDesativar}>
                                Desativar
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" onClick={handleClickAtivar}>
                                Ativar
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button
                                variant="contained"
                                className={classes.botaoSalvar}
                                onClick={handleSalvar}
                            >
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        justifyContent="center"
                        alignItems="center"
                        xs={12}
                        sm={12}
                        md={3}
                    >
                        <Grid item container alignItems="center" justifyContent="flex-end">
                            <TextField
                                type="text"
                                id="buscarMassa"
                                variant="outlined"
                                label="Buscar Produto"
                                size="small"
                                onChange={campoVazio}
                            />
                            <IconButton onClick={procurarProduto} tabIndex={0}>
                                <Search />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container>
                    <Grid item alignItems="center" xs={12} sm={6} md={10}>
                        <Button variant="contained" onClick={handleSelectAll}>
                            Marcar Todos
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleDeselectAll}
                            style={{ marginLeft: "10px" }}
                        >
                            Desmarcar Todos
                        </Button>
                    </Grid>
                    <Grid xs={12} sm={6} md={2}>
                        <FormControl fullWidth size="small" variant="outlined">
                            <InputLabel>Categoria</InputLabel>
                            <Select
                                label="Categoria"
                                value={categoriaSelecionada}
                                onChange={handleChangeCategoriaSelecionada}
                                style={{ backgroundColor: "#fff" }}
                            >
                                <MenuItem value={null}>Todas</MenuItem>
                                {categorias?.map((categoria, i) => (
                                    <MenuItem key={categoria} value={categoria}>
                                        {categoria}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Divider />
                <Grid className={classes.containerList}>
                    <PaginatedList
                        items={itensMostrar}
                        resetPagination={resetPagination}
                        handleCadastrarImagem={handleCadastrarImagem}
                        handleInputChange={handleInputChange}
                        setItems={setItems}
                    />
                </Grid>
            </Grid>
        </>
    )
}
