import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core"
import { Print } from "@material-ui/icons"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { mandarImprimir } from "../../../services/functionsGerais"
import CardPedido from "./CardPedido"

const DialogPedido = ({
    openPedido,
    handleClosePedido,
    fullWidth,
    alertStart,
    pedidoEnviar,
    atualizarMesas,
    classes,
    mesa,
}) => {
    const history = useHistory()
    return (
        <Dialog open={openPedido} onClose={handleClosePedido} fullWidth={fullWidth}>
            <DialogTitle>Pedido</DialogTitle>
            <DialogContent>
                <CardPedido
                    alertStart={alertStart}
                    pedidoEnviar={pedidoEnviar}
                    handleClosePedido={handleClosePedido}
                    atualizarMesas={atualizarMesas}
                    classes={classes}
                />
            </DialogContent>
            <DialogActions className={classes.actionsCardPedidos}>
                <Button
                    onClick={() => mandarImprimir({ texto: pedidoEnviar?.textoImpressao })}
                    startIcon={<Print />}
                >
                    Imprimir
                </Button>
                <div>
                    <Button
                        onClick={() =>
                            history.push(
                                "/servicos/modulo/registrador-de-pedidos?mesa=" +
                                    mesa.numeroMesa +
                                    "&idPedido=" +
                                    pedidoEnviar.id
                            )
                        }
                    >
                        Adicionar Item
                    </Button>
                    <Button onClick={handleClosePedido}>Fechar</Button>
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default DialogPedido
