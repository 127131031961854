import { useContext } from "react"
import CaixaContext from "../../../contexts/caixaContext"

import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import { useSnackbar } from "notistack"
import { Col, Row } from "react-bootstrap"
import LoadingContext from "../../../contexts/loadingContext"
import { RegistrarLogErro } from "../../../services/clientefiel"
import { obterTiposCaixaParaAbrir } from "../../../services/controlecaixa"

const useStyles = makeStyles(theme => ({}))

export default function CardAddCaixa({ setOpenDialogAddCaixa, setTiposCaixasPermitidosParaAbrir }) {
    const { setLoading } = useContext(LoadingContext)
    const { enqueueSnackbar } = useSnackbar()
    const theme = useTheme()
    const classes = useStyles()
    const autorizacao = localStorage.getItem("chaveSistema")

    const { estabelecimentoSelecionado } = useContext(CaixaContext)

    const handleObterTiposCaixaParaAbrir = async id => {
        try {
            setLoading(true)
            const tiposCaixaParaAbrirRetorno = await obterTiposCaixaParaAbrir(id, autorizacao)
            console.log("---Tipos Permitidos para abrir--", tiposCaixaParaAbrirRetorno)
            if (tiposCaixaParaAbrirRetorno.status >= 300) {
                enqueueSnackbar(tiposCaixaParaAbrirRetorno.message, { variant: "error" })
            } else {
                if (tiposCaixaParaAbrirRetorno.response.length === 0) {
                    enqueueSnackbar(
                        "Não é possivel abrir caixa! Você não tem nenhum tipo dispónivel.",
                        { variant: "warning" }
                    )
                } else {
                    setTiposCaixasPermitidosParaAbrir(tiposCaixaParaAbrirRetorno.response)
                    setOpenDialogAddCaixa(true)
                }
            }
        } catch (error) {
            RegistrarLogErro(error, "handleObterTiposCaixaParaAbrir", id)
        }
        setLoading(false)
    }

    return (
        <Card className={classes.root}>
            <CardActionArea
                onClick={() => {
                    handleObterTiposCaixaParaAbrir(estabelecimentoSelecionado.id)
                }}
                style={{ height: "100%", flexFlow: "column-reverse", alignItems: "normal" }}
            >
                <Row
                    style={{
                        margin: 0,
                        textAlign: "",
                        width: "100%",
                        padding: "1em",
                        alignItems: "center",
                    }}
                >
                    <Col xs={12} md={12} lg={12} style={{ padding: 0, textAlign: "center" }}>
                        <AddCircleIcon style={{ fontSize: "7.5rem", opacity: "0.3" }} />
                    </Col>
                    <Col xs={12} md={12} lg={12} style={{ padding: 0, textAlign: "center" }}>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Abrir um Novo Caixa
                        </Typography>
                    </Col>
                </Row>
            </CardActionArea>
        </Card>
    )
}
