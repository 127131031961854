import { useSnackbar } from "notistack"
import { useContext } from "react"
import CaixaContext from "../../../contexts/caixaContext"
import LoadingContext from "../../../contexts/loadingContext"

import { dataAtualFormatada } from "../../../services/controlecaixa"

import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardMedia from "@material-ui/core/CardMedia"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { Col, Row } from "react-bootstrap"
import CAIXA_ICON from "../../../assets/icons/svg/033-admin.svg"

const useStyles = makeStyles(theme => ({}))

export default function CardCaixas({ caixaAberto }) {
    const { setCaixaSelecionada, handleBuscarCaixa } = useContext(CaixaContext)

    const { setLoading } = useContext(LoadingContext)
    const { enqueueSnackbar } = useSnackbar()
    const theme = useTheme()
    const classes = useStyles()
    const autorizacao = localStorage.getItem("chaveSistema")

    return (
        <Card className={classes.root}>
            <CardActionArea
                onClick={() => {
                    handleBuscarCaixa(caixaAberto.id)
                }}
                style={{ height: "100%", flexFlow: "column-reverse", alignItems: "normal" }}
            >
                <Row
                    style={{
                        margin: 0,
                        textAlign: "",
                        width: "100%",
                        padding: "1em",
                        alignItems: "center",
                    }}
                >
                    <Col xs={3} md={3} lg={3} style={{ padding: 0 }}>
                        <CardMedia
                            component="img"
                            className={"logoLojas"}
                            style={{ opacity: "0.65" }}
                            alt={caixaAberto.id}
                            image={CAIXA_ICON}
                            title={caixaAberto.id}
                        />
                    </Col>

                    <Col xs={9} md={9} lg={9}>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {dataAtualFormatada(new Date(caixaAberto.abertoEm))}
                        </Typography>

                        <Typography gutterBottom component="h2">
                            {caixaAberto.nomeAbertura}
                        </Typography>

                        {caixaAberto.permissoesCaixa.map((idTipo, index) => (
                            <span key={index}> • {idTipo.nome}</span>
                        ))}
                    </Col>
                </Row>

                <CardActions style={{ padding: "0" }}>
                    <Typography
                        variant={"button"}
                        style={{ textAlign: "center", padding: "3px" }}
                        className={(true ? "botaoVerde" : "botaoVermelho") + " naoArredondado"}
                    >
                        ABERTO
                    </Typography>
                </CardActions>
            </CardActionArea>
        </Card>
    )
}
