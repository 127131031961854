import Backdrop from "@material-ui/core/Backdrop"
import CircularProgress from "@material-ui/core/CircularProgress"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
    backdrop: {
        color: "black",
        zIndex: "1302!important",
        backgroundColor: "rgb(255 255 255 / 50%)",
    },
}))

export default function Loading(props) {
    const configuracoes = JSON.parse(localStorage.getItem("configs"))

    const classes = useStyles()

    const { loading } = props

    return (
        <Backdrop
            className={classes.backdrop}
            style={{ color: configuracoes?.corSitePrimaria }}
            open={loading}
        >
            <img
                style={{
                    width: "40px",
                    position: "fixed",
                    borderRadius: "100%",
                }}
                src={
                    configuracoes?.user?.parametros?.estabelecimento?.urlLogo
                        ? configuracoes?.user?.parametros?.estabelecimento?.urlLogo
                        : ""
                }
            />
            <CircularProgress color="inherit" size={80} />
        </Backdrop>
    )
}
