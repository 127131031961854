import Backdrop from "@material-ui/core/Backdrop"
import CircularProgress from "@material-ui/core/CircularProgress"
import Container from "@material-ui/core/Container"
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles"
import AlertFixo from "@material-ui/lab/Alert"
import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import Cabecalho from "../Cabecalho"

import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Paper from "@material-ui/core/Paper"
import Select from "@material-ui/core/Select"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import "date-fns"
import { buscarestabelecimentos } from "../../services/integracao"

import DialogFiltro from "./dialogFiltro"

import DateFnsUtils from "@date-io/date-fns"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { RegistrarLogErro } from "../../services/clientefiel"
import { buscarNotas } from "../../services/notasFiscais"

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 999999,
        color: "#fff",
    },
    tipo: {
        display: "flex",
        flexDirection: "column",
    },
    appContainer: {
        paddingTop: "6em",
        backgroundColor: "#f4f4f4",
        // height: "100%",
        // minHeight: "-webkit-fill-available",
    },
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    appBar: {
        position: "fixed",
    },
    sucesso: {
        backgroundColor: "#28a745",
        color: "#fff",
    },
    warning: {
        backgroundColor: "#ffc107",
        color: "#fff",
    },
    error: {
        backgroundColor: "#dc3545",
        color: "#fff",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    table: {
        minWidth: 700,
    },
    dataFiltro: {
        width: "25%",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch",
        },
    },
}))

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell)

const StyledTableRow = withStyles(theme => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow)

export default function Caixa() {
    //DECLARAÇÃO DE VARIÁVEIS

    const [loading, setLoading] = React.useState(false)
    const [notasEncontradas, setNotasEncontradas] = React.useState([])
    const [estabelecimentos, setEstabelecimentos] = React.useState({
        todos: [],
        selecionado: {},
    })
    const [alert, setAlert] = useState({ status: false, tipo: "success", mesangem: "" })
    const theme = useTheme()
    const classes = useStyles()
    const autorizacao = localStorage.getItem("chaveSistema")
    const [selectedDate, setSelectedDate] = React.useState({
        de: new Date(new Date() - 60000 * 60 * 24 * 7),
        ate: new Date(),
    })

    console.log("selectedDate", selectedDate)

    //FUNCÕES
    const alertStart = (msg, tipo) => {
        setAlert({ status: true, tipo: tipo, mesangem: msg })
    }

    const handleDateChange1 = date => {
        setSelectedDate({
            ...selectedDate,
            de: new Date(date),
        })
    }
    const handleDateChange2 = date => {
        setSelectedDate({
            ...selectedDate,
            ate: new Date(date),
        })
    }

    useEffect(() => {
        handleBuscarDadosEstabelecimentos()
    }, [])

    const handleBuscarDadosEstabelecimentos = async () => {
        try {
            setLoading(true)
            const response = await buscarestabelecimentos(autorizacao)
            console.log("Parametros", response)
            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
                setLoading(false)
            } else {
                if (response.length == 1) {
                    //buscar Caixa direto
                    setEstabelecimentos({
                        todos: response,
                        selecionado: response[0],
                    })
                    handleBuscarNotasFiscais(response[0])
                } else {
                    //exibir estabelecimentos
                    setEstabelecimentos({
                        todos: response,
                        selecionado: {},
                    })
                }
                setLoading(false)
            }
        } catch (error) {
            RegistrarLogErro(error, "handleBuscarDadosEstabelecimentos")
        }
    }

    const handleBuscarNotasFiscais = async estabelecimento => {
        try {
            setLoading(true)

            const data = {
                dateStart: selectedDate.de,
                dateEnd: selectedDate.ate,
                idEstabelecimento: estabelecimento.id,
                idPedidos: null,
                statusNotaFiscal: null,
            }

            const notasFiscaisResponse = await buscarNotas(data, autorizacao)
            console.log("---Buscar Notas Fiscais--", notasFiscaisResponse)

            if (notasFiscaisResponse.status >= 300) {
                alertStart(notasFiscaisResponse.message, "error")
            } else {
                setNotasEncontradas(notasFiscaisResponse.response)
            }
        } catch (error) {
            RegistrarLogErro(error, "handleBuscarNotasFiscais")
        }
        setLoading(false)
    }

    const [filtroAberto, setFiltroAberto] = React.useState({
        tipo: "",
        valor: "",
        open: false,
    })

    const handleFiltroAberto = event => {
        setFiltroAberto({
            tipo: event.target.value,
            valor: "",
            open: event.target.value ? true : false,
        })
    }

    //HTML

    return (
        <Container component="main" style={{ marginTop: "6em" }}>
            <Cabecalho
                pagina={"Nota Fiscal"}
                trocarEstabelecimento={setEstabelecimentos}
                estabelecimentos={estabelecimentos}
            />

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Row style={{ marginTop: "5em", textAlign: "center" }}>
                <Col xs={12} md={12} lg={12} style={{ marginBottom: "1em" }}>
                    <MuiPickersUtilsProvider variant="outlined" utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            style={{ margin: "16px 8px 8px 8px" }}
                            disableToolbar
                            variant="outlined"
                            format="dd/MM/yyyy"
                            // margin="normal"
                            // className={classes.dataFiltro}
                            // id="date-picker-inline"
                            label="De"
                            value={selectedDate.de}
                            onChange={handleDateChange1}
                            // KeyboardButtonProps={{
                            //   'aria-label': 'change date',
                            // }}
                        />
                        <KeyboardDatePicker
                            disableToolbar
                            variant="outlined"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Até"
                            value={selectedDate.ate}
                            onChange={handleDateChange2}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                        />
                    </MuiPickersUtilsProvider>

                    <FormControl
                        className={classes.formControl}
                        style={{ margin: "16px 8px 8px 8px", minWidth: "182px" }}
                    >
                        <InputLabel htmlFor="outlined-age-native-simple">Selecione</InputLabel>
                        <Select
                            native
                            value={filtroAberto.tipo}
                            onChange={handleFiltroAberto}
                            label="Selecione"
                            inputProps={{
                                name: "selecione",
                                id: "outlined-age-native-simple",
                            }}
                        >
                            <option value={null} />
                            <option value="id">Id</option>
                            <option value="nome"></option>
                            <option value="valor">Valor</option>
                        </Select>
                    </FormControl>

                    {filtroAberto.open && (
                        <DialogFiltro
                            filtroAberto={filtroAberto}
                            setFiltroAberto={setFiltroAberto}
                        />
                    )}

                    <FormControl
                        className={classes.formControl}
                        style={{ margin: "16px 8px 8px 8px", minWidth: "182px" }}
                    >
                        <InputLabel htmlFor="outlined-age-native-simple">Status</InputLabel>
                        <Select
                            native
                            // value={state.filtroAberto}
                            // onChange={handleChange}
                            label="Status"
                            inputProps={{
                                name: "status",
                                id: "outlined-age-native-simple",
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value="emProcesso">Em processo</option>
                            <option value="naoProcessado">Não processado</option>
                            <option value="processado">Processado</option>
                        </Select>
                    </FormControl>
                    <FormControl
                        className={classes.formControl}
                        style={{ margin: "16px 8px 8px 8px", minWidth: "182px" }}
                    >
                        <InputLabel htmlFor="outlined-age-native-simple">Plataforma</InputLabel>
                        <Select
                            native
                            // value={state.filtroAberto}
                            // onChange={handleChange}
                            label="Plataforma"
                            inputProps={{
                                name: "plataforma",
                                id: "outlined-age-native-simple",
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value="IOS">IOS</option>
                            <option value="Android">Android</option>
                            <option value="Site">Site</option>
                        </Select>
                    </FormControl>
                </Col>
                <Col xs={12} md={12} lg={12}>
                    {notasEncontradas && notasEncontradas.length ? (
                        <>
                            <AlertFixo
                                severity="warning"
                                style={{ width: "100%", marginBottom: "1em" }}
                            >
                                Filtro Aplicado!
                            </AlertFixo>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Nome do Pedido</StyledTableCell>
                                            <StyledTableCell align="right">Id</StyledTableCell>
                                            <StyledTableCell align="right">Data</StyledTableCell>
                                            <StyledTableCell align="right">
                                                Plataforma
                                            </StyledTableCell>
                                            <StyledTableCell align="right">Status</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {notasEncontradas.map(row => (
                                            <StyledTableRow key={row.name}>
                                                <StyledTableCell component="th" scope="row">
                                                    {row.nomePedido}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {row.id}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {row.dataPedido}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {row.plataformaPedido}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <Button
                                                        style={
                                                            row.statusNotaFiscal === "em processo"
                                                                ? {
                                                                      background: "#ffc107",
                                                                      color: "white",
                                                                      padding: "5px 28px",
                                                                  }
                                                                : row.statusNotaFiscal ===
                                                                  "processado"
                                                                ? {
                                                                      background: "#28a745",
                                                                      color: "white",
                                                                      padding: "5px 30px",
                                                                  }
                                                                : {
                                                                      background: "#dc3545",
                                                                      color: "white",
                                                                  }
                                                        }
                                                        variant="outlined"
                                                    >
                                                        {row.statusNotaFiscal}
                                                    </Button>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    ) : (
                        <AlertFixo severity="error" style={{ marginTop: "1em" }}>
                            Infelizmente não encontramos nenhuma nota fiscal.
                        </AlertFixo>
                    )}
                </Col>
            </Row>
        </Container>
    )
}
