import PinnDeliveryRepresentante from "./PinnDeliveryRepresentante"
class LocalhostRepresentante {
    //unique
    getLocalhostRepresentante() {
        return PinnDeliveryRepresentante
        // return new ClienteFielRepresentante();
    }

    getIdentifier() {
        return "localhost"
    }

    getUrlSistemaAdministrativo() {
        return this.getLocalhostRepresentante().getUrlSistemaAdministrativo()
    }

    isClienteFiel() {
        return false
    }
}

const classe = new LocalhostRepresentante()
export default classe
