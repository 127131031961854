//INIT
import React from "react"

import { textoHTML } from "../../../services/functionsGerais"

//IMPORTS
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Slide from "@material-ui/core/Slide"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { Row } from "react-bootstrap"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />
})

export default function VisualizarPedido(props) {
    const { pedido, setModalPedido } = props
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                open={pedido.id}
                TransitionComponent={Transition}
                keepMounted
                scroll={"body"}
                onClose={() => {
                    setModalPedido({})
                }}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Informações do Pedido"}</DialogTitle>
                <DialogContent>
                    <Row style={{ justifyContent: "center" }}>
                        <div id="textoHTML">{pedido.texto && textoHTML(pedido.texto)}</div>
                    </Row>
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={() =>{setModalInformacoes(false)}} >
                        voltar
                    </Button> */}
                </DialogActions>
            </Dialog>
        </>
    )
}
