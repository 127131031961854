import { Card, CardContent, Collapse, makeStyles } from "@material-ui/core"
import { useContext, useEffect, useState } from "react"
import LoadingContext from "../../../contexts/loadingContext"
import {
    ConfirmacaoCancelamentoUber,
    DadosEntregadorUber,
    DetalhesEntregaUber,
    SolicitarEntregadorButton,
} from "./components"
import { buscarDetalhesEntregaUber, solicitarEntregadorUber } from "./services"

const useStyles = makeStyles({
    telaIntegracaoUber: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "4px",
        "&:last-child": {
            padding: "4px",
        },
    },
})

export default function ({
    expandIntegracaoUber,
    setExpandIntegracaoUber,
    pedido,
    indexPedido,
    autorizacao,
    mudarStatus,
    setCorIconeUber,
    setIconeUberPendente,
    alertStart,
}) {
    const classes = useStyles()

    const [entregadorChamado, setEntregadorChamado] = useState(true)
    const [entregaFinalizada, setEntregaFinalizada] = useState(false)
    const [detalhesEntregaUber, setDetalhesEntregaUber] = useState({})
    const [abrirDetalhes, setAbrirDetalhes] = useState(false)
    const [abrirCancelamento, setAbrirCancelamento] = useState(false)

    const { setLoading } = useContext(LoadingContext)

    const verificarStatusDaEntrega = status => {
        if (status === "pending") setIconeUberPendente(true)
        else setIconeUberPendente(false)

        switch (status) {
            case "pending":
                setCorIconeUber("#000")
                setEntregadorChamado(true)
                setEntregaFinalizada(false)
                return
            case "pickup":
                setCorIconeUber("#000")
                setEntregadorChamado(true)
                setEntregaFinalizada(false)
                return
            case "pickup_complete":
            case "ongoing":
            case "dropoff":
                setCorIconeUber("#0080ff")
                setEntregadorChamado(true)
                setEntregaFinalizada(false)
                return
            case "delivered":
                setCorIconeUber("#51a351")
                setEntregadorChamado(false)
                setEntregaFinalizada(true)
                mudarStatus(pedido, indexPedido) //Concerta erro no qual o pedido não move da tela de "Em entrega" para "Entregue" //GAMBIARRA
                return
            case "returned":
                mudarStatus(pedido, indexPedido) //Concerta erro no qual o pedido não move da tela de "Em entrega" para "Entregue" //GAMBIARRA
            case "canceled":
                setCorIconeUber("#dc3545")
                setEntregadorChamado(false)
                setEntregaFinalizada(true)
                return
            default:
                setCorIconeUber("#868686")
                setEntregadorChamado(false)
                setEntregaFinalizada(false)
                return
        }
    }

    useEffect(() => {
        setEntregadorChamado(true)
        let status
        if (pedido.descricaoStatus === "Entregue") {
            status = "delivered" //Concerta erro de não atualizar o pedido.statusEntrega para "delivered" //GAMBIARRA
        } else {
            status = detalhesEntregaUber.status ? detalhesEntregaUber.status : pedido.statusEntrega
        }
        verificarStatusDaEntrega(status)
    }, [detalhesEntregaUber, pedido])

    const buscarDetalhes = async () => {
        const detalhes = await buscarDetalhesEntregaUber(pedido.id)
        if (detalhes.status < 200 || detalhes.status > 299) {
            alertStart(detalhes.data.mensagem, "error")
            setEntregadorChamado(false)
        } else {
            if (detalhes.data.uuid !== null) {
                setDetalhesEntregaUber(detalhes.data)
            }
        }
    }

    const chamarEntregador = async () => {
        setLoading(true)
        const solicitacao = await solicitarEntregadorUber(pedido.id, autorizacao)
        setLoading(false)
        if (solicitacao.data !== "") {
            if (!solicitacao.data.mensagem) {
                //Trata erro que vem como string, mas não permite JSON.parse
                const erro = solicitacao.data.split(",")[0].split(":")[1].split('"')
                alertStart(erro, "error")
                setLoading(false)
                return
            } else {
                alertStart(solicitacao.data.mensagem, "error")
            }
        }
        if (solicitacao.status < 200 || solicitacao.status > 299) {
            alertStart(solicitacao.data.mensagem, "error")
            setEntregadorChamado(false)
        } else {
            setEntregadorChamado(true)
        }
        setExpandIntegracaoUber(false)
        await buscarDetalhes()
        setLoading(false)
    }

    return (
        <>
            <Card maxWidth>
                <Collapse in={expandIntegracaoUber} mountOnEnter>
                    <CardContent className={classes.telaIntegracaoUber}>
                        {entregadorChamado || entregaFinalizada ? (
                            <DadosEntregadorUber
                                pedido={pedido}
                                detalhesEntregaUber={detalhesEntregaUber}
                                setAbrirDetalhes={setAbrirDetalhes}
                                setAbrirCancelamento={setAbrirCancelamento}
                                chamarEntregador={chamarEntregador}
                            />
                        ) : (
                            <SolicitarEntregadorButton chamarEntregador={chamarEntregador} />
                        )}
                    </CardContent>
                </Collapse>
            </Card>

            <DetalhesEntregaUber
                detalhesEntregaUber={detalhesEntregaUber}
                abrirDetalhes={abrirDetalhes}
                setAbrirDetalhes={setAbrirDetalhes}
                setAbrirCancelamento={setAbrirCancelamento}
                pedido={pedido}
                alertStart={alertStart}
                setLoading={setLoading}
                setEntregadorChamado={setEntregadorChamado}
                setDetalhesEntregaUber={setDetalhesEntregaUber}
                buscarDetalhes={buscarDetalhes}
            />

            <ConfirmacaoCancelamentoUber
                abrirCancelamento={abrirCancelamento}
                setAbrirCancelamento={setAbrirCancelamento}
                setLoading={setLoading}
                pedido={pedido}
                autorizacao={autorizacao}
                setAbrirDetalhes={setAbrirDetalhes}
                setExpandIntegracaoUber={setExpandIntegracaoUber}
                buscarDetalhes={buscarDetalhes}
            />
        </>
    )
}
