import React, { useContext } from "react"
import { useHistory } from "react-router-dom"

import { useSnackbar } from "notistack"
import LoadingContext from "../../../../contexts/loadingContext"
import RegistradorContext from "../../../../contexts/registradorContext"
import ServicosContext from "../../../../contexts/servicosContext"

import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Slide from "@material-ui/core/Slide"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import { Col, Row } from "react-bootstrap"

import TelefoneMask from "../../../../masks/telefone"

import { RegistrarLogErro } from "../../../../services/clientefiel"

import { atualizarUsuarioBasico } from "../../../../services/integracao"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles(theme => ({
    fixed: {
        position: "fixed",
        right: "2em",
        bottom: "2em",
        zIndex: 1000,
    },
    fixedMesa: {
        position: "fixed",
        right: "2em",
        bottom: "2em",
        zIndex: 2000,
    },
    fixedMesaFechar: {
        position: "fixed",
        right: "2em",
        bottom: "7em",
        zIndex: 2000,
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    rootAlert: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
    backdrop: {
        // zIndex: theme.zIndex.drawer + 999999,
        color: "#fff",
    },
    pos: {
        marginBottom: 12,
    },
    heading: {
        width: "100%",
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    rootList: {
        width: "100%!important",
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: "inline",
        width: "100%",
    },
    appBar: {
        position: "fixed",
    },
    appContainer: {
        paddingTop: "6em",
        backgroundColor: "#f4f4f4",
        // height: "100%",
        // minHeight: "-webkit-fill-available",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    list: {
        width: 250,
    },
    fullList: {
        width: "auto",
    },
}))

export default function Index() {
    const autorizacao = localStorage.getItem("chaveSistema")
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    )
    const classes = useStyles()
    const theme = useTheme()
    const history = useHistory()

    const { setLoading, loading } = useContext(LoadingContext)

    const { editarCliente, setEditarCliente, handleSelecionarCliente } =
        useContext(RegistradorContext)

    const { enqueueSnackbar } = useSnackbar()

    const { configuracoes } = useContext(ServicosContext)

    const alertStart = (msg, tipo) => {
        enqueueSnackbar(msg, { variant: tipo })
    }

    const atualizarUsuario = async e => {
        try {
            e.preventDefault()

            const novoTelefone = document.getElementById("telefone-cadastro")?.value
            const novoNome = document.getElementById("nome-cadastro")?.value

            if (document.getElementById("nome-cadastro")?.value === "") {
                alertStart("Nome do cliente é necessário.", "error")
                return false
            } else if (document.getElementById("telefone-cadastro")?.value === "") {
                alertStart("Telefone do cliente é necessário.", "error")
                return false
            }

            const data = {}
            data.novoTelefone = novoTelefone
            data.novoNome = novoNome
            data.idUsuario = editarCliente.id
            setLoading(true)
            setEditarCliente({})

            const retornoUsuario = await atualizarUsuarioBasico(autorizacao, data)
            if (retornoUsuario.retornoErro) {
                //algum erro EstabelecimentoAberto
                alertStart(retornoUsuario.mensagem, "error")
            } else {
                alertStart("Usuário Atualizado com sucesso.", "success")
                handleSelecionarCliente(retornoUsuario)
            }
        } catch (error) {
            RegistrarLogErro(error, "atualizarUsuario", {
                telefone: document.getElementById("telefone-cadastro")?.value,
                nome: document.getElementById("nome-cadastro")?.value,
            })
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
        setLoading(false)
    }

    return editarCliente.id ? (
        <Dialog
            open={editarCliente.id ? true : false}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setEditarCliente({})}
            // style={{"zIndex": "999999"}}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <form onSubmit={atualizarUsuario}>
                <DialogTitle id="alert-dialog-slide-title">{"Editar Cliente"}</DialogTitle>
                <DialogContent>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <TextField
                                id="nome-cadastro"
                                required
                                fullWidth
                                label="Nome"
                                defaultValue={editarCliente.nome}
                                variant="outlined"
                                style={{ background: "#fff" }}
                            />
                        </Col>
                        <Col xs={12} md={12} lg={12} style={{ marginTop: "1em" }}>
                            <TextField
                                id="telefone-cadastro"
                                required
                                fullWidth
                                label="Telefone"
                                defaultValue={editarCliente.telefone}
                                variant="outlined"
                                style={{ background: "#fff" }}
                                InputProps={{
                                    inputComponent: TelefoneMask,
                                }}
                            />
                        </Col>
                    </Row>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setEditarCliente({})
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button type={"submit"} variant="contained" color="primary">
                        Atualizar
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    ) : (
        <React.Fragment></React.Fragment>
    )
}
