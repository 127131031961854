import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TextField,
} from "@material-ui/core"
import { useEffect, useRef, useState } from "react"

const useStyles = makeStyles(() => ({
    botaoDescartar: {
        backgroundColor: "#dc3545",
        color: "white",
    },
    botaoSalvar: {
        backgroundColor: "#28a745",
        color: "white",
    },
}))

export default function DialogCategorias({
    open,
    cadastrar,
    modalCategoria,
    estabelecimento,

    handleSalvarCategoria,
    handleCloseCategoria,
}) {
    const classes = useStyles()

    const id = modalCategoria ? modalCategoria?.id : null
    const versao = modalCategoria?.versao
    const nomeRef = useRef(modalCategoria?.nome)
    const ordemExibRef = useRef(modalCategoria?.ordemExib)

    const [diaSemana, setDiaSemana] = useState(null)
    const handleChangeDiaDisponivel = e => setDiaSemana(e.target.value)
    const [turnoProduto, setTurnoProduto] = useState(null)
    const handleChangeTurnoDisponivel = e => setTurnoProduto(e.target.value)
    const [exibicaoCategoriaEnum, setExibicaoCategoriaEnum] = useState(null)
    const handleChangeExibicao = e => setExibicaoCategoriaEnum(e.target.value)
    const [ativo, setAtivo] = useState(null)
    const handleChangeAtivo = e => setAtivo(e.target.value)

    const quantidadeMinimaRef = useRef(modalCategoria?.quantidadeMinima)
    const quantidadeMaximaRef = useRef(modalCategoria?.quantidadeMaxima)

    useEffect(() => {
        if (!cadastrar) {
            setDiaSemana(modalCategoria?.diaSemana)
            setTurnoProduto(modalCategoria?.turnoProduto)
            setExibicaoCategoriaEnum(modalCategoria?.exibicaoCategoriaEnum)
            setAtivo(modalCategoria?.ativo)
        } else {
            setDiaSemana(null)
            setTurnoProduto(null)
            setExibicaoCategoriaEnum(null)
            setAtivo(true)
        }
    }, [modalCategoria])

    return (
        <>
            {open && (
                <Dialog open={open} onClose={handleCloseCategoria} maxWidth="md">
                    <DialogTitle>
                        {cadastrar ? "Cadastrar Categoria" : "Editar Categoria"}
                    </DialogTitle>
                    <form
                        onSubmit={e => {
                            e.preventDefault()
                            const data = {
                                id,
                                versao,

                                nome: nomeRef?.current?.value,
                                ordemExib: ordemExibRef?.current?.value,
                                diaSemana,
                                turnoProduto,
                                exibicaoCategoriaEnum,
                                ativo,
                                quantidadeMinima: quantidadeMinimaRef?.current?.value,
                                quantidadeMaxima: quantidadeMaximaRef?.current?.value,
                                token: "a~I?;;+_@!AL^",
                                estabelecimento: estabelecimento,
                            }
                            handleSalvarCategoria(data)
                            handleCloseCategoria()
                        }}
                    >
                        <DialogContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={9} md={9}>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        label="Nome"
                                        InputLabelProps={{ shrink: true }}
                                        placeholder="Ex: Bebidas"
                                        inputRef={nomeRef}
                                        defaultValue={modalCategoria?.nome}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3} md={3}>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        label="Posição"
                                        InputLabelProps={{ shrink: true }}
                                        placeholder="999"
                                        inputRef={ordemExibRef}
                                        defaultValue={modalCategoria?.ordemExib}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel>Dia Disponível</InputLabel>
                                        <Select
                                            label="Dia Disponível"
                                            value={diaSemana}
                                            onChange={handleChangeDiaDisponivel}
                                        >
                                            <MenuItem value={null}>Todos</MenuItem>
                                            <MenuItem value={"DOMINGO"}>Domingo</MenuItem>
                                            <MenuItem value={"SEGUNDA"}>Segunda</MenuItem>
                                            <MenuItem value={"TERCA"}>Terça</MenuItem>
                                            <MenuItem value={"QUARTA"}>Quarta</MenuItem>
                                            <MenuItem value={"QUINTA"}>Quinta</MenuItem>
                                            <MenuItem value={"SEXTA"}>Sexta</MenuItem>
                                            <MenuItem value={"SABADO"}>Sabado</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel>Turno Disponível</InputLabel>
                                        <Select
                                            label="Turno Disponível"
                                            value={turnoProduto}
                                            onChange={handleChangeTurnoDisponivel}
                                        >
                                            <MenuItem value={null}>Todos</MenuItem>
                                            <MenuItem value={"APENAS_DIURNO"}>
                                                Apenas Diurno
                                            </MenuItem>
                                            <MenuItem value={"APENAS_NOTURNO"}>
                                                Apenas Noturno
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel>Exibicao Categoria</InputLabel>
                                        <Select
                                            label="Exibicao Categoria"
                                            value={exibicaoCategoriaEnum}
                                            onChange={handleChangeExibicao}
                                        >
                                            <MenuItem value={null}>Todos</MenuItem>
                                            <MenuItem value="DELIVERY">Delivery</MenuItem>
                                            <MenuItem value="PEDIDO_MESA">Pedido Mesa</MenuItem>
                                            <MenuItem value="REGISTRADOR">Registrador</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel>Ativo</InputLabel>
                                        <Select
                                            label="Ativo"
                                            value={ativo}
                                            onChange={handleChangeAtivo}
                                        >
                                            <MenuItem value={true}>Sim</MenuItem>
                                            <MenuItem value={false}>Não</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        label="Qtd Minima De Produtos"
                                        InputLabelProps={{ shrink: true }}
                                        placeholder="000"
                                        inputRef={quantidadeMinimaRef}
                                        defaultValue={modalCategoria?.quantidadeMinima}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        label="Qtd Maxima De Produtos"
                                        InputLabelProps={{ shrink: true }}
                                        placeholder="999"
                                        inputRef={quantidadeMaximaRef}
                                        defaultValue={modalCategoria?.quantidadeMaxima}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleCloseCategoria}
                                className={classes.botaoDescartar}
                            >
                                Voltar
                            </Button>
                            <Button type="submit" className={classes.botaoSalvar}>
                                Salvar
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            )}
        </>
    )
}
