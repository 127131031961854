import { Button, makeStyles, Typography } from "@material-ui/core"
import { useEffect, useState } from "react"
import { UberIcon } from "../assets"
import { CancelButton, SolicitarEntregadorButton } from "../components"

const useStyles = makeStyles(theme => ({
    botaoUber: {
        color: "#FFF",
        backgroundColor: "#000",
        margin: "8px 0",
        "&:hover": {
            backgroundColor: "#000",
        },
    },
    statusEntrega: {
        padding: "12px 0",
    },
    observacaoCancelamento: {
        padding: "4px",
    },
    statusOriginal: {
        display: "none",
    },
}))

export default function DadosEntregadorUber({
    pedido,
    detalhesEntregaUber,
    setAbrirDetalhes,
    setAbrirCancelamento,
    chamarEntregador,
}) {
    const classes = useStyles()

    const [statusEntrega, setStatusEntrega] = useState("")

    useEffect(() => {
        if (detalhesEntregaUber.status) {
            setStatusEntrega(traduzirStatusEntrega(detalhesEntregaUber.status))
            return
        }
        if (pedido.statusEntrega) {
            setStatusEntrega(traduzirStatusEntrega(pedido.statusEntrega))
            return
        }
        // Sempre chama automaticamente, mudar caso haja a opção contrária
        setStatusEntrega("Aguardando entregador")
    }, [detalhesEntregaUber, pedido])

    const traduzirStatusEntrega = status => {
        switch (status) {
            case "pending":
                return "Aguardando entregador"
            case "pickup":
                return "Em retirada"
            case "pickup_complete":
                return "Retirado da loja"
            case "dropoff":
                return "Em entrega"
            case "delivered":
                return "Entregue"
            case "canceled":
                return "Cancelado"
            case "returned":
                return "Retornado"
            case "ongoing":
                return "A caminho"
            default:
                return "Erro ao obter status da entrega"
        }
    }

    function DetalhesEntregaButton() {
        return (
            <Button
                variant="contained"
                onClick={() => {
                    setAbrirDetalhes(true)
                }}
                endIcon={<UberIcon color="#FFF" />}
                className={classes.botaoUber}
            >
                Detalhes
            </Button>
        )
    }

    const textoObservacao = (
        <div className={classes.observacaoCancelamento}>
            <Typography variant="button">Observação</Typography>
            <Typography variant="body1">{detalhesEntregaUber.observacao}</Typography>
        </div>
    )

    if (statusEntrega === "")
        return <SolicitarEntregadorButton chamarEntregador={chamarEntregador} />

    return (
        <>
            <div className={classes.statusEntrega}>
                <Typography variant="h6">Status da Entrega</Typography>
                <Typography variant="body1">{statusEntrega}</Typography>
                <span className={classes.statusOriginal}>{detalhesEntregaUber.status}</span>
            </div>

            {statusEntrega === "Cancelado" && pedido.status >= 0 && (
                <>
                    <SolicitarEntregadorButton chamarEntregador={chamarEntregador} />
                </>
            )}

            {statusEntrega === "Cancelado"
                ? detalhesEntregaUber.observacao && textoObservacao
                : detalhesEntregaUber === {} && (
                      <CancelButton size="small" handleClick={() => setAbrirCancelamento(true)} />
                  )}

            <DetalhesEntregaButton />
        </>
    )
}
