import React, { useContext } from "react"
import { useHistory } from "react-router-dom"

import { useSnackbar } from "notistack"
import RegistradorContext from "../../../../contexts/registradorContext"
import ServicosContext from "../../../../contexts/servicosContext"

import AppBar from "@material-ui/core/AppBar"
import Button from "@material-ui/core/Button"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import Container from "@material-ui/core/Container"
import Dialog from "@material-ui/core/Dialog"
import IconButton from "@material-ui/core/IconButton"
import Slide from "@material-ui/core/Slide"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import CloseIcon from "@material-ui/icons/Close"
import Skeleton from "@material-ui/lab/Skeleton"
import { Card, Col, Row } from "react-bootstrap"

import { RegistrarLogErro } from "../../../../services/clientefiel"

import { valorTotalDaMesa } from "../../functions"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles(theme => ({
    fixed: {
        position: "fixed",
        right: "2em",
        bottom: "2em",
        zIndex: 1000,
    },
    fixedMesa: {
        position: "fixed",
        right: "2em",
        bottom: "2em",
        zIndex: 2000,
    },
    fixedMesaFechar: {
        position: "fixed",
        right: "2em",
        bottom: "7em",
        zIndex: 2000,
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    rootAlert: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
    backdrop: {
        // zIndex: theme.zIndex.drawer + 999999,
        color: "#fff",
    },
    pos: {
        marginBottom: 12,
    },
    heading: {
        width: "100%",
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    rootList: {
        width: "100%!important",
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: "inline",
        width: "100%",
    },
    appBar: {
        position: "fixed",
    },
    appContainer: {
        paddingTop: "6em",
        backgroundColor: "#f4f4f4",
        // height: "100%",
        // minHeight: "-webkit-fill-available",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    list: {
        width: 250,
    },
    fullList: {
        width: "auto",
    },
    cartaoMesaDados: {
        flex: 5,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        padding: "1rem",
        gap: "1rem",
    },
    numeroMesa: {
        flex: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    dadosMesa: {
        flex: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    nomeMesa: {
        width: "40%",
        display: "flex",
        flexDirection: "column",
        "& > span": {
            display: "inline-flex",
            whiteSpace: "nowrap",
            overflow: "hidden",
            margin: "0 auto",
            width: "100%",
            justifyContent: "center",
        },
    },
}))

export default function Index() {
    const autorizacao = localStorage.getItem("chaveSistema")
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    )
    const classes = useStyles()
    const theme = useTheme()
    const history = useHistory()

    const {
        listaRepeticao,
        listaMesas,
        openGerenciarMesa,
        setOpenGerenciarMesa,
        setPedidosDaMesa,
        setOpenGerenciarPedidosMesa,
    } = useContext(RegistradorContext)

    const lista = listaMesas.filter(e => !e.naoTem)

    console.log("listaMesas", lista)
    const { enqueueSnackbar } = useSnackbar()

    const { configuracoes } = useContext(ServicosContext)

    const getNomesMesas = mesa => {
        return mesa.pedidosMesa.reduce((listaNomes, pedido) => {
            if (
                listaNomes.length < 3 &&
                !listaNomes.includes(pedido.cliente.nome) &&
                pedido.cliente.nome !== "Anônimo"
            )
                listaNomes.push(pedido.cliente.nome)

            return listaNomes
        }, [])
    }

    const alertStart = (msg, tipo) => {
        enqueueSnackbar(msg, { variant: tipo })
    }

    const handleCloseGerenciamentoMesa = () => {
        try {
            document.getElementById("procurar-cliente").value = ""
            setOpenGerenciarMesa(false)
        } catch (error) {
            RegistrarLogErro(error, "handleCloseGerenciamentoMesa")
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    return openGerenciarMesa ? (
        <Dialog
            fullScreen
            open={openGerenciarMesa}
            onClose={handleCloseGerenciamentoMesa}
            TransitionComponent={Transition}
        >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleCloseGerenciamentoMesa}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        ({lista.length}) Mesas Abertas
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container className={classes.appContainer}>
                <Row>
                    <Col
                        xs={12}
                        md={12}
                        lg={12}
                        style={{ marginBottom: "0.5em", textAlign: "center" }}
                    >
                        <Button
                            size="small"
                            onClick={() => {
                                setOpenGerenciarMesa(false)
                            }}
                            style={{ color: "#28a745" }}
                        >
                            Voltar
                        </Button>
                    </Col>

                    {listaMesas.length
                        ? listaMesas.map((mesa, index) => (
                              <React.Fragment key={index}>
                                  {mesa?.pedidosMesa?.length ? (
                                      <Col xs={12} md={6} lg={4} style={{ marginBottom: "0.5em" }}>
                                          <Card className={classes.root} style={{ height: "100%" }}>
                                              <CardActionArea
                                                  onClick={() => {
                                                      setPedidosDaMesa(mesa)
                                                      setOpenGerenciarPedidosMesa(true)
                                                  }}
                                                  className={classes.cartaoMesaDados}
                                              >
                                                  <Typography
                                                      variant="h2"
                                                      component="span"
                                                      className={classes.numeroMesa}
                                                  >
                                                      {mesa.numeroMesa}
                                                  </Typography>
                                                  <div className={classes.dadosMesa}>
                                                      <Typography
                                                          variant="body2"
                                                          color="textSecondary"
                                                          component="p"
                                                      >
                                                          {mesa.pedidosMesa?.length} pedidos
                                                      </Typography>
                                                      <Typography
                                                          className={"StatusEstabelecimento"}
                                                          variant="body2"
                                                          component="p"
                                                      >
                                                          {Intl.NumberFormat("pt-BR", {
                                                              style: "currency",
                                                              currency: "BRL",
                                                          }).format(
                                                              valorTotalDaMesa(mesa.pedidosMesa)
                                                          )}
                                                      </Typography>
                                                  </div>
                                                  <div className={classes.nomeMesa}>
                                                      {getNomesMesas(mesa).map(nome => (
                                                          <Typography
                                                              key={nome}
                                                              gutterBottom
                                                              component="span"
                                                          >
                                                              {nome}
                                                          </Typography>
                                                      ))}
                                                  </div>
                                              </CardActionArea>
                                              <CardActions
                                                  style={{ cursor: "pointer", padding: "0" }}
                                              >
                                                  <Typography
                                                      variant={"button"}
                                                      style={{
                                                          textAlign: "center",
                                                          padding: "3px",
                                                      }}
                                                      className={
                                                          configuracoes.user.parametros == null
                                                              ? "botaoCinza naoArredondado"
                                                              : configuracoes.user.parametros
                                                                    .exibirRegistroPedidos
                                                              ? "botaoVerde naoArredondado"
                                                              : "botaoVermelho naoArredondado"
                                                      }
                                                  >
                                                      ABERTA
                                                  </Typography>
                                              </CardActions>
                                          </Card>
                                      </Col>
                                  ) : null}
                              </React.Fragment>
                          ))
                        : listaRepeticao.map((repeticao, index) => (
                              <Col
                                  key={index}
                                  xs={12}
                                  md={6}
                                  lg={4}
                                  style={{ marginBottom: "0.5em" }}
                              >
                                  <Skeleton
                                      variant="rect"
                                      width={"100%"}
                                      height={145}
                                      style={{ marginBottom: "1em" }}
                                  />
                              </Col>
                          ))}
                </Row>
            </Container>
        </Dialog>
    ) : (
        <React.Fragment></React.Fragment>
    )
}
