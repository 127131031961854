import { createMuiTheme, ThemeProvider, useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { Redirect, Route, Switch } from "react-router-dom"
import Package from "../package.json"
import api from "../src/services/api"
import DivulgacoesPainel from "./pages/DivulgacoesPainel"
import Facebook from "./pages/Facebook"

import { criarConfig } from "./services/functionsGerais"

import Caixa from "./pages/Caixa"
import Filter from "./pages/Filter"
import Login from "./pages/Login"
import NotaFiscal from "./pages/NotaFiscal"
import RealizadorDePedidos from "./pages/RegistradorDePedidos"
import Servicos from "./pages/Servicos"

import RecebedorDePedidos from "./pages/RecebedorDePedidos"
import Teste from "./pages/Teste"

import Cardapio from "./pages/Cardapio"
import GerenciadorMesas from "./pages/GerenciadorMesas"
import QRCodeMesa from "./pages/QRCodeMesa"

import GeradorDeQrCode from "./pages/GeradorDeQrCode"
import { RegistrarLogErro } from "./services/clientefiel"

const atualizarAppDados = aplicativoDados => {
    try {
        api.post(
            "clientefiel/AplicativoDadosUrl",
            {
                urlAcesso: aplicativoDados.urlAcesso,
                token: "a~I?;;+_@!AL^",
            },
            {
                headers: { "Content-Type": "application/json" },
            }
        ).then(response => {
            if (!(response && typeof response !== "string")) {
                RegistrarLogErro(
                    "Erro ao procurar aplicativo",
                    "atualizarAppDados",
                    aplicativoDados
                )
                // alertStart(`Erro ao procurar aplicativo`, "error")
                setTimeout(() => {
                    window.location.reload()
                }, 10000)
            } else if (response.data.codErro > 0) {
                // RegistrarLogErro(error,${window')          // alertStart('${window.location.hostname} Não encontrou o aplicativo', 'error');
                // alertStart(`${window.location.hostname} Não encontrou o aplicativo`, 'error');
                setTimeout(() => {
                    window.location.reload()
                }, 10000)
            } else {
                localStorage.setItem("aplicativoCF", JSON.stringify(response.data))
                console.log(" ===== appDados atualizado ===== ")

                //Atualziar estabelecimento atual
                let estabelecimentoAtual = JSON.parse(
                    localStorage.getItem("estabelecimentoAtualCF")
                )
                if (estabelecimentoAtual?.id) {
                    for (let j = 0; response.data.estabelecimentos.length > j; j++) {
                        if (response.data.estabelecimentos[j].id === estabelecimentoAtual.id) {
                            localStorage.setItem(
                                "estabelecimentoAtualCF",
                                JSON.stringify(response.data.estabelecimentos[j])
                            )
                        }
                    }
                }
            }
        })
    } catch (error) {
        alert("Erro nao identificado ao manter aplicativo atualizado")
    }
}

const criarTema = aplicativoDados => {
    return {
        palette: {
            primary: {
                light: `${aplicativoDados.corSitePrimaria}`,
                main: `${aplicativoDados.corSitePrimaria}`,
                contrastText: `#fff`,
            },
            secondary: {
                light: `${aplicativoDados.corSiteSecundaria}`,
                main: `${aplicativoDados.corSiteSecundaria}`,
                contrastText: `#fff`,
            },
            success: {
                light: `#28a745`,
                main: `#28a745`,
                contrastText: `#fff`,
            },
            error: {
                light: `#e57373`,
                main: `#f44336`,
                contrastText: `#fff`,
            },
        },
    }
}

const atualizarInformacoes = aplicativoDados => {
    if (new Date() - new Date(localStorage.getItem("dateAplicativoDadosCF")) > 600000) {
        atualizarAppDados(aplicativoDados)
    }
}

const RouteDadosDeslogado = ({ component: Component, ...rest }) => {
    var aplicativoDados

    try {
        aplicativoDados = JSON.parse(localStorage.getItem("aplicativoCF"))
        if (aplicativoDados.codErro > 0 || typeof aplicativoDados === "string") {
            localStorage.removeItem("aplicativoCF")
            return <Redirect to={"/" + rest.location.search} />
        }
    } catch (error) {
        localStorage.removeItem("aplicativoCF")
        return <Redirect to={"/" + rest.location.search} />
    }

    console.log(
        "RouteDados",
        !!aplicativoDados,
        typeof aplicativoDados !== "string",
        navigator.onLine
    )

    if (!aplicativoDados || aplicativoDados == null) {
        return <Redirect to={"/" + rest.location.search} />
    }
    headAdd(aplicativoDados)

    atualizarInformacoes(aplicativoDados)

    const theme = createMuiTheme(criarTema(aplicativoDados))
    console.log("theme", theme)
    return (
        <ThemeProvider theme={theme}>
            <Route
                {...rest}
                render={props =>
                    aplicativoDados && typeof aplicativoDados !== "string" && navigator.onLine ? (
                        <Component {...props} />
                    ) : (
                        <Redirect to="/" />
                    )
                }
            />
        </ThemeProvider>
    )
}

const headAdd = aplicativoDados => {
    document.title = aplicativoDados.projectName

    const shortCurt = document.createElement("link")
    shortCurt.setAttribute("rel", "shortcut icon")
    shortCurt.setAttribute("sizes", "192x192")
    shortCurt.setAttribute("href", aplicativoDados.urlLogo)
    document.head.insertAdjacentElement("beforeend", shortCurt)

    const shortCurt2 = document.createElement("link")
    shortCurt2.setAttribute("rel", "shortcut icon")
    shortCurt2.setAttribute("sizes", aplicativoDados.corSitePrimaria)
    shortCurt2.setAttribute("href", aplicativoDados.urlLogo)
    document.head.insertAdjacentElement("beforeend", shortCurt2)

    const themeLinkUrl = document.createElement("link")
    themeLinkUrl.setAttribute("rel", "icon")
    themeLinkUrl.setAttribute("href", aplicativoDados.urlLogo)
    document.head.insertAdjacentElement("beforeend", themeLinkUrl)
}

function removeNull(obj) {
    if (Array.isArray(obj)) return obj

    return Object.fromEntries(
        Object.entries(obj)
            .filter(([_, value]) => value != null)
            .map(([key, value]) => [key, value === Object(value) ? removeNull(value) : value])
    )
}

const RouteDadosLogado = ({ component: Component, ...rest }) => {
    var aplicativoDados
    var configs
    var chaveSistema = localStorage.getItem("chaveSistema")

    try {
        aplicativoDados = JSON.parse(localStorage.getItem("aplicativoCF"))
        configs = JSON.parse(localStorage.getItem("configs"))

        if (aplicativoDados.codErro > 0 || typeof aplicativoDados === "string") {
            localStorage.removeItem("aplicativoCF")
            return <Redirect to={"/" + rest.location.search} />
        }

        if (!configs) {
            criarConfig()
        } else if (chaveSistema && !configs.user.id) {
            return <Redirect to={"/?chave=" + chaveSistema} />
        }
    } catch (error) {
        localStorage.removeItem("aplicativoCF")
        return <Redirect to={"/" + rest.location.search} />
    }

    console.log(
        "RouteDados",
        rest,
        !!aplicativoDados,
        typeof aplicativoDados !== "string",
        navigator.onLine
    )

    if (!chaveSistema || !aplicativoDados) {
        localStorage.removeItem("aplicativoCF")
        return <Redirect to={"/"} />
    } else if (rest.path !== "/" && rest.path !== "/login" && !chaveSistema) {
        const configs = JSON.parse(localStorage.getItem("configs"))
        configs.user = {}
        localStorage.clear()
        localStorage.setItem("configs", JSON.stringify(removeNull(configs)))
        return <Redirect to={"/"} />
    }

    headAdd(aplicativoDados)

    atualizarInformacoes(aplicativoDados)

    const theme = createMuiTheme(criarTema(aplicativoDados))
    console.log("theme", theme)

    return (
        <ThemeProvider theme={theme}>
            <Route
                {...rest}
                render={props =>
                    aplicativoDados && typeof aplicativoDados !== "string" && navigator.onLine ? (
                        <Component {...props} />
                    ) : (
                        <Redirect to="/" />
                    )
                }
            />
        </ThemeProvider>
    )
}

const Routes = () => {
    let theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))
    const versaoLancamento = "V" + Package.version
    localStorage.setItem("versao", `${versaoLancamento} | `)
    var aplicativoDados = {}
    try {
        aplicativoDados = JSON.parse(localStorage.getItem("aplicativoCF"))
        if (aplicativoDados.codErro > 0 || typeof aplicativoDados === "string") {
            localStorage.removeItem("aplicativoCF")
        }
    } catch (error) {
        localStorage.removeItem("aplicativoCF")
    }

    console.log("Routes", !!aplicativoDados)

    if (aplicativoDados) {
        theme = createMuiTheme(criarTema(aplicativoDados))
    }
    if (window.caches) {
        try {
            window.caches.keys().then(cacheNames => {
                cacheNames.forEach(cacheName => {
                    caches.delete(cacheName)
                })
            })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="global">
            <span
                id="versao-atual"
                style={{ cursor: "pointer", zIndex: "99999999", color: "white" }}
                onClick={() => {
                    window.location.href = window.location.href
                }}
            >
                {versaoLancamento}
            </span>
            <ThemeProvider theme={theme}>
                <Switch>
                    <Route path="/" exact component={Filter} />
                    <Route path={"/teste"} exact component={Teste} />
                    <Route path={"/login/loginUnificado"} component={Facebook} />

                    <RouteDadosDeslogado path={"/login"} component={Login} />
                    <RouteDadosLogado path="/servicos" exact component={Servicos} />

                    <RouteDadosLogado
                        path={"/servicos/ferramenta/edicao-cardapio/:tela"}
                        component={Cardapio}
                    />

                    <RouteDadosLogado path={"/qr-codes-mesas"} component={QRCodeMesa} />
                    <RouteDadosLogado
                        path={"/servicos/ferramenta/pedido-de-mesa"}
                        component={QRCodeMesa}
                    />

                    <RouteDadosLogado path={"/aceitar-pedidos"} component={RecebedorDePedidos} />
                    <RouteDadosLogado
                        path={"/recebedor-de-pedidos"}
                        component={RecebedorDePedidos}
                    />
                    <RouteDadosLogado
                        path={"/servicos/modulo/recebedor-de-pedidos"}
                        component={RecebedorDePedidos}
                    />

                    <RouteDadosLogado
                        path={"/registrador-de-pedidos"}
                        component={RealizadorDePedidos}
                    />
                    <RouteDadosLogado
                        path={"/servicos/modulo/registrador-de-pedidos"}
                        component={RealizadorDePedidos}
                    />

                    <RouteDadosLogado path={"/servicos/modulo/caixa"} component={Caixa} />
                    <RouteDadosLogado
                        path={"/servicos/modulo/nota-fiscal"}
                        component={NotaFiscal}
                    />

                    <RouteDadosLogado
                        path={"/servicos/modulo/gerenciador-de-mesas"}
                        component={GerenciadorMesas}
                    />

                    <RouteDadosLogado
                        path={"/servicos/modulo/gerador-de-qrcode"}
                        component={GeradorDeQrCode}
                    />

                    <Route component={Filter} />
                </Switch>
                <DivulgacoesPainel />
            </ThemeProvider>
        </div>
    )
}

export default Routes
