import { useSnackbar } from "notistack"
import { useContext, useEffect, useState } from "react"
import CaixaContext from "../../contexts/caixaContext"
import LoadingContext from "../../contexts/loadingContext"
import ServicosContext from "../../contexts/servicosContext"

import { buscarCaixa, obterCaixasAbertos } from "../../services/controlecaixa"
import { buscarestabelecimentos } from "../../services/integracao"

import { RegistrarLogErro, salvarPermissoesCaixaFornecedor } from "../../services/clientefiel"

import MostrarEstabelecimentos from "../Componentes/MostrarEstabelecimentos"
import ModalCaixa from "./modalCaixa"
import MostrarCaixas from "./MostrarCaixas"

import Container from "@material-ui/core/Container"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 999999,
        color: "#fff",
    },

    divChip: {
        marginBottom: "1em",
        textAlign: "center",
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    tipo: {
        display: "flex",
        flexDirection: "column",
    },
    appContainer: {
        paddingTop: "6em",
        backgroundColor: "#f4f4f4",
        // height: "100%",
        // minHeight: "-webkit-fill-available",
    },
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    appBar: {
        position: "fixed",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    trocarEstabelecimento: {
        marginTop: "1em",
        textAlign: "center",
    },

    list: {
        width: "100%",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}))

export default function Caixa() {
    const { configuracoes } = useContext(ServicosContext)
    const theme = useTheme()
    const classes = useStyles()
    const autorizacao = localStorage.getItem("chaveSistema")

    const { setLoading } = useContext(LoadingContext)
    const { enqueueSnackbar } = useSnackbar()
    const [caixaSelecionada, setCaixaSelecionada] = useState({})
    //DECLARAÇÃO DE VARIÁVEIS

    const [estabelecimentos, setEstabelecimentos] = useState([])
    const [controladorTela, setControladorTela] = useState(0) // 0 não exibe nada, 1 exibe os estabelecimentos e 2 exibe os caixas abertos;
    const [estabelecimentoSelecionado, setEstabelecimentoSelecionado] = useState({}) //quando clicar no estabelecimento
    const [caixasAbertos, setCaixasAbertos] = useState([]) //quando clicar no caixa

    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

    console.log("estabelecimentoSelecionado", estabelecimentoSelecionado)

    useEffect(() => {
        handleBuscarDadosEstabelecimentos()
        salvarPermissoesCaixa()
    }, [])

    const salvarPermissoesCaixa = async () => {
        try {
            setLoading(true)
            const response = await salvarPermissoesCaixaFornecedor(
                configuracoes.user.id,
                autorizacao
            )
            console.log("Parametros", response)
            // enqueueSnackbar("Ferramenta em Testes, deixe seu feedback no grupo de suporte!");
        } catch (error) {
            RegistrarLogErro(error, "salvarPermissoesCaixaFornecedor")
        }
    }

    const handleBuscarDadosEstabelecimentos = async () => {
        try {
            setLoading(true)
            const response = await buscarestabelecimentos(autorizacao)
            console.log("buscarestabelecimentos", response)
            if (response.retornoErro) {
                enqueueSnackbar(response.mensagem, { variant: "error" })
                setLoading(false)
            } else {
                if (response.length == 1) {
                    //buscar Caixa direto
                    setEstabelecimentoSelecionado(response[0])
                    setControladorTela(2)
                    handleBuscarCaixasAbertos(response[0])
                } else {
                    //exibir estabelecimentos
                    setControladorTela(1)
                    setLoading(false)
                }
                setEstabelecimentos(response)
            }
        } catch (error) {
            RegistrarLogErro(error, "handleBuscarDadosEstabelecimentos")
        }
    }

    const handleBuscarCaixasAbertos = async estabelecimento => {
        try {
            setLoading(true)
            setEstabelecimentoSelecionado(estabelecimento)
            const caixasAbertosRetorno = await obterCaixasAbertos(estabelecimento.id, autorizacao)
            console.log("---Caixas Abertos--", caixasAbertosRetorno)
            if (caixasAbertosRetorno.status >= 300) {
                enqueueSnackbar(caixasAbertosRetorno.message, { variant: "error" })
            } else {
                if (caixasAbertosRetorno.response.length === 0) {
                    enqueueSnackbar("Nenhum caixa foi encontrado.", { variant: "warning" })
                }
                setCaixasAbertos(caixasAbertosRetorno.response)
                setControladorTela(2)
            }
        } catch (error) {
            RegistrarLogErro(error, "handleBuscarDadosEstabelecimentos")
        }
        setLoading(false)
    }

    const handleBuscarCaixa = async idCaixa => {
        try {
            setLoading(true)
            const buscarCaixaResponse = await buscarCaixa(idCaixa, autorizacao)
            console.log("---Buscar Caixa--", buscarCaixaResponse)
            if (buscarCaixaResponse.status >= 300) {
                enqueueSnackbar(buscarCaixaResponse.message, { variant: "error" })
            } else {
                setCaixaSelecionada(buscarCaixaResponse.response)
            }
        } catch (error) {
            RegistrarLogErro(error, "handleBuscarCaixa")
        }
        setLoading(false)
    }

    //HTML

    return (
        <CaixaContext.Provider
            value={{
                caixaSelecionada,
                setCaixaSelecionada,
                handleBuscarCaixa,
                handleBuscarCaixasAbertos,
                estabelecimentos,
                estabelecimentoSelecionado,
                caixasAbertos,
                setCaixasAbertos,
                setControladorTela,
            }}
        >
            <Container component="main" style={{ marginTop: "4em" }} className="container-buscando">
                {controladorTela === 1 && (
                    <MostrarEstabelecimentos
                        handle={handleBuscarCaixasAbertos}
                        estabelecimentos={estabelecimentos}
                    />
                )}

                {controladorTela === 2 && <MostrarCaixas />}

                {caixaSelecionada.id && <ModalCaixa />}
            </Container>
        </CaixaContext.Provider>
    )
}
