import { createContext, useContext, useEffect, useState } from "react"
import { getJSONParametros } from "../services/clientefiel"

export const ParametrosContext = createContext(null)

export function ParametrosContextProvider(props) {
    const [parametros, setParametros] = useState({})

    const configs = JSON.parse(localStorage.getItem("configs") ?? "{}")
    const idEstabelecimentoAtual = configs?.user?.parametros?.estabelecimento?.id

    useEffect(() => {
        const getParametros = async () => {
            const params = await getJSONParametros(idEstabelecimentoAtual)

            if (params) {
                setParametros(params)
                localStorage.setItem("custom-params", JSON.stringify(params))
            }
        }

        if (idEstabelecimentoAtual) getParametros()
    }, [idEstabelecimentoAtual])

    return (
        <ParametrosContext.Provider value={{ parametros }}>
            {props.children}
        </ParametrosContext.Provider>
    )
}

const useParametros = () => {
    const context = useContext(ParametrosContext)

    if (!context) throw new Error("useParametros must be within a Context Provider")

    return context.parametros
}

export default useParametros
