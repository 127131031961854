import NumberFormat from "react-number-format"

export default function Reais(props) {
    const { inputRef, onChange, ...other } = props

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                console.log("values", values)
                onChange({
                    name: props.name,
                    floatValue: values.floatValue ? values.floatValue : 0,
                })
            }}
            // format={'##,##'}
            thousandSeparator={"."}
            decimalSeparator={","}
            isNumericString
            // prefix="R$ "
        />
    )
}
