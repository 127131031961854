import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Typography,
} from "@material-ui/core"
import { Delete } from "@material-ui/icons"
import { useEffect, useState } from "react"
import { RegistrarLogErro } from "../../../services/clientefiel"
import { EditarPedidoMesa, ExcluirItemDoPedidoMesa } from "../../../services/integracao"

const CardPedido = ({ alertStart, pedidoEnviar, handleClosePedido, atualizarMesas, classes }) => {
    const autorizacao = localStorage.getItem("chaveSistema")
    const [itens, setItens] = useState([])
    const [dialogExcluir, setDialogExcluir] = useState(false)
    const [itemExcluir, setItemExluir] = useState(null)
    const [indexExcluir, setIndexExcluir] = useState(null)

    useEffect(() => {
        pedidoEnviar.itens.forEach(item => {
            const quantidade = item.quantidade
            if (quantidade >= 2) {
                for (let i = 0; i < quantidade; i++) {
                    setItens(prev => [...prev, item])
                }
            } else {
                setItens(prev => [...prev, item])
            }
        })
    }, [])

    const handleToggleDialogExcluir = () => {
        setDialogExcluir(false)
    }

    const excluirItem = () => {
        setItens(prev => {
            const novosItens = [...prev]
            novosItens.splice(indexExcluir, 1)
            return novosItens
        })
    }

    const excluirItemDoPedidoMesa = async () => {
        try {
            const response = await ExcluirItemDoPedidoMesa(
                itemExcluir.id,
                pedidoEnviar,
                autorizacao
            )
            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
                return false
            }
            atualizarMesas()
            excluirItem()
        } catch (error) {
            RegistrarLogErro(error, "excluirItemDoPedidoMesa")
            alertStart(`Procure os desenvolvedores! Erro: ${error.message}`, "error")
        }
    }

    const editarPedidoMesa = async (pedido, item) => {
        const data = {
            id: item.id,
            produto: { id: item.produto.id },
            valorProdutoHistorico:
                item.valorProdutoHistorico - item.valorProdutoHistorico / item.quantidade,
            quantidade: item.quantidade - 1,
        }
        try {
            const response = await EditarPedidoMesa(pedido.id, data, autorizacao)
            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
                return false
            }
            atualizarMesas()
            handleClosePedido()
        } catch (error) {
            RegistrarLogErro(error, "editarPedidoMesa")
            alertStart(`Procure os desenvolvedores! Erro: ${error.message}`, "error")
        }
    }

    const handleClickExcluir = async item => {
        try {
            if (itemExcluir.quantidade >= 2) {
                await editarPedidoMesa(pedidoEnviar, item)
                handleToggleDialogExcluir()
            } else if (pedidoEnviar.itens.length === 1) {
                throw new Error(
                    "Não é permitido excluir um produto de um pedido que possui apenas um item; a operação recomendada é fechar a mesa."
                )
            } else {
                await excluirItemDoPedidoMesa()
                handleToggleDialogExcluir()
            }
        } catch (error) {
            alertStart(error.message, "error")
        }
    }

    return (
        <div className={classes.cardPedido}>
            <Dialog open={dialogExcluir} onClose={handleToggleDialogExcluir}>
                <DialogTitle>Confirmação de exclusão</DialogTitle>
                <DialogContent>
                    <Typography>
                        Essa operação é irreversível, tem certeza que deseja excluir esse item?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        style={{ color: "white", backgroundColor: "#dc3545" }}
                        onClick={handleToggleDialogExcluir}
                    >
                        Descartar
                    </Button>
                    <Button
                        style={{ color: "white", backgroundColor: "#28a745" }}
                        onClick={async () => {
                            await handleClickExcluir(itemExcluir)
                            handleToggleDialogExcluir()
                        }}
                    >
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
            {itens.map((item, index) => (
                <Paper key={index} className={classes.paperCardPedido}>
                    {item.produto.nome}
                    <IconButton
                        onClick={() => {
                            setItemExluir(item)
                            setIndexExcluir(index)
                            setDialogExcluir(true)
                        }}
                    >
                        <Delete />
                    </IconButton>
                </Paper>
            ))}
        </div>
    )
}
export default CardPedido
