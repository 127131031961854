import { useSnackbar } from "notistack"
import React, { useContext } from "react"
import CaixaContext from "../../../contexts/caixaContext"
import LoadingContext from "../../../contexts/loadingContext"

import { fazerRetiradaCaixa } from "../../../services/controlecaixa"

import { formatReal, getMoney } from "../../../services/functionsGerais"

import Button from "@material-ui/core/Button"
import Chip from "@material-ui/core/Chip"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { makeStyles } from "@material-ui/core/styles"

import FormControl from "@material-ui/core/FormControl"
import InputAdornment from "@material-ui/core/InputAdornment"
import InputLabel from "@material-ui/core/InputLabel"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import clsx from "clsx"
import { RegistrarLogErro } from "../../../services/clientefiel"

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
    },
    margin: {
        margin: theme.spacing(1),
    },
    chip: {
        margin: theme.spacing(0.5),
        textTransform: "uppercase",
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: "25ch",
    },
}))

export default function DialogAddRetirada(props) {
    const { setLoading } = useContext(LoadingContext)
    const { enqueueSnackbar } = useSnackbar()
    const autorizacao = localStorage.getItem("chaveSistema")
    const classes = useStyles()
    const [tipoAlteracao, setTipoAlteracao] = React.useState([
        {
            id: 0,
            nome: "Retirar",
            ativo: false,
        },
        {
            id: 1,
            nome: "Adicionar",
            ativo: false,
        },
    ])

    const { caixaSelecionada, setCaixaSelecionada } = useContext(CaixaContext)

    const { open, fullScreen, setOpen } = props

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleFazerRetiradaCaixa = async e => {
        try {
            e.preventDefault()
            setLoading(true)

            const data = {
                valor: parseFloat(document.getElementById("valor").value.replace(",", ".")),
                motivo: document.getElementById("motivo").value,
                tipo: tipoAlteracao.filter(tipo => tipo.ativo)[0].id,
                idCaixa: caixaSelecionada.id,
            }

            console.log("fazerRetiradaCaixa", data)
            const fazerRetiradaCaixaResponse = await fazerRetiradaCaixa(data, autorizacao)
            console.log("fazerRetiradaCaixa", fazerRetiradaCaixaResponse)
            if (fazerRetiradaCaixaResponse.status >= 300) {
                enqueueSnackbar(fazerRetiradaCaixaResponse.message, { variant: "error" })
            } else {
                enqueueSnackbar(fazerRetiradaCaixaResponse.message, { variant: "success" })
                setCaixaSelecionada(fazerRetiradaCaixaResponse.response)
                setOpen(false)
            }
        } catch (error) {
            RegistrarLogErro(error, "fazerRetiradaCaixa")
        }
        setLoading(false)
    }

    const tipoAtivo = tipoAlteracao.filter(tipo => tipo.ativo)[0]

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-alteracao-caixa"
            >
                <form>
                    <DialogTitle id="form-dialog-alteracao-caixa">Alteração no Caixa</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Para um maior controle, digite o valor da retirada e algum motivo
                            referente a retirada ou acrescimo.
                        </DialogContentText>
                        <div className={classes.root}>
                            <FormControl
                                className={clsx(classes.margin)}
                                fullWidth
                                variant="outlined"
                            >
                                <InputLabel htmlFor="valor">Valor</InputLabel>
                                <OutlinedInput
                                    id="valor"
                                    startAdornment={
                                        <InputAdornment position="start">R$</InputAdornment>
                                    }
                                    aria-describedby="valor"
                                    fullWidth
                                    defaultValue="0,00"
                                    onChange={e => {
                                        const valorFinal =
                                            e.target.value === ""
                                                ? "0,00"
                                                : formatReal(getMoney(e.target.value))
                                        e.target.value = valorFinal
                                    }}
                                    inputProps={{
                                        "aria-label": "valor",
                                    }}
                                />
                            </FormControl>

                            <FormControl
                                className={clsx(classes.margin)}
                                fullWidth
                                variant="outlined"
                            >
                                <InputLabel htmlFor="motivo">Motivo</InputLabel>
                                <OutlinedInput
                                    startAdornment={
                                        <InputAdornment position="start">PS</InputAdornment>
                                    }
                                    id="motivo"
                                    aria-describedby="motivo"
                                    inputProps={{
                                        "aria-label": "motivo",
                                    }}
                                    fullWidth
                                />
                            </FormControl>
                        </div>

                        <div className={classes.root}>
                            {tipoAlteracao.map((tipo, index) => (
                                <Chip
                                    key={index}
                                    className={classes.chip}
                                    label={tipo.nome}
                                    color={tipo.ativo ? "primary" : "default"}
                                    onClick={() => {
                                        const novoTipoAlteracao = JSON.parse(
                                            JSON.stringify(tipoAlteracao)
                                        )
                                        novoTipoAlteracao[0].ativo = false
                                        novoTipoAlteracao[1].ativo = false
                                        novoTipoAlteracao[index].ativo = true
                                        setTipoAlteracao(novoTipoAlteracao)
                                    }}
                                />
                            ))}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        {/* <Button onClick={handleClose} color="primary">
              Cancelar
            </Button> */}
                        <Button
                            type="submit"
                            onClick={handleFazerRetiradaCaixa}
                            disabled={!tipoAtivo}
                            color="primary"
                        >
                            {tipoAtivo ? tipoAtivo.nome : "SELECIONAR"}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}
