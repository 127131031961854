import ClienteFielRepresentante from "./ClienteFielRepresentante"
import LocalhostRepresentante from "./LocalhostRepresentante"
import PinnDeliveryRepresentante from "./PinnDeliveryRepresentante"
class RepresentanteSelector {
    getRepresentante() {
        let representantesClass = [
            LocalhostRepresentante,
            PinnDeliveryRepresentante,
            ClienteFielRepresentante,
        ]

        let hostname = window.location.hostname
        for (var i = 0; i < representantesClass.length; i++) {
            let representante = representantesClass[i]
            if (representante.getIdentifier()) {
                if (hostname.includes(representante.getIdentifier())) {
                    return representante
                }
            }
        }

        return ClienteFielRepresentante
    }
}

const classe = new RepresentanteSelector()
export default classe
